import { useEffect, useState } from 'react';

import { timeTrackingApi } from '../../services/timeTrackingApi/timeTrackingApi';
import { optionType } from '../../components/ui/CustomSelect/useSelect';
import {
  ITimeTrackingFilter,
} from './TimeTracking.types';
import { searchParamsToObject } from '../../helpers/object/searchParams-to-object.helper';

export const useTimeTracking = (searchParams: URLSearchParams) => {
  const params: ITimeTrackingFilter =
    searchParamsToObject(searchParams);
  const [filters, setFilters] = useState<ITimeTrackingFilter>(params);

  const [listUsers, setListUsers] = useState<optionType[]>([]);
  const [listProjects, setListProjects] = useState<optionType[]>([]);

  const [globalLoading, setGlobalLoading] = useState(true);

  const fetchLists = async () => {
    try {
      const resProjects =
        await timeTrackingApi.getTimeTrackingProjects();
      const resUsers = await timeTrackingApi.getTimeTrackingUsers();
      setListUsers(
        resUsers.map((e) => {
          return { name: e.id.toString(), label: e.full_name, avatar:e.avatar_url };
        })
      );
      setListProjects(
        resProjects.map((e) => {
          return { name: e.id.toString(), label: e.name };
        })
      );
      setGlobalLoading(false);
    } catch (e) {
      setGlobalLoading(false);
      console.log('error');
    }
  };
  useEffect(() => {
    fetchLists();
  }, []);

  return {
    globalLoading,
    listUsers,
    listProjects,
    filters,
    setFilters,
    params,
  };
};
