import { FC, ReactNode } from 'react';
import styles from './styles.module.css';

import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plus.svg';
interface IAddWrapper {
  title: string;
  children: ReactNode;
  onClose: () => void;
}
export const AddWrapper: FC<IAddWrapper> = ({
  children,
  title,
  onClose,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {/* <div onClick={onClose} className={styles.btnIcon}>
          <PlusIcon />
        </div> */}
      </div>
      <div className={styles.container}>{children}</div>
    </div>
  );
};
