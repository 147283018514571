import * as Yup from 'yup';

export const addTeamMemberSchema = Yup.object().shape({
  tech_stacks: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
      })
    )
    .test('haveItems', 'Enter Tech Stack', (value) => {
      return value && Boolean(value[0]);
    })
    .required('Required'),
  departments: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
      })
    )
    .test('haveItems', 'Enter departments', (value) => {
      return value && Boolean(value[0]);
    })
    .required('Required'),
  positions: Yup.object()
    .shape({
      name: Yup.string(),
    })
    .test('haveItems', 'Enter positions', (value) => {
      return value && Boolean(value.name);
    })
    .required('Required'),
});
