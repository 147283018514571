export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) {
    return bytes + ' B'
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB'
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
  }
}

export const isImageFileType = (file: any) => {
  return file && file.type && file.type.startsWith('image/');
}

export const onMultiSelectChangeHandler = (setFormsData: any, name: string, value: any) => {
  setFormsData((prev: any) => ({
    ...prev,
    [name]: value
  }))
}

export const findLabelByValue = (value: any, optionsData: any) => {
  const selectedOption = optionsData?.find((option: { value: any }) => option.value.toString() === value);
  return selectedOption ? selectedOption.label : ''
}

export const onSelectChangeHandler = (setFormsData: any, name: string, value: any) => {
  setFormsData((prev: any) => ({
    ...prev,
    [name]: value.value
  }))
}
