import { FC, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './Calendar.module.css';
import { Calendar } from '../../../../../ui/Calendar/Calendar';
import { useDate } from '../../../../../ui/DatePicker/useDate';
import { ReactComponent as MessageIcon } from '../../../../../../assets/icons/message-circle.svg';
import moment from 'moment';

export const CalendarComponent: FC<{
  date?: string;
  onClick: (e: React.MouseEvent) => void;
  comment?: string;
}> = ({ onClick, date, comment }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      {date && (
        <p className={styles.title}>
          {moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY') || ''}
        </p>
      )}
      {comment && <p className={styles.comment}>{comment}</p>}
    </div>
  );
};

export interface ICalendarSelect {
  onClick?: () => void;
  initialDate?: Date;
  initialComment?: string;
  onFetch?: (date?: string, comment?: string) => void;
}
export const CalendarSelect: FC<ICalendarSelect> = ({
  onClick,
  initialDate,
  initialComment = '',
  onFetch,
}) => {
  const {
    date,
    handlePrevMonthButtonClick,
    handleNextMonthButtonClick,
    monthData,
    handleDayClick,
    includedDate,
    selectedDate,
    currentDate,
    setDate,
  } = useDate(initialDate);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [visiblePlug, setVisiblePlug] = useState(true);
  const [textAreaValue, setTextAreaValue] = useState(initialComment);

  const handleButtonClick = () => {
    setVisiblePlug(false);
    inputRef.current?.focus();
  };

  const absoluteStyles = clsx(
    styles.absolute,
    visiblePlug && styles.visible
  );

  const handleOnBlur = () => {
    if (
      onFetch &&
      JSON.stringify(`${initialDate}${initialComment}`) !==
        JSON.stringify(`${selectedDate}${textAreaValue}`)
    ) {
      onFetch(
        moment(selectedDate).format('YYYY-MM-DD') !== 'Invalid date'
          ? moment(selectedDate).format('YYYY-MM-DD')
          : undefined,
        textAreaValue
      );
    }
  };

  const [isLeave, setIsLeave] = useState(false);
  return (
    <div
      tabIndex={Math.random()}
      onMouseLeave={() => setIsLeave(true)}
      onMouseEnter={() => setIsLeave(false)}
      onBlur={() => isLeave && handleOnBlur()}
      className={styles.wrapper}>
      <Calendar
        date={date}
        handlePrevMonthButtonClick={handlePrevMonthButtonClick}
        handleNextMonthButtonClick={handleNextMonthButtonClick}
        monthData={monthData}
        setDate={setDate}
        handleDayClick={handleDayClick}
        currentDate={currentDate}
        additionalStyles={(date: Date) =>
          includedDate(date, selectedDate)
        }
      />
      <div className={styles.relative}>
        <textarea
          value={textAreaValue}
          onChange={(e) => setTextAreaValue(e.target.value)}
          ref={inputRef}
          onBlur={() => !textAreaValue && setVisiblePlug(true)}
          className={styles.textArea}
        />
        <div onClick={handleButtonClick} className={absoluteStyles}>
          <div className={styles.absoluteContainer}>
            <MessageIcon />
            <p className={styles.plugTitle}>Comment here</p>
          </div>
        </div>
      </div>
    </div>
  );
};
