export const secondsToHM = (seconds: number | string) => {
  if (typeof seconds === 'string') return seconds;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}`;

  return formattedTime;
};
