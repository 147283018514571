import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { authApi } from '../../../services/authApi';
import routes from '../../../constants/routes';

import AuthFormContainer from '../../AuthFormContainer';
import PasswordInput from '../../helpers/PasswordInput';
import Input from '../../ui/Input';
import Button from '../../ui/Button';
import Divider from '../../ui/Divider';
import FormControl from '../../ui/FormControl';
import InputHelpText from '../../ui/InputHelpText';
import Typography from '../../ui/Typography';

import dogNewspaperImage from '../../../assets/image/dog-newspaper.svg';

import styles from './PasswordRecoveryPage.module.css';

export type PasswordRecoveryFormInputs = {
  new_password: 'string'
  confirm_password: 'string'
}

const PasswordRecoveryPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('jwt');
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<PasswordRecoveryFormInputs>({});
  const newPassword = watch('new_password');

  const onSubmit = (data: PasswordRecoveryFormInputs) => {
    if (token) {
      authApi.forgotPasswordSetNewPassword({ new_password: data.new_password, token })
        .then(() => {
          navigate(routes.signIn);
        });
    }
  };

  return (
    <AuthFormContainer>
      <Typography component="h4" align="center" variant="subHeading1">
        Create a new password
      </Typography>
      <div className={styles.imageWrap}>
        <img src={dogNewspaperImage} alt="" width={250} height={250} />
      </div>
      <Divider className={styles.divider} />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Controller
            control={control}
            name="new_password"
            rules={{
              required: { value: true, message: 'Password is required' },
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              validate: value =>
                value === newPassword || 'The passwords do not match'
            }}
            render={() => (
              <PasswordInput
                label="New Password"
                {...register('new_password')}
              />
            )}
          />
          {errors.new_password && (
            <InputHelpText hasError>
              {errors.new_password.message}
            </InputHelpText>
          )}
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="confirm_password"
            rules={{
              required: { value: true, message: 'Password is required' },
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              validate: value =>
                value === newPassword || 'The passwords do not match'
            }}
            render={() => (
              <Input
                type="password"
                label="Confirm New Password"
                placeholder="****************"
                {...register('confirm_password')}
              />
            )}
          />
          {errors.confirm_password && (
            <InputHelpText hasError>
              {errors.confirm_password.message}
            </InputHelpText>
          )}
        </FormControl>
        <Button disabled={isSubmitting} fullWidth color="black" type="submit">
          Change password
        </Button>
      </form>
    </AuthFormContainer>
  );
};

export default PasswordRecoveryPage;