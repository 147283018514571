import { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';

import Modal from '../../../../../ui/Modal';
import Button from '../../../../../ui/Button';
import CloseModalButton from '../../../../../ui/CloseModalButton/CloseModalButton';

import styles from './SharedResourcesModal.module.css';
import { commonApi } from '../../../../../../services/commonApi/commonApi';
import { optionType } from '../../../../../ui/CustomSelect/useSelect';
import { CustomSelect } from '../../../../../ui/CustomSelect/CustomSelect';
import { hrApi } from '../../../../../../services/hrApi/hrApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../../ui/Snackbar/constants';
import { SharedResourcesSchema } from './SharedResourcesSchema';

interface AvailableResource {
  email: optionType[];
  date: string | undefined;
}

interface SharedResourcesModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  availables: number[];
}

const SharedResourcesModal: FC<SharedResourcesModalProps> = ({
  isOpen,
  onClose,
  availables,
}) => {
  const initialValues: AvailableResource = {
    email: [],
    date: undefined,
  };

  const [listUsers, setListUsers] = useState<optionType[]>([]);
  const fetchList = async () => {
    const res = await commonApi.getUsers({
      departments_or_positions: ['Customer Success', 'Management', 'Founders']
    });
    console.log('res', res)
    setListUsers(
      res
        .map((e) => {
          return { name: e.email_corporate, label: e.full_name };
        })
    );
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <Modal
      className={styles.oveflowOff}
      isOpen={isOpen || false}
      onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={SharedResourcesSchema}
        isInitialValid={false}
        validateOnMount
        onSubmit={async (values, formikHelpers) => {
          try {
            formikHelpers.setSubmitting(true);
            await hrApi.sendEmails({
              user_ids_for_send: availables,
              emails_to: values.email.map((e) => e.name),
            });
            enqueueSnackbar('Sent successfully', {
              variant: 'success',
              anchorOrigin: snackbarPosTopRight,
            });
            formikHelpers.setSubmitting(false);
          } catch (e: any) {
            enqueueSnackbar(e.response.data.detail, {
              variant: 'error',
              anchorOrigin: snackbarPosTopRight,
            });
            formikHelpers.setSubmitting(false);
          }
        }}>
        {({
          setFieldValue,
          values,
          errors,
          submitCount,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => (
          <div className={styles.container}>
            <div className={styles.titleWrapper}>
              <span className={styles.titleStyle}>
                Share Available Resources
              </span>
              <CloseModalButton onClick={onClose} />
            </div>
            <span className={styles.infoStyle}>
              The list of available resources will be sent to the
              below recipients via email
            </span>
            <div className={styles.mTop24}>
              {listUsers.length && (
                <CustomSelect
                  withoutAnimation
                  multiSearch
                  multiSearchPlaceholder='Type to search'
                  detailedInputStyle={styles.customInput}
                  detailedInput
                  options={listUsers}
                  defaultOptions={values.email}
                  onChangeMultiValue={(e) =>
                    setFieldValue('email', e)
                  }
                  multi
                  placeholder='Select recipient'
                  label='Email to'
                  error={errors.email as string}
                  wasRequest={Boolean(submitCount)}
                />
              )}
              {/* <DatePicker
                label='Scheduled email'
                classNames={styles.datePickerWrapper}
                dateProp={
                  values.date
                    ? moment(values.date, 'YYYY-MM-DD').toDate()
                    : values.date
                }
                onChange={(e) => setFieldValue('date', e)}
                isInput
                placeholder='Choose Date'
                formatDate='DD MMMM YYYY'
              /> */}
              <Button
                className={styles.mTop6}
                disabled={!isValid || isSubmitting}
                fullWidth
                color='black'
                onClick={() => handleSubmit()}>
                Confirm
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default SharedResourcesModal;
