import styles from './Counter.module.css';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { FC } from 'react';

interface ICounter {
  value: number;
  setValue: (i: number) => void;
  maxNum?: number;
}
const Counter: FC<ICounter> = ({ value, setValue, maxNum = 31 }) => {
  const handleCountDown = () => {
    value && setValue(value - 1);
  };
  const handleCountUp = () => {
    value < maxNum && setValue(value + 1);
  };
  return (
    <div className={styles.container}>
      <div
        onClick={handleCountDown}
        className={styles.containerIconLeft}>
        <ChevronDownIcon />
      </div>
      <p className={styles.counterValue}>{value}</p>
      <div
        onClick={handleCountUp}
        className={styles.containerIconRight}>
        <ChevronDownIcon />
      </div>
    </div>
  );
};

export default Counter;
