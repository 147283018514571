import { useEffect, useState } from 'react';
import styles from './DatePicker.module.css';
import { getMonthData } from '../../../helpers/date/get-month-date.helper';

export const useDate = (
  initialDate?: Date,
  minDate?: Date,
  maxDate?: Date,
  dateProp?: string | Date,
  handleCloseOptions?: () => void
) => {
  const getMonth = () => date.getMonth();
  const getYear = () => date.getFullYear();
  const [date, setDate] = useState(new Date());

  const currentDate = new Date().toDateString();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialDate
  );
  useEffect(() => {
    setSelectedDate(initialDate);
  }, [dateProp]);

  const handlePrevMonthButtonClick = () => {
    const date = new Date(getYear(), getMonth() - 1);

    setDate(date);
  };
  const handleNextMonthButtonClick = () => {
    const date = new Date(getYear(), getMonth() + 1);

    setDate(date);
  };

  const handleClearRange = () => {
    setSelectedDate(undefined);
  };

  const handleDayClick = (date: Date) => {
    setSelectedDate(date);
    handleCloseOptions && handleCloseOptions();
  };
  const includedDate = (date: Date, selectedDate?: Date) => {
    if (selectedDate) {
      if (`${date}` === `${selectedDate}`)
        return `${styles.includedBoxRight} ${styles.round}`;
    }
  };

  const monthData = getMonthData(
    getYear(),
    getMonth(),
  );

  return {
    date,
    handlePrevMonthButtonClick,
    handleNextMonthButtonClick,
    monthData,
    handleDayClick,
    includedDate,
    selectedDate,
    currentDate,
    handleClearRange,
    setDate,
  };
};
