import { EmployeeRecentProjectsRowProps } from './EmployeeRecentProjectsRowProps';
import styles from './EmployeeRecentProjectsRow.module.css';
import TableCell from '../../../ui/TableCell';
import TableRow from '../../../ui/TableRow';
import Typography from '../../../ui/Typography';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import moment from 'moment';
import { CategoryCard } from '../../../HR/Bookkeping/CategoryCard/CategoryCard';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../constants/routes';

const EmployeeRecentProjectsRow = ({
  data,
}: EmployeeRecentProjectsRowProps) => {
  const getPeriod = (startDate?: string, endDate?: string) => {
    const parseDate = (date: string) =>
      moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
    if (startDate) {
      if (endDate) {
        return `${parseDate(startDate)} - ${parseDate(endDate)}`;
      }
      return parseDate(startDate);
    }
    return '';
  };

  const navigate = useNavigate();

  const navigateToProjectProfile = () => {
    navigate(routes.projectsProfile + `/${data.project_id}`);
  };
  return (
    <TableRow
      onClick={navigateToProjectProfile}
      className={styles.rowStyles}>
      <TableCell
        align='left'
        className={`${styles.rootCell} ${styles.rowLeftBorder}`}>
        <div className={styles.containerDateCell}>
          <div className={styles.row}>
            <div
              style={{ backgroundColor: data.color }}
              className={styles.emojiWrapper}>
              <Emoji
                unified={data.emoji}
                emojiStyle={EmojiStyle.APPLE}
                size={20}
              />
            </div>
            {/* <p className={styles.projectName}></p>
            <div className={styles.dot} /> */}
            <p className={styles.projectName}>{data.name}</p>
          </div>
          {data.start_date && (
            <p className={styles.projectDate}>
              {getPeriod(data.start_date, data.end_date)}
            </p>
          )}
        </div>
      </TableCell>
      <TableCell className={styles.rootCell}>
        <CategoryCard type={data.status as 'In Progress'} />
      </TableCell>
      <TableCell className={styles.rootCell}>
        <Typography variant={'smallText'} color={'gray'}>
          {data?.position_name}
        </Typography>
      </TableCell>
      <TableCell
        align='center'
        className={`${styles.rootCell} ${styles.rowRightBorder}`}>
        <Typography variant={'smallText'} color={'gray'}>
          {data?.involvement_type}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default EmployeeRecentProjectsRow;
