import Modal from '../../ui/Modal';
import Stack from '../../ui/Stack';
import Button from '../../ui/Button';
import Typography from '../../ui/Typography';

import image from '../../../assets/image/delete.png';

import styles from './DeleteModal.module.css';
import { TrashIcon } from '../../../assets/icons/components';

type DeleteModalProps = {
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

export default function DeleteModal(
  {
    title = 'Do you want to delete this item?',
    description = 'Are you sure you want to delete this item? This action isirreversible.',
    isOpen,
    onClose,
    onDelete
  }: DeleteModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.root}>
      <Typography variant="subHeading1" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="smallText" align="center">
        {description}
      </Typography>
      <div className={styles.imageWrapper}>
        <img src={image} alt="" width={172} height={172} />
      </div>
      <Stack justify="between">
        <Button
          color="black"
          variant="outlined"
          className={styles.button}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          className={styles.button}
          startIcon={TrashIcon}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Stack>
    </Modal>
  );
}