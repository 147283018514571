import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { IGetUsersResponse } from '../../../../../../services/commonApi/types';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import { commonApi } from '../../../../../../services/commonApi/commonApi';
import styles from './Managers.module.css';

export const useManagers = (
  selectedItems: IGetUsersResponse[],
  isOpen?: boolean,
  onFetch?: (items: IGetUsersResponse[]) => void,
  oneItem?: boolean,
  position?: string,
  positions?: string[],
  department?: string,
  departments_or_positions?: string[]
) => {
  const [selected, setSelected] =
    useState<IGetUsersResponse[]>(selectedItems);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<IGetUsersResponse[] | undefined>(
    undefined
  );

  const handleSelectItem = (item: IGetUsersResponse) => {
    if (selected?.find((el) => el.id === item.id)) return;
    if (oneItem) return setSelected([item]);
    setSelected((prev) => [...prev, item]);
  };
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const getList = async () => {
    try {
      const res = await commonApi.getUsers({
        position,
        positions,
        department,
        departments_or_positions,
        search: searchValue,
      });
      setList(res);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (position ||
      positions ||
      department ||
      departments_or_positions) &&
      isOpen &&
      getList();
  }, [debouncedSearchValue]);
  const handleRemoveItem = (item: IGetUsersResponse) => {
    setSelected((prev) => prev?.filter((el) => el.id !== item.id));
  };

  const handleOnBlur = () => {
    if (
      onFetch &&
      JSON.stringify(selected) !== JSON.stringify(selectedItems)
    ) {
      onFetch(selected);
    }
  };
  const cardStyles = clsx(styles.avatar, styles.bgGray);
  const height =
    30 +
    40 *
      ((list?.length && (list?.length > 5 ? 5 : list?.length)) || 1);
  return {
    handleOnBlur,
    handleRemoveItem,
    handleSelectItem,
    selected,
    setSearchValue,
    searchValue,
    loading,
    list,
    cardStyles,
    height,
  };
};
