import { FC, useState } from 'react';
import moment from 'moment';

import styles from './DateCalendar.module.css';
import { Calendar } from '../../../../../ui/Calendar/Calendar';
import { useDate } from '../../../../../ui/DatePicker/useDate';

export const DateCalendarComponent: FC<{
  onClick: (e: React.MouseEvent) => void;
  date: string;
}> = ({ onClick, date }) => {
  const getJoinedMonth = () => {
    const joined = moment(date, 'YYYY-MM-DD');
    const today = moment();
    const years = today.diff(joined, 'years');
    const months = today.diff(joined, 'months') - years * 12;
    const format = (years: number, months: number): string => {
      const yearStr =
        years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
      const monthStr =
        months > 0
          ? `${months} month${months > 1 ? 's' : ''}`
          : years
          ? ''
          : 'this month';
      return `${joined.format('DD MMMM YYYY')} (${yearStr}${
        years && months ? ' ' : ''
      }${monthStr})`;
    };
    return years > 0 ? format(years, months) : format(0, months);
  };

  return (
    <div className={styles.container} onClick={onClick}>
      {getJoinedMonth()}
    </div>
  );
};

export interface IDateCalendarSelect {
  onClick?: () => void;
  startDate?: string;
  endDate?: string;
  minDate?: Date;
  maxDate?: Date;
  onFetch?: (date: string) => void;
  handleShowOptions?: () => void;
  dateProp?: string;
  formatDate?: string;
}
export const DateCalendarSelect: FC<IDateCalendarSelect> = ({
  // onClick,
  minDate,
  maxDate,
  onFetch,
  // handleShowOptions,
  dateProp,
  formatDate = 'YYYY-MM-DD',
}) => {
  const initialDate =
    typeof dateProp === 'string'
      ? moment(dateProp, formatDate).toDate()
      : dateProp;

  const {
    date,
    handlePrevMonthButtonClick,
    handleNextMonthButtonClick,
    monthData,
    handleDayClick,
    includedDate,
    selectedDate,
    currentDate,
    setDate,
  } = useDate(initialDate, minDate, maxDate, dateProp);

  const [isLeave, setIsLeave] = useState(false);
  return (
    <div
      tabIndex={Math.random()}
      onMouseLeave={() => setIsLeave(true)}
      onMouseEnter={() => setIsLeave(false)}
      onBlur={() => {
        if (isLeave) {
          if (`${initialDate}` !== `${selectedDate}`) {
            onFetch &&
              onFetch(moment(selectedDate).format('YYYY-MM-DD'));
          }
        }
      }}
      className={styles.wrapper}>
      <Calendar
        date={date}
        setDate={setDate}
        handlePrevMonthButtonClick={handlePrevMonthButtonClick}
        handleNextMonthButtonClick={handleNextMonthButtonClick}
        monthData={monthData}
        handleDayClick={handleDayClick}
        currentDate={currentDate}
        additionalStyles={(date: Date) =>
          includedDate(date, selectedDate)
        }
      />
    </div>
  );
};
