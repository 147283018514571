import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import {snackbarPosTopRight} from '../../../ui/Snackbar/constants';

import { IProposal } from '../../../../services/proposalApi/types';

import Modal from '../../../ui/Modal';
import Typography from '../../../ui/Typography';
import Button from '../../../ui/Button';
import Stack from '../../../ui/Stack';
import Loader from '../../../ui/Loader';
import IconButton from '../../../ui/IconButton';
import ProposalStatus from '../ProposalStatus';

import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';

import styles from './ProposalInfo.module.css';

type ProposalInfoProps = {
  data: IProposal | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function ProposalInfo({ data, isOpen, onClose }: ProposalInfoProps) {
  const handleCopy = () => {
    navigator.clipboard.writeText(data?.password_code || '');
    enqueueSnackbar('Password copied ', {
      variant: 'success',
      anchorOrigin: snackbarPosTopRight,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {data ? (
        <div className={styles.root}>
          <Typography variant="subHeading1" weight="bold">
            Proposal Info
          </Typography>
          <Stack direction="column" space={0} align="start">
            <Typography color="muted" variant="mediumLabel" gutterBottom>Status</Typography>
            <ProposalStatus status={data.status} />
          </Stack>
          <div>
            <Typography color="muted" variant="mediumLabel" gutterBottom>Client name</Typography>
            <Typography>{data.client_name}</Typography>
          </div>
          <div>
            <Typography color="muted" variant="mediumLabel" gutterBottom>Client title</Typography>
            <Typography>{data.client_position || '-'}</Typography>
          </div>
          <div>
            <Typography color="muted" variant="mediumLabel" gutterBottom>Client contact</Typography>
            <Typography>{data.client_contact}</Typography>
          </div>
          <div>
            <Typography color="muted" variant="mediumLabel" gutterBottom>Last Viewed</Typography>
            <Typography>
              {data.viewed_at ? moment(data.viewed_at).format('DD.MM.YYYY HH:mm') : '-'}
            </Typography>
          </div>
          <div>
            <Typography color="muted" variant="mediumLabel" gutterBottom>Accepted</Typography>
            <Typography>
              {data.accepted_at ? moment(data.accepted_at).format('DD.MM.YYYY HH:mm') : '-'}
            </Typography>
          </div>
          <div>
            <Typography color="muted" variant="mediumLabel">Password</Typography>
            <Stack fullWidth justify="between" align="center">
              <span>********</span>
              <IconButton icon={CopyIcon} onClick={handleCopy} />
            </Stack>
          </div>
          <div>
            <Button color="black" onClick={onClose}>Close</Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}
