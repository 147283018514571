import {  useState } from 'react';
import Table from '../../../../ui/Table';
import TableCell from '../../../../ui/TableCell';
import TableRow from '../../../../ui/TableRow/TableRow';
import TablePagination from '../../../../ui/TablePagination';
import Plug from '../Plug';
import styles from './Table.module.css';
import HumanResourcesHeader from '../Header/Header';
import HumanResourcesTableRow from './TableRow/TableRow';
import SharedResourcesModal from './SharedResourcesModal/SharedResourcesModal';
import { IHRListParams } from '../../../../../services/hrApi/types';
import { DepartmentList } from '../DepartmentList/DepartmentList';
import { useTable } from './useTable';
import Typography from '../../../../ui/Typography/Typography';
import Stack from '../../../../ui/Stack/Stack';
import Button from '../../../../ui/Button/Button';
import AddTeamMemberButton from '../../../../AddTeamMemberButton/AddTeamMemberButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import routes from '../../../../../constants/routes';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/icons/archive.svg';
import { useGetQueryParams } from '../../../../../utils/common';
import { ArchiveAccountModal } from '../../../../HR/Bookkeping/Modals/ArchiveAccount/ArchiveAccount';
const HumanResourcesTable = () => {
  const params: IHRListParams = useGetQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);

  const handleShowArchiveModal = () => {
    setArchiveModalIsOpen((prev) => !prev);
  };

  const {
    filter,
    setFilter,
    searchValue,
    setSearchValue,
    positions,
    handleArchiveUser,
    handleRemoveUser,
    // handleChangeActivity,
    data,
    total,
    // listIsLoading,
    listIsEmpty,
    listParams,
    // totalAvailability,
  } = useTable(searchParams, setSearchParams);

  const [
    shareAvailableResourcesIsOpen,
    setShareAvailableResourcesIsOpen,
  ] = useState(false);

  const onModalCloseHandler = () => {
    setShareAvailableResourcesIsOpen(false);
  };

  const navigate = useNavigate();

  const onArchiveClickHandler = () => {
    navigate(routes.humanResourcesArchive);
  };
  const [availables, setAvailables] = useState<number[]>([]);

  const handleChangeActivity = (id: number, isAvailable: boolean) => {
    if (!isAvailable) {
      setAvailables((prev) => prev.filter((e) => e !== id));
      return;
    }
    setAvailables((prev) => {
      return [...prev, id];
    });
  };

  const handleResetAvailables = () => {
    setAvailables([]);
  };
  return (
    <>
      <div className={styles.title}>
        <Typography variant='h5' weight='bold'>
          Employees
        </Typography>
        <Stack space={2} align='center'>
          <Button
            color='secondary'
            onClick={onArchiveClickHandler}
            className={styles.btn}>
            <div className={styles.iconArchive}>
              <ArchiveIcon />
            </div>
            <p>Archive</p>
          </Button>
          <AddTeamMemberButton data={listParams()} />
        </Stack>
      </div>

      <div>
        <div className={styles.navContainer}>
          <DepartmentList
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            total={total}
            params={params}
            data={data}
            positions={positions}
          />
        </div>
        <HumanResourcesHeader
          totalAvailability={availables.length}
          filter={filter}
          setFilter={setFilter}
          handleResetAvailables={handleResetAvailables}
          onClickView={() => {
            console.log('clicked view');
          }}
          onResourcesClickHandler={() => {
            setShareAvailableResourcesIsOpen(true);
          }}
        />
        <SharedResourcesModal
          availables={availables}
          isOpen={shareAvailableResourcesIsOpen}
          onClose={onModalCloseHandler}
        />
        {!listIsEmpty ? (
          <>
            <Table tableContainerClass={styles.table}>
              <thead>
                <TableRow className={styles.thead}>
                  <TableCell component='th'></TableCell>
                  <TableCell
                    columnClass={styles.clear}
                    component='th'
                    sortBy='first_name'>
                    Name
                  </TableCell>
                  <TableCell component='th' sortBy='departments'>
                    Department
                  </TableCell>
                  <TableCell component='th' sortBy='positions'>
                    Position
                  </TableCell>
                  <TableCell component='th' sortBy='seniority_level'>
                    Seniority
                  </TableCell>
                  <TableCell component='th'>Email</TableCell>
                  <TableCell component='th'>Phone</TableCell>
                  <TableCell component='th'></TableCell>
                </TableRow>
              </thead>
              <tbody>
                {data?.items.map((item) => (
                  <HumanResourcesTableRow
                    key={item.id}
                    isAvailable={availables.includes(item.id)}
                    item={item}
                    handleArchiveUser={handleArchiveUser}
                    handleRemoveUser={handleRemoveUser}
                    handleChangeActivity={handleChangeActivity}
                  />
                ))}
              </tbody>
            </Table>
            <TablePagination
              searchParams={searchParams}
              params={params}
              setSearchParams={setSearchParams}
              currentStartPage={data?.page}
              count={data?.total}
              itemsPerPage={data?.size}
            />
          </>
        ) : (
          <Plug />
        )}
        <ArchiveAccountModal
          isOpen={archiveModalIsOpen}
          handleShowModal={handleShowArchiveModal}
        />
      </div>
    </>
  );
};

export default HumanResourcesTable;
