const getDomainFromURL = (url: string) => {
  try {
    const urlObject = new URL(url);
    const domain = urlObject?.hostname;
    return domain;
  } catch (e) {
    return false;
  }
};

export { getDomainFromURL };
