import { ReactNode, useEffect } from 'react';
import clsx from 'clsx';

import Portal from '../../Portal';

import styles from './Modal.module.css';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  className?:  any;
  size?: boolean;
  children?: ReactNode;
  classNameOverlay?: string;
}

const Modal = ({
  isOpen,
  onClose,
  className,
  children,
  classNameOverlay,
    size
}: ModalProps) => {
  const classNames = clsx(styles.root, className, size && styles.rootFull);
  const classNamesOverlay = clsx(
    styles.overlay,
    styles.transition,
    classNameOverlay
  );

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const modalContent = document.querySelector(`.${styles.root}`);
      if (
        modalContent &&
        !modalContent.contains(event.target as Node)
      ) {
        onClose?.();
      }
    };
    if (isOpen)
      document.addEventListener('click', handleOutsideClick);
    return () =>
      document.removeEventListener('click', handleOutsideClick);
  }, []);

  return isOpen ? (
    <Portal>
      <div className={classNames}>{children}</div>
      <div className={classNamesOverlay} onClick={onClose} />
    </Portal>
  ) : null;
};

export default Modal;
