import { FC } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';

import { PaletteAvatar } from '../PaletteAvatar/PaletteAvatar';
import styles from './ProjectUpdateData.module.css';
import Button from '../../ui/Button/Button';
import { CustomSelect } from '../../ui/CustomSelect/CustomSelect';
import FormikInput from '../../ui/FormikInput';
import { projectUpdateSchema } from './ProjectUpdateData.schema';
import {
  ICreateNewProjectFormData,
  IInitialValues,
} from '../../../pages/Projects/ProjectProfile/CreateNewProfile/types';
import { projectServiceSelect } from '../../../common/values/values';

interface IProjectUpdateData {
  buttonTitle: string;
  inputStyle?: string;
  handleSubmitForm?: (data: ICreateNewProjectFormData) => void;
  initialData?: {
    emoji?: string;
    color?: string;
    name?: string;
    client_full_name?: string;
    services?: string[];
  };
}

export const ProjectUpdateData: FC<IProjectUpdateData> = (props) => {
  const { buttonTitle, inputStyle, handleSubmitForm, initialData } =
    props;

  const initialValues: IInitialValues = {
    name: initialData?.name || '',
    emoji: initialData?.emoji || '1f978',
    color: initialData?.color || '#E6F7F6',
    client_full_name: initialData?.client_full_name || '',
    services:
      initialData?.services?.map((e) => {
        return { name: e };
      }) || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        const object: any = { ...values };
        object.services = values.services.map((e) => e.name);

        handleSubmitForm && handleSubmitForm(object);
      }}
      validationSchema={projectUpdateSchema}
      validateOnMount
      isInitialValid={Boolean(initialData)}
      enableReinitialize>
      {({
        values,
        setFieldValue,
        errors,
        submitForm,
        submitCount,
        isSubmitting,
        isValid,
        resetForm,
      }) => {
        return (
          <>
            <PaletteAvatar
              selectedColor={values.color}
              setSelectedColor={(e) => setFieldValue('color', e)}
              selectedEmoji={values.emoji}
              setSelectedEmoji={(e) => setFieldValue('emoji', e)}
            />
            {/* 
            <CustomSelect
         label={'Client'}
         placeholder='Choose Client'
         classNameOptions={styles.containerOptions}
         detailed={{
           title: 'Select one or more person',
           multi: true,
           options: projectServiceManagers,
           selected: form.client,
           search: false,
           onChangeValue: useForm('client'),
           btn: true,
           btnTitle: 'Add new client',
         }}
         detailedInput
       /> */}
            <div className={clsx(styles.wrapperInputs, inputStyle)}>
              <FormikInput
                required
                label={'Client'}
                onChange={(e) =>
                  setFieldValue('client_full_name', e.target.value)
                }
                value={values.client_full_name}
                placeholder='Client Full Name'
                error={errors.client_full_name}
                wasRequest={Boolean(submitCount)}
              />
              <FormikInput
                required
                label={'Project Name'}
                onChange={(e) =>
                  setFieldValue('name', e.target.value)
                }
                value={values.name}
                placeholder='Enter Project Name'
                error={errors.name}
                wasRequest={Boolean(submitCount)}
              />
              <CustomSelect
                required
                label={'Services'}
                options={projectServiceSelect.map((e) => {
                  return { name: e.value };
                })}
                multi
                placeholder='Choose Services'
                onChangeMultiValue={(e) =>
                  setFieldValue('services', e)
                }
                multiAllTitle='All Services'
                defaultOptions={values.services}
                detailedInput
                notAutoHeight
                menuAbove
                error={errors.services as string}
                wasRequest={Boolean(submitCount)}
              />
            </div>
            <Button
              disabled={!isValid || isSubmitting}
              onClick={submitForm}
              fullWidth
              color='black'>
              {buttonTitle}
            </Button>
          </>
        );
      }}
    </Formik>
  );
};
