import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Breadcrumbs.module.css';

import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron-right.svg';
import { useNavigate } from 'react-router-dom';
interface IBreadcrumbs {
  routeName: string;
  itemComponent?: ReactNode;
  indexItemComponent?: number;
}

interface IBreadcrumb {
  item: { title: string; image?: string; navigate: string };
  isLastIndex: boolean;
}

const Breadcrumb: FC<IBreadcrumb> = ({ item, isLastIndex }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => !isLastIndex && navigate(item.navigate)}
      className={styles.container}>
      <div
        className={clsx(
          styles.containerTitle,
          isLastIndex && styles.lastTitle
        )}>
        {item.title}
      </div>
      {!isLastIndex && (
        <div className={styles.containerIcon}>
          <ChevronIcon />
        </div>
      )}
    </div>
  );
};

const Breadcrumbs: FC<IBreadcrumbs> = ({
  routeName,
  itemComponent,
  indexItemComponent,
}) => {
  const breadcrumsData = routeName
    .split(/\/(?![^(]*\))/g)
    .map((el) => {
      const splitted = el.split('$', 3);

      return {
        title: splitted[0],
        image: splitted[2]?.slice(1, -1),
        navigate: splitted[1]?.slice(1, -1),
      };
    });

  return (
    <div className={styles.wrapper}>
      {breadcrumsData.map((item, index, arr) =>
        index === indexItemComponent && itemComponent ? (
          itemComponent
        ) : (
          <Breadcrumb
            key={index}
            item={item}
            isLastIndex={index === arr.length - 1}
          />
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
