import Typography from '../../components/ui/Typography/Typography';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import { Table } from '../../components/HumanResources/Archive/Table/Table';

import routes from '../../constants/routes';
import styles from './Archive.module.css';
// import { useAppDispatch } from '../../store';

const HumanResourcesArchive = () => {
  return (
    <div className={styles.root}>
      <Breadcrumbs
        routeName={`Employees$(${routes.humanResources})/Archive$(${routes.humanResourcesArchive})`}
      />
      <div className={styles.title}>
        <Typography variant='h5' weight='bold'>
          Archive
        </Typography>
      </div>
      <Table />
    </div>
  );
};

export default HumanResourcesArchive;
