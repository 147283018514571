import moment, { Moment } from 'moment';
import { TimeSheetUser } from '../../../../services/timeTrackingApi/types';
import { ITimeTrackingFilter } from '../../../../pages/TimeTracking/TimeTracking.types';

export const useConvertingDate = (filters: ITimeTrackingFilter) => {
  const YMDtoDate = (date: string) => moment(date, 'YYYY-MM-DD');
  const dateToYMD = (date: Moment) =>
    moment(date).format('YYYY-MM-DD');

  const daysBetweenDates = (startDate: string, endDate: string) => {
    return YMDtoDate(startDate).diff(YMDtoDate(endDate), 'days');
  };

  const getDateDaysRange = (date: string, numDays: number) => {
    const dateArray: string[] = [];
    Array.from({ length: numDays + 1 }).forEach((_, index) => {
      dateArray.push(
        YMDtoDate(date).add(index, 'days').format('YYYY-MM-DD')
      );
    });
    return dateArray;
  };

  const getDateMonthRange = (
    startDate: string,
    daysToSplit: number
  ) => {
    let currentDate: Moment;
    const dateRanges = [];
    if (startDate.includes(' - ')) {
      currentDate = moment(startDate.split(' - ')[0]);
    } else {
      currentDate = moment(startDate);
    }

    while (daysToSplit > 0) {
      const endOfMonth = moment(currentDate).endOf('month');
      const remainingDaysInMonth =
        endOfMonth.diff(currentDate, 'days') + 1;
      const daysToAdd = Math.min(remainingDaysInMonth, daysToSplit);

      const endDate = moment(currentDate).add(daysToAdd - 1, 'days');

      dateRanges.push(
        `${dateToYMD(currentDate)} - ${dateToYMD(endDate)}`
      );

      currentDate = moment(endDate).add(1, 'day');
      daysToSplit -= daysToAdd;
    }

    return dateRanges;
  };

  const getDateYearRange = (
    startDate: string,
    daysToSplit: number
  ) => {
    const result = [];
    let currentDate = moment(startDate);

    while (daysToSplit > 0) {
      const endOfYear = moment(currentDate).endOf('year');
      const daysInYear = endOfYear.diff(currentDate, 'days') + 1;
      const daysToAdd = Math.min(daysToSplit, daysInYear);

      const endDate = moment(currentDate).add(daysToAdd - 1, 'days');
      result.push(
        `${dateToYMD(currentDate)} - ${dateToYMD(endDate)}`
      );

      currentDate = moment(endDate).add(1, 'day');
      daysToSplit -= daysToAdd;
    }

    return result;
  };

  function getDateWeekRange(date: string, numDays: number) {
    const dateIntervals = [];
    let currentDate = moment(date);
    const endDate = moment(date).add(numDays, 'days');

    while (currentDate.isBefore(endDate)) {
      const nextSunday = currentDate.clone().endOf('isoWeek');
      const nextIntervalEnd = moment.min(nextSunday, endDate.clone());

      if (currentDate.isSame(nextIntervalEnd)) {
        dateIntervals.push(currentDate.format('YYYY-MM-DD'));
      } else {
        dateIntervals.push(
          `${currentDate.format(
            'YYYY-MM-DD'
          )} - ${nextIntervalEnd.format('YYYY-MM-DD')}`
        );
      }

      currentDate = nextIntervalEnd.add(1, 'days');
    }

    if (
      dateIntervals[dateIntervals.length - 1].split(' - ')[1] !==
      endDate.format('YYYY-MM-DD')
    ) {
      dateIntervals.push(
        `${endDate.format('YYYY-MM-DD')} - ${endDate.format(
          'YYYY-MM-DD'
        )}`
      );
    }

    return dateIntervals;
  }

  const convertingDate = (data: TimeSheetUser[]) => {
    const newObject = {};

    // getDate
    data.forEach((e) => {
      Object.assign(newObject, e.dates);
    });

    // set Date
    const set = new Set(Object.keys(newObject));
    const array = Array.from(set);

    //sorting Date
    let sorted: string[] = [];

    let days = 0;

    if (filters.date_from) {
      sorted = [filters.date_from];
      days = daysBetweenDates(
        filters.date_to ?? filters.date_from,
        filters.date_from
      );
    } else {
      sorted = array.sort(
        (a, b) => YMDtoDate(a).unix() - YMDtoDate(b).unix()
      );
      if (sorted[0].includes(' - ')) {
        days = daysBetweenDates(
          sorted[sorted.length - 1].split(' - ')[1],
          sorted[0].split(' - ')[0]
        );
        sorted[0] = sorted[0].split(' - ')[0];
      } else {
        days = daysBetweenDates(sorted[sorted.length - 1], sorted[0]);
      }
    }

    // get Diff days

    // breakdown by days, weeks, months and years

    if (days <= 14) {
      return {
        sortedDate: getDateDaysRange(sorted[0], days),
        format: 'day',
      };
    }
    if (days <= 35) {
      return {
        sortedDate: getDateWeekRange(sorted[0], days),
        format: 'week',
      };
    }
    if (days <= 731) {
      return {
        sortedDate: getDateMonthRange(sorted[0], days + 1),
        format: 'month',
      };
    }
    if (days > 731) {
      return {
        sortedDate: getDateYearRange(sorted[0], days + 1),
        format: 'year',
      };
    }
  };

  const getInterval = () => {
    if (filters.date_from) {
      const days = daysBetweenDates(
        filters.date_to ?? filters.date_from,
        filters.date_from
      );

      if (days <= 14) return 'day';
      if (days <= 35) return 'week';
      if (days <= 731) return 'month';
      if (days > 731) return 'year';
    }
    return 'month';
  };

  const parseHeadCellDate = (date: string, format: string) => {
    if (format === 'day') {
      return YMDtoDate(date).format('MMM D');
    }

    const splittedDate = date.split(' - ');
    const startDate = YMDtoDate(splittedDate[0]);
    const endDate = YMDtoDate(splittedDate[1]);

    if (format === 'week') {
      if (startDate.format('MMM D') === endDate.format('MMM D')) {
        return endDate.format('MMM D');
      }
      return `${startDate.format('MMM D')} - ${endDate.format(
        'MMM D'
      )}`;
    }
    if (format === 'month') {
      const amountDays = startDate.daysInMonth();
      if (amountDays === endDate.diff(startDate, 'days') + 1) {
        return startDate.format('MMM YYYY');
      }

      return `${startDate.format('MMM D YYYY')} - ${endDate.format(
        'MMM D YYYY'
      )}`;
    }

    if (format === 'year') {
      const year = startDate.year();
      const daysInYear = moment(`${year}-12-31`, 'YYYY-MM-DD').diff(
        moment(`${year}-01-01`, 'YYYY-MM-DD'),
        'days'
      );

      if (daysInYear === endDate.diff(startDate, 'days')) {
        return startDate.format('MMM YYYY');
      }

      return `${startDate.format('MMM D YYYY')} - ${endDate.format(
        'MMM D YYYY'
      )}`;
    }
  };

  return { convertingDate, parseHeadCellDate, getInterval };
};
