import React, {useState} from 'react';
import { DateRange} from 'react-date-range';
import clsx from 'clsx';
import moment from 'moment';
import Button from '../Button/Button';
import Menu from '../Menu';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import {useMenu} from '../../../hooks/useMenu';

import {DateRangePickerInputProps} from './DateRangePickerInput.model';

import {ReactComponent as CalendarIcon} from '../../../assets/icons/calendar.svg';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import classes from './DateRangePickerInput.module.css';


const DateRangePickerInput = ({
                                  variant = 'text',
                                  label,
                                  error,
                                  startDate,
                                  endDate,
                                  startDateName,
                                  endDateName,
                                  onChange,
                                  onSetFieldValue,
                                  className,
                              }: DateRangePickerInputProps) => {
    const {isOpen, anchorEl, handleMenuClose, handleMenuOpen} = useMenu();
    const [range, setRange] = useState([{
        startDate: moment(startDate || new Date()).toDate(),
        endDate: moment(endDate || new Date()).toDate(),
        key: 'selection',
    }]);

    const handleChange = (date: any) => {
        const d = new Date();
        if (onSetFieldValue) {
            const normalizeDate = (date: any) => moment(date).set('hours', d.getHours()).set('minutes', d.getMinutes());
            onSetFieldValue(startDateName, normalizeDate(date.selection.startDate).format());
            onSetFieldValue(endDateName, normalizeDate(date.selection.endDate).format());
        }
        if (onChange) onChange(date.selection);
        setRange([date.selection]);
    };

    return (
        <div>
            {
                label && <InputLabel>{label}</InputLabel>
            }
            <Button
                fullWidth
                component="span"
                variant={variant}
                className={clsx([classes.button, {[classes.error]: error}, className])}
                onClick={handleMenuOpen}
            >
                <CalendarIcon/>
                <span>{startDate||endDate  ?`${moment(startDate || new Date()).format('DD.MM.YYYY')} - ${moment(endDate || new Date()).format('DD.MM.YYYY')}` : 'All time'}</span>
            </Button>
            {
                error && <InputError>{error}</InputError>
            }
            <Menu
                position="bottomRight"
                isOpen={isOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                className={classes.menuDate}
            >
                    <DateRange  showPreview={false} ranges={range}
                              onChange={handleChange}/>
            </Menu>

        </div>
    );
};

export default DateRangePickerInput;
