const buildFormData = (
  formData: FormData,
  data: any,
  parentKey?: string,
  isArray?: boolean
) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key, index) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? parentKey : key,
        typeof data?.name === 'string'
      );
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(
      (isArray ? `${parentKey}[]` : parentKey) || '',
      value
    );
  }
};

export const jsonToFormData = (data: any) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};
