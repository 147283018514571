import { FC, useState } from 'react';

import { PlusIcon } from '../../../components/SvgIcons';
import styles from './QuickFilter.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { useSelect } from '../../../hooks/useSelect';
import { QuickFilterList } from './QuickFilterList/QuickFilterList';
import { FilterModal } from './FilterModal/FilterModal';

interface IQuickFilter<T> {
  ModalComponent: FC<{
    onCloseModal: () => void;
    data?: T;
    handleSetUpFilter?: (e: any) => void;
    additionalOptions?: any;
  }>;
  btnDotOn?: boolean;
  filter?: T;
  onClickDeleteFilter?: (name: string) => void;
  handleClickCurrentFilter?: (e: T) => void;
  additionalOptions?: object;
}
export const QuickFilter: FC<IQuickFilter<any>> = ({
  ModalComponent,
  btnDotOn,
  filter,
  onClickDeleteFilter,
  handleClickCurrentFilter,
  additionalOptions,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [data, setData] = useState<any>();
  const onCloseModal = () => {
    setShowModal(false);
  };

  const handleCreateFilter = () => {
    setData(undefined);
    quickFilterListSelect.handleShowOptions();
    setShowModal(true);
  };

  const handleOpenCurrentFilter = (e: any) => {
    quickFilterListSelect.handleShowOptions();
    handleClickCurrentFilter && handleClickCurrentFilter(e);
  };

  const handleSetUpFilter = (e: any) => {
    onCloseModal();
    handleClickCurrentFilter && handleClickCurrentFilter(e);
  };

  const handleDeleteFilter = (e: any) => {
    onClickDeleteFilter && onClickDeleteFilter(e);
  };

  const handleOpenEditFilter = (e: any) => {
    setData(e);
    quickFilterListSelect.handleShowOptions();
    setShowModal((prev) => !prev);
  };

  const quickFilterListSelect = useSelect(
    QuickFilterList as FC,
    {
      filters: filter,
      handleCreateFilter,
      handleOpenCurrentFilter,
      handleOpenEditFilter,
      handleDeleteFilter,
    },
    styles.menuDate
  );

  const handleShowFiltersList = (e: React.MouseEvent) => {
    quickFilterListSelect.handleShowOptions(e);
  };

  return (
    <div>
      <div className={styles.relative}>
        <Button
          onClick={handleShowFiltersList}
          className={styles.rootFilterBtn}>
          <PlusIcon />
          <p>Add Filter</p>
          {btnDotOn && <div className={styles.dot} />}
        </Button>
        {quickFilterListSelect.component}
      </div>
      <FilterModal
        showModal={showModal}
        onCloseModal={onCloseModal}
        data={data}
        handleSetUpFilter={handleSetUpFilter}
        additionalOptions={additionalOptions}
        ModalComponent={ModalComponent}
      />
      {/* <Modal
        className={styles.maxWidthModal}
        isOpen={showModal}
        onClose={onCloseModal}>
        <ModalComponent
          data={data}
          handleSetUpFilter={handleSetUpFilter}
          onCloseModal={onCloseModal}
          additionalOptions={additionalOptions}
        />
      </Modal> */}
    </div>
  );
};
