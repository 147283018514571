const routes = {
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  passwordRecovery: '/password_recovery',
  home: '/',
  dashboard: '/human-resources',
  policyHolidays: '/policy/holidays',
  policyConditions: '/policy/conditions',
  holidays: '/human-resources/holidays',
  policyEdit: '/human-resources/holidays/edit',
  complaints: '/support',
  employeeProfile: '/human-resources/employees/employee-profile',
  employeeDocuments:
    '/human-resources/employees/employee-profile/:id/documents',
  addNewMember: '/add-new-member',
  createProject: '/create-project',
  editProject: '/edit-project',
  humanResources: '/human-resources/employees',
  leave: '/human-resources/leave',
  bench: '/human-resources/bench',
  benchMember: '/human-resources/bench/member',
  dashboardInfo: '/dashboard',
  humanResourcesArchive: '/human-resources/archive',
  sickLeave: '/human-resources/employees/employee-profile/:id/sick-leave',
  dayOff: '/human-resources/employees/employee-profile/:id/day-off',
  hrDashboard: '/hr/',
  hrBookKeping: '/hr/book-keping',
  hrBKAnalytics: '/hr/book-keping/analytics',
  hrInvoices: '/hr/invoices',
  projectsTable: '/projects',
  proposalsPage: '/proposals',
  projectsTimeTracking: '/projects/time-tracking',
  timeTracking: '/time-tracking',
  requests: '/human-resources/requests',
  projectsCreate: '/projects/create',
  projectsProfile: '/projects/profile',
  daysOff: '/human-resources/daysOff',
  myProfile: '/my-profile',
  mySickLeave: '/my-profile/sick-leave',
  myDayOff: '/my-profile/day-off',
};

export default routes;
