import { useEffect, useState } from 'react';
import { ICondition } from '../../../services/policyApi/types';
import { policyApi } from '../../../services/policyApi/policyApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopLeft } from '../../../components/ui/Snackbar/constants';
import { optionType } from '../../../components/ui/CustomSelect/useSelect';
import { useModal } from '../../../hooks/useModal';

export const useConditions = () => {
  const handleCreateCondition = () => handleOpenModal();

  const [list, setList] = useState<ICondition[]>([]);

  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);

  const fetchList = async () => {
    const res = await policyApi.getConditions();
    setList(res);
    setLoading(false);
  };
  useEffect(() => {
    fetchList();
  }, [render]);

  const [data, setData] = useState<ICondition | undefined>(undefined);

  const handleEditCondition = async (data: ICondition) => {
    await setData(data);
    await handleOpenModal();
  };
  const handleOpenDeleteCondition = async (data: ICondition) => {
    setData(data);
    openDeleteModal();
  };
  const handleDeleteCondition = async () => {
    try {
      data?.id && (await policyApi.deleteCondition(data.id));
      enqueueSnackbar('Condition successfully deleted', {
        variant: 'success',
        anchorOrigin: snackbarPosTopLeft,
      });
      closeDeleteModal();
      setRender((prev) => prev + 1);
    } catch (e: any) {
      enqueueSnackbar(e.response.data.detail, {
        variant: 'error',
        anchorOrigin: snackbarPosTopLeft,
      });
      closeDeleteModal();
    }
  };

  const handleCloseModal = (e?: 'render') => {
    setData(undefined);
    close();
    if (e === 'render') setRender((prev) => prev + 1);
  };

  const defaultOption = { period: [], type: [] };

  const [options, setOptions] = useState<{
    period: optionType[];
    type: optionType[];
  }>(defaultOption);

  const { isOpen, open: handleOpenModal, close } = useModal();
  const {
    isOpen: isOpenDelete,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();

  const handleCloseDeleteModal = () => {
    setData(undefined);
    closeDeleteModal();
  };

  const handleChangeValue = (
    name: 'period' | 'type',
    option: optionType[]
  ) => {
    setOptions((prev) => {
      prev[name] = option;
      return { ...prev };
    });
  };

  const filterList = (array: ICondition[]) => {
    return (
      options.type.length > 0
        ? array.filter((e) =>
            options.type.some((filter) =>
              e.forms_of_coops.includes(filter.name)
            )
          )
        : array
    ).filter((e) =>
      typeof options.period[0]?.value === 'number'
        ? options.period[0]?.value === e.years_of_cooperation
        : true
    );
  };

  const handleResetFilter = () => {
    setOptions(defaultOption);
  };
  return {
    handleCreateCondition,
    options,
    handleChangeValue,
    handleResetFilter,
    handleEditCondition,
    list,
    handleOpenDeleteCondition,
    filterList,
    data,
    isOpen,
    isOpenDelete,
    handleCloseModal,
    handleCloseDeleteModal,
    handleDeleteCondition,
    loading,
  };
};
