import { useEffect, useState } from 'react';
import { IProjectTag } from '../../../../../../services/projectsApi/types';
import { projectsApi } from '../../../../../../services/projectsApi/projectsApi';
import { useDebounce } from '../../../../../../hooks/useDebounce';

export const useTagsFetch = (
  baseUrl: string | undefined,
  isOpen: boolean | undefined,
  onFetch?: (items: { name: string; id: number }[]) => void,
  selectedItems?: { name: string; id: number }[]
) => {
  const [selected, setSelected] = useState(selectedItems || []);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<IProjectTag[] | undefined>(
    undefined
  );

  const selectOpen = useDebounce(isOpen, 50);
  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    baseUrl && selectOpen && getList(baseUrl, debouncedSearchValue);
  }, [debouncedSearchValue]);

  const handleSelectItem = (item: { name: string; id: number }) => {
    if (selected.find((el) => el.id === item.id)) return;
    setSelected((prev) => [...prev, item]);
  };

  const handleDeleteItem = async (id: number) => {
    if (baseUrl) {
      try {
        const res = await projectsApi.deleteProjectTag(baseUrl, {
          tag_id: id,
          confirm: true,
        });
        setList((prev) => prev?.filter((el) => el.id !== id));
        setSelected((prev) => prev.filter((el) => el.id !== id));
      } catch {
        console.log('red');
      }
    }
  };
  const getList = async (baseUrl: string, search?: string) => {
    try {
      const res = await projectsApi.getProjectTags(baseUrl, search);
      setList(res);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRemoveItem = (item: { name: string; id: number }) => {
    setSelected((prev) => prev.filter((el) => el.id !== item.id));
  };

  const handleOnBlur = () => {
    if (
      onFetch &&
      JSON.stringify(selected) !== JSON.stringify(selectedItems)
    ) {
      onFetch(selected);
    }
  };

  const handleAddTag = async (event: React.KeyboardEvent) => {
    if (searchValue && baseUrl && event.key === 'Enter') {
      try {
        const res = await projectsApi.addProjectTag(baseUrl, {
          name: searchValue,
        });
        if (res.msg === 'ok') {
          getList(baseUrl, searchValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  return {
    handleOnBlur,
    handleRemoveItem,
    handleAddTag,
    handleSelectItem,
    handleDeleteItem,
    selected,
    setSearchValue,
    searchValue,
    loading,
    list,
  };
};
