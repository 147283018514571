import queryString from 'query-string';
import API from '../http';
import { ITimeTrackingApi } from './types';

export const timeTrackingApi: ITimeTrackingApi = {
  getTimeSheetUsers: (data) =>
    API.get(
      `/time-tracking/timesheet/users?${queryString.stringify(data)}`
    ),
  getTimeSheetProjects: (data) =>
    API.get(
      `/time-tracking/timesheet/projects?${queryString.stringify(
        data
      )}`
    ),
  getWorkSummaryProjects: (data) =>
    API.get(
      `/time-tracking/work-summary/projects?${queryString.stringify(
        data
      )}`
    ),
  getWorkSummaryMembers: (data) =>
    API.get(
      `/time-tracking/work-summary/users?${queryString.stringify(
        data
      )}`
    ),
    getTimeTrackingSubmissions: (data) => API.get(`/time-tracking/submissions?${queryString.stringify(data)}`),
    postTimeTrackingSubmissions: (data) => API.post('/time-tracking/submission', data),

  getTimeTrackingUsers: () => API.get('/time-tracking/users'),
  getTimeTrackingCurrentUser: (data) => API.get(`/time-tracking/timesheet/projects?${queryString.stringify(data)}`),
  getTimeTrackingCurrentProjects: (data) => API.get(`/time-tracking/timesheet/users?${queryString.stringify(data)}`),
    deleteTimeTrackingSubmission: (id: number) => API.delete(`/time-tracking/submission?id=${id}`),
  getTimeTrackingProjects: () => API.get('/time-tracking/projects'),
};
