import {formsOfCoops} from '../../../../common/values/values';
import {hrApi} from '../../../../services/hrApi/hrApi';
import {
    IMembersProfile,
    IMembersProfileInfoEditRequest,
} from '../../../../services/hrApi/types';
import {
    DescriptionSelect,
    IDescriptionSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Description/Description';
import {
    IManagersSelect,
    ManagersSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Managers/Managers';
import {
    ITagsSelect,
    TagsSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Tags/Tags';
import {useSelect} from '../../../Projects/Profile/ProfileInformation/useSelect/useSelect';
import styles from './Selects.module.css';
import {
    IWorkScheduleSelect,
    WorkScheduleSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/WorkSchedule/WorkSchedule';
import {
    DateRangeSelect,
    IDateRangeSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/DateRange/DateRange';
import {
    DateCalendarSelect,
    IDateCalendarSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/DateCalendar/DateCalendar';
import {useSelector} from 'react-redux';
import {getPositionsSelector} from '../../../../store/app/selectors';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopRight} from '../../../ui/Snackbar/constants';
import {
    ILanguagesSelect,
    LanguagesSelect,
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Languages/Languages';

export const useSelects = (
    membersProfile: IMembersProfile,
    setMembersProfile: React.Dispatch<
        React.SetStateAction<IMembersProfile>
    >
) => {
    const positions = useSelector(getPositionsSelector);
    const fetchDescription = async (
        item: keyof IMembersProfileInfoEditRequest,
        data: IMembersProfileInfoEditRequest,
        isArray?: unknown,
        arrayName?: string
    ) => {
        try {
            const filteredData: any = Object.entries(data).reduce((acc: any, [key, value]) => {
                acc[key] = value !== '' ? value : null;
                return acc;
            }, {});
            const res = await hrApi.membersProfileInfoEdit(filteredData);
            if (res.msg) {
                const res = await hrApi.getMembersProfile({user_id: data?.user_id});
                setMembersProfile(() => {
                    const obj: any = {...res};
                    if (!arrayName && data[item]) {
                        obj[item] = data[item];
                    } else {
                        if (arrayName && Array.isArray(isArray))
                            obj[arrayName] =
                                arrayName === 'hr_manager' ? isArray[0] : isArray;
                    }
                    return obj;
                });
                enqueueSnackbar('Successfully changed', {
                    variant: 'success',
                    anchorOrigin: snackbarPosTopRight,
                });
            }
        } catch (e: any) {
            if (typeof e.response.data.detail === 'string') {
                enqueueSnackbar(e.response.data.detail, {
                    variant: 'error',
                    anchorOrigin: snackbarPosTopRight,
                });
                return;
            }
            enqueueSnackbar('Validation error', {
                variant: 'error',
                anchorOrigin: snackbarPosTopRight,
            });
        }
    };

    const fetchRange = async (
        from: keyof IMembersProfileInfoEditRequest,
        to: keyof IMembersProfileInfoEditRequest,
        data: IMembersProfileInfoEditRequest
    ) => {
        try {
            const res = await hrApi.membersProfileInfoEdit(data);
            if (res.msg) {
                setMembersProfile((prev) => {
                    const obj: any = {...prev};
                    obj[from] = data[from];
                    obj[to] = data[to];

                    return obj;
                });
            }
            enqueueSnackbar('Successfully changed', {
                variant: 'success',
                anchorOrigin: snackbarPosTopRight,
            });
        } catch (e: any) {
            if (typeof e.response.data.detail === 'string') {
                enqueueSnackbar(e.response.data.detail, {
                    variant: 'error',
                    anchorOrigin: snackbarPosTopRight,
                });
                return;
            }
            enqueueSnackbar('Validation error', {
                variant: 'error',
                anchorOrigin: snackbarPosTopRight,
            });
        }
    };
    const managersSelect = useSelect<IManagersSelect>(
        ManagersSelect,
        {
            selectedItems: membersProfile.hr_manager
                ? [membersProfile.hr_manager]
                : [],
            departments_or_positions: [
                'HR Manager',
                'Management',
                'CEO',
                'COO',
                'CPO',
                'Creative Director',
                'CTO',
                'CFO',
            ],
            menuTitle: 'Select manager',
            oneItem: true,
            onFetch: (e) => {
                fetchDescription(
                    'hr_manager_id',
                    {
                        hr_manager_id: e[0]?.id ? e[0].id : null,
                        user_id: membersProfile.id,
                    },
                    e,
                    'hr_manager'
                );
            },
        },
        styles.cardInfoManagers
    );

    const workEmailSelect = useSelect<IDescriptionSelect>(
        DescriptionSelect,
        {
            text: membersProfile.email_corporate,
            onFetch: (e) => {
                fetchDescription('email_corporate', {
                    email_corporate: e.trim(),
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoDescription
    );

    const personalEmailSelect = useSelect<IDescriptionSelect>(
        DescriptionSelect,
        {
            text: membersProfile.email_personal,
            onFetch: (e) => {
                fetchDescription('email_personal', {
                    email_personal: e.trim(),
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoDescription
    );
    const phoneNumberSelect = useSelect<IDescriptionSelect>(
        DescriptionSelect,
        {
            text: membersProfile.phone,
            onFetch: (e) => {
                fetchDescription('phone', {
                    phone: e,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoDescription
    );
    const addressSelect = useSelect<IDescriptionSelect>(
        DescriptionSelect,
        {
            text: membersProfile.address,
            onFetch: (e) => {
                fetchDescription('address', {
                    address: `${e}`,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoDescription
    );
    const deliverySelect = useSelect<IDescriptionSelect>(
        DescriptionSelect,
        {
            text: membersProfile.delivery_address,
            onFetch: (e) => {
                fetchDescription('delivery_address', {
                    delivery_address: `${e}`,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoDescription
    );

    const cooperationSelect = useSelect<ITagsSelect>(
        TagsSelect,
        {
            list: formsOfCoops,
            selectedItems: membersProfile.forms_of_coops?.map((item) => {
                return {name: item};
            }),
            onFetch: (e) => {
                fetchDescription('forms_of_coops', {

                    forms_of_coops: e,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoManagers,
        undefined,
        true
    );
    const workScheduleSelect = useSelect<IWorkScheduleSelect>(
        WorkScheduleSelect,
        {
            from: membersProfile.working_time_from,
            to: membersProfile.working_time_to,
            onFetch: (from?: string, to?: string) => {
                fetchRange('working_time_from', 'working_time_to', {
                    working_time_from: from,
                    working_time_to: to,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardWorkSchedule,
        undefined,
        true
    );
    const probationDateSelect = useSelect<IDateRangeSelect>(
        DateRangeSelect,
        {
            startDate: membersProfile.probation_date_from,
            endDate: membersProfile.probation_date_to,
            onFetch: (from: string, to: string) => {
                fetchRange('probation_date_from', 'probation_date_to', {
                    probation_date_from: from,
                    probation_date_to: to,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardCalendar,
        undefined,
        true
    );
    const dateCalendarSelect = useSelect<IDateCalendarSelect>(
        DateCalendarSelect,
        {
            dateProp: membersProfile.joined_at,
            maxDate: new Date(),
            onFetch: (date: string) => {
                fetchDescription('joined_at', {
                    joined_at: date,
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardCalendar,
        undefined,
        true
    );

    const skillsSelect = useSelect<ITagsSelect>(
        TagsSelect,
        {
            list: positions?.tech_stacks,
            selectedItems: membersProfile.tech_stacks,
            color: '#551BCE',
            bgColor: '#F0EAFC',
            onFetch: (e) => {
                fetchDescription('tech_stacks', {
                    tech_stacks: e.map((e) => {
                        return {name: e};
                    }),
                    user_id: membersProfile.id,
                });
            },
        },
        styles.cardInfoTag,
        undefined,
        true
    );
    const languagesSelect = useSelect<ILanguagesSelect>(
        LanguagesSelect,
        {
            onFetch: (data) => {
                fetchDescription('languages', {
                    languages: data,
                    user_id: membersProfile.id,
                });
            },
            selectedItems: membersProfile.languages,
        },
        styles.cardLanguages
    );

    return {
        managersSelect,
        workEmailSelect,
        personalEmailSelect,
        phoneNumberSelect,
        addressSelect,
        deliverySelect,
        cooperationSelect,
        workScheduleSelect,
        probationDateSelect,
        dateCalendarSelect,
        skillsSelect,
        languagesSelect,
    };
};
