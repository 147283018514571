import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import AsideItem from '../AsideItem/AsideItem';
import { NavItem } from '../Aside.constants';
import styles from './AsideItemDrop.module.css';
import { NavLink, useLocation } from 'react-router-dom';
interface IAsideItemDrop {
  item: NavItem;
  isMenuOpen: boolean;
  isMenuOverlayOpen: boolean;
}

export const AsideItemDrop: FC<IAsideItemDrop> = ({
  item,
  isMenuOpen,
  isMenuOverlayOpen,
}) => {
  const location = useLocation();

  const [openDropDown, setOpenDropDown] = useState(
    item.keyLabel ? location.pathname.includes(item.keyLabel) : false
  );
  useEffect(() => {
    if (item.keyLabel) {
      setOpenDropDown(location.pathname.includes(item.keyLabel));
    }
  }, [location.pathname]);
  return (
    <div
      className={clsx(
        styles.container,
        openDropDown && styles.containerOn
      )}
      style={
        openDropDown && item.submenu?.length
          ? {
              height:
                65 +
                item.submenu.length * 28 +
                (item.submenu.length - 1) * 24,
            }
          : undefined
      }>
      <AsideItem
        open={openDropDown}
        icon={item.icon}
        label={item.label}
        isMenuOpen={isMenuOpen}
        to={item.to}
        withDrop={true}
        onClick={() => setOpenDropDown((prev) => !prev)}
        className={clsx(
          styles.textBold,
          openDropDown && styles.textBoldOn,
          {
            [styles.smallMenuItem]: !(
              isMenuOpen || isMenuOverlayOpen
            ),
          }
        )}
      />
      <div
        className={clsx(
          styles.submenuBase,
          !(isMenuOpen || isMenuOverlayOpen || !openDropDown) &&
            styles.subMenuSmall
        )}>
        {item.submenu?.map((submenuItem, subIndex) => (
          <AsideItem
            key={submenuItem.label + subIndex}
            component={NavLink}
            to={submenuItem.to}
            isMenuOpen={isMenuOpen}
            icon={submenuItem.icon}
            label={submenuItem.label}
            className={clsx(styles.submenuInnerBtn, {
              [styles.smallMenuItem]: !(
                isMenuOpen ||
                isMenuOverlayOpen ||
                !openDropDown
              ),
            })}
          />
        ))}
      </div>
    </div>
  );
};
