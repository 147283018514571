import { FC, ReactNode } from 'react';
import styles from './styles.module.css';
import clsx from 'clsx';

import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plus.svg';

interface IEditWrapper {
  title: string;
  children: ReactNode;
  onClose: () => void;
  disableHeader?: boolean;
}
export const EditWrapper: FC<IEditWrapper> = ({
  children,
  title,
  onClose,
  disableHeader,
}) => {
  return (
    <div className={clsx(styles.wrapper, styles.padding0)}>
      {!disableHeader && (
        <div className={styles.header}>
          <p className={styles.title}>{title}</p>
          {/* <div onClick={onClose} className={styles.btnIcon}>
            <PlusIcon />
          </div> */}
        </div>
      )}
      <div className={styles.container}>{children}</div>
    </div>
  );
};
