import {  useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './CustomSelect.module.css';
import { IUseSelect } from '../../../pages/MedicalLeave/MedicalLeaveDrawerContent/types';

export type optionType = {
  value?: number | string;
  name: string;
  label?: string;
  id?: number;
  avatar?: any;
};

export const useSelect = ({
  smallPadding,
  className,
  classNameOptions,
  onChangeValue,
  rootRef,
  selectRef,
  height,
  menuAbove,
  // withoutAnimation,
}: IUseSelect) => {
  const [showOptions, setShowOptions] = useState(false);
  const [heightSelect, setHeightSelect] = useState<
    number | undefined
  >(undefined);

  const [blurred, setBlurred] = useState(false);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) setMounted(true);
    if (!showOptions && mounted) setBlurred(true);
  }, [showOptions]);

  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  useEffect(() => {
    const positionElement =
      rootRef.current?.getBoundingClientRect().bottom;
    const minimumDistance = 30;
    if (positionElement && selectRef.current) {
      const result = windowHeight - positionElement - minimumDistance;
      setHeightSelect(result);
      if (height && result < height) {
        selectRef.current.style.maxHeight = result + 'px';
      }
    }
  }, [rootRef.current, selectRef.current]);

  const classNames = clsx(
    styles.root,
    smallPadding && styles.smallPadding,
    className
  );
  const stylesOptions = clsx(
    styles.containerOptions,
    showOptions && styles.showOptions,
    classNameOptions,
    menuAbove && styles.menuAbove,
    // !withoutAnimation && styles.showOptionsAnimation
  );

  const handleClickOutside = (event: any) => {
    if (
      rootRef.current &&
      !rootRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };
  const handleSelectOneOption = (el: optionType) => {
    onChangeValue && onChangeValue(el);
    setShowOptions(false);
  };

  const handleShowOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const handleCloseOptions = () => {
    setShowOptions((prev) => false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return {
    classNames,
    handleShowOptions,
    stylesOptions,
    handleSelectOneOption,
    handleCloseOptions,
    showOptions,
    blurred,
  };
};
