import { FC, useEffect, useState } from 'react';

import styles from '../QuickFilterWrapperModal.module.css';

import Button from '../../../Button/Button';
import FormControl from '../../../FormControl/FormControl';
import Input from '../../../Input/Input';
import Typography from '../../../Typography/Typography';
import { useSelector } from 'react-redux';
import { getHRSelector } from '../../../../../store/humanResources/selectors';

interface ISaveModal {
  onCloseSaveModal: () => void;
  onCloseParentModal: () => void;
  handleSaveFilter: (e: string, func?: () => void) => void;
  defaultFilterName?: string;
  filterNames?: { name: string }[];
}

export const SaveFilter: FC<ISaveModal> = ({
  onCloseSaveModal,
  handleSaveFilter,
  defaultFilterName,
  onCloseParentModal,
}) => {
  const [nameFilter, setNameFilter] = useState(
    defaultFilterName || ''
  );

  const [error, setError] = useState('');
  const { filterCreateError } = useSelector(getHRSelector);

  useEffect(() => {
    if (filterCreateError) nameFilter && setError(filterCreateError);
  }, [filterCreateError]);

  useEffect(() => {
    if (nameFilter) setError('');
  }, [nameFilter]);

  const handleCloseAllModals = () => {
    onCloseParentModal();
    onCloseSaveModal();
  };

  return (
    <div>
      <div className={styles.header}>
        <Typography variant='subHeading1'>Save Filter</Typography>
      </div>
      <div className={styles.row}>
        <FormControl className={styles.flexSizeInput}>
          <Input
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            label={'Name the filter'}
            placeholder='Enter name the filter'
            error={error}
          />
        </FormControl>
      </div>
      <div className={styles.saveModalContainerButtons}>
        <Button
          onClick={onCloseSaveModal}
          variant='outlined'
          className={`${styles.outlinedBtn} ${styles.baseBtn}`}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSaveFilter(nameFilter, handleCloseAllModals);
          }}
          className={styles.baseBtn}
          color='black'
          disabled={Boolean(error)}>
          Save Filter
        </Button>
      </div>
    </div>
  );
};
