import { useSearchParams } from 'react-router-dom';

import RequestsTable from './components/RequestsTable';
import TitleWithCalendar from './components/TitleWithCalendar';

import { useGetPropositionsQuery } from '../../store/propositions/propositionApi';

import classes from './ComplaintsProposition.module.css';
import { useGetQueryParams } from '../../utils/common';

const ComplaintsProposition = () => {
  const params: {
    proposition_statuses?: string;
    proposition_date_from?: string;
    proposition_date_to?: string;
    proposition_type?: string;
    sort_by_type?: string;
    sort_by?: string;
    page?: number;
  } = useGetQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isFetching, refetch } = useGetPropositionsQuery(
    { params: { ...params, size: 10 } },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <div className={classes.root}>
      <TitleWithCalendar
        setSearchParams={setSearchParams}
        params={params}
      />
      <RequestsTable
        isFetching={isFetching}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        selectedType={params.proposition_type}
        refetch={refetch}
      />
    </div>
  );
};

export default ComplaintsProposition;
