import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ApiBaseQuery } from '../ApiBaseQuery';
import { ProposalFormData } from '../../pages/Proposal';

export const proposalApi = createApi({
  reducerPath: 'proposalApi',
  baseQuery: ApiBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getProposals: builder.query({
      query: (data) => ({
        url: '/proposals',
        method: 'get',
        data: data,
      }),
    }),
    createProposal: builder.mutation({
      query: (data: Partial<ProposalFormData>) => ({
        url: '/proposal',
        method: 'post',
        data: data,
      }),
    }),
    editProposal: builder.mutation({
      query: (data: Partial<ProposalFormData>) => ({
        url: '/proposal',
        method: 'put',
        data: data,
      }),
    }),
    deleteProposal: builder.mutation({
      query: (proposal_id: number) => ({
        url: '/proposal',
        method: 'delete',
        data: {
          params: {
            proposal_id
          }
        }
      }),
    }),
  }),
})

export const {
  useGetProposalsQuery,
  useCreateProposalMutation,
  useEditProposalMutation,
  useDeleteProposalMutation,
} = proposalApi