import { ReactComponent as AlertIcon } from '../../../assets/icons/snackbar/alert.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/snackbar/warning.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/snackbar/error.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/snackbar/success.svg';
import { ReactComponent as AlertBigIcon } from '../../../assets/icons/snackbar/alert-big.svg';
import { ReactComponent as WarningBigIcon } from '../../../assets/icons/snackbar/warning-big.svg';
import { ReactComponent as ErrorBigIcon } from '../../../assets/icons/snackbar/error-big.svg';
import { ReactComponent as SuccessBigIcon } from '../../../assets/icons/snackbar/success-big.svg';
import { SnackbarOrigin } from 'notistack';

export const iconsSnackbar = [
  { name: 'info', icon: <AlertIcon />, iconBig: <AlertBigIcon /> },
  {
    name: 'success',
    icon: <SuccessIcon />,
    iconBig: <AlertBigIcon />,
  },
  { name: 'error', icon: <ErrorIcon />, iconBig: <AlertBigIcon /> },
  {
    name: 'warning',
    icon: <WarningIcon />,
    iconBig: <AlertBigIcon />,
  },
];

export const snackbarPosBottomLeft: SnackbarOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
};

export const snackbarPosBottomRight: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};
export const snackbarPosTopLeft: SnackbarOrigin = {
  horizontal: 'left',
  vertical: 'top',
};
export const snackbarPosTopRight: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'top',
};
