import { ChangeEvent, FC, useRef, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusWhite.svg';
import styles from './SearchInput.module.css';

interface IProps {
  placeholder: string;
  disableBottomBorder?: boolean;
  disableRightBorder?: boolean;
  classNameInput?: string;
  className?: string;
  value: string;
  setValue: (e: IProps['value']) => void;
}

export const SearchInput: FC<IProps> = ({
                                          placeholder,
                                          disableBottomBorder,
                                          disableRightBorder,
                                          classNameInput,
                                          value,
                                          setValue,
                                          className,
                                        }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFocusInput = () => {
    inputRef.current?.focus();
  };

  const handleClear = () => {
    setValue('');
  };

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const containerStyles = clsx(
    styles.containerInput,
    focused && styles.containerFocused,
    disableBottomBorder && styles.disableBorderBottom,
    className
  );

  const inputStyles = clsx(
    styles.input,
    focused && styles.inputFocused,
    classNameInput
  );

  const plusIconStyles = clsx(
    styles.plusIcon,
    value.length > 0 && styles.plusIconActive
  );

  const searchIconStyles = clsx(
    styles.searchIcon,
    value.length !== 0 && styles.searchIconDisabled,
    focused && styles.searchIconFocused
  );

  const divideStyles = clsx(
    styles.divide,
    focused && styles.divideActive
  );

  return (
    <div onClick={handleFocusInput} className={containerStyles}>
      <input
        ref={inputRef}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        className={inputStyles}
        placeholder={placeholder}
      />
      {value.length !== 0 ?
        <div onClick={handleClear} className={plusIconStyles}>
          <PlusIcon />
        </div> :
        <div className={searchIconStyles}>
          <SearchIcon />
        </div>}
      {disableBottomBorder && !disableRightBorder && (
        <div className={divideStyles} />
      )}
    </div>
  );
};
