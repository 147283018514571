import { FC } from 'react';
import clsx from 'clsx';

import styles from './StageCard.module.css';

interface IStageCard {
  type:
    | 'Design'
    | 'Development'
    | 'QA'
    | 'Front-end'
    | 'Back-end'
    | 'PM'
    | 'Pending'
    | 'In Progress'
    | 'Done'
    | 'On Hold'
    | 'Finished'
    | 'Branding';
  className?: string;
}

const StageCard: FC<IStageCard> = ({ type, className }) => {
  const stylesCard = clsx(
    styles.card,
    styles[type.toLowerCase().replaceAll(/\s+/g, '-')],
    className
  );
  switch (type) {
    case 'Design':
      return <div className={stylesCard}>Design</div>;
    case 'Development':
      return <div className={stylesCard}>Development</div>;
    case 'QA':
      return <div className={stylesCard}>QA</div>;
    case 'Front-end':
      return <div className={stylesCard}>Front-end</div>;
    case 'Back-end':
      return <div className={stylesCard}>Back-end</div>;
    case 'PM':
      return <div className={stylesCard}>PM</div>;
    case 'Pending':
      return <div className={stylesCard}>Pending</div>;
    case 'In Progress':
      return <div className={stylesCard}>In Progress</div>;
    case 'Done':
      return <div className={stylesCard}>Done</div>;
    case 'On Hold':
      return <div className={stylesCard}>On Hold</div>;
    case 'Finished':
      return <div className={stylesCard}>Finished</div>;
    case 'Branding':
      return <div className={stylesCard}>Branding</div>;
    default:
      return <div />;
  }
};

export default StageCard;
