import moment from 'moment';
import React from 'react';
import Table from '../../ui/Table';
import TableRow from '../../ui/TableRow';
import TableCell from '../../ui/TableCell';
import defaultAvatar from '../../../assets/icons/movadex.png';
import styles from '../BenchPage.module.css'
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';

export default function HistoryTable(list: any) {
    return (
            <Table>
                <thead>
                <TableRow className={styles.tableRows}>
                    <TableCell component="th" sortBy="created_at">Date / Time</TableCell>
                    <TableCell component="th">Action</TableCell>
                    <TableCell component="th">Decision maker</TableCell>
                    <TableCell component="th" sortBy="date_to">End date</TableCell>
                    <TableCell component="th">Comment</TableCell>
                </TableRow>
                </thead>
                <tbody>
                {list?.list?.map((i: any, index: number) => (
                    <TableRow key={index} hover className={styles.tableRows}>
                        <TableCell component="td">{moment(i.created_at).format('HH:mm D MMM YYYY')}</TableCell>
                        <TableCell component="td">
                            <span className={clsx(
                                 i.action === 'Removed' && styles.removed,
                                i.type === 'Bench-50' && styles.benchHalf,
                                i.type === 'Bench-0' && styles.bench
                            )}>{i.display_action}</span>
                        </TableCell>

                        <TableCell component="td" className={styles.memberInfo}>
                            <img src={i?.creator?.avatar_url || defaultAvatar} alt="avatar"/>
                            {i?.creator.first_name} {i?.creator.last_name}
                        </TableCell>
                        <TableCell component="td">
                            {moment(i.date_to).format('D MMM YYYY')}
                        </TableCell>
                        <TableCell component="td">
                            <Tooltip title={i.comment}>
                                     <span className={styles.tableRowsLast}>
                                    {i.comment}
                                 </span>
                            </Tooltip>
                    </TableCell>
                    </TableRow>
                ))}
                </tbody>
            </Table>
    );
}
