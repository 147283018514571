import styles from '../FileUploader.module.css';
import React from 'react';
import { UploadedFileProps } from './UploadedFileProps';
import Typography from '../../ui/Typography';
import { formatFileSize, isImageFileType } from '../../../helpers/functions/functions';
import { ReactComponent as FileIcon } from '../../../assets/icons/files-placeholders.svg';
import { CrossIcon } from '../../../assets/icons/components';

const UploadedFile = ({ file, requests, onDelete }: UploadedFileProps) => {

  const handleOpenFile = (file: any) => {
    const newWindow = window.open(file.path, '_blank');
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error('Unable to open a new window/tab. Please check your browser settings.');
    }
  };

  return (
    <div className={styles.documentWrapper}>
      {(isImageFileType(file)) ?
        <img
          src={URL.createObjectURL(file)}
          className={styles.image}
          alt="profile-avatar"
        />
        :
        <div className={styles.fileIconWrapper}>
          <FileIcon />
        </div>
      }
      <div>
        <div className={`mLeft4 ${styles.documentContainer}`} onClick={() => handleOpenFile(file)}>
          <Typography className={styles.nameWrapper} variant={'smallText'}>{file?.name}</Typography>
          <Typography className={styles.imageWeight} variant={'mediumLabel'}>
            {file.size && formatFileSize(file.size)}
            {file.document_size_mb && Number(file.document_size_mb).toFixed(4) + 'MB'}
          </Typography>
        </div>
      </div>
      {!requests &&
        <div className={styles.crossIconWrapper} onClick={onDelete}>
          <CrossIcon size={'20px'} />
        </div>}
    </div>
  );
};

export default UploadedFile;
