import { FC } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import Button from '../../../../ui/Button';
import styles from './ConditionForm.module.css';

import { ICondition } from '../../../../../services/policyApi/types';
import { CustomSelect } from '../../../../ui/CustomSelect/CustomSelect';
import Counter from '../../../../ui/Counter';
import RadioDayOffCondition from '../../../RadioDayOffCondition';
import FormikTextArea from '../../../../ui/FormikTextArea';
import { policyApi } from '../../../../../services/policyApi/policyApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../ui/Snackbar/constants';
import {
  periodOfCooperationSelect,
  typeOfCooperationSelect,
} from '../../../../../common/values/values';

interface IConditionForm {
  data?: ICondition;
  onClose: (e?: 'render') => void;
}
export const ConditionForm: FC<IConditionForm> = ({
  data,
  onClose,
}) => {
  interface IInitialValues {
    id?: number;
    max_days_off: number;
    max_sick_leaves: number;
    reset_days_off: boolean;
    reset_sick_leaves: boolean;
    comment: string;
    forms_of_coops: string[];
    years_of_cooperation: number;
  }

  const initialValues: IInitialValues = {
    max_days_off: data?.max_days_off || 20,
    max_sick_leaves: data?.max_sick_leaves || 7,
    reset_days_off: data?.reset_days_off || false,
    reset_sick_leaves: data?.reset_sick_leaves || false,
    comment: data?.comment || '',
    forms_of_coops:
      data?.forms_of_coops ||
      periodOfCooperationSelect.map((e) => e.name),
    years_of_cooperation: data?.years_of_cooperation || 0,
  };

  const flexMr24Style = clsx(styles.flex, styles.mR24);
  return (
    <div className={styles.wrapper}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          const obj = { ...values };
          formikHelpers.setSubmitting(true);
          try {
            if (data?.id) {
              obj.id = data?.id;
              await policyApi.editCondition(obj);
              enqueueSnackbar('Successfully edited condition', {
                variant: 'success',
                anchorOrigin: snackbarPosTopRight,
              });
              onClose('render');
            } else {
              await policyApi.createCondition(obj);
              enqueueSnackbar('Successfully created condition', {
                variant: 'success',
                anchorOrigin: snackbarPosTopRight,
              });
              onClose('render');
            }
            formikHelpers.setSubmitting(false);
          } catch (e: any) {
            enqueueSnackbar(e.response.data.detail, {
              variant: 'error',
              anchorOrigin: snackbarPosTopRight,
            });
            formikHelpers.setSubmitting(false);
          }
        }}
        validateOnMount
        enableReinitialize>
        {({ values, setFieldValue, submitForm, isSubmitting }) => {
          return (
            <>
              <div className={styles.header}>
                <p className={styles.title}>
                  {data?.id
                    ? `Edit Condition #${data.id}`
                    : ' Add New Condition'}
                </p>
                <Button
                  onClick={submitForm}
                  disabled={isSubmitting}
                  color='black'>
                  Save Changes
                </Button>
              </div>
              <div className={styles.container}>
                <div className={styles.row}>
                  <div className={flexMr24Style}>
                    <CustomSelect
                      label='Period of cooperation'
                      subTitle='How long a team member has worked since they joined'
                      defaultOptions={typeOfCooperationSelect.filter(
                        (e) => e.value === values.years_of_cooperation
                      )}
                      onChangeValue={(e) => {
                        setFieldValue(
                          'years_of_cooperation',
                          typeOfCooperationSelect.filter(
                            (type) => type.name === e.name
                          )[0].value
                        );
                      }}
                      options={typeOfCooperationSelect}
                      detailedInput
                      placeholder='Choose period'
                    />
                  </div>
                  <div className={styles.flex}>
                    <CustomSelect
                      label={'Type of cooperation'}
                      subTitle='These types will have a specified number of days off'
                      options={periodOfCooperationSelect}
                      multi
                      detailedInput
                      placeholder='Select Type of cooperations'
                      onChangeMultiValue={(e) =>
                        setFieldValue(
                          'forms_of_coops',
                          e.map((e) => e.name)
                        )
                      }
                      multiAllTitle='All Types of cooperation'
                      defaultOptions={values.forms_of_coops.map(
                        (e) => {
                          return { name: e };
                        }
                      )}
                    />
                  </div>
                </div>
                <div className={clsx(styles.row, styles.mB22)}>
                  <div className={flexMr24Style}>
                    <div className={styles.containerInput}>
                      <p className={styles.inputTitle}>Days off</p>
                      <p className={styles.inputSubTitle}>
                        Excluding self-paid
                      </p>
                      <div className={styles.rowCounter}>
                        <Counter
                          value={values.max_days_off}
                          setValue={(e) =>
                            setFieldValue('max_days_off', e)
                          }
                          maxNum={365}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.flex}>
                    <div className={styles.containerInput}>
                      <p className={styles.inputTitle}>
                        Medical leaves
                      </p>
                      <p className={styles.inputSubTitle}>
                        Excluding self-paid
                      </p>
                      <div className={styles.rowCounter}>
                        <Counter
                          value={values.max_sick_leaves}
                          setValue={(e) =>
                            setFieldValue('max_sick_leaves', e)
                          }
                          maxNum={365}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={clsx(styles.row, styles.mB22)}>
                  <div className={flexMr24Style}>
                    <div className={styles.containerInput}>
                      <RadioDayOffCondition
                        value={values.reset_days_off}
                        setValue={(e) =>
                          setFieldValue('reset_days_off', e)
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.flex}>
                    <div className={styles.containerInput}>
                      <RadioDayOffCondition
                        title='Medical leave conditions'
                        value={values.reset_sick_leaves}
                        setValue={(e) =>
                          setFieldValue('reset_sick_leaves', e)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.containerComment}>
                    <FormikTextArea
                      label='Comment'
                      subTitle='This comment is visible only for admins'
                      placeholder='Enter comment'
                      value={values.comment}
                      onChange={(e) =>
                        setFieldValue('comment', e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
