import { FC } from 'react';
import styles from './Contacts.module.css';
import {ReactComponent as Copy} from '../../../../../../assets/icons/copy.svg'
import { ReactComponent as UserIcon } from '../../../../../../assets/icons/user.svg';
import { IContactsItem } from '../../../../../../services/projectsApi/types';
import { timezone } from '../../../../../../common/values/timezone';
import {enqueueSnackbar} from 'notistack';
import { snackbarPosTopRight} from '../../../../../ui/Snackbar/constants';
interface IContacts {
  data: IContactsItem[];
}
export const Contacts: FC<IContacts> = ({ data }) => {
  const handleCopy = async (email: string) => {
      await navigator.clipboard.writeText(email);
    enqueueSnackbar('Email copied', {
      variant: 'success',
      anchorOrigin: snackbarPosTopRight,
    });
  };

  return (
    <div className={styles.wrapper}>
      {data.map((contact, index) => (
        <div className={styles.card} key={index}>
          <div className={styles.cardHeader}>
            <div className={styles.cardIcon}>
              <UserIcon />
            </div>
            <p className={styles.contactName}>{contact.full_name}</p>
            <p className={styles.contactTime}>
              {
                timezone.filter(
                  (e) => e.value === contact.gmt_timezone
                )[0].name
              }
            </p>
          </div>
          <p className={styles.contactRole}>{contact.position}</p>
          <div className={styles.email}>
            <a
                href={`mailto:${contact.email}`}
                className={styles.contactEmail}>
              {contact.email}
            </a>
            <Copy onClick={() => handleCopy(contact.email)}/>
          </div>
        </div>
      ))}
    </div>
  );
};
