import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectsApi } from '../../services/projectsApi/projectsApi';
import {
  IEditProfileProjectRequest,
  IProjectFilters,
  IProjectsListQuery,
} from '../../services/projectsApi/types';

export const getProfileProject = createAsyncThunk(
  'getProfileProject',
  async (data: number) => {
    try {
      const res = await projectsApi.getProfileProject(data);
      return res;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);

export const editProfileProject = createAsyncThunk(
  'editProfileProject',
  async (data: IEditProfileProjectRequest, thunkAPI) => {
    try {
      const res = await projectsApi.editProjectProfile(data);
      return res;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);

export const getFilters = createAsyncThunk(
  'getFiltersProjects',
  async (data, thunkAPI) => {
    try {
      const res = await projectsApi.getFilters();
      return res;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);

export const createFilter = createAsyncThunk(
  'createFilterProjects',
  async (data: IProjectFilters, thunkAPI) => {
    try {
      await projectsApi.saveFilter(data);
      thunkAPI.dispatch(getFilters());
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);

export const editFilter = createAsyncThunk(
  'editFilterProjects',
  async (data: IProjectFilters, thunkAPI) => {
    try {
      await projectsApi.editFilter(data);
      thunkAPI.dispatch(getFilters());
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);
export const deleteFilter = createAsyncThunk(
  'deleteFilterProjects',
  async (name: string) => {
    await projectsApi.deleteFilter(name);
    return name;
  }
);

export const getList = createAsyncThunk(
  'getListProjects',
  async (data: IProjectsListQuery) => {
    const res = await projectsApi.list(data);
    if (!res?.items?.length) throw 'no items';
    return res;
  }
);

export const totalByStatuses = createAsyncThunk(
  'totalByStatuses',
  async (data: IProjectsListQuery) => {
    try {
      const res = await projectsApi.totalByStatuses(data);
      return { data: res };
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);
