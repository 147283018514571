import moment from 'moment';
import { MouseEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import {snackbarPosTopRight} from '../../../ui/Snackbar/constants';

import routes from '../../../../constants/routes';
import { useModal } from '../../../../hooks/useModal';
import { useMenu } from '../../../../hooks/useMenu';
import { useAppDispatch } from '../../../../store';
import { deleteProposalReducer } from '../../../../store/proposal/reducer';
import { IProposal } from '../../../../services/proposalApi/types';
import { ApiListResponse } from '../../../../types/api';
import { useDeleteProposalMutation } from '../../../../store/proposal/proposalApi';

import TableRow from '../../../ui/TableRow';
import TableCell from '../../../ui/TableCell';
import Table from '../../../ui/Table';
import Stack from '../../../ui/Stack';
import IconButton from '../../../ui/IconButton';
import ProposalInfo from '../ProposalInfo';
import ProposalStatus from '../ProposalStatus';
import ProposalsActionMenu from './ProposalsActionMenu';
import MenuItem from '../../../ui/MenuItem';
import DeleteModal from '../../../modals/DeleteModal';
import TablePagination from '../../../ui/TablePagination';
import { ImageFallback } from '../../../ui/ImageFallback/ImageFallback';

import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon.svg';
import { ReactComponent as DownHorizontalIcon } from '../../../../assets/icons/more-options.svg';

import styles from './ProposalsTable.module.css';

type ProposalsTableProps = {
  list: ApiListResponse<IProposal>;
}

export default function ProposalsTable({ list }: ProposalsTableProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [id, setId] = useState<IProposal['id'] | null>(null);
  const [data, setData] = useState<IProposal | null>(null);

  const [deleteProposal] = useDeleteProposalMutation();

  const { isOpen: isOpenInfoModal, open: onOpenInfoModal, close: onCloseInfoModal } = useModal();
  const { isOpen: isOpenDeleteModal, open: onOpenDeleteModal, close: onCloseDeleteModal } = useModal();
  const { isOpen: isOpenMenu, anchorEl, handleMenuClose: onClose, handleMenuOpen: onOpen } = useMenu();

  const handleOpenModal = (data: IProposal) => () => {
    setData(data);
    onOpenInfoModal();
  };

  const handleCopy = (e: MouseEvent, title: string) => {
    e.stopPropagation();
    navigator.clipboard.writeText(`${process.env.REACT_APP_OFFER_DOMAIN_URL}/${title}`);
    enqueueSnackbar('Link copied', {
      variant: 'success',
      anchorOrigin: snackbarPosTopRight,
    });
  };

  function handleEdit(e: MouseEvent) {
    e.stopPropagation();
    navigate(`${routes.proposalsPage}/${id}`);
  }

  const handleMenuOpen = (e: MouseEvent, id: number) => {
    e.stopPropagation();
    setId(id);
    onOpen(e);
  };

  const handleMenuClose = () => {
    onClose();
    setId(null);
  };

  const handleOpenDeleteModal = () => {
    onOpenDeleteModal()
    onClose();
  };

  const handleDeleteProposal = () => {
    if (id) {
      deleteProposal(id).then(() => {
        onCloseDeleteModal();
        dispatch(deleteProposalReducer(id))
      });
    }
  };

  return (
    <>
      <Table>
        <thead>
        <TableRow>
          <TableCell component="th" sortBy="title">
            Project
          </TableCell>
          <TableCell component="th" sortBy="client_name">
            Client
          </TableCell>
          <TableCell component="th" sortBy="client_contact">
            Email
          </TableCell>
          <TableCell component="th" sortBy="status" align="center">
            Status
          </TableCell>
          <TableCell component="th" sortBy="creator_full_name" align="center">
            Created by
          </TableCell>
          <TableCell component="th" sortBy="created_at" align="center">
            Created
          </TableCell>
          <TableCell component="th" sortBy="accepted_at" align="center">
            Accepted
          </TableCell>
          <TableCell component="th" align="right">
            Link
          </TableCell>
        </TableRow>
        </thead>
        <tbody>
        {list.items.map((i: IProposal) => (
          <TableRow key={i.id} hover onClick={handleOpenModal(i)}>
            <TableCell component="td">
              {i.title}
            </TableCell>
            <TableCell component="td">
              {i.client_name}
            </TableCell>
            <TableCell component="td">
              {i.client_contact}
            </TableCell>
            <TableCell component="td" align="center">
              <ProposalStatus status={i.status} />
            </TableCell>
            <TableCell component="td">
              <ImageFallback
                width={24}
                alt=""
                src={i.creator.avatar_url}
              />
              {i.creator.full_name}
            </TableCell>
            <TableCell component="td">
              {moment(i.created_at).format('DD.MM.YYYY HH:mm')}
            </TableCell>
            <TableCell component="td">
              {i.accepted_at ? moment(i.accepted_at).format('DD.MM.YYYY HH:mm') : '-'}
            </TableCell>
            <TableCell component="td" width={100} align="right">
              <Stack space={0} className={styles.button} justify="end">
                <IconButton
                  color="inherit"
                  icon={LinkIcon}
                  onClick={(e: MouseEvent) => handleCopy(e, i.title)}
                />
                <IconButton
                  color="inherit"
                  icon={DownHorizontalIcon}
                  onClick={(e: MouseEvent) => handleMenuOpen(e, i.id)}
                />
              </Stack>
            </TableCell>
          </TableRow>
        ))}
        </tbody>
      </Table>
      <ProposalsActionMenu
        anchorEl={anchorEl}
        isOpen={isOpenMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleMenuClose}
      >
        <MenuItem hover onClick={handleEdit}>
          <Stack align="center">
            <PencilIcon />
            Edit
          </Stack>
        </MenuItem>
        <MenuItem hover onClick={handleOpenDeleteModal}>
          <Stack align="center">
            <TrashIcon />
            Delete
          </Stack>
        </MenuItem>
      </ProposalsActionMenu>
      <TablePagination
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        currentStartPage={Number(searchParams.get('page')) || 1}
        count={list.total}
        itemsPerPage={10}
      />
      <DeleteModal
        title="Do you want to delete this proposal?"
        description="Are you sure you want to delete this proposal? This action isirreversible."
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={handleDeleteProposal}
      />
      <ProposalInfo data={data} isOpen={isOpenInfoModal} onClose={onCloseInfoModal} />
    </>
  );
}
