import { useEffect, useState } from 'react';
import styles from './ProfileAdditionalInfo.module.css';

import { Contacts } from './Section/Contacts/Contacts';
import { Links } from './Section/Links/Links';
import { LinksModal } from './Section/Links/LinksModal/LinksModal';
import { RateCards } from './Section/RateCards/RateCards';

import { Section } from './Section/Section';
import { links, contacts, rateCards } from './mocks';
import { ContactsModal } from './Section/Contacts/ContactsModal/ContactsModal';
import Modal from '../../../ui/Modal/Modal';
import { RateCardForm } from './Section/RateCards/Modals/RateCardForm/RateCardForm';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import {
  IContactsItem,
  ILinksItem,
} from '../../../../services/projectsApi/types';
import { projectsApi } from '../../../../services/projectsApi/projectsApi';
import { useParams } from 'react-router-dom';

export const ProfileAdditionalInfo = () => {
  const { id } = useParams();
  const [showLinksModal, setShowLinksModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const onShowContactsModal = () => {
    setShowContactsModal(true);
  };

  const onCloseContactsModal = () => {
    setShowContactsModal(false);
  };
  const onShowLinksModal = () => {
    setShowLinksModal(true);
  };

  const onCloseLinksModal = () => {
    setShowLinksModal(false);
  };

  const [Linkslist, setLinksList] = useState<ILinksItem[]>([]);
  const getLinksList = async () => {
    const res = await projectsApi.getLinks({
      project_id: Number(id),
    });
    setLinksList(res);
  };

  useEffect(() => {
    if (!showLinksModal) {
      getLinksList();
    }
  }, [showLinksModal]);
  const [contactslist, setContactsList] = useState<IContactsItem[]>(
    []
  );
  const getContactsList = async () => {
    const res = await projectsApi.getContacts({
      project_id: Number(id),
    });
    setContactsList(res);
  };

  useEffect(() => {
    if (!showContactsModal) {
      getContactsList();
    }
  }, [showContactsModal]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Section
          onClickPlus={onShowLinksModal}
          title='Links'
          component={<Links data={Linkslist} />}
        />
        <LinksModal
          isOpen={showLinksModal}
          onClose={onCloseLinksModal}
        />
        <Section
          onClickPlus={onShowContactsModal}
          title='Contacts'
          component={<Contacts data={contactslist} />}
        />
        <ContactsModal
          isOpen={showContactsModal}
          onClose={onCloseContactsModal}
        />
        {/* <Section
          onClickPlus={() => setShowAddModal(true)}
          title='Rate Card'
          disableBB={!!rateCards.length}
          component={<RateCards data={rateCards} />}
        /> */}
        {/* <Modal
          className={styles.modal}
          classNameOverlay={styles.overlay}
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}>
          <div className={styles.header}>
            <p className={styles.title}>Add Rate</p>
            <div
              onClick={() => setShowAddModal(false)}
              className={styles.btnIcon}>
              <PlusIcon />
            </div>
          </div>
          <RateCardForm />
        </Modal> */}
      </div>
    </div>
  );
};
