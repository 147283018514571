import { FC, useEffect, useState } from 'react';
import { CRUDList } from '../../CRUDList/CRUDList';
import { useSelector } from 'react-redux';
import { getProjectProfileSelector } from '../../../../../../../store/project/selectors';
import { ContactsList } from './ContactsList';
import { ContactsAdd } from './ContactsAdd';
import { ContactsProfile } from './ContactsProfile';
import { IContactsItem } from '../../../../../../../services/projectsApi/types';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { projectsApi } from '../../../../../../../services/projectsApi/projectsApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../../../ui/Snackbar/constants';

interface IContactsWrapperCRUD {
  onClose: () => void;
}
export const ContactsWrapperCRUD: FC<IContactsWrapperCRUD> = ({
  onClose,
}) => {
  type typeDisplays = 'add' | 'edit' | 'not' | 'profile' | 'loading';
  const [rightSectionView, setRightSectionView] =
    useState<typeDisplays>('add');

  const [currentItem, setCurrentItem] = useState<any | undefined>(
    undefined
  );
  const profileData = useSelector(getProjectProfileSelector);

  const [list, setList] = useState<IContactsItem[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [render, setRender] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  console.log('isLoading',isLoading)
  const debouncedValue = useDebounce<string>(searchValue, 400);
  const getList = async (
    id: number,
    func?: (data: IContactsItem[]) => void
  ) => {
    try {
      setIsLoading(true);
      const res = await projectsApi.getContacts({
        project_id: id,
        search: debouncedValue,
      });
      setList(res);
      if (func) func(res);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar('Error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
      setIsLoading(false);
    }
  };

  const handleRemoveContact = async (
    data: IContactsItem,
    setData: React.Dispatch<React.SetStateAction<typeDisplays>>
  ) => {
    try {
      await projectsApi.deleteContacts({
        project_contact_id: Number(data.id),
      });
      profileData?.id && (await getList(profileData?.id));
      setData('add');
      enqueueSnackbar('Successfully removed', {
        variant: 'success',
        anchorOrigin: snackbarPosTopRight,
      });
    } catch (e) {
      enqueueSnackbar('Error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
    }
  };

  useEffect(() => {
    profileData?.id && getList(profileData?.id);
  }, [debouncedValue, render]);
  return (
    <CRUDList
      // listBtn={
      //   <div className={styles.wrapperListBtn}>
      //     <Button
      //       className={styles.listBtn}
      //       fullWidth
      //       startIcon={ArrowForwardIcon}>
      //       Import Contacts from Client profile
      //     </Button>
      //   </div>
      // }
      title='Contacts'
      titleProfileSaveButton='Remove from Contacts'
      addTitle='Add Contact'
      onRemoveItem={handleRemoveContact}
      editTitle='Edit Contact'
      list={list}
      onClose={onClose}
      setRender={setRender}
      ListComponent={ContactsList}
      AddComponent={ContactsAdd}
      EditComponent={ContactsAdd}
      ProfileComponent={ContactsProfile}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      rightSectionView={rightSectionView}
      setRightSectionView={setRightSectionView}
      setCurrentItem={setCurrentItem}
      currentItem={currentItem}
    />
  );
};
