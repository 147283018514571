import styles from './Plug.module.css';

import { ReactComponent as Laptop } from '../../../../assets/image/laptop.svg';
export const Plug = () => {
  return (
    <div className={styles.container}>
      <Laptop className={styles.img} />
      <pre className={styles.text}>
        {`You don’t have any reports for selected criteria.
Try different filters.`}
      </pre>
    </div>
  );
};
