import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import MDEditor from '@uiw/react-md-editor';

import routes from '../../constants/routes';
import { useAppDispatch } from '../../store';
import { getProposal } from '../../store/proposal/thunks';
import { getProposalSelector } from '../../store/proposal/selectors';
import {
  useCreateProposalMutation,
  useEditProposalMutation
} from '../../store/proposal/proposalApi';
import { IProposalEstimate } from '../../services/proposalApi/types';
import { clearProposal } from '../../store/proposal/reducer';

import Input from '../../components/ui/Input';
import InputLabel from '../../components/ui/InputLabel';
import Typography from '../../components/ui/Typography';
import PasswordInput from '../../components/helpers/PasswordInput';
import Button from '../../components/ui/Button';
import Checkbox from '../../components/ui/Checkbox';
import Textarea from '../../components/ui/TextArea';
import InputError from '../../components/ui/InputError';
import ProposalEstimates from '../../components/pages/Proposal/ProposalEstimates';
import FileUploader from '../../components/FileUploader';

import styles from './Proposal.module.css';

export type ProposalFormData = {
  title: string;
  project_type: string;
  password_code: string;
  background_image_base64: string;
  client_name: string;
  client_position: string;
  client_contact: string;
  overview: string;
  overviewCheckbox: boolean;
  features: string;
  featuresCheckbox: boolean;
  estimated_delivery_time: string;
  estimatedTimeCheckbox: boolean;
  teamCheckbox: boolean;
  team_summary: string;
  estimates: Array<IProposalEstimate>;
  proposal_id?: number;
}

export default function Proposal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createProposal] = useCreateProposalMutation();
  const [editProposal] = useEditProposalMutation();
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setValue,
  } = useForm<ProposalFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'estimates'
  });
  const overview = watch('overview') || '';
  const overviewCheckbox = watch('overviewCheckbox') || false;
  const features = watch('features') || '';
  const featuresCheckbox = watch('featuresCheckbox') || false;
  const estimatedTimeCheckbox = watch('estimatedTimeCheckbox') || false;
  const teamCheckbox = watch('teamCheckbox') || false;
  const data = useSelector(getProposalSelector);

  useEffect(() => {
    dispatch(clearProposal())
    if (id && id !== 'create') {
      dispatch(getProposal(+id));
    }
  }, [id]);

  useEffect(() => {
    if (data && id && id !== 'create') {
      reset({
        overviewCheckbox: !!data.overview,
        featuresCheckbox: !!data.features,
        estimatedTimeCheckbox: !!data.estimated_delivery_time,
        teamCheckbox: !!data.team_summary,
        background_image_base64: data.background_image,
        ...data as Partial<ProposalFormData>
      });
    }
  }, [id, data]);

  const onSubmit: SubmitHandler<ProposalFormData> = async (values) => {
      const data: Partial<ProposalFormData> = {
        title: values.title,
        project_type: values.project_type,
        password_code: values.password_code,
        background_image_base64: values.background_image_base64?.replace(/^data:image\/\w+;base64,/, ''),
        client_name: values.client_name,
        client_position: values.client_position,
        client_contact: values.client_contact,
        overview: values.overview,
        features: values.features,
        estimated_delivery_time: values.estimated_delivery_time,
        team_summary: values.team_summary,
        estimates: values.estimates
      };
      const res: { [key: string]: any } = (id && id !== 'create') ? await editProposal({
        proposal_id: +id,
        ...data
      }) : await createProposal(data);
      if (res?.error) {
        enqueueSnackbar(res.error?.data?.detail)
      } else {
        navigate(routes.proposalsPage);
      }
  };

  return (
    <div className={styles.root}>
      <Typography variant="h5" weight="bold">
        {id === 'create' ? 'New' : 'Edit'} Proposal
      </Typography>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formInner}>
          <div>
            <Typography weight="bold" gutterBottom>General Information</Typography>
            <div className={styles.formWrapper}>
              <InputLabel>Project title</InputLabel>
              <Input
                autoComplete="one-time-code" {...register('title', { required: 'Please input a title' })} />
              {errors.title && <InputError>{errors.title.message}</InputError>}
            </div>
            <div className={styles.formWrapper}>
              <InputLabel>Project type</InputLabel>
              <Input
                autoComplete="one-time-code" {...register('project_type', { required: 'Please input a project type' })} />
              {errors.project_type && <InputError>{errors.project_type.message}</InputError>}
            </div>
            <div className={styles.formWrapper}>
              <PasswordInput
                autoComplete="one-time-code"
                placeholder="********"
                {
                  ...register('password_code',
                    {
                      required: 'Please input a password',
                      minLength: {
                        value: 6,
                        message: 'Password must be 6 characters long'
                      },
                      maxLength: {
                        value: 6,
                        message: 'Password must be 6 characters long'
                      }
                    })
                }
              />
              {errors.password_code && <InputError>{errors.password_code.message}</InputError>}
            </div>
            <div className={styles.formWrapper}>
              <InputLabel>Upload background picture</InputLabel>
              <FileUploader
                multiple={false}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
            </div>
            {errors.background_image_base64 && (
              <InputError>{errors.background_image_base64.message}</InputError>
            )}
          </div>
        </div>
        <div className={styles.formInner}>
          <div>
            <Typography weight="bold" gutterBottom>Client Info</Typography>
            <div className={styles.formWrapper}>
              <InputLabel>Client name</InputLabel>
              <Input
                placeholder="Clients full name"
                {...register('client_name', { required: 'Please input a client name' })}
              />
              {errors.client_name && <InputError>{errors.client_name.message}</InputError>}
            </div>
            <div className={styles.formWrapper}>
              <InputLabel>Client title</InputLabel>
              <Input
                {...register('client_position', { required: 'Please input a client title' })}
                placeholder="CEO, COO, VP of Product"
              />
              {errors.client_position && <InputError>{errors.client_position.message}</InputError>}
            </div>
            <div className={styles.formWrapper}>
              <InputLabel>Client’s contact</InputLabel>
              <Input
                {...register('client_contact', { required: 'Please input a client’s contact' })}
                placeholder="Client’s contact email/phone"
              />
              {errors.client_name && <InputError>{errors.client_name.message}</InputError>}
            </div>
          </div>
        </div>
        <div className={styles.formInner}>
          <div>
            <div className={styles.formSectionHeader}>
              <Typography weight="bold">Proposal page summary</Typography>
              <Typography color="gray400">Check items to include on proposal page</Typography>
            </div>
            <div className={styles.formWrapper}>
              <Checkbox
                label="Overview"
                checked={overviewCheckbox}
                {...register('overviewCheckbox')}
              />
              {overviewCheckbox && (
                <div className={styles.formTextarea}>
                  <MDEditor
                    value={overview}
                    onChange={v => setValue('overview', v || '')}
                  />
                </div>
              )}
            </div>
            <div className={styles.formWrapper}>
              <Checkbox
                label="Features"
                checked={featuresCheckbox}
                {...register('featuresCheckbox')}
              />
              {featuresCheckbox && (
                <div className={styles.formTextarea}>
                  <MDEditor
                    value={features}
                    onChange={v => setValue('features', v || '')}
                  />
                </div>
              )}
            </div>
            <div className={styles.formWrapper}>
              <Checkbox
                checked={estimatedTimeCheckbox}
                label="Estimated delivery time"
                {...register('estimatedTimeCheckbox')}
              />
              {estimatedTimeCheckbox && (
                <div className={styles.formTextarea}>
                  <Textarea
                    fullWidth
                    placeholder=""
                    {...register('estimated_delivery_time')}
                    onChange={e => setValue('estimated_delivery_time', e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className={styles.formWrapper}>
              <Checkbox
                label="Team"
                checked={teamCheckbox}
                {...register('teamCheckbox')}
              />
              {teamCheckbox && (
                <div className={styles.formTextarea}>
                  <Textarea
                    fullWidth
                    placeholder=""
                    {...register('team_summary')}
                    onChange={e => setValue('team_summary', e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <ProposalEstimates data={fields} append={append} remove={remove} register={register} />
        <div className={styles.formFooter}>
          <Button color="black" size="wide" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}