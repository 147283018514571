import { FC, useState } from 'react';
import styles from './RadioDayOffCondition.module.css';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import DayOffConditionSelect from '../DayOffConditionSelect/DayOffConditionSelect';

export interface Props {
  value: boolean;
  setValue: (state: boolean) => void;
  title?: string;
}

const RadioDayOffCondition: FC<Props> = (props) => {
  const { value, setValue, title } = props;

  const handlePressReset = () => {
    setValue(true);
  };
  const handlePressAccumulate = () => {
    setValue(false);
  };

  const [period, setPeriod] = useState<
    { label: string; value: string } | undefined
  >(undefined);

  return (
    <div>
      <p className={styles.title}>{title ?? 'Day off conditions'}</p>
      <div className={styles.containerRadio}>
        <div onClick={handlePressReset} className={styles.row}>
          <div
            className={`${styles.radioBase} ${
              value && styles.radioOn
            }`}
          />
          <p className={styles.radioTitle}>Reset Days</p>
        </div>
        {value && (
          <DayOffConditionSelect
            value={period}
            onChangeValue={setPeriod}
          />
        )}
      </div>
      <div className={styles.containerRadio}>
        <div onClick={handlePressAccumulate} className={styles.row}>
          <div
            className={`${styles.radioBase} ${
              !value && styles.radioOn
            }`}
          />
          <p className={styles.radioTitle}>Accumulate days</p>
        </div>
        {!value && (
          <DayOffConditionSelect
            value={period}
            onChangeValue={setPeriod}
          />
        )}
      </div>
      <div></div>
    </div>
  );
};

export default RadioDayOffCondition;
