import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import Breadcrumbs from '../../components/ui/Breadcrumbs';
import Button from '../../components/ui/Button';
import AbsenceDaysTitle from '../../components/AbsenceDaysTitle/AbsenceDaysTitle';
import NumberOutOfNumber from '../../components/NumberOutOfNumber/NumberOutOfNumber';
import TableRow from '../../components/ui/TableRow';
import TableCell from '../../components/ui/TableCell';
import Table from '../../components/ui/Table';
import Chip from '../../components/ui/Chip';
import Typography from '../../components/ui/Typography';
import Drawer from '../../components/ui/Drawer/Drawer';
import {ProfileBreadCrumb} from '../../components/Projects/Profile/ProfileBreadCrumb/ProfileBreadCrumb';
import DateRangePicker from '../../components/ui/DateRangePicker/DateRangePicker';

import MedicalLeaveTable from './MedicalLeaveTable/MedicalLeaveTable';
import MedicalLeaveDrawerContent from './MedicalLeaveDrawerContent/MedicalLeaveDrawerContent';
import {getHRSelector} from '../../store/humanResources/selectors';

import {HeartIcon, SunIcon} from '../../assets/icons/components';
import {ReactComponent as Pencil} from '../../assets/icons/pencil.svg';
import {ReactComponent as Plus} from '../../assets/icons/plus.svg';
import {ReactComponent as NothingFoundIcon} from '../../assets/image/NoHolidaysIcon.svg';

import routes from '../../constants/routes';
import styles from './MedicalLeave.module.css';
import {useMedicalLeave} from './useMedicalLeave';
import {NothingWasFound} from '../../components/HR/Bookkeping/Table/Plugs/NothingWasFound/NothingWasFound';
import defaultAvatar from '../../assets/icons/movadex.png';
import Default from '../../components/Default';
import {
    getCanEditSelector,
} from '../../store/profile/selectors';
import clsx from 'clsx';
import {hrApi} from '../../services/hrApi/hrApi';
import {useEffect, useState} from 'react';

const MedicalLeave = () => {
    const [dataUser, setDataUser] = useState<any>(null)
    const param = useParams();
    const canEdit = useSelector(getCanEditSelector);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await hrApi.getMembersProfile({ user_id: Number(param?.id) });
                setDataUser(user)
            } catch (err) {console.log('err',err)}
        };
        fetchData?.();
    }, []);
    const id: string = !canEdit ? String(dataUser?.id) : String(param.id);
    const {membersProfile} = useSelector(getHRSelector);
    const navigate = useNavigate();
    const {
        isSickLeave,
        searchParams,
        list,
        counts,
        selectedItem,
        drawerCreateShow,
        drawerEditShow,
        onDrawerCreateHandler,
        onDrawerEditHandler,
        handleOpenEditDrawer,
        handleDeleteItem,
        handleUploadFile,
        handleOnChangeDatePicker,
        setSearchParams,
        handleUpdateTable,
        params,
        used,
        totals,
        loading,
    } = useMedicalLeave(id);

    const redirectToPolicy = () => {
        navigate(routes.policyConditions);
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.menuWrapper}>
                <Breadcrumbs
                    itemComponent={
                        membersProfile?.id && (
                            <ProfileBreadCrumb
                                clickable={true}
                                onClick={() =>
                                    canEdit
                                        ? navigate(routes.employeeProfile + `/${id}`)
                                        : navigate(routes.myProfile)
                                }
                                key={id + dataUser?.full_name}
                                url={dataUser?.avatar_url || defaultAvatar}
                                title={dataUser?.full_name}
                            />
                        )
                    }
                    indexItemComponent={1}
                    routeName={`${canEdit ? `Human Resources$(${routes.humanResources})/` : ''}Profile$(${routes.employeeProfile}/${id})/${
                        isSickLeave ? 'Medical Leave' : 'Day Off'
                    }`}
                />



                <div className={styles.profileWrapperRight}>
                    {canEdit &&
                      <Button
                        onClick={redirectToPolicy}
                        color='secondary'
                        className={styles.buttonText}>
                        <Pencil className={styles.iconStyle}/>
                        <p>Edit policy</p>
                      </Button>}
                    <Button onClick={onDrawerCreateHandler} color='black'>
                        <Plus className={styles.iconStyle}/>
                        <p>
                            {isSickLeave ? `${canEdit ? 'Add ' : 'Request '}Medical Leave` : `${canEdit ? 'Add ' : 'Request '}Vacations `}
                        </p>
                    </Button>
                </div>

            </div>
            <div className={styles.containerTitle}>
                <AbsenceDaysTitle
                    icon={isSickLeave ? <HeartIcon/> : <SunIcon/>}
                    title={isSickLeave ? 'Medical Leaves' : 'Days Off'}
                    secondColor={
                        isSickLeave
                            ? styles.medicalLeaveSecondColor
                            : styles.daysOffSecondColor
                    }
                />
                {counts && (
                    <NumberOutOfNumber
                        firstNumber={used}
                        secondNumber={totals}
                        additionalStyle={'mLeft16'}
                    />
                )}
            </div>
            <div className={styles.containerRange}>
                <DateRangePicker
                    startDate={
                        JSON.stringify(searchParams.get('date_from')) || undefined
                    }
                    endDate={
                        JSON.stringify(searchParams.get('date_to')) || undefined
                    }
                    onChange={handleOnChangeDatePicker}
                />
                {params.date_from && (
                    <Chip
                        className={'mLeft8'}
                        variant='contained'
                        color={'error'}
                        background={'errorBg'}
                        onClick={() => setSearchParams()}>
                        <Typography variant={'smallText'} weight={'regular'}>
                            Reset time period
                        </Typography>
                    </Chip>
                )}
            </div>
            {!loading ? (
                list.length ? (
                    <Table tableContainerClass={styles.table}>
                        <thead>
                        <TableRow>
                            <TableCell component='th' sortBy='date_from'>
                                Period
                            </TableCell>
                            <TableCell
                                align='center'
                                component='th'
                                sortBy='is_self_paid'>
                                Self Paid
                            </TableCell>
                            <TableCell align='center' component='th'>
                                Duration
                            </TableCell>
                            <TableCell align='center' component='th'>
                                Status
                            </TableCell>
                            <TableCell component='th'>Comment</TableCell>
                            {isSickLeave && (
                                <TableCell align='center' component='th'>
                                    File
                                </TableCell>
                            )}
                            <TableCell align='right' component='th'/>
                        </TableRow>
                        </thead>
                        <tbody
                            className={clsx(
                                !canEdit && styles.disablePointerEvent
                            )}>
                        {list?.map((e) => (
                            <MedicalLeaveTable
                                key={e.id}
                                data={e}
                                isSickLeave={isSickLeave}
                                handleUploadFile={handleUploadFile}
                                handleEdit={handleOpenEditDrawer}
                                handleDelete={handleDeleteItem}
                            />
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className={styles.rootPlug}>
                        <NothingWasFound
                            icon={
                                isSickLeave ? (
                                    <NothingFoundIcon
                                        className={styles.nothingFoundIcon}
                                    />
                                ) : undefined
                            }
                            title={
                                isSickLeave
                                    ? 'Stay healthy and well!'
                                    : 'Enjoy your well-deserved breaks!'
                            }
                            subTitle={
                                isSickLeave
                                    ? 'View medical leave records and history here. No records available at the moment.'
                                    : 'Review your days off history and details here. No records available currently.'
                            }
                            btnTitle={
                                isSickLeave ? 'Add Medical Leave' : 'Add Vacations'
                            }
                            onClickBtn={onDrawerCreateHandler}
                        />
                    </div>
                )
            ) : (
                <Default/>
            )}
            <Drawer
                headerIcon={isSickLeave ? <HeartIcon/> : <SunIcon/>}
                title={isSickLeave ? 'Medical Leave' : 'Days Off'}
                classNameIcon={
                    isSickLeave
                        ? styles.medicalLeaveSecondColor
                        : styles.daysOffSecondColor
                }
                anchor='right'
                open={drawerCreateShow}
                classNames={styles.drawer}
                onClose={onDrawerCreateHandler}>
                <MedicalLeaveDrawerContent
                    type={isSickLeave ? 'Sick Leave' : 'Day Off'}
                    userId={id}
                    updateTable={handleUpdateTable}
                    handleClose={onDrawerCreateHandler}
                    used={used}
                    total={totals}
                    isEdit={false}
                />
            </Drawer>
            <Drawer
                headerIcon={isSickLeave ? <HeartIcon/> : <SunIcon/>}
                title={isSickLeave ? 'Edit Medical Leave' : 'Edit Days Off'}
                classNameIcon={
                    isSickLeave
                        ? styles.medicalLeaveSecondColor
                        : styles.daysOffSecondColor
                }
                anchor='right'
                open={drawerEditShow}
                classNames={styles.drawer}
                onClose={onDrawerEditHandler}>
                <MedicalLeaveDrawerContent
                    type={isSickLeave ? 'Sick Leave' : 'Day Off'}
                    userId={id}
                    isEdit={true}
                    data={selectedItem}
                    updateTable={handleUpdateTable}
                    handleClose={onDrawerEditHandler}
                    used={used}
                    total={totals}
                />
            </Drawer>
        </div>
    );
};

export default MedicalLeave;
