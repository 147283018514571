import queryString from 'query-string';
import API from '../http';
import { IHRApi } from './types';

export const hrApi: IHRApi = {
  getRecentProjects: (user_id) =>
    API.get(`/user/recent_projects?user_id=${user_id}`),
  filters: () => API.get('/human_resources/filter'),
  saveFilter: (data) => API.post('/human_resources/filter', data),
  editFilter: (data) => API.put('/human_resources/filter', data),
  deleteFilter: (name) =>
    API.delete('/human_resources/filter', { params: { name: name } }),
  list: (data) =>
    API.get(`/human_resources?${queryString.stringify(data)}`),
  archiveMember: (data) =>
    API.post('/human_resources/team-member/archive', data),
  deleteMember: (data) =>
    API.delete('/user/profile', { params: data }),
  totalByDepartment: (data) =>
    API.get(
      `/human_resources/total-by-departments?${queryString.stringify(
        data
      )}`
    ),
  isAvailableTotal: () =>
    API.get('/human_resources/team-member/is_available/total'),
  getTotalEmployees: () =>
    API.get('/human_resources/total-employees'),
  getLeaveToday: () => API.get('/day-off/on-leave-today'),
  getUpcomingBirthday: () => API.get('/user/users_birthdays'),
  getPendingRequests: () => API.get('/requests/count-by-type?request_statuses=Pending'),
  updateUserAvailability: (data) =>
    API.post('/human_resources/team-member/availability', data),
  createMembersProfile: (data) =>
    API.post('/user/profile', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // "Content-Type": "multipart/form-data",
      },
    }),
  getMembersProfile: (data) =>
    API.get('/user/profile', { params: data }),
  getAllHolidays: (data) => API.get('/holidays', { params: data }),
  membersProfileInfoEdit: (data) =>
    API.put('/user/profile_info', data),
  membersProfileEdit: (data) => API.put('/user/profile', data),
  getPaidCount: (data) =>
    API.get('/day-off/paid-counts', { params: data }),
  getDayOffList: (data) => API.get('/day-off', { params: data }),
  getLeaveCalendar: (data) =>
    API.get('/day-off/calendar', { params: data }),
  getLeaveSummary: (data) => API.get('/day-off/summary', { params: data }),
  createDayOff: (data) => API.post('/day-off', data),
  editDayOff: (data) => API.put('/day-off', data),
  deleteDayOff: (data: number) =>
    API.delete('/day-off', { params: { day_off_id: data } }),
  getEquipments: (data) =>
    API.get('/equipments', { params: { user_id: data } }),
  editEquipments: (data) => API.put('/equipments', data),
  createEquipments: (data) => API.post('/equipments', data),
  uploadFile: (data, id) => {
    const queryParams = new URLSearchParams({
      user_id: id,
    }).toString(); // Create query parameters string
    const url = `/documents?${queryParams}`;
    return API.post(url, data);
  },
  deleteEquipments: (data) =>
    API.delete('/equipments', { params: { equipment_id: data } }),
  sendEmails: (data) => API.post('/human_resources/email/send', data),
};
