import { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import styles from './DetailedInput.module.css';
import { detailedOptionType } from '../DetailedSelect/types';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/chevron-down.svg';
import { optionType } from '../useSelect';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import Typography from '../../Typography';

interface IDetailedInput {
  onClick: (e: React.MouseEvent) => void;
  placeholder?: string;
  isDetailed: boolean;
  detailedSelected?: detailedOptionType[];
  defaultOptions?: optionType[];
  label?: string;
  isError?: boolean;
  showOptions?: boolean;
  disabled?: boolean;
  subTitle?: string;
  required?: boolean;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  showSearch?: boolean;
  disableAllSubTitles?: boolean;
  detailedInputStyle?: string;
  customTitle?: string;
  placeholderStyles?: string;
  handleCloseOptions?: () => void;
  smallPadding?: boolean;
}

export const DetailedInput: FC<IDetailedInput> = ({
  onClick,
  detailedSelected,
  placeholder,
  defaultOptions,
  isDetailed,
  label,
  isError,
  showOptions,
  disabled,
  subTitle,
  required,
  setSearchValue,
  searchValue,
  showSearch,
  disableAllSubTitles,
  detailedInputStyle,
  customTitle,
  placeholderStyles,
  handleCloseOptions,
  smallPadding,
}) => {
  const length = detailedSelected?.length;

  const absoluteWrapperStyles = clsx(
    styles.absolute,
    showOptions && styles.absoluteFocused,
    isError && styles.absoluteError
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showOptions && document) {
      inputRef.current?.focus();
    }
  }, [showOptions]);
  return (
    <>
      {!disableAllSubTitles && label && (
        <p
          onClick={() =>
            !disabled && handleCloseOptions && handleCloseOptions()
          }
          className={clsx(
            styles.label,
            disabled && styles.disabledLabel
          )}>
          {label}
          {required && (
            <span
              className={clsx(
                styles.required,
                disabled && styles.requiredDisabled
              )}>
              *
            </span>
          )}
        </p>
      )}
      {!disableAllSubTitles && subTitle && (
        <Typography
          variant={'mediumLabel'}
          className={`mBottom4 ${styles.subTitle}`}>
          {subTitle}
        </Typography>
      )}
      <div
        className={clsx(
          styles.input,
          smallPadding && styles.smallPadding,
          disabled && styles.disabled,
          showOptions && styles.focused,
          isError && styles.redBorder,
          detailedInputStyle
        )}
        onClick={(e) => !disabled && onClick(e)}>
        <div className={absoluteWrapperStyles} />
        <div
          className={clsx(
            styles.containerText,
            smallPadding && styles.smallContainerText
          )}>
          {(defaultOptions?.length || detailedSelected?.length) &&
          (!showSearch || (showSearch && !showOptions)) ? (
            detailedSelected && length === 1 ? (
              <div className={styles.container}>
                <ImageFallback
                  className={styles.avatar}
                  src={detailedSelected[0].avatar_url}
                />
                <p className={styles.text}>
                  {detailedSelected[0].full_name}
                </p>
              </div>
            ) : (
              <div className={styles.text}>
                {customTitle
                  ? customTitle
                  : isDetailed
                  ? detailedSelected
                      ?.map((item) => item.full_name)
                      .join(', ')
                  : defaultOptions
                      ?.map((item) =>
                        item.label ? item.label : item.name
                      )
                      .join(', ')}
              </div>
            )
          ) : showSearch ? (
            <input
              ref={inputRef}
              className={styles.selectInput}
              placeholder={placeholder}
              id='input_select'
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          ) : (
            <p
              className={clsx(styles.placeholder, placeholderStyles)}>
              {placeholder}
            </p>
          )}
        </div>
        <div className={styles.chevron}>
          <ChevronDownIcon />
        </div>
      </div>
    </>
  );
};
