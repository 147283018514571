import { memo } from 'react';
import clsx from 'clsx';
import Typography from '../Typography/Typography';

import { InputLabelProps } from './InputLabel.model';

import styles from './InputLabel.module.css';

const InputLabel = ({
                      noMargin,
                      className,
                      children,
                      marginSm,
                      muted,
                      ...props
                    }: InputLabelProps) => {
  const classNames = clsx(
    styles.root,
    className,
    {
      [styles.noMargin]: noMargin,
      [styles.marginSm]: marginSm,
      [styles.muted]: muted,
    },
  );

  return (
    <label className={classNames} {...props}>
      <Typography variant="smallBody">
        {children}
      </Typography>
    </label>
  );
};

export default memo(InputLabel);
