import { FC, memo } from 'react';
import styles from './Status.module.css';
import { IHRListItem } from '../../../services/hrApi/types';

interface IStatus {
  item: IHRListItem['departments'][0];
}
const Status: FC<IStatus> = ({ item }) => {
  return (
    <div
      style={
        item.fill_color
          ? { backgroundColor: item.fill_color }
          : undefined
      }
      className={styles.status}>
      {item.emoji && <p className={styles.emoji}>{item.emoji}</p>}
      <p
        style={
          item.color
            ? { color: item.color, userSelect: 'text' }
            : undefined
        }>
        {item?.name && item.name}
      </p>
    </div>
  );
};

export default memo(Status);
