import { useState } from 'react';
import Breadcrumbs from '../../../../components/ui/Breadcrumbs/Breadcrumbs';
import routes from '../../../../constants/routes';
import styles from './CreateNewProfile.module.css';
import { useNavigate } from 'react-router-dom';
import { ProjectUpdateData } from '../../../../components/Projects/ProjectUpdateData/ProjectUpdateData';
import { useCreateProjectMutation } from '../../../../store/project/projectApi';
import { ICreateNewProjectFormData } from './types';

const CreateNewProfile = () => {
  const [selectedColor, setSelectedColor] = useState('#E6F7F6');

  const navigate = useNavigate();

  const handleNavigateToProfile = () =>
    navigate(routes.projectsProfile);

  const [createProject] = useCreateProjectMutation();

  const handleSubmitForm = async (
    data: ICreateNewProjectFormData
  ) => {
    try {
      const res: any = await createProject(data);
      if (res?.error) {
        console.log('Error: ', res.error);
      } else {
        navigate(routes.projectsProfile + `/${res.data.id}`, {
          state: res.data,
        });
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  return (
    <div className={styles.root}>
      <Breadcrumbs
        routeName={`projects$(${routes.projectsTable})/Create New Project$(${routes.projectsCreate})$(https://cdn.pixabay.com/photo/2016/11/13/19/43/logo-1821863_640.png)`}
      />
      <div className={styles.container}>
        <div className={styles.form}>
          <p className={styles.title}>Сreate new project</p>
          <ProjectUpdateData
            handleSubmitForm={handleSubmitForm}
            buttonTitle='Create New Project'
            inputStyle={styles.sizeInput}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateNewProfile;
