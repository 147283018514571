import { useRef } from 'react';
import Menu from '../ui/Menu';
import Typography from '../ui/Typography';
import {
  ActionMenuItemProps,
  ActionMenuProps,
} from './ActionMenu.model';
import styles from '../HR/Bookkeping/Accounts/Accounts.module.css';

export const ActionMenuItem = ({
  icon,
  name,
  onClick,
  lastElement,
  textColor,
}: ActionMenuItemProps) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.containerSelectItem} ${
        !lastElement && styles.borderBottom
      }`}>
      <div className={`${styles.containerIcon}`}>{icon}</div>
      <Typography color={textColor as 'gray'} variant='largeLabel'>
        {name}
      </Typography>
    </div>
  );
};

const ActionMenu = ({
  isOpen,
  anchorEl,
  onClose,
  options,
  className,
  position = 'bottomLeft',
}: ActionMenuProps) => {
  const notifications = useRef<HTMLDivElement>(null);

  return (
    <Menu
      node={notifications?.current}
      position={position}
      isOpen={isOpen}
      anchorEl={anchorEl}
      className={className}
      onClose={onClose}>
      {options.map((option, index) => (
        <ActionMenuItem
          key={index}
          lastElement={options?.length - 1 === index}
          {...option}
        />
      ))}
    </Menu>
  );
};

export default ActionMenu;
