import React, {FC, useEffect, useState} from 'react';
import moment from 'moment';
import PropositionType from '../../PropositionType/PropositionType';
import Typography from '../../ui/Typography/Typography';
import styles from './SupportInformation.module.css';
import {propositionsApi} from '../../../services/propositionsApi';
import {OptionComponent} from './OptionComponent/OptionComponent';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopLeft} from '../../ui/Snackbar/constants';

export interface IItem {
    created_at: string;
    id: number;
    proposition_status: 'Resolved' | 'Pending' | 'In Progress';
    proposition_type: string;
    text: string;
    user_avatar_url: null;
    user_first_name: string;
    user_id: null;
    user_last_name: null;
}

enum RequestStatus {
    Resolved = 'Resolved',
    Pending = 'Pending',
    InProgress = 'In Progress',
}

interface IProps {
    item: IItem | undefined;
    refetch: any;
    onClose?: any
}

export const SupportInformationDrawer: FC<IProps> = ({item, refetch, onClose}) => {
    const [info, setInfo] = useState<IItem | undefined>(item);

    useEffect(() => {
        setInfo(item);
    }, [item]);

        const handleChangeStatus = async (status: 'Resolved' | 'Pending' | 'In Progress') => {
            setInfo((prev: IItem | undefined) => {
                return prev && { ...prev, proposition_status: status };
            });
            if (item) {
                try {
                    await propositionsApi.changeStatus({
                        proposition_id: item.id,
                        proposition_status: status,
                    });
                    refetch();
                    enqueueSnackbar(`Status has been changed to ${status}`, {
                        variant: 'success',
                        anchorOrigin: snackbarPosTopLeft,
                    });
                    onClose?.(); // Call onClose after the asynchronous operations are complete
                } catch (error) {
                    console.error('Error changing status:', error);
                    enqueueSnackbar('An error occurred', {
                        variant: 'error',
                        anchorOrigin: snackbarPosTopLeft,
                    });
                }
            } else {
                enqueueSnackbar('An error occurred', {
                    variant: 'error',
                    anchorOrigin: snackbarPosTopLeft,
                });
            }
        };


return (
    <div className={styles.wrapper}>
        <Typography variant='subHeading1'>Support</Typography>
        <div className={styles.row}>
            <PropositionType
                type={item?.proposition_type === 'Proposition' ? 'Proposition' : 'Complaint'}
            />
            <div style={{marginLeft: 8}}/>
            <Typography variant='smallBody' className={styles.textColor}>
                {item?.user_first_name && item.user_first_name}
            </Typography>
        </div>
        <div>
            <div className={styles.containerRow}>
                <Typography variant='smallBody' className={styles.containerText}>
                    Date
                </Typography>
                <p className={styles.monospace}>
                    {item?.created_at && moment(item.created_at).format('D MMMM YYYY HH:mm')}
                </p>
            </div>
            <div className={styles.containerRow}>
                <Typography variant='smallBody' className={styles.containerText}>
                    Status
                </Typography>
                {Object.values(RequestStatus).map((status) => (
                    <span
                        onClick={() => handleChangeStatus(status)}
                        key={status}
                        className={`${styles.status} ${status === info?.proposition_status ? styles[`active${status === 'In Progress' ? 'InProgress' : status}`] : ''}`}
                    >{status}</span>)
                )}
            </div>
            <div className={`${styles.divide} ${styles.mBottom}`}/>
            <Typography variant='body'>{item?.text && item.text}</Typography>
            <div className={`${styles.divide} ${styles.mTop}`}/>
        </div>
    </div>
);
}
;

// Export the OptionComponent if needed
export {OptionComponent};
