import { createSlice } from '@reduxjs/toolkit'
import { accountsApi } from './accountsApi'

export type AccountsState = {
  account: Array<any>
}

const initialState: AccountsState = {
  account: []
}

export const accountSlice  = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      accountsApi.endpoints.getAccount.matchFulfilled,
      (state, { payload }) => {
        state.account = payload
      }
    )
  }
})

export const { setAccount } = accountSlice.actions

export default accountSlice.reducer
