import { NumberOutOfNumberProps } from './NumberOutOfNumberProps'
import Typography from '../ui/Typography'
import styles from './NumberOutOfNumber.module.css'

const NumberOutOfNumber = ({ firstNumber = 0, secondNumber = 0, additionalStyle }: NumberOutOfNumberProps) => {
  return(
    <div className={`wrap ${styles.wrapper} ${additionalStyle}`}>
      <Typography
        variant={'subHeading2'}
        weight={'bold'}>
        {firstNumber}/{secondNumber}
      </Typography>
    </div>
  )
}

export default NumberOutOfNumber
