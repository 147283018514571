import {FC} from 'react';

import styles from './TeamCard.module.css';
import { TeamMemberCard } from './TeamMemberCard/TeamMemberCard';
import { TeamListCard } from './TeamListCard/TeamListCard';
import { IProjectMember } from '../../../services/projectsApi/types';
import { useNavigate } from 'react-router-dom';
import routes from '../../../constants/routes';

const TeamCard: FC<{ array: IProjectMember[] }> = ({ array }) => {
  const clicked = array.slice(0, 4);
  const navigate = useNavigate();
  const handleNavigateToProfile = (id: number) => {
    navigate(routes.employeeProfile + `/${id}`);
  };
  return (
    <div className={styles.container}>
      {clicked.map((el, index) =>
        index < 3 ? (
            <TeamMemberCard
                handleNavigateToProfile={handleNavigateToProfile}
                key={el.id}
                index={el.id}
                item={el}
          />
            ) : (
        <TeamListCard
            key={'listCardKey'}
            index={el.id}
            item={el}
            handleNavigateToProfile={handleNavigateToProfile}
            array={array.slice(3, array.length)}
          />
        )
        )}
            </div>
  );
};

export default TeamCard;
