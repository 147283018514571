export const currencies = [{ name: '$ USD' }, { name: '₴ UAH' }];

export const seniorityLevels = [
  { name: 'Trainee' },
  { name: 'Junior' },
  { name: 'Strong Junior' },
  { name: 'Middle' },
  { name: 'Senior' },
  { name: 'Freelance' },
];

export const productTypes = [
  { name: 'Mobile Application' },
  { name: 'Website' },
  { name: 'CRM' },
  { name: 'CMS' },
  { name: 'Web Platform' },
];

export const developmentMethodology = [
  { name: 'Waterfall' },
  { name: 'Agile' },
  { name: 'Scrum' },
  { name: 'Kanban' },
  { name: 'Lean Development' },
  { name: 'DevOps' },
  { name: 'Extreme Programming' },
  { name: 'Projects in Controlled Environments' },
  { name: 'Crystal' },
  { name: 'Rapid Application Development' },
  { name: 'Spiral' },
  { name: 'Feature-Driven Development' },
];

export const productIndustry = [
  { name: 'Art, media & entertainment' },
  { name: 'Business' },
  { name: 'Ecology' },
  { name: 'eCommerce' },
  { name: 'Education' },
  { name: 'Financial Services' },
  { name: 'Government' },
  { name: 'Lifestyle & Healthcare' },
  { name: 'Marketing & advertising' },
  { name: 'Retail' },
  { name: 'Real Estate' },
  { name: 'Science' },
  { name: 'Software & Informational Technology' },
  { name: 'Startup' },
];

export const formsOfCoops = [
  { name: 'Full-time' },
  { name: 'Freelancer' },
  { name: 'Outsource' },
  { name: 'Part-time' },
  { name: 'Outstaff' },
];

export const languagesLevels = [
  { name: 'Beginner' },
  { name: 'Pre-intermediate' },
  { name: 'Intermediate' },
  { name: 'Upper-Intermediate' },
  { name: 'Advanced' },
  { name: 'Mastery' },
  { name: 'Native' },
];

export const statuses = [
  { name: 'In Progress' },
  { name: 'On Hold' },
  { name: 'Finished' },
  { name: 'Canceled' },
];

export const services = [
  { name: 'Design' },
  { name: 'Branding' },
  { name: 'Development' },
  { name: 'QA' },
];

export const categoriesLinks = [
  { name: 'Design' },
  { name: 'Development' },
  { name: 'QA' },
  { name: 'Other' },
];

export const equipmentOwn = [{ name: 'Rent' }, { name: 'Mortgage' }];

export const periodOfCooperationSelect = [
  { name: 'Full-time', value: 'Full-time' },
  { name: 'Part-time', value: 'Part-time' },
  { name: 'Freelancer', value: 'Freelancer' },
  { name: 'Outsource', value: 'Outsource' },
  { name: 'Outstaff', value: 'Outstaff' },
];

export const typeOfCooperationSelect = [
  { name: 'Less than 1 year', value: 0 },
  { name: 'More than 1 year', value: 1 },
  { name: 'More than 2 years', value: 2 },
  { name: 'More than 3 years', value: 3 },
  { name: 'More than 4 years', value: 4 },
  { name: 'More than 5 years', value: 5 },
];

export const typeOfDocumentSortSelect = [
  { name: 'Sort by Name', label: 'document_name' },
  { name: 'Sort by Size', label: 'document_size_mb' },
  { name: 'Sort by Date', label: 'created_at' },
];

export const projectServiceSelect = [
  { label: 'design', value: 'Design' },
  { label: 'brand', value: 'Branding' },
  { label: 'development', value: 'Development' },
  { label: 'QA', value: 'QA' },
];
