import { FC } from 'react';

import Modal from '../../../../../../ui/Modal/Modal';
import { LinksWrapperModal } from './LinksWrapperCRUD';

import styles from './LinksModal.module.css';

interface ILinksModal {
  isOpen: boolean;
  onClose: () => void;
}

export const LinksModal: FC<ILinksModal> = ({ isOpen, onClose }) => {
  return (
    <Modal
      className={styles.modal}
      classNameOverlay={styles.overlay}
      isOpen={isOpen}
      onClose={onClose}>
      <LinksWrapperModal onClose={onClose} />
    </Modal>
  );
};
