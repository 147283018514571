import { FC } from 'react';
import clsx from 'clsx';
import {
  CategoryCard,
  ICategoryCard,
} from '../../../../../HR/Bookkeping/CategoryCard/CategoryCard';
import styles from './Status.module.css';

const categories: ICategoryCard['type'][] = [
  'In Progress',
  'On Hold',
  'Finished',
  'Canceled',
];

export const StatusComponent: FC<{
  onClick?: (e: React.MouseEvent) => void;
  status: string;
  style?: string;
  reference?: React.MutableRefObject<null>;
}> = ({ onClick, status, style, reference }) => {
  return (
    <div
      ref={reference}
      className={clsx(styles.container, style)}
      onClick={onClick}>
      <CategoryCard type={status as ICategoryCard['type']} />
    </div>
  );
};

export interface IStatusSelect {
  onClick?: () => void;
  handleShowOptions?: () => void;
  onFetch: (text: string) => void;
}
export const StatusSelect: FC<IStatusSelect> = ({
  onFetch,
  handleShowOptions,
}) => {
  return (
    <>
      {categories.map((el, index) => (
        <div
          onClick={() => {
            onFetch(el);
            handleShowOptions && handleShowOptions();
          }}
          key={index}
          className={styles.option}>
          <CategoryCard type={el} />
        </div>
      ))}
    </>
  );
};
