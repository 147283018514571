import React, {useEffect, useState} from 'react';
import styles from './BenchPage.module.css';
import Typography from '../ui/Typography';
import {DepartmentList} from '../sharedPages/HumanResourcesPage/components/DepartmentList/DepartmentList';
import {useTable} from '../sharedPages/HumanResourcesPage/components/Table/useTable';
import {useSearchParams} from 'react-router-dom';
import {IHRListParams} from '../../services/hrApi/types';
import {useGetQueryParams} from '../../utils/common';
import {CustomSelect} from '../ui/CustomSelect/CustomSelect';
import Switch from '../ui/Switch';
import BenchTable from './BenchTable/BenchTable';
import {benchApi} from '../../services/benchApi';
import Default from '../Default';
import {commonApi} from '../../services/commonApi/commonApi';
import TablePagination from '../ui/TablePagination';
import {IData, IFilterData, IManager} from './types';


const filterData: IFilterData = {
    team: false,
    onlyBench: true,
    teamManager: null
};

const BenchPage = () => {
    const params: IHRListParams = useGetQueryParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const {searchValue, setSearchValue, positions, total, data} = useTable(searchParams, setSearchParams);
    const [managers, setManagers] = useState<IManager>({options: [], selected: []});
    const [filters, setFilters] = useState<IFilterData>(filterData);
    const [benchData, setBenchData] = useState<IData | null>(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await benchApi.getBenchList({
                    page: params?.page,
                    my_team_only: filters.team,
                    on_bench: filters.onlyBench,
                    size: params?.size || 20,
                    hr_manager_ids: managers.selected?.map(e => e.id) || [],
                    sort_by: params.sort_by || [],
                    sort_by_type: params.sort_by_type || [],
                    search: searchValue,
                    department: params.department || [],
                });
                setBenchData(res);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData?.();
    }, [filters, managers.selected, params.page, searchValue, params.department, params.sort_by, params.sort_by_type]);

    useEffect(() => {
        getManagers?.();
    }, []);

    const getManagers = async () => {
        try {
            const res: any[] = await commonApi.getUsers({hr_manager: true});
            const formattedManagers: any[] = res.map(manager => ({
                name: manager.full_name,
                id: manager.id,
            }));
            setManagers({...managers, options: formattedManagers});
        } catch (error) {
            console.error('Error occurred while fetching managers:', error);
        }
    };
     const handleSelectChange = (selectedOptions: any[]) => { // Change parameter type
        const selectedNames = selectedOptions.map(option => option.name);
        const filters = managers.options.filter(option => selectedNames.includes(option.name));
        setManagers(prevState => ({
            ...prevState,
            selected: filters
        }));
    };
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Typography variant="h5" weight="bold">Bench</Typography>
            </div>
            <div className={styles.navContainer}>
                <DepartmentList
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    total={total}
                    params={params}
                    data={data}
                    positions={positions}
                />
            </div>
            <div className={styles.filters}>
                <CustomSelect
                    options={managers.options}
                    multi
                    limitedViewOption
                    placeholder='Team manager'
                    onChangeMultiValue={handleSelectChange}
                    multiAllTitle='All Managers'
                    disableAllSubTitles
                    defaultOptions={managers.options}
                />
                <div className={styles.clickerLeftCheckbox}>
                    <Switch
                        checked={filters.team}
                        onChange={() => setFilters(prev => ({...prev, team: !prev.team}))}
                    />
                    Show my team only
                </div>
                <div className={styles.clickerLeftCheckbox}>
                    <Switch
                        checked={filters.onlyBench}
                        onChange={() => setFilters(prev => ({...prev, onlyBench: !prev.onlyBench}))}
                    />
                    Show only those on bench
                </div>
            </div>
            {benchData?.items ? <BenchTable list={benchData.items}/> : <Default/>}
            {benchData?.total ?
              <TablePagination
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                currentStartPage={benchData?.page}
                count={benchData?.total}
                itemsPerPage={benchData?.size}
              /> : null}
        </div>
    );
};

export default BenchPage;
