import * as Yup from 'yup';

export const projectUpdateSchema = Yup.object().shape({
  name: Yup.string().required('Project Name is required.'),
  emoji: Yup.string(),
  color: Yup.string(),
  client_full_name: Yup.string().required(
    'Client Full Name is required.'
  ),
  services: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
      })
    )
    .test(
      'haveItems',
      'At least one Service must be selected.',
      (value) => {
        return value && Boolean(value[0]);
      }
    )
    .required('Required'),
});
