import clsx from 'clsx';
import { FC } from 'react';

import styles from './Card.module.css';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon.svg';
import { ICondition } from '../../../../services/policyApi/types';

interface ICard {
  data: ICondition;
  handleDeleteCondition: (data: ICondition) => void;
  handleEditCondition: (data: ICondition) => void;
}
export const Card: FC<ICard> = ({
  data,
  handleDeleteCondition,
  handleEditCondition,
}) => {
  const options = [
    {
      label: 'Period of cooperation',
      value:
        data?.years_of_cooperation > 0
          ? 'More than ' +
            data.years_of_cooperation +
            ' year' +
            (data.years_of_cooperation > 1 ? 's' : '')
          : 'Less than 1 year',
    },
    {
      label: 'Type of cooperation',
      value: data?.forms_of_coops?.join(', '),
    },
    {
      label: 'Days off',
      value: `${data?.max_days_off}${
        data?.reset_days_off ? ', Reset Days' : ', Accumulate days'
      }`,
    },
    {
      label: 'Medical leaves',
      value: `${data?.max_sick_leaves}${
        data?.reset_sick_leaves ? ', Reset Days' : ', Accumulate days'
      }`,
    },
    {
      label: 'Comment',
      value: data.comment ? (
        data.comment
      ) : (
        <p className={styles.grayText}>No comment</p>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>{'Condition #' + data.id}</p>
        <div className={styles.containerIcons}>
          <div
            onClick={() => handleEditCondition(data)}
            className={styles.containerIcon}>
            <div className={styles.icon}>
              <PencilIcon />
            </div>
          </div>
          <div
            onClick={() => handleDeleteCondition(data)}
            className={clsx(styles.containerIcon, styles.colorWine)}>
            <div className={styles.icon}>
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
      {options.map((e, index) => (
        <div key={index} className={styles.containerRow}>
          <p className={styles.label}>{e.label}</p>
          <p className={styles.value}>{e.value}</p>
        </div>
      ))}
    </div>
  );
};
