import queryString from 'query-string';
import API from '../http';
import { IPolicyApi } from './types';

export const policyApi: IPolicyApi = {
  getConditions: () => API.get('/day-off-policy'),
  editCondition: (data) => API.put('/day-off-policy', data),
  createCondition: (data) => API.post('/day-off-policy', data),
  deleteCondition: (id) =>
    API.delete('/day-off-policy', { params: { dop_id: id } }),
  getHolidays: (data) =>
    API.get(`/holidays?${queryString.stringify(data)}`),
  deleteHoliday: (id) => API.delete('/holidays', { params: { id } }),
  createHoliday: (data) => API.post('/holidays', data),
  editHoliday: (data) => API.put('/holidays', data),
};
