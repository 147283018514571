import React, {FC, useState, useEffect} from 'react';
import styles from './style.module.css';
import {CustomSelect} from '../../ui/CustomSelect/CustomSelect';
import DatePicker from '../../ui/DatePicker';
import CommentInput from '../../CommentInput/CommentInput';
import Button from '../../ui/Button';
import FormikInput from '../../ui/FormikInput/FormikInput';
import {timeTrackingApi} from '../../../services/timeTrackingApi/timeTrackingApi';
import moment from 'moment';
import {projectsApi} from '../../../services/projectsApi/projectsApi';
import {enqueueSnackbar} from 'notistack';
import { snackbarPosTopRight} from '../../ui/Snackbar/constants';

interface IData {
    date: Date;
    project: string | any;
    member: string | any;
    time: string;
    description: string;
}

const dataValue: IData = {
    date: new Date(),
    project: '',
    member: '',
    time: '',
    description: '',
};

interface IProps {
    members: any[];
    onClose?: () => void;
}

const SubmitHoursPopup: FC<IProps> = ({members, onClose}) => {
    const [data, setData] = useState<IData>(dataValue);
    const [options, setOptions] = useState<any>(null)

    useEffect(() => {
        const membersArray = members.map((e) => ({
            name: e.label,
            id: e.name,
            avatar: e.avatar
        }));

        setOptions((prevOptions: any) => ({
            ...prevOptions,
            members: membersArray
        }));
    }, [members]);

    const getProjects = async () => {
        const res = await projectsApi.list({
            member_ids: data?.member?.id
        });
        const projectsArray = res?.items.map((e) => ({
            name: e.name,
            avatar: e.emoji,
            id: e.id
        }));
        setOptions((prevOptions: any) => ({
            ...prevOptions,
            projects: projectsArray
        }));
    };



    useEffect(() => {
        getProjects?.()
    }, [data?.member?.id]);

    const handleChange = (fieldName: string, value: any) => {
        console.log('value', value)
        setData(prevData => ({
            ...prevData,
            [fieldName]: value
        }));
    };


    const handleSubmit = async () => {
        const res = await timeTrackingApi.postTimeTrackingSubmissions({
            user_id: Number(data?.member.id),
            project_id: Number(data?.project.id),
            date: moment(data?.date).format('YYYY-MM-DD'),
            time: data?.time,
            description: data?.description
        });
        if(res) {
            enqueueSnackbar('Successfully submitted', {
                variant: 'success',
                anchorOrigin: snackbarPosTopRight,
            });
            onClose?.()
        }
        console.log('res', res)

    }


    return (
        <div className={styles.container}>
            <form className={styles.container_form}>
                <DatePicker
                    dateProp={data.date}
                    onChange={(date: any) => handleChange('date', date)}
                    label="Date"
                    formatDate={'DD MMM YYYY'}
                    isInput
                    isWeekend
                    placeholder="Choose prolong to"
                />
                <CustomSelect
                    multiSearchPlaceholder='Type to search'
                    optionImage
                    placeholder={'Select Member'}
                    disableAllSubTitles
                    label="Member"
                    className={styles.selectInput}
                    value={data.member}
                    onChangeValue={(e) => handleChange('member', e)}
                    options={options?.members}
                />

                <CustomSelect
                    inputEmoji
                    multiSearchPlaceholder='Type to search'
                    disabled={data?.member === ''}
                    multiSearch
                    placeholder={'Select Project'}
                    disableAllSubTitles
                    label="Project"
                    className={styles.selectInput}
                    value={data.project}
                    onChangeValue={(e) => handleChange('project', e)}
                    options={options?.projects}
                />
                <FormikInput
                    wasRequest
                    label='Time'
                    value={data?.time}
                    className={styles.timeSelect}
                    mask='hh:mm'
                    placeholder='hh:mm'
                    regex={/[^0-9]/g}
                    onChange={(e) => handleChange('time', e.target.value)}
                />
                <CommentInput
                    optional
                    title={'Work description '}
                    placeHolder={'Type here...'}
                    value={data?.description}
                    setValue={(e) => handleChange('description', e)}
                />

            </form>

            <Button color={'black'} fullWidth onClick={handleSubmit}>
                Submit
            </Button>


        </div>
    );
};

export default SubmitHoursPopup;
