import React, {FC, useState, useEffect, useRef} from 'react';
import clsx from 'clsx';
import styles from './TeamMemberCard.module.css';
import {ReactComponent as TriangleIcon} from '../../../../assets/icons/triangle.svg';
import {MemberCard} from '../MemberCard/MemberCard';
import {IProjectMember} from '../../../../services/projectsApi/types';
import {ImageFallback} from '../../../ui/ImageFallback/ImageFallback';

interface ITeamMemberCard {
    item: IProjectMember;
    index: number;
    className?: string;
    handleNavigateToProfile: (id: number) => void;
}

export const TeamMemberCard: FC<ITeamMemberCard> = ({
                                                        item,
                                                        index,
                                                        handleNavigateToProfile,
                                                    }) => {
    const [showOptionsIndex, setShowOptionsIndex] = useState<number | null>(null);
    const [id, setId] = useState<number | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setShowOptionsIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleShowOptions = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, index: number) => {
        e.stopPropagation();
        setShowOptionsIndex(prevIndex => (prevIndex === index ? null : index));
        setId(index)
        setOpen(prev => !prev)
    };
    console.log('showOptionsIndex',showOptionsIndex)
    console.log('id',id)
    console.log('open',open)

    return (
        <div className={styles.relative}>
            <ImageFallback
                onClick={(e) => handleShowOptions(e, index)}
                className={clsx(styles.img, styles[`z-${index}`])}
                src={item.user?.avatar_url ? item.user.avatar_url : ''}
            />
            {(open && (showOptionsIndex === id)) && (
                <div
                    ref={containerRef}
                    onClick={(e) => e.stopPropagation()}
                    className={clsx(styles.containerOptions, styles.showOptions, styles[`left-${index}`])}
                >
                    <MemberCard
                        handleNavigateToProfile={handleNavigateToProfile}
                        item={item}
                    />
                    <div className={styles.arrow}>
                        <TriangleIcon/>
                    </div>
                </div>
            )}
        </div>
    );
};
