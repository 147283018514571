import styles from './Error.module.css'
import {ReactComponent as TV} from '../../assets/icons/error.svg'
import Button from '../../components/ui/Button';
import {useNavigate} from 'react-router-dom';
import routes from '../../constants/routes';
import {startTransition} from 'react';

const Error = () => {
    const navigate = useNavigate()
    const handleNavigate = () => {
        startTransition(() => {
            navigate(routes.dashboardInfo);
        });
    };
    return (
        <div className={styles.error}>
            <TV/>
            <span>Oops! The page you were <br /> looking for doesn’t exist</span>
            <p>You may have mistyped the address or the page may have <br/> been removed</p>
            <Button color='black' onClick={handleNavigate}>
                Go to Homepage
            </Button>
        </div>
    );
};

export default Error;
