import React, {FC} from 'react';
import clsx from 'clsx';

import styles from './ProfileCardPlug.module.css';
import {ReactComponent as CircleIcon} from '../../../../../../assets/icons/circle-dotted.svg';
import {ReactComponent as CalendarIcon} from '../../../../../../assets/icons/calendar.svg';
import {ReactComponent as AssignIcon} from '../../../../../../assets/icons/assign.svg';

interface IProfileCardPlug {
    isCalendar?: boolean;
    isAssign?: boolean;
    canEdit?: boolean;
    title: string;
    onClick: (e: React.MouseEvent) => void;
}

export const ProfileCardPlug: FC<IProfileCardPlug> = ({
                                                          title,
                                                          isCalendar,
                                                          canEdit,
                                                          isAssign,
                                                          onClick,
                                                      }) => {
    return (
        <div onClick={onClick} className={styles.container}>
            {canEdit &&
              <div
                className={clsx(
                    styles.icon,
                    isCalendar && styles.isCalendar
                )}>
                  {isCalendar ? (
                      <CalendarIcon/>
                  ) : isAssign ? (
                      <AssignIcon/>
                  ) : (
                      <CircleIcon/>
                  )}
              </div>}
            <p className={styles.title}>{title}</p>
        </div>
    );
};
