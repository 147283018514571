import styles from '../../MedicalLeave/MedicalLeaveDrawerContent/MedicalLeaveDrawerContent.module.css'
import CheckBox from '../../../components/ui/Checkbox'
import Typography from '../../../components/ui/Typography'
import React from 'react'
import DatePickerInput from '../../../components/ui/DatePickerInput'
import dateStyles from '../../../components/ProfileForm/ProfileForm.module.css'
import FormControl from '../../../components/ui/FormControl'
import CommentInput from '../../../components/CommentInput/CommentInput'
import Button from '../../../components/ui/Button'

const DaysOffDrawerContent = () => {
  return(
    <>
      <div style={{flex: 1}} className={'paddingHorizontal24'}>
        <div className={styles.checkBoxWrapper}>
          <CheckBox
            // checked={selectedWorkersIndicator}
            // onClick={onSelectAllUsers}
          />
          <Typography variant={'smallText'} className={styles.checkBoxWrapperText}>Self Paid</Typography>
        </div>
        <FormControl>
          <DatePickerInput
            name="period"
            label="Period"
            maxDate={new Date()}
            // date={watch('period') || ''}
            className={`${dateStyles.inputBorder}`}
            classNameMenu={dateStyles.menuStyle}
            // onChange={}
          />
        </FormControl>
        <FormControl>
          <CommentInput
            title={'Comment'}
            placeHolder={'Type here...'}
            value={''}
            setValue={() => ''}
          />
        </FormControl>
      </div>
      <div className={'padding24'}>
        <Button
          fullWidth
          color={'black'}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default DaysOffDrawerContent
