import { FC, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import Table from '../../../ui/Table';
import TableCell from '../../../ui/TableCell';
import TableRow from '../../../ui/TableRow';
import Typography from '../../../ui/Typography';
import { snackbarPosTopLeft } from '../../../ui/Snackbar/constants';

import { PublicHolidayTableRow } from './Row/TableRow';
import { useModal } from '../../../../hooks/useModal';
import { DeleteModal } from '../../../HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';
import { policyApi } from '../../../../services/policyApi/policyApi';
import { IPublicHoliday } from '../../../../services/policyApi/types';
import { ReactComponent as ChartLine } from '../../../../assets/icons/chart-line.svg';

import styles from './Table.module.css';
import Drawer from '../../../ui/Drawer/Drawer';
import EditHoliday from '../../Drawers/EditHoliday/EditHoliday';
interface IPublicHolidaysTable {
  data: IPublicHoliday[];
  setRender: React.Dispatch<React.SetStateAction<number>>;
}
export const PublicHolidaysTable: FC<IPublicHolidaysTable> = ({
  data,
  setRender,
}) => {
  const [item, setItem] = useState<IPublicHoliday | undefined>(
    undefined
  );

  const {
    isOpen: isOpenDelete,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();

  const handleDeleteHoliday = async () => {
    try {
      item?.id && (await policyApi.deleteHoliday(item.id));
      enqueueSnackbar('Public holiday successfully deleted', {
        variant: 'success',
        anchorOrigin: snackbarPosTopLeft,
      });
      closeDeleteModal();
      setRender((prev) => prev + 1);
    } catch (e: any) {
      enqueueSnackbar(e.response.data.detail, {
        variant: 'error',
        anchorOrigin: snackbarPosTopLeft,
      });
      closeDeleteModal();
    }
  };
  const handleRemoveItem = (data: IPublicHoliday) => {
    setItem(data);
    openDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setItem(undefined);
    closeDeleteModal();
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleEditItem = (data: IPublicHoliday) => {
    setItem(data);
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setItem(undefined);
    setOpenDrawer(false);
  };

  return (
    <>
      <Table
        tableContainerClass={styles.tbody}
        className={styles.table}>
        <thead>
          <TableRow className={styles.rootTableRow}>
            <TableCell sortBy={'date_from'}>
              <Typography
                uppercase
                variant='mediumLabel'
                color={'lightMuted'}>
                Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                uppercase
                variant='mediumLabel'
                color={'lightMuted'}>
                Holiday
              </Typography>
            </TableCell>
            <TableCell align='right' />
          </TableRow>
        </thead>
        <tbody>
          {data.map((item, index: number) => (
            <PublicHolidayTableRow
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              key={index}
              data={item}
            />
          ))}
        </tbody>
      </Table>
      <Drawer
        headerIcon={<ChartLine />}
        anchor='right'
        open={openDrawer}
        classNames={styles.drawerWrapper}
        onClose={handleCloseDrawer}
        title='Edit Public Holiday'>
        {item && (
          <EditHoliday
            setRender={setRender}
            closeDrawer={handleCloseDrawer}
            item={item}
          />
        )}
      </Drawer>
      <DeleteModal
        isOpen={isOpenDelete}
        handleShowModal={handleCloseDeleteModal}
        handleSubmit={handleDeleteHoliday}
        title='Do you want to delete this this 
        public holiday?'
        subTitle={
          <span className={styles.deleteSubTitle}>
            Are you sure you want to delete{' '}
            <span className={styles.deleteSubTitleBold}>
              {item?.name}
            </span>
            {`? This action is irreversible, and you won't be able to
            recover the holiday after deleting it.`}
          </span>
        }
      />
    </>
  );
};
