import { useModal } from '../../hooks/useModal';
import Modal from '../ui/Modal';
import clsx from 'clsx';
import styles from '../../pages/EmployeeProfile/EmployeeProfile.module.css';
import stylesModal from './EmployeeEditProfile.module.css';
import Button from '../ui/Button';
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg';
import ProfileForm from '../ProfileForm/ProfileForm';
import { IRequestTeamMember } from '../../services/hrApi/types';
import { getHRSelector } from '../../store/humanResources/selectors';
import { useSelector } from 'react-redux';
// import { hrApi } from '../../services/hrApi/hrApi';
import { useAppDispatch } from '../../store';
import { editMemberProfile } from '../../store/humanResources/thunks';
import { jsonToFormData } from '../../helpers/formData/jsonToFormData.helper';

const EmployeeEditProfile = () => {
  const { isOpen, open, close } = useModal();

  const { membersProfile } = useSelector(getHRSelector);
  const dispatch = useAppDispatch();
  const handleSubmit = async (editedData: IRequestTeamMember) => {
    try {
      const obj = jsonToFormData({
        ...editedData,
        user_id: membersProfile?.id,
      });
      await dispatch(editMemberProfile(obj));
      close();
    } catch (e) {
      return 'error';
    }
  };
  return (
    <>
      <div className={stylesModal.btn} onClick={open}>
        <Pencil className={stylesModal.iconStyle} />
      </div>
      <Modal
        isOpen={isOpen}
        onClose={close}
        className={stylesModal.profileModal}>
        <ProfileForm
          edit={true}
          initialData={membersProfile}
          onClose={close}
          onSubmitMember={handleSubmit}
          title='Edit Member’s Profile'
        />
      </Modal>
    </>
  );
};

export default EmployeeEditProfile;
