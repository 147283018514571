import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const propositionApi = createApi({
    reducerPath: 'propositionApi',
    baseQuery: ApiBaseQuery({baseUrl: '/propositions'}),
    endpoints: (builder) => ({
        getPropositions: builder.query({
            query: (data) => ({url: '', method: 'get', data, params: data.params}),
            transformResponse: (response, meta) => {
                return response;
            },
        }),
        changePropositionsStatus: builder.mutation({
            query: (data) => {
                return ({url: '/change-status', method: 'post', data: {}, params: data.params})
            },
        }),
    })
});

export const {useGetPropositionsQuery, useChangePropositionsStatusMutation} = propositionApi;
