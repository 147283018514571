import { FC } from 'react';

import styles from './DateRange.module.css';
import { Calendar } from '../../../../../ui/Calendar/Calendar';
import { useDateRange } from '../../../../../ui/DateRangePicker/useDateRange';
import moment from 'moment';
import Button from '../../../../../ui/Button/Button';

export const DateRangeComponent: FC<{
  onClick: (e: React.MouseEvent) => void;
  from: string;
  to: string;
}> = ({ onClick, from, to }) => {
  const formatToYMD = (date: string) => {
    return moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
  };
  return (
    <div className={styles.container} onClick={onClick}>
      {`${formatToYMD(from)} → ${formatToYMD(to)}`}
    </div>
  );
};

export interface IDateRangeSelect {
  onClick?: () => void;
  startDate?: string;
  endDate?: string;
  minDate?: Date;
  maxDate?: Date;
  onFetch?: (from: string, to: string) => void;
  handleShowOptions?: () => void;
}
export const DateRangeSelect: FC<IDateRangeSelect> = ({
  onClick,
  startDate,
  endDate,
  minDate,
  maxDate,
  onFetch,
  handleShowOptions,
}) => {
  const initialRange = [
    moment(startDate, 'YYYY-MM-DD').toDate(),
    moment(endDate, 'YYYY-MM-DD').toDate(),
  ].filter((el) => moment(el).isValid());

  const {
    date,
    handlePrevMonthButtonClick,
    handleNextMonthButtonClick,
    monthData,
    handleDayClick,
    includedInDateRange,
    range,
    currentDate,
    handleClearRange,
    setDate,
  } = useDateRange(initialRange, minDate, maxDate);
  const formatToYMD = (date: Date) => {
    return moment(date).format('YYYY-MM-DD');
  };
  return (
    <div className={styles.wrapper}>
      <Calendar
        date={date}
        setDate={setDate}
        handlePrevMonthButtonClick={handlePrevMonthButtonClick}
        handleNextMonthButtonClick={handleNextMonthButtonClick}
        monthData={monthData}
        handleDayClick={handleDayClick}
        currentDate={currentDate}
        additionalStyles={(date: Date) =>
          includedInDateRange(date, range, true)
        }
      />
      <div className={styles.footer}>
        <div className={styles.periodContainer}>
          Period:
          <span className={styles.period}>
            {range?.[0] && moment(range[0]).format('D MMM YYYY')}
            {range?.[1] &&
              ` - ${moment(range[1]).format('D MMM YYYY')}`}
          </span>
        </div>
        <div className={styles.containerButtons}>
          <Button
            onClick={handleClearRange}
            variant='outlined'
            className={`${styles.outlinedBtn} ${styles.baseBtn}`}>
            Сlear
          </Button>
          <Button
            onClick={() => {
              onFetch &&
                onFetch(formatToYMD(range[0]), formatToYMD(range[1]));
              handleShowOptions && handleShowOptions();
            }}
            className={styles.baseBtn}
            color='black'>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};
