import { FC } from 'react';

import styles from './WorkSchedule.module.css';
import { WorkSchedule } from '../../../../../ui/WorkSchedule/WorkSchedule';
import moment from 'moment';

export const WorkScheduleComponent: FC<{
  onClick: (e: React.MouseEvent) => void;
  from: string;
  to: string;
}> = ({ onClick, from, to }) => {
  const getTime = (time: string) =>
    moment(time, 'hh:mm:ss').format('HH:mm');
  return (
    <div className={styles.container} onClick={onClick}>
      Monday→Friday {getTime(from)} - {getTime(to)}
    </div>
  );
};

export interface IWorkScheduleSelect {
  onClick?: () => void;
  onFetch: (from?: string, to?: string) => void;
  handleShowOptions?: () => void;
  from: string;
  to: string;
}
export const WorkScheduleSelect: FC<IWorkScheduleSelect> = ({
  onClick,
  onFetch,
  handleShowOptions,
  from,
  to,
}) => {
  return (
    <>
      <WorkSchedule
        initialFrom={from}
        initialTo={to}
        onSave={(from, to) => {
          handleShowOptions && handleShowOptions();
          onFetch(from, to);
        }}
      />
    </>
  );
};
