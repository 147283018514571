import React, {FC} from 'react';
import styles from './Submission.module.css';
import routes from '../../../../constants/routes';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Emoji} from 'emoji-picker-react';
import Button from '../../../ui/Button';
import defaultAvatar from '../../../../assets/icons/movadex.png';

interface IProps {
    data: any;
    handleClose?: () => void
}

const CurrentSubmission: FC<IProps> = ({data, handleClose}) => { // Destructure the props object
    const navigate = useNavigate();
    return (
        <div className={styles.block}>
            <ul className={styles.head}>
                <li>From<span onClick={() => navigate(routes.employeeProfile + `/${data?.user.id}`)}>
                <img src={data?.user?.avatar_url || defaultAvatar} alt={'avatar'}/>
                    {data?.user?.full_name}
        </span></li>
                <li>Submitted<span>{moment(data?.created_at).format('DD MMM YYYY, HH:mm')}</span></li>
                <li>Project<span>
                    <Emoji size={24} unified={data?.project?.emoji}/>
                    {data?.project?.name}</span></li>
                <li>Date<span>{moment(data?.date).format('DD MMM YYYY')}</span></li>
                <li>Time<span>{data?.time.slice(0, 5)}</span></li>
                <li>Work Description:<span>{data?.description || 'No work description'}</span></li>
            </ul>
            <Button color='black' onClick={() => handleClose?.()}>
               Close
            </Button>
        </div>
    );
}

export default CurrentSubmission;
