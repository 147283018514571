import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const getProfile = (state: RootState) => state.profile.profile;
export const getProfileSelector = createSelector(
  getProfile,
  (profile) => profile
);
const getCanEdit = (state: RootState) => state.profile.canEdit;

export const getCanEditSelector = createSelector(
  getCanEdit,
  (canEdit) => canEdit
);

const getPositions = (state: RootState) => state.profile.positions;
export const getPositionsSelector = createSelector(
  getPositions,
  (positions) => positions
);
