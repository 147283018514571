import { FC } from 'react';
import styles from './LinksModal.module.css';
import { getDomainFromURL } from '../../../../../../../helpers/url/get-domain-from-url.helper';

import { ReactComponent as DefaultIcon } from '../../../../../../../assets/icons/favicons/default-link.svg';
import { ReactComponent as FigmaIcon } from '../../../../../../../assets/icons/favicons/figma.svg';
import { ReactComponent as GitHubIcon } from '../../../../../../../assets/icons/favicons/github.svg';
import { ReactComponent as SlackIcon } from '../../../../../../../assets/icons/favicons/slack.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/copy.svg';
import { ILinksItem } from '../../../../../../../services/projectsApi/types';
import { LinksParser } from '../linksParser';

type linksTypes = 'slack.com' | 'www.figma.com' | 'github.com';

const icons = {
  'slack.com': <SlackIcon />,
  'www.figma.com': <FigmaIcon />,
  'github.com': <GitHubIcon />,
};
export interface IList {
  list: ILinksItem[] | [];
  onItemClick: (e: any) => void;
}

export const LinksList: FC<IList> = ({ list, onItemClick }) => {
  const handleCopyText = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={styles.gridLinks}>
      {LinksParser(list)?.map(
        (el, index) =>
          !!el.items.length && (
            <div key={index} className={styles.linkWrapper}>
              <p className={styles.cardTitle}>{el.category}</p>
              <div className={styles.linksContainer}>
                {el.items.map((link: ILinksItem, index: number) => (
                  <div
                    onClick={() => onItemClick(link)}
                    key={index}
                    className={styles.linkCard}>
                    <div className={styles.linkInfoCard}>
                      <div className={styles.icon}>
                        {icons[
                          getDomainFromURL(link.url) as linksTypes
                        ] || <DefaultIcon />}
                      </div>
                      <p className={styles.cardLinkTitle}>
                        {link.name}
                      </p>
                    </div>
                    <div
                      onClick={(e) => handleCopyText(e, link.url)}
                      className={styles.copyIcon}>
                      <CopyIcon />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};
