import queryString from 'query-string';
import API from '../http';
export interface IBenchList {
    search?: string;
    hr_manager_ids?: number[],
    sort_by?: string | never[];
    sort_by_type?: string | never[];
    my_team_only?: boolean;
    on_bench?: boolean;
    size?: number,
    page?: number,
    department?: string | never[];
}

export interface IMove {
    user_id: number | any,
    type?: string,
    comment: string
    date_to?: string;
}

export interface IBenchMember {
    sort_by?: string | never[];
    sort_by_type?: string | never[];
    action?: string[] | never,
    created_by?: string[] | never,
    date_from?: string,
    date_to?: string
}

export const benchApi = {
    getBenchList: (params: IBenchList) => API.get(`/bench/list?${queryString.stringify(params)}`),
    getBenchMember: (id: any, params: IBenchMember) => API.get(`/bench/${id}/history?${queryString.stringify(params)}`),
    getBenchStatus: (id: string | undefined) => API.get(`/bench/${id}/status-bar`),
    createProposal: (data: IMove) => API.post('/bench/move', data),
    deleteBench: (data: IMove) => API.post('/bench/remove', data),
    prolongBench: (data: IMove) => API.post('/bench/prolong', data),

    // editProposal: (data: Partial<ProposalFormData>) => API.put('/proposal', data),
    // list: (params: IProposalQueryParams) => API.get(`/proposals?${queryString.stringify(params)}`),
};


