import queryString from 'query-string';
import API from '../http';
import { ICommonApi } from './types';

export const commonApi: ICommonApi = {
  positions: () => API.get('/positions'),
  getUsers: (data) =>
    API.get(
      `/user/users_list${
        data ? `?${queryString.stringify(data)}` : ''
      } `
    ),
  languages: (data) => API.get('/languages', { params: data }),
};
