import styles from '../components/sharedPages/HumanResourcesPage/HumanResourcesPage.module.css';
import LeavePage from '../components/sharedPages/LeavePage';

export default function Leave() {
    return (
        <div className={styles.root}>
            <LeavePage/>
        </div>

    );
}


