import React from 'react';
import clsx from 'clsx';

import { FormControlProps } from './FormControl.model';

import classes from './FormControl.module.css';

const FormControl = ({ margin, marginSm, marginXs, fullWidth, className, children }: FormControlProps) => {
  const classNames = clsx(
    classes.root,
    className,
    {
      [classes.margin]: margin,
      [classes.marginSm]: marginSm,
      [classes.marginXs]: marginXs,
      [classes.fullWidth]: fullWidth,
    },
  );

  return (
    <div className={classNames}>
      {children}
    </div>
  );
};

export default FormControl;
