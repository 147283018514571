import { useEffect, useState } from 'react';
import { IWorkSummaryItem } from '../../../../services/timeTrackingApi/types';
import { ITimeTrackingFilter } from '../../../../pages/TimeTracking/TimeTracking.types';
import { timeTrackingApi } from '../../../../services/timeTrackingApi/timeTrackingApi';

export const useWorkSummary = (filters?: ITimeTrackingFilter) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<IWorkSummaryItem[]>([]);

  const fetchList = async () => {
    try {
      const obj = {
        date_from: filters?.date_from,
        date_to: filters?.date_to,
        project_ids:
          typeof filters?.projects === 'string'
            ? [filters?.projects].map((e) => Number(e))
            : filters?.projects?.map((e) => Number(e)),
        user_ids:
          typeof filters?.members === 'string'
            ? [filters?.members].map((e) => Number(e))
            : filters?.members?.map((e) => Number(e)),
        search: filters?.search,
      };
      if (
        !filters?.show_by ||
        filters?.show_by === 'Show by Project'
      ) {
        const res = await timeTrackingApi.getWorkSummaryProjects(obj);
        setList(res);
      } else {
        const res = await timeTrackingApi.getWorkSummaryMembers(obj);
        setList(res);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchList();
  }, [filters]);
  return { loading, list };
};
