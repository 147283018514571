import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getHRFiltersSelector } from '../../../../../store/humanResources/selectors';
import { useAppDispatch } from '../../../../../store';
import { deleteFilter } from '../../../../../store/humanResources/thunks';
import { IHRFilters } from '../../../../../store/humanResources/reducer';
import { ModalFilterForm } from '../Table/FilterModal/FilterForm';

import Button from '../../../../ui/Button/Button';
import Typography from '../../../../ui/Typography/Typography';
import { QuickFilter } from '../../../../ui/QuickFilter/QuickFIlter';

import { PlusIcon } from '../../../../SvgIcons';
import styles from './Header.module.css';

interface IFilter {
  departments: string[];
  positions: string[];
  tech_stacks: string[];
  seniority_levels: string[];
  forms_of_coops: string[];
}
interface IHumanResourcesHeader {
  onClickView: () => void;
  handleResetAvailables?: () => void;
  setFilter: React.Dispatch<
    React.SetStateAction<IFilter | undefined>
  >;
  onResourcesClickHandler?: () => void;
  filter?: IFilter;
  totalAvailability?: number;
}
const HumanResourcesHeader: FC<IHumanResourcesHeader> = ({
  onClickView,
  handleResetAvailables,
  onResourcesClickHandler,
  setFilter,
  filter,
  totalAvailability,
}) => {
  const filters = useSelector(getHRFiltersSelector);

  const dispatch = useAppDispatch();

  const handleDeleteItemInFilter = async (name: string) => {
    await dispatch(deleteFilter(name));
  };
  const handleClickCurrentFilter = (item: IHRFilters) => {
    const object = { ...item };
    object?.name && delete object.name;
    object?.created_at && delete object?.created_at;
    setFilter(object);
  };

  const validateFilter = (filter?: IFilter) => {
    if (filter) {
      const items = Object.keys(filter);
      for (const e of items) {
        if (filter[e as keyof IFilter].length) {
          return true;
        }
      }
    }
    return false;
  };

  const handleClickItem = (name: string, index: number) => {
    setFilter((prev) => {
      const newPrev = structuredClone(prev);
      newPrev &&
        (typeof newPrev[name as keyof IFilter] === 'string'
          ? (newPrev[name as keyof IFilter] = [])
          : newPrev[name as keyof IFilter].splice(index, 1));

      return newPrev;
    });
  };

  const filterTags = () => {
    if (filter) {
      return Object.keys(filter).map(
        (name) =>
          name !== 'name' &&
          (typeof filter[name as keyof IFilter] == 'string'
            ? [filter[name as keyof IFilter]]
            : filter[name as keyof IFilter]
          ).map(
            (el, index) =>
              el && (
                <Button
                  onClick={() => handleClickItem(name, index)}
                  key={Math.random()}
                  className={styles.rootSelectedBtn}>
                  <p>{el}</p>
                  <PlusIcon />
                </Button>
              )
          )
      );
    }
    return;
  };

  const resetFilter = () => {
    handleResetAvailables && handleResetAvailables();
    setFilter({
      departments: [],
      positions: [],
      tech_stacks: [],
      seniority_levels: [],
      forms_of_coops: [],
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <QuickFilter
          filter={filters}
          ModalComponent={ModalFilterForm}
          onClickDeleteFilter={handleDeleteItemInFilter}
          handleClickCurrentFilter={handleClickCurrentFilter}
          btnDotOn={validateFilter(filter)}
        />
        {totalAvailability && totalAvailability !== 0 ? (
          <Typography
            className={styles.m}
            variant='smallBody'
            color='lightMuted'>
            {totalAvailability}{' '}
            {totalAvailability === 1 ? 'Member' : 'Members'} Chosen
          </Typography>
        ) : (
          <div />
        )}
        {(validateFilter(filter) || !!totalAvailability) && (
          <Button
            onClick={resetFilter}
            className={styles.rootResetBtn}>
            Reset
          </Button>
        )}
        {filterTags()}
      </div>
      <div className={styles.rightSize}>
        {onResourcesClickHandler && (
          <Button
            color={'black'}
            disabled={!totalAvailability}
            onClick={onResourcesClickHandler}
            className={styles.mRight16}>
            <p className={styles.breakword}>
              Share Available Resources
            </p>
          </Button>
        )}
        {/* <Button onClick={onClickView} className={styles.rootViewBtn}>
          <p>View</p>
        </Button> */}
      </div>
    </div>
  );
};

export default HumanResourcesHeader;
