import React from 'react';

import { PlusIconProps } from './PlusIcon.model';

export const PlusIcon = ({stroke}: PlusIconProps) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 0.583333C6.41421 0.583333 6.75 0.919119 6.75 1.33333V5.25H10.6667C11.0809 5.25 11.4167 5.58579 11.4167 6C11.4167 6.41421 11.0809 6.75 10.6667 6.75H6.75V10.6667C6.75 11.0809 6.41421 11.4167 6 11.4167C5.58579 11.4167 5.25 11.0809 5.25 10.6667V6.75H1.33333C0.919119 6.75 0.583333 6.41421 0.583333 6C0.583333 5.58579 0.919119 5.25 1.33333 5.25H5.25V1.33333C5.25 0.919119 5.58579 0.583333 6 0.583333Z" fill="#0373FE"/>
        </svg>

    )
}
