import DashboardPage from '../components/DashboardPage/DashboardPage';


export default function DashboardInfo() {
    return (
        <div>
            <DashboardPage />
        </div>

    );
}


