import React, { memo } from 'react';
import clsx from 'clsx';

import Button from '../Button/Button';

import { ChipProps } from './Chip.model';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

import classes from './Chip.module.css';

const Chip = (
  {
    size = 'small',
    variant = 'outlined',
    color = '',
    className,
    children,
    background = '',
    onAdd,
    onRemove,
    onClick,
    ...props
  }: ChipProps) => {
  const classNames = clsx(classes.root, className, {
    [classes[size]]: size,
    [classes[color]]: color,
    [classes[background]]: background,
    [classes[variant]]: variant,
    [classes.cursorPointer]: onClick,
  });

  return (
    <div className={classNames} {...(onClick && { onClick })} {...props}>
      {children}
      {(onAdd || onRemove) && (
        <div className={classes.icon}>
          {onAdd && (
            <Button className={classes.button} onClick={onAdd}>
              <PlusIcon />
            </Button>
          )}
          {onRemove && (
            <Button
              component="span"
              className={classes.button}
              onClick={onRemove}
            >
              <CloseIcon />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Chip);
