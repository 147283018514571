import styles from '../../components/sharedPages/HumanResourcesPage/HumanResourcesPage.module.css';
import BenchPage from '../../components/Bench/BenchPage';

export default function Bench() {
    return (
        <div className={styles.root}>
            <BenchPage/>
        </div>

    );
}


