import { FC, useState } from 'react';
import clsx from 'clsx';
import styles from './QuickFilterList.module.css';

import { PlusIcon } from '../../../SvgIcons';

import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon.svg';
import { useSelector } from 'react-redux';
import { getHRFiltersSelector } from '../../../../store/humanResources/selectors';

interface IQuickFilterList {
  filters?: { name: string }[];
  handleCreateFilter: () => void;
  handleOpenCurrentFilter: (e: any) => void;
  handleOpenEditFilter: (e: any) => void;
  handleDeleteFilter: (e: any) => void;
}

interface IQuickFilterListItem {
  item: { name?: string };
  handleOpenCurrentFilter: (e: any) => void;
  handleOpenEditFilter: (e: any) => void;
  handleDeleteFilter: (e: any) => void;
}

const QuickFilterListItem: FC<IQuickFilterListItem> = ({
  item,
  handleOpenCurrentFilter,
  handleOpenEditFilter,
  handleDeleteFilter,
}) => {
  const [mouseHover, setMouseHover] = useState(false);
  const onMouseHoverHandle = (event: boolean) => {
    setMouseHover(event);
  };
  return (
    <div
      onMouseEnter={() => onMouseHoverHandle(true)}
      onMouseLeave={() => onMouseHoverHandle(false)}
      onClick={() => {
        handleOpenCurrentFilter(item);
      }}
      className={styles.cardFilter}>
      <div className={styles.carfFilterInfo}>
        <div className={styles.containerIcon}>
          <FilterIcon />
        </div>
        <p className={styles.cardTitle}>{item.name}</p>
      </div>
      <div
        className={clsx(
          styles.carfFilterAdditionalInfo,
          mouseHover && styles.carfFilterAdditionalInfoActive
        )}>
        {mouseHover && (
          <div className={styles.additionalInfoContainer}>
            <div className={styles.containerIcons}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFilter(item.name);
                }}
                className={clsx(styles.icon, styles.iconTrash)}>
                <TrashIcon />
              </div>
              <div className={styles.divide} />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenEditFilter(item);
                }}
                className={clsx(styles.icon, styles.iconPencil)}>
                <PencilIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const QuickFilterList: FC<IQuickFilterList> = ({
  filters,
  handleCreateFilter,
  handleOpenCurrentFilter,
  handleOpenEditFilter,
  handleDeleteFilter,
}) => {
  //   const filter = useSelector(getHRFiltersSelector);

  return (
    <div>
      {filters?.length ? (
        <div className={styles.maxHeight}>
          {filters.map((el, index) => (
            <QuickFilterListItem
              key={index}
              item={el}
              handleOpenCurrentFilter={handleOpenCurrentFilter}
              handleOpenEditFilter={handleOpenEditFilter}
              handleDeleteFilter={handleDeleteFilter}
            />
          ))}
        </div>
      ) : (
        <div className={styles.emptyFilter}>
          Create new quick filters for optimisation
        </div>
      )}
      <div onClick={handleCreateFilter} className={styles.footerCard}>
        <div className={styles.plusIcon}>
          <PlusIcon />
        </div>
        <p className={styles.footerCardTitle}>Create new filters</p>
      </div>
    </div>
  );
};
