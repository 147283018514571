import React from 'react'

export const UploadFile = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4336_253027)">
        <path d="M13.9583 3.1875V7.10417C13.9583 7.36386 14.0614 7.61291 14.245 7.79654C14.4287 7.98017 14.6777 8.08333 14.9374 8.08333H18.8541" stroke="#0373FE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
        <path d="M16.8958 20.8125H7.10409C6.5847 20.8125 6.08659 20.6062 5.71933 20.2389C5.35208 19.8717 5.14575 19.3735 5.14575 18.8542V5.14583C5.14575 4.62645 5.35208 4.12834 5.71933 3.76108C6.08659 3.39382 6.5847 3.1875 7.10409 3.1875H13.9583L18.8541 8.08333V18.8542C18.8541 19.3735 18.6478 19.8717 18.2805 20.2389C17.9132 20.6062 17.4151 20.8125 16.8958 20.8125Z" stroke="#0373FE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
        <path d="M12 11.0208V16.8958" stroke="#0373FE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
        <path d="M9.0625 13.9583L12 11.0208L14.9375 13.9583" stroke="#0373FE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
      </g>
      <defs>
        <clipPath id="clip0_4336_253027">
          <rect width="23.5" height="23.5" fill="white" transform="translate(0.25 0.25)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
