import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import routes from '../../../constants/routes';
import { useLoginMutation } from '../../../store/auth/authApi';
import { loginSuccess } from '../../../store/auth/reducer';
import { getTokenSelector } from '../../../store/auth/selectors';
import { SignInData } from '../../../types/auth';
import { EMAIL_VALIDATION_PATTERN } from '../../../utils/common';

import AuthFormContainer from '../../AuthFormContainer';
import PasswordInput from '../../helpers/PasswordInput';
import Button from '../../ui/Button';
import Divider from '../../ui/Divider';
import FormControl from '../../ui/FormControl';
import Input from '../../ui/Input';
import InputHelpText from '../../ui/InputHelpText';
import Typography from '../../ui/Typography';

import errorDogImage from '../../../assets/image/error-dog.svg';

import styles from './SignInPage.module.css';

const SignInPage = () => {
  const dispatch = useDispatch();
  const [signIn] = useLoginMutation();
  const navigate = useNavigate();
  const token = useSelector(getTokenSelector);
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<SignInData>();

  // TODO update this part
  useEffect(() => {
    if (token) {
      navigate(routes.dashboardInfo);
    }
  }, [token, navigate]);

  const onSubmit = async (values: SignInData) => {
    const { data, error }: any = await signIn(values);
    if (error) {
      setError('root', { message: error.data.detail });
    }
    if (data) {
      dispatch(loginSuccess(data));
    }
  };

  return (
    <AuthFormContainer>
      <Typography component="h4" align="center" variant="subHeading1">
        Welcome Back
      </Typography>
      <Typography align="center" className={styles.description}>
         We&apos;re excited to see you here. Please log in to get started.
      </Typography>
      <div className={styles.imageWrap}>
        <img src={errorDogImage} alt="" width={196} height={196} />
      </div>
      <Divider className={styles.divider} />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Controller
            control={control}
            name="email_corporate"
            rules={{
              required: { value: true, message: 'Email is required' },
              pattern: { value: EMAIL_VALIDATION_PATTERN, message: 'Incorrect email format' },
            }}
            render={() => (
              <Input
                type="email"
                label="Work Email"
                placeholder="yourname@movadex.com"
                {...register('email_corporate')}
              />
            )}
          />
          {errors.email_corporate && (
            <InputHelpText hasError>
              {errors.email_corporate.message}
            </InputHelpText>
          )}
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="password"
            rules={{
              required: { value: true, message: 'Password is required' },
              minLength: { value: 8, message: 'Password must have a minimum 8 characters' },
            }}
            render={() => (
              <PasswordInput
                {...register('password')}
              />
            )}
          />
          {errors.password && (
            <Typography color="error" variant="boldLabel">
              {errors.password.message}
            </Typography>
          )}
        </FormControl>
        {errors.root && (
          <Typography color="error" variant="boldLabel">
            {errors.root.message}
          </Typography>
        )}
        <Button
          fullWidth
          type="submit"
          color="black"
          disabled={isSubmitting}
        >
          Log In
        </Button>
      </form>
      <div className={styles.bottomText}>
        <Button
          variant="text"
          color="primary"
          component={Link}
          to={routes.forgotPassword}
        >
          <Typography underline variant="textLabel">
            Forgot password?
          </Typography>
        </Button>
      </div>
    </AuthFormContainer>
  );
};

export default SignInPage;
