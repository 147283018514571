import { FC, SetStateAction } from 'react';
import moment from 'moment';

import Stack from '../../ui/Stack/Stack';

import classes from './HolidayCard.module.css';
import DateRangePicker from '../../ui/DateRangePicker';
import FormikTextArea from '../../ui/FormikTextArea';
import { IPublicHoliday } from '../../../services/policyApi/types';

interface IHolidayCard {
  item: { name: string; date_to: string; date_from: string };
  setData: (prev: SetStateAction<IHolidayCard['item'][]>) => void;
  ind: number;
}
const HolidayCard: FC<IHolidayCard> = ({ item, setData, ind }) => {
  const onChangeText = (value: string) => {
    setData((prev) => {
      prev[ind].name = value;
      return [...prev];
    });
  };
  const onChangeDate = (date: { endDate: Date; startDate: Date }) => {
    setData((prev) => {
      prev[ind].date_from = moment(date.startDate).format(
        'YYYY-MM-DD'
      );
      prev[ind].date_to = date.endDate
        ? moment(date.endDate).format('YYYY-MM-DD')
        : prev[ind].date_from;
      return [...prev];
    });
  };

  return (
    <div className={classes.card}>
      <Stack className={classes.datePicker} direction='column'>
        <DateRangePicker
          startDate={item.date_from}
          endDate={item.date_to}
          label='Date'
          placeholder='Enter period'
          isInput
          onChange={(el) => {
            onChangeDate({ startDate: el[0], endDate: el[1] });
          }}
        />
      </Stack>
      <Stack direction='column'>
        <FormikTextArea
          placeholder='Enter holiday title'
          label='Holiday Title'
          onChange={(e) => onChangeText(e.target.value)}
          value={item.name}
        />
      </Stack>
    </div>
  );
};

export default HolidayCard;
