import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/global.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { SnackbarProvider } from 'notistack';
import CustomSnackBar from './components/ui/Snackbar';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
if (root) {
    root.render(
        // <React.StrictMode>
            <Provider store={store}>
                <SnackbarProvider
                    Components={{
                        info: CustomSnackBar,
                        success: CustomSnackBar,
                        error: CustomSnackBar,
                        warning: CustomSnackBar,
                    }}
                    autoHideDuration={5000}
                >
                    <App />
                </SnackbarProvider>
            </Provider>
        // </React.StrictMode>
    );
} else {
    console.error('Root element not found.');
}

reportWebVitals();
