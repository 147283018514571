import {
  IStatusSelect,
  StatusSelect,
} from './ProfileCards/Status/Status';
import { useSelect } from './useSelect/useSelect';

import styles from './Selects.module.css';
import {
  IManagersSelect,
  ManagersSelect,
} from './ProfileCards/Managers/Managers';
import {
  CalendarSelect,
  ICalendarSelect,
} from './ProfileCards/Calendar/Calendar';
import {
  DescriptionSelect,
  IDescriptionSelect,
} from './ProfileCards/Description/Description';

import {
  IEditProfileProjectInfoRequest,
  IManager,
  IProfileProjectResponse,
} from '../../../../services/projectsApi/types';
import { projectsApi } from '../../../../services/projectsApi/projectsApi';
import { ITagsSelect, TagsSelect } from './ProfileCards/Tags/Tags';
import {
  developmentMethodology,
  productIndustry,
  productTypes,
} from '../../../../common/values/values';
import TagsFetchSelect, {
  ITagsFetchSelect,
} from './ProfileCards/Tags/TagsFetch';
import React from 'react';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../ui/Snackbar/constants';

export const useSelects = (
  profileInfo: IProfileProjectResponse,
  setProjectInfo: React.Dispatch<
    React.SetStateAction<IProfileProjectResponse>
  >
) => {
  const fetchDescription = async (
    item: keyof IEditProfileProjectInfoRequest,
    data: IEditProfileProjectInfoRequest,
    isArray?: unknown,
    arrayName?: string
  ) => {
    try {
      const res = await projectsApi.editProjectProfileInfo(data);
      if (res.msg) {
        setProjectInfo((prev) => {
          const obj: any = { ...prev };
          if (!arrayName && data[item]) {
            obj[item] = data[item];
          } else {
            if (arrayName && Array.isArray(isArray))
              obj[arrayName] =
                arrayName === 'managers'
                  ? isArray.map((e: IManager) => {
                      return { user: e };
                    })
                  : isArray;
          }
          return obj;
        });
        enqueueSnackbar('Successfully changed', {
          variant: 'success',
          anchorOrigin: snackbarPosTopRight,
        });
      }
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string') {
        enqueueSnackbar(e.response.data.detail, {
          variant: 'error',
          anchorOrigin: snackbarPosTopRight,
        });
        return;
      }
      enqueueSnackbar('Validation error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
    }
  };

  const fetchCalendar = async (
    first: keyof IEditProfileProjectInfoRequest,
    second: keyof IEditProfileProjectInfoRequest,
    data: IEditProfileProjectInfoRequest
  ) => {
    try {
      const res = await projectsApi.editProjectProfileInfo(data);
      if (res.msg) {
        setProjectInfo((prev) => {
          const obj: any = { ...prev };
          if (data[first]) {
            obj[first] = data[first];
          }
          if (data[second]) {
            obj[second] = data[second];
          }
          return obj;
        });
        enqueueSnackbar('Successfully changed', {
          variant: 'success',
          anchorOrigin: snackbarPosTopRight,
        });
      }
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string') {
        enqueueSnackbar(e.response.data.detail, {
          variant: 'error',
          anchorOrigin: snackbarPosTopRight,
        });
        return;
      }
      enqueueSnackbar('Validation error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
    }
  };

  const statusSelect = useSelect<IStatusSelect>(
    StatusSelect,
    {
      onFetch: (text: string) =>
        fetchDescription('status', {
          status: text,
          project_id: profileInfo.id,
        }),
    },
    styles.cardInfoStatus
  );
  const managersSelect = useSelect<IManagersSelect>(
    ManagersSelect,
    {
      selectedItems: profileInfo.managers?.map((e) => e.user),
      position: 'Project Manager',
      onFetch: (e) => {
        fetchDescription(
          'managers_ids',
          {
            managers_ids: e.map((e) => e.id),
            project_id: profileInfo.id,
          },
          e,
          'managers'
        );
      },
    },
    styles.cardInfoManagers
  );
  const calendarStartDateSelect = useSelect<ICalendarSelect>(
    CalendarSelect,
    {
      initialDate: moment(
        profileInfo.start_date,
        'YYYY-MM-DD'
      ).toDate(),
      initialComment: profileInfo.start_date_comment,
      onFetch: (date, comment) =>
        fetchCalendar('start_date', 'start_date_comment', {
          start_date: moment(date).format('YYYY-MM-DD'),
          start_date_comment: comment,
          project_id: profileInfo.id,
        }),
    },
    styles.cardInfoCalendar
  );
  const calendarDueDateSelect = useSelect<ICalendarSelect>(
    CalendarSelect,
    {
      initialDate: moment(
        profileInfo.end_date,
        'YYYY-MM-DD'
      ).toDate(),
      initialComment: profileInfo.end_date_comment,
      onFetch: (date, comment) =>
        fetchCalendar('end_date', 'end_date_comment', {
          end_date: date,
          end_date_comment: comment,
          project_id: profileInfo.id,
        }),
    },
    styles.cardInfoCalendar
  );
  const industrySelect = useSelect<ITagsSelect>(
    TagsSelect,
    {
      list: productIndustry,
      selectedItems: profileInfo.product_industry
        ? [{ name: profileInfo.product_industry }]
        : undefined,
      color: '#2A303C',
      bgColor: '#EDF2F7',
      menuTitle: 'Select Industry',
      oneItem: true,
      onFetch: (e) => {
        fetchDescription('product_industry', {
          product_industry: e[0] || null,
          project_id: profileInfo.id,
        });
      },
    },
    styles.cardInfoManagers,
    undefined,
    true
  );

  const productSelect = useSelect<ITagsSelect>(
    TagsSelect,
    {
      list: productTypes,
      selectedItems: profileInfo.product_type
        ? [{ name: profileInfo.product_type }]
        : undefined,
      color: '#2A303C',
      bgColor: '#EDF2F7',
      menuTitle: 'Select Product',
      oneItem: true,
      onFetch: (e) => {
        fetchDescription('product_type', {
          product_type: e[0] || null,
          project_id: profileInfo.id,
        });
      },
    },
    styles.cardInfoManagers,
    undefined,
    true
  );

  const methodologySelect = useSelect<ITagsSelect>(
    TagsSelect,
    {
      list: developmentMethodology,
      selectedItems: profileInfo.methodology
        ? [{ name: profileInfo.methodology }]
        : undefined,
      color: '#2A303C',
      bgColor: '#EDF2F7',
      menuTitle: 'Select Methodology',
      oneItem: true,
      onFetch: (e) => {
        fetchDescription('methodology', {
          methodology: e[0] || null,
          project_id: profileInfo.id,
        });
      },
    },
    styles.cardInfoManagers,
    undefined,
    true
  );
  const tagsSelect = useSelect<ITagsFetchSelect>(
    TagsFetchSelect,
    {
      baseUrl: '/project/tags',
      selectedItems: profileInfo.tags,
      color: '#2A303C',
      bgColor: '#EDF2F7',
      onFetch: (e) => {
        fetchDescription(
          'tags_ids',
          {
            tags_ids: e.map((e) => e.id),
            project_id: profileInfo.id,
          },
          e,
          'tags'
        );
      },
    },
    styles.cardInfoManagers,
    undefined,
    true
  );

  const descriptionSelect = useSelect<IDescriptionSelect>(
    DescriptionSelect,
    {
      text: profileInfo.description,
      onFetch: (e) => {
        fetchDescription('description', {
          description: e.trim(),
          project_id: profileInfo.id,
        });
      },
    },
    styles.cardInfoDescription
  );

  const fetchDescriptionOnBlur = (text: string) => {
    fetchDescription('description', {
      description: text,
      project_id: profileInfo.id,
    });
  };

  return {
    statusSelect,
    managersSelect,
    calendarStartDateSelect,
    calendarDueDateSelect,
    descriptionSelect,
    industrySelect,
    productSelect,
    methodologySelect,
    tagsSelect,
    fetchDescriptionOnBlur,
  };
};
