import { hrApi } from '../../services/hrApi/hrApi';
import { useEffect, useState } from 'react';
import {
    IBirthday,
    ICurrentHolidays,
    IHolidays,
    IPaidCountResponse,
    ITotalLeave,
    ITotalRequest,
    ITotalUsers,
} from '../../services/hrApi/types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCanEditSelector, getProfileSelector } from '../../store/profile/selectors';

export const useDashboardPage = () => {
    const canEdit = useSelector(getCanEditSelector);
    const user = useSelector(getProfileSelector);
    const [dataList, setDataList] = useState<ITotalUsers>();
    const [dataLeave, setDataLeave] = useState<ITotalLeave>();
    const [availableLeave, setAvailableLeave] = useState<IPaidCountResponse>();
    const [dataBirthday, setDataBirthday] = useState<IBirthday>();
    const [dataRequest, setDataRequest] = useState<ITotalRequest>();
    const [dataHolidays, setDataHolidays] = useState<ICurrentHolidays>();
    const [loading, setLoading] = useState<boolean>(true);

    const fetchData = async (apiFunction: () => Promise<any>, setDataFunction: (data: any) => void) => {
        try {
            const res = await apiFunction();
            setDataFunction(res);
        } catch (e) {
            console.error(e);
        }
    };
    const id = user?.id;

    const getHolidaysYears = async () => {
        try {
            const currentYear = new Date();
            const data: IHolidays = {
                date_from: moment(currentYear).format('YYYY-MM-DD'),
            };
            await fetchData(() => hrApi.getAllHolidays(data), setDataHolidays);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        const fetchDataList = () => fetchData(hrApi.getTotalEmployees, setDataList);
        const fetchDataLeave = () => fetchData(hrApi.getLeaveToday, setDataLeave);
        const fetchAvailableLeave = () => fetchData(() => hrApi.getPaidCount({ user_id: Number(id) }), setAvailableLeave);
        const fetchDataBirthday = () => fetchData(hrApi.getUpcomingBirthday, setDataBirthday);
        const fetchDataRequests = () => fetchData(hrApi.getPendingRequests, setDataRequest);

        const fetchDataAll = async () => {
            if (canEdit) {
                // Send all requests if canEdit is true
                await Promise.all([fetchDataList(), fetchDataLeave(), fetchAvailableLeave(), fetchDataBirthday(), fetchDataRequests(), getHolidaysYears()]);
            } else {
                // Send only fetchDataLeave and fetchDataRequests if canEdit is false
                await Promise.all([fetchDataLeave(), fetchDataRequests(), fetchAvailableLeave(), getHolidaysYears()]);
            }
            setTimeout(() => setLoading(false), 2500);
        };

        fetchDataAll();
    }, [canEdit, id]); // Include canEdit and id in the dependency array

    return { dataList, dataLeave, dataBirthday, availableLeave, dataRequest, dataHolidays, loading };
};
