import {useEffect, useState} from 'react';
import moment from 'moment';
import {enqueueSnackbar} from 'notistack';
import {useLocation, useSearchParams} from 'react-router-dom';

import {
    IDayOffListItem,
    IPaidCountResponse,
} from '../../services/hrApi/types';
import {hrApi} from '../../services/hrApi/hrApi';
import {useGetQueryParams} from '../../utils/common';
import {snackbarPosTopLeft} from '../../components/ui/Snackbar/constants';
import {FileData} from './MedicalLeaveTable/MedicalLeaveTable';
import JSZip from 'jszip';

export const useMedicalLeave = (id?: string, types?: string) => {
    const location = useLocation();
    const isSickLeave = (location.pathname.includes('sick-leave') || types === 'Sick Leave');
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState<IDayOffListItem[]>([]);
    // const [disabledBtns, setDisabledBtns] = useState(false);

    const [counts, setCounts] = useState<
        IPaidCountResponse | undefined
    >(undefined);
    const [selectedItem, setSelectedItem] = useState<
        IDayOffListItem | undefined
    >(undefined);
    const [render, setRender] = useState(0);
    const handleUpdateTable = () => {
        setRender((prev) => prev + 1);
    };
    // const fileInputRef = useRef<HTMLInputElement | null>(null);
    const ids = location.search.match(/\d+/)
    const params: {
        date_from?: string;
        date_to?: string;
        openModal?: boolean;
    } = useGetQueryParams();


    const [drawerCreateShow, setDrawerCreateShow] = useState(
        params.openModal || false
    );
    const [drawerEditShow, setDrawerEditShow] = useState(false);
    const onDrawerCreateHandler = () => {
        setDrawerCreateShow((prev) => !prev);
    };
    const onDrawerEditHandler = () => {
        setDrawerEditShow((prev) => !prev);
    };

    const handleOpenEditDrawer = (item: IDayOffListItem) => {
        setDrawerEditShow((prev) => !prev);
        setSelectedItem(item);
    };

    const handleDeleteItem = async (id: number) => {
        try {
            await hrApi.deleteDayOff(id);
            enqueueSnackbar('Deleted Succesfully', {
                variant: 'success',
                anchorOrigin: snackbarPosTopLeft,
            });
        } catch (e) {
            enqueueSnackbar('Not deleted', {
                variant: 'error',
                anchorOrigin: snackbarPosTopLeft,
            });
        }
        handleUpdateTable();
    };

    async function handleUploadFile(files: FileData[]): Promise<void> {
        const zip = new JSZip();
        files.forEach((file) => {
            if (file.path) {
                zip.file(file.name, file.path);
            }
        });
        zip.generateAsync({type: 'blob'}).then((blob) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'archive.zip'); // Set the desired name for the ZIP archive
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

  const getList = async () => {
    try {
      const dayOffType = isSickLeave ? 'Sick Leave' : 'Day Off';
      const res = await hrApi.getDayOffList({
        day_off_type: dayOffType,
        user_id: id,
        ...params,
      });
      if (ids && ids.length > 0) {
        const selectedItem = res.find(e => e.id === +ids[0]);
        setList(selectedItem ? [selectedItem] : []);
      } else {
        setList(res);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error in list:', error);
    }
  };
    const getCount = async () => {
        try {
            const res = await hrApi.getPaidCount({
                user_id: Number(id),
            });
            setCounts(res);
            // setDisabledBtns(false);
        } catch (e: any) {
            // setDisabledBtns(true);
            if (e.response.data.detail) {
                enqueueSnackbar(e.response.data.detail, {
                    variant: 'error',
                    anchorOrigin: snackbarPosTopLeft,
                });
            }
        }
    };
    useEffect(() => {
        id && getList();
    }, [id, JSON.stringify(params), render]);

    useEffect(() => {
        id && getCount();
    }, [id, render]);

    const handleOnChangeDatePicker = (el: Date[]) => {
        if (el.length) {
            const date_to = el[1] && moment(el[1]).format('YYYY-MM-DD');
            const date_from = el[0] && moment(el[0]).format('YYYY-MM-DD');
            if (el.length === 2) {
                return setSearchParams({date_from, date_to});
            }
            return setSearchParams({date_from});
        }
        setSearchParams();
    };

    const used = isSickLeave
        ? counts?.used_sick_leave_count
        : counts?.used_days_off_count;


    const totals = isSickLeave
        ? counts?.total_sick_leaves_count
        : counts?.total_days_off_count;
    return {
        isSickLeave,
        searchParams,
        list,
        counts,
        selectedItem,
        drawerCreateShow,
        drawerEditShow,
        onDrawerCreateHandler,
        onDrawerEditHandler,
        handleOpenEditDrawer,
        handleDeleteItem,
        handleUploadFile,
        handleOnChangeDatePicker,
        setSearchParams,
        handleUpdateTable,
        params,
        used,
        totals,
        loading,
    };
};
