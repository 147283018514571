import { FC } from 'react';
import styles from './CategoryCard.module.css';

export interface ICategoryCard {
  type:
    | 'Income'
    | 'Salary'
    | 'Transfer'
    | 'Office'
    | 'Overdue'
    | 'Pending'
    | 'In Progress'
    | 'Done Date'
    | 'On Hold'
    | 'Canceled'
    | 'Finished';
}
export const CategoryCard: FC<ICategoryCard> = ({ type }) => {
  switch (type) {
    case 'Pending':
      return (
        <div className={`${styles.card} ${styles.pending}`}>
          Pending
        </div>
      );
    case 'In Progress':
      return (
        <div className={`${styles.card} ${styles.progress}`}>
          In Progress
        </div>
      );
    case 'Done Date':
      return (
        <div className={`${styles.card} ${styles.doneDate}`}>
          Done Date
        </div>
      );
    case 'Finished':
      return (
        <div className={`${styles.card} ${styles.doneDate}`}>
          Finished
        </div>
      );
    case 'On Hold':
      return (
        <div className={`${styles.card} ${styles.onHold}`}>
          On Hold
        </div>
      );
    case 'Income':
      return (
        <div className={`${styles.card} ${styles.income}`}>
          Invoice Income
        </div>
      );
    case 'Salary':
      return (
        <div className={`${styles.card} ${styles.salary}`}>
          Salary
        </div>
      );
    case 'Transfer':
      return (
        <div className={`${styles.card} ${styles.transfer}`}>
          Transfer
        </div>
      );
    case 'Office':
      return (
        <div className={`${styles.card} ${styles.default}`}>
          Office
        </div>
      );
    case 'Overdue':
      return (
        <div className={`${styles.card} ${styles.overdue}`}>
          Overdue
        </div>
      );
    case 'Canceled':
      return (
        <div className={`${styles.card} ${styles.overdue}`}>
          Canceled
        </div>
      );
    default:
      return <div />;
  }
};
