import { FC } from 'react';

import { ReactComponent as NoHolidaysIcon } from '../../../assets/image/NoHolidaysIcon.svg';
import { ReactComponent as SickLeave } from '../../../assets/icons/plusWhite.svg';

import Typography from '../../ui/Typography';
import Button from '../../ui/Button';

import classes from './NoHolidays.module.css';

interface INoHolidays {
  openModal: () => void;
}
const NoHolidays: FC<INoHolidays> = ({ openModal }) => {
  return (
    <div className={classes.noHolidaysForm}>
      <NoHolidaysIcon />
      <Typography variant='subHeading1'>
        Everybody needs a chill
      </Typography>
      <Typography align='center'>
        Make sure you stay on top of your schedule by adding public
        holidays to your company calendar.
      </Typography>
      <Button
        color='black'
        onClick={openModal}
        startIcon={SickLeave}
        className={classes.buttonAddHoliday}>
        Add Public Holidays
      </Button>
    </div>
  );
};

export default NoHolidays;
