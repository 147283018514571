import styles from './Plug.module.css';

import { ReactComponent as NothingFoundIcon } from '../../../../assets/image/hr-no-data.svg';

export const ArchivePlug = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.imgWrapper}>
          <NothingFoundIcon />
        </div>
        <p className={styles.ohNoText}>
          Oh no! UFO has stolen all data
        </p>
      </div>
    </div>
  );
};
