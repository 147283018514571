import { FC } from 'react';

import styles from './Plug.module.css';
import { ReactComponent as PuzzleIcon } from '../../../../../assets/image/puzzle.svg';
import Button from '../../../../ui/Button/Button';

import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../constants/routes';

interface IPlug {
  onClick: () => void;
}

export const Plug: FC<IPlug> = ({ onClick }) => {
  const navigate = useNavigate();

  const handleRedirectToHR = () => {
    navigate(routes.humanResources + '/?page=1');
  };
  return (
    <div className={styles.wrapper}>
      <PuzzleIcon />
      <p className={styles.title}>
        No employee was assigned to this project
      </p>
      <span className={styles.text}>
        {`Add team members from the list of employees. If you don't find
        a worker in the list, create a profile of him through the `}
        <a onClick={handleRedirectToHR} className={styles.link}>
          Human Resources page.
        </a>
      </span>
      <Button onClick={onClick} color='black' startIcon={PlusIcon}>
        Add Team Member
      </Button>
    </div>
  );
};
