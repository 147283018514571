import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Portal from '../../Portal/Portal';

import { MenuProps } from './Menu.model';

import classes from './Menu.module.css';

const Menu = (props: MenuProps) => {
  const {
    anchorEl,
    isOpen,
    node,
    position = 'topLeft',
    fullWidth,
    elevation,
    topMargin,
    className,
    children,
    onClose,
  } = props;

  const classNames = clsx(
    classes.root,
    className,
    {
      [classes[`elevation${elevation}`]]: elevation,
    },
  );
  const menuRef: any = useRef();
  const [positions, setPositions] = useState<any>();
  const anchorRect = anchorEl?.getBoundingClientRect();
  const width = anchorRect ? (anchorRect.right - anchorRect.left) : 0;

  useEffect(() => {
    const displayWidth = window.innerWidth
    if (menuRef?.current && anchorRect) {
      const { width: menuWidth, height: menuHeight } = menuRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY
      const pageHeight = document.body.scrollHeight
      const menuPosition = scrollTop + menuHeight + anchorRect.bottom + 32
      const shouldRenderOnTop = menuPosition >= pageHeight
      setPositions({
        topLeft: {
          top: (topMargin && topMargin - 40) ?? anchorRect.bottom,
          left: topMargin ? anchorRect.left - width : anchorRect.left,
        },
        topRight: {
          top: (topMargin && topMargin - 40) ?? anchorRect.top,
          left: anchorRect.left + width,
        },
        topCenter: {
          top: (topMargin && topMargin - 40) ?? anchorRect.top,
          left: topMargin ? anchorRect.left : anchorRect.left - menuWidth / 2,
        },
        bottomLeft: {
          top: (topMargin && topMargin + 20) ?? anchorRect.bottom,
          left: topMargin ? anchorRect.left : anchorRect.left - menuWidth / 2,
        },
        bottomRight: {
          top: (topMargin && topMargin + 20) ?? anchorRect.bottom,
          left: topMargin ? anchorRect.left : anchorRect.left - menuWidth / 5,
        },
        bottom: {
          top: anchorRect.bottom + 8,
          left: anchorRect.left
        },
        bottomCenter: {
          top: anchorRect.bottom + menuWidth / 16,
          left: anchorRect.right - menuWidth / 4,
        },
        bottomRightToLeft: {
          top: shouldRenderOnTop ? anchorRect.top + scrollTop - menuHeight : anchorRect.bottom + scrollTop - 10,
          right: (displayWidth - anchorRect.right) + menuWidth / 2,
          left: 'unset'
        }
      });
    }
    // eslint-disable-next-line
  }, [anchorEl]);


  const style = {
    ...positions?.[position],
    ...(fullWidth && {
      width,
      minWidth: 0,
    }),
  };

  return (
    isOpen ? (
        <Portal node={node}>
          <div className={classNames} ref={menuRef} style={style}>
            {children}
          </div>
          <div className={classes.backDrop} onClick={onClose} />
        </Portal>
    ) : null
  );
};

export default Menu;
