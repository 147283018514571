import React from 'react';
import styles from './Requests.module.css'
import Typography from '../../components/ui/Typography';
import {useSearchParams} from 'react-router-dom';
import {useRequest} from './useRequest';
import RequestTable from './Table /Table';
import {TableHeader} from '../../components/Request/TableHeaderRequest/TableHeader';
import {ReactComponent as Astronaut} from '../../assets/icons/astronaut.svg'
import Default from '../../components/Default';
import TablePagination from '../../components/ui/TablePagination';

const Requests = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {tableData, params, loading, listUsers, setUpdate, listData} = useRequest(searchParams);
    return (
        <div className={styles.root}>
            <Typography variant='h5' weight='bold'>
                Requests
            </Typography>
            {loading ?
                <div className={styles.containerPlug}><Default/></div> :
                <>
                    <TableHeader
                        listData={listData}
                        listUsers={listUsers}
                        params={params}
                        setSearchParams={setSearchParams as any}
                    />
                    {tableData && tableData?.total > 0 ?
                        <div className={styles.table}>
                            <RequestTable data={tableData?.items} setUpdate={setUpdate}/>
                            <TablePagination
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                currentStartPage={tableData?.page}
                                count={tableData?.total}
                                itemsPerPage={tableData?.size}
                            />
                        </div>
                        : <div className={styles.into}>
                            <Astronaut/>
                            <p>No requests have been found</p>
                        </div>}


                </>
            }
        </div>
    );
};

export default Requests;
