import { UseFormRegister } from 'react-hook-form';

import IconButton from '../../../../ui/IconButton';
import InputLabel from '../../../../ui/InputLabel';
import Input from '../../../../ui/Input';
import Stack from '../../../../ui/Stack';

import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash-icon.svg';

import styles from '../ProposalEstimates.module.css';

import { ProposalFormData } from '../../../../../pages/Proposal';

type ProposalEstimateItemProps = {
  index: number;
  register: UseFormRegister<ProposalFormData>;
  onRemove: () => void;
}

export default function ProposalEstimateItem({ index, register, onRemove }: ProposalEstimateItemProps) {
  return (
    <>
      <div className={styles.item}>
        <IconButton
          component="span"
          icon={TrashIcon}
          className={styles.deleteButton}
          onClick={onRemove}
        />
        <div>
          <div className={styles.formWrapper}>
            <InputLabel marginSm>Section title</InputLabel>
            <Input
              {...register(`estimates.${index}.title`)}
            />
          </div>
          <div className={styles.formWrapper}>
            <InputLabel marginSm>Section description</InputLabel>
            <Input
              {...register(`estimates.${index}.description`)}
            />
          </div>
        </div>
        <div>
          <div className={styles.formWrapper}>
            <InputLabel marginSm>Type</InputLabel>
            <Input
              {...register(`estimates.${index}.estimate_type`)}
              placeholder="Front-end/Back-end/Mobile"
            />
          </div>
          <div className={styles.formWrapper}>
            <Stack>
              <div className={styles.formWrapper}>
                <InputLabel marginSm>Time</InputLabel>
                <Input
                  {...register(`estimates.${index}.time`)}
                />
              </div>
              <div className={styles.formWrapper}>
                <InputLabel marginSm>Rate</InputLabel>
                <Input
                  {...register(`estimates.${index}.rate`)}
                />
              </div>
              <div className={styles.formWrapper}>
                <InputLabel marginSm>Cost</InputLabel>
                <Input
                  {...register(`estimates.${index}.cost`)}
                />
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </>
  )
}