import { FC } from 'react';
import { WorkSummaryRow } from './Row/WorkSummaryRow';
import Table from '../../../ui/Table';
import TableCell from '../../../ui/TableCell';
import TableRow from '../../../ui/TableRow';
import styles from './WorkSummary.module.css';
import { ITimeTrackingFilter } from '../../../../pages/TimeTracking/TimeTracking.types';
import { Plug } from '../Plug/Plug';
import { useWorkSummary } from './useWorkSummary';
import Default from '../../../Default';

interface IWorkSummaryTable {
  filters?: ITimeTrackingFilter;
}

export const WorkSummaryTable: FC<IWorkSummaryTable> = ({
  filters,
}) => {
  const { loading, list } = useWorkSummary(filters);
  return (
    <>
      {!loading ? (
          <>
            {list.length !== 0 ?
            <Table className={styles.table}>
              <thead>
                <TableRow className={styles.theadRow}>
                  <th />
                  <TableCell component='th'>
                    {!filters?.show_by ||
                    filters?.show_by === 'Show by Project'
                      ? 'Project | Member'
                      : 'Member | Project'}
                  </TableCell>
                  <TableCell component='th'>Time</TableCell>
                </TableRow>
              </thead>
              <tbody>
                {list?.map((el) => (
                  <WorkSummaryRow
                    key={el.id}
                    data={el}
                    showByProject={!filters?.show_by || filters?.show_by === 'Show by Project'}
                  />
                ))}
              </tbody>
            </Table> :     <Plug />}
          </>
      ) : (
        <Default />
      )}
    </>
  );
};


