import { forwardRef, useState } from 'react';
import clsx from 'clsx';
import styles from './FormikTextArea.module.css';
import InputLabel from '../InputLabel/InputLabel';
import Typography from '../Typography/Typography';
import InputError from '../InputError/InputError';
import { ITextArea } from './FormikTextArea.model';

const FormikTextArea = forwardRef<HTMLTextAreaElement, ITextArea>(
  (
    {
      label,
      margin,
      component,
      inputClassName,
      fullWidth,
      error,
      EndIcon,
      StartIcon,
      type = 'text',
      className,
      mutedLabel,
      required,
      subTitle,
      boldLabel,
      wasRequest,
      ...props
    },
    ref
  ) => {
    const Component = component || 'textarea';
    const classNames = clsx(styles.wrap, className, {
      [styles.margin]: margin,
      [styles.fullWidth]: fullWidth,
    });

    const [focused, setFocused] = useState(false);

    const [blurred, setBlurred] = useState(false);

    const isError = () => {
      return (wasRequest || blurred) && Boolean(error);
    };

    const absoluteWrapperStyles = clsx(
      styles.absolute,
      focused && styles.absoluteFocused,
      isError() && styles.absoluteError
    );

    const autoResize = (
      textarea: React.FormEvent<HTMLTextAreaElement>
    ) => {
      textarea.currentTarget.style.height = 'auto';
      textarea.currentTarget.style.height =
        textarea.currentTarget.scrollHeight + 2 + 'px';
    };

    return (
      <>
        {label && (
          <InputLabel marginSm muted={props.disabled}>
            <span className={clsx(boldLabel && styles.bold)}>
              {label}
            </span>
            {required && (
              <span
                className={clsx(
                  styles.required,
                  props.disabled && styles.requiredDisabled
                )}>
                *
              </span>
            )}
          </InputLabel>
        )}
        {subTitle && (
          <Typography
            variant={'mediumLabel'}
            className={`mBottom4 ${styles.subTitle}`}>
            {subTitle}
          </Typography>
        )}
        <div className={classNames}>
          <div className={absoluteWrapperStyles} />
          {StartIcon && (
            <div className={styles.startIcon}>{StartIcon}</div>
          )}
          <Component
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              setBlurred(true);
            }}
            ref={ref}
            className={clsx(styles.root, inputClassName, {
              [styles.error]: isError() && error,
              [styles.withAdornments]: EndIcon,
              [styles.startIconContainer]: StartIcon,
            })}
            {...props}
            onInput={(e) => autoResize(e)}
          />
          {EndIcon && <div className={styles.endIcon}>{EndIcon}</div>}
        </div>
        <InputError>{isError() && error}</InputError>
      </>
    );
  }
);

export default FormikTextArea;
