import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const dayOffsPolicyApi = createApi({
  reducerPath: 'dayOffsPolicy',
  baseQuery: ApiBaseQuery({ baseUrl: '/day-off-policy' }),
  endpoints: (builder) => ({
    getDayOffPolicy: builder.query({
      query: (data) => ({
        url: '',
        method: 'get',
        data,
        params: data.params,
      }),
      transformResponse: (response, meta) => {
        return response;
      },
    }),
    updateDayOffPolicy: builder.mutation({
      query: (data) => ({
        url: '',
        method: 'put',
        data,
        params: data.params,
      }),
      transformResponse: (response, meta) => {
        return response;
      },
    }),
    createDayOffPolicy: builder.mutation({
      query: (data) => ({
        url: '',
        method: 'post',
        data,
        params: data.params,
      }),
      transformResponse: (response, meta) => {
        return response;
      },
    }),
    deleteDayOffPolicy: builder.mutation({
      query: (id: number) => ({
        url: `?dop_id=${id}`,
        method: 'delete',
      }),
      transformResponse: (response, meta) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetDayOffPolicyQuery,
  useUpdateDayOffPolicyMutation,
  useCreateDayOffPolicyMutation,
  useDeleteDayOffPolicyMutation,
} = dayOffsPolicyApi;
