import React, { ComponentPropsWithRef, ElementType, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

import { PolymorphicRef } from '../../../types/utils';

import styles from './Button.module.css';

export type ButtonProps<T extends ElementType> = {
  children?: ReactNode;
  className?: string;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'black' | 'blackBorder';
  component?: T;
  disabled?: boolean;
  endIcon?: ElementType;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large' | 'wide';
  startIcon?: ElementType;
  variant?: 'contained' | 'outlined' | 'text';
}

const ButtonBase = <T extends ElementType = 'button'>(
  {
    children,
    className,
    component,
    color = 'default',
    disabled,
    endIcon: EndIcon,
    size = 'medium',
    startIcon: StartIcon,
    variant = 'contained',
    fullWidth,
    ...props
  }: ButtonProps<T> & Omit<ComponentPropsWithRef<T>, keyof ButtonProps<T>>,
  ref: PolymorphicRef<T>
) => {
  // Determine the component to use (default is 'button')
  const Component = component || 'button';

  // Create the classNames based on the props
  const classNames = clsx(styles.root, className, {
    [styles[color]]: color,
    [styles.disabled]: disabled,
    [styles.fullWidth]: fullWidth,
    [styles[size]]: size,
    [styles[variant]]: variant,
  });

  // Render the Button component
  return (
    <Component ref={ref} className={classNames} disabled={disabled} {...props}>
      {StartIcon && (
        <span className={clsx(styles.iconWrap, styles.iconStart)}>
          <StartIcon className={styles.icon} />
        </span>
      )}
      {children}
      {EndIcon && (
        <span className={clsx(styles.iconWrap, styles.iconEnd)}>
          <EndIcon className={styles.icon} />
        </span>
      )}
    </Component>
  );
};

// Create the final Button component using forwardRef
const Button = forwardRef(ButtonBase) as typeof ButtonBase;

// Export the Button component
export default Button;
