import styles from './MemberCard.module.css';
import { FC } from 'react';
import { IProjectMember } from '../../../../services/projectsApi/types';
import { ImageFallback } from '../../../ui/ImageFallback/ImageFallback';
import Status from '../../../ui/Status';
interface ITeamMemberCard {
  item: IProjectMember;
  className?: string;
  handleNavigateToProfile: (id: number) => void;
}

export const MemberCard: FC<ITeamMemberCard> = ({
  item,
  handleNavigateToProfile,
}) => {
  return (
    <div className={styles.card}>
      <ImageFallback
        className={styles.img}
        src={item.user?.avatar_url ? item.user.avatar_url : ''}
        onClick={() => handleNavigateToProfile(item.user.id)}
      />
      <div className={styles.containerInfo}>
        <p
          onClick={() => handleNavigateToProfile(item.user.id)}
          className={styles.title}>
          {item.user?.full_name}
        </p>
        <p className={styles.subTitle}>{item.position_name}</p>
        {item.departments?.length ? (
          <div className={styles.containerStages}>
            {item.departments?.map((item, index) => (
              <Status key={index} item={item} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
