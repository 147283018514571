import { FC, ReactNode } from 'react';

import { ReactComponent as SickLeaveDog } from '../../../assets/image/sick-leave-dog.svg';
import { ReactComponent as SickLeave } from '../../../assets/icons/plusWhite.svg';

import Typography from '../../ui/Typography';
import Button from '../../ui/Button';

import styles from './NoDayOff.module.css';

interface INoDayOff {
  openModal: () => void;
  title?: string;
  icon?: ReactNode;
  subTitle?: string;
  btnTitle?: string;
}
const NoDayOff: FC<INoDayOff> = ({
  openModal,
  title = 'Sick leave and day off in a couple of clicks',
  icon = <SickLeaveDog />,
  subTitle = 'Set up the conditions for receiving sick leave and vacation days in a couple of clicks.',
  btnTitle = 'Day off and Medical Leaves Conditions',
}) => {
  return (
    <div className={styles.wrapper}>
      {icon}
      <Typography variant='body' weight='bold'>
        {title}
      </Typography>
      <Typography align='center'>{subTitle}</Typography>
      <Button
        color='black'
        onClick={openModal}
        startIcon={SickLeave}
        className={styles.buttonAdd}>
        {btnTitle}
      </Button>
    </div>
  );
};

export default NoDayOff;
