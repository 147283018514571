import moment from 'moment';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Table from '../../ui/Table';
import TableRow from '../../ui/TableRow';
import TableCell from '../../ui/TableCell';
import routes from '../../../constants/routes';
import defaultAvatar from '../../../assets/icons/movadex.png';
import {ReactComponent as Puzzle} from '../../../assets/image/puzzle.svg';
import styles from '../BenchPage.module.css'
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';


export default function BenchTable(list: any) {
    const navigate = useNavigate();
    const handleView = (id: number) => navigate(routes.benchMember + `/${id}?page=1&sort_by_type=desc&sort_by=created_at`)
    return (
        <div className={styles.benchTable}>
            {list?.list.length ?
                <Table>
                    <thead className={styles.benchTable}>
                    <TableRow className={styles.tableRow}>
                        <TableCell component="th" sortBy="user_full_name">
                            Name
                        </TableCell>
                        <TableCell component="th" sortBy="bench_action_and_type">
                            Bench Status
                        </TableCell>
                        <TableCell component="th" sortBy="bench_period">
                            Period
                        </TableCell>
                        <TableCell component="th">
                            Comment
                        </TableCell>
                    </TableRow>
                    </thead>
                    <tbody>
                    {list?.list?.map((i: any, index: number) => (
                        <TableRow key={index} hover onClick={() => handleView(i.user_id)} className={styles.tableRow}>
                            <TableCell component="td" className={styles.memberInfo}>
                                <img src={i?.avatar_url || defaultAvatar} alt="avatar"/>
                                {i.first_name} {i.last_name}
                            </TableCell>
                            <TableCell component="td">
                            <span className={clsx(
                                i.bench_status === 'Not on Bench' && styles.notBench,
                                i.bench_status === 'On Bench-50' && styles.benchHalf,
                                i.bench_status === 'On Bench-0' && styles.bench
                            )}>{i.bench_status}</span>
                            </TableCell>
                            <TableCell component="td">
                                {i.date_from ? <>{moment(i.date_from).format('D MMM YYYY')} {'->'} {moment(i.date_to).format('D MMMM YYYY')}</> : '-'}
                            </TableCell>
                            <TableCell component="td">
                                <Tooltip title={i.comment}>
                                     <span className={styles.tableRowsLast}>
                                    {i.comment}
                                 </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    </tbody>
                </Table> :
                <div className={styles.emptyTable}>
                    <Puzzle/>
                    <span>No results found</span>
                    <span>Double-check filter parameters above if we’re missing something</span>
                </div>
            }
        </div>

    );
}
