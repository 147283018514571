import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { ProfileBreadCrumb } from '../../components/Projects/Profile/ProfileBreadCrumb/ProfileBreadCrumb';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import Button from '../../components/ui/Button';
import { useAppDispatch } from '../../store';
import {
  archiveMember,
  getMemberDaysOffCounts,
  getMemberProfile,
} from '../../store/humanResources/thunks';
import { getHRSelector } from '../../store/humanResources/selectors';
import routes from '../../constants/routes';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/archive.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import styles from './EmployeeProfile.module.css';
import ProfileInfoCard from '../../components/HumanResources/MembersProfile/ProfileInfoCard/ProfileInfoCard';
import EmployeeWorkInfo from '../../components/EmployeeWorkInfo/EmployeeWorkInfo';
import Default from '../../components/Default';
import defaultAvatar from '../../assets/icons/movadex.png';
import {
  getCanEditSelector,
  getProfileSelector,
} from '../../store/profile/selectors';

const EmployeeProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(getProfileSelector);
  const canEdit = useSelector(getCanEditSelector);
  const id: number = !canEdit ? Number(user?.id) : Number(params.id);
  const { membersProfile, membersProfileIsLoading } =
    useSelector(getHRSelector);
  const getMembersProfileFetch = async () => {
    id && dispatch(getMemberProfile({ user_id: Number(id) }));
    id && dispatch(getMemberDaysOffCounts({ user_id: Number(id) }));
  };
  useEffect(() => {
    getMembersProfileFetch?.();
  }, [params?.id]);

  const onArchiveClickHandler = () => {
    id &&
      dispatch(
        archiveMember({
          data: { user_id: Number(id), is_archived: true },
        })
      );
    id && navigate(routes.humanResourcesArchive);
  };

  const onDocumentsClickHandler = () => {
    id && navigate(routes.employeeProfile + `/${id}/documents`);
  };

  return (
    <div className={styles.wrapper}>
      {!membersProfileIsLoading && membersProfile ? (
        <>
          {canEdit && (
            <div className={styles.topContentWrapper}>
              <Breadcrumbs
                itemComponent={
                  <ProfileBreadCrumb
                    isLastIndex
                    key={
                      (id as unknown as number) +
                      membersProfile.full_name
                    }
                    url={membersProfile.avatar_url || defaultAvatar}
                    title={membersProfile.full_name}
                  />
                }
                indexItemComponent={1}
                routeName={`Human Resources$(${routes.humanResources})/Profile`}
              />
              <div className={styles.profileWrapperRight}>
                <Button
                  color='secondary'
                  className={styles.buttonDocument}
                  onClick={onDocumentsClickHandler}>
                  <FileIcon className={styles.iconDocumentStyle} />
                  <p>Documents</p>
                </Button>
                <Button
                  color='secondary'
                  className={styles.buttonText}
                  onClick={onArchiveClickHandler}>
                  <ArchiveIcon className={styles.iconStyle} />
                  <p>Move to Archive</p>
                </Button>
              </div>
            </div>
          )}
          <div
            className={clsx(
              styles.profileWrapper,
              !canEdit && styles.paddingTop
            )}>
            <ProfileInfoCard
              canEdit={canEdit}
              profileInfo={membersProfile}
            />
            <EmployeeWorkInfo canEdit={canEdit} />
          </div>
        </>
      ) : (
        <Default />
      )}
    </div>
  );
};

export default EmployeeProfile;
