import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const SunIcon: React.FC<Props> = (props) => {
  const { color, size } = props

  return (
    <svg width={size || '22'} height={size || '22'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.75 11H3.66667M11 2.75V3.66667M18.3333 11H19.25M11 18.3333V19.25M5.13333 5.13333L5.775 5.775M16.8667 5.13333L16.225 5.775M16.225 16.225L16.8667 16.8667M5.775 16.225L5.13333 16.8667M14.6667 11C14.6667 13.025 13.025 14.6667 11 14.6667C8.97496 14.6667 7.33333 13.025 7.33333 11C7.33333 8.97496 8.97496 7.33333 11 7.33333C13.025 7.33333 14.6667 8.97496 14.6667 11Z" stroke={color || '#E0B457'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </svg>
  )
}
