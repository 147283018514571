import { FC, MouseEvent, useState } from 'react';
import classes from '../AddHoliday/AddHoliday.module.css';
import Typography from '../../../ui/Typography';
import Stack from '../../../ui/Stack';
import Button from '../../../ui/Button';
import HolidayCard from '../../HolidayCard/HolidayCard';
import { IPublicHoliday } from '../../../../services/policyApi/types';
import { policyApi } from '../../../../services/policyApi/policyApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopLeft } from '../../../ui/Snackbar/constants';

interface IEditHoliday {
  item: IPublicHoliday;
  closeDrawer: () => void;
  setRender: React.Dispatch<React.SetStateAction<number>>;
}

const EditHoliday: FC<IEditHoliday> = ({
  item,
  closeDrawer,
  setRender,
}) => {
  const [holidays, setHolidays] = useState<IPublicHoliday[]>([
    { ...item },
  ]);

  const onSumbit = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const obj = holidays[0];
    delete obj.created_at;
    try {
      await policyApi.editHoliday(obj);
      closeDrawer();
      setRender((prev) => prev + 1);
      enqueueSnackbar('Successfully edited Public Holiday', {
        variant: 'success',
        anchorOrigin: snackbarPosTopLeft,
      });
    } catch (e: any) {
      if (e.response.data.detail) {
        enqueueSnackbar(e.response.data.detail, {
          variant: 'success',
          anchorOrigin: snackbarPosTopLeft,
        });
      }
    }
  };

  const validate = (holidays?: IPublicHoliday[]) => {
    return holidays
      ?.map((el) => !!el.name && !!el.date_from && !!el.date_to)
      .includes(false);
  };
  const isNotValid = validate(holidays);

  return (
    <Stack className={classes.formPopupBody}>
      <div className={classes.form}>
        <Stack className={classes.holidayForm} direction='column'>
          {holidays.map((el, index) => (
            <HolidayCard
              key={index}
              ind={index}
              item={el}
              setData={setHolidays}
            />
          ))}
        </Stack>
        <Stack className={classes.containerBtn}>
          <Button
            disabled={isNotValid}
            onClick={onSumbit}
            color='black'
            type='submit'
            fullWidth>
            <Typography variant='buttonText'>Save</Typography>
          </Button>
        </Stack>
      </div>
    </Stack>
  );
};

export default EditHoliday;
