import React from 'react'

interface Props {
  stroke?: string
  size?: string
}

export const CrossIcon: React.FC<Props> = (props) => {
  const { stroke, size } = props

  return (
    <svg width={size || '15'} height={size || '15'} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.25 3.75L3.75 11.25M3.75 3.75L11.25 11.25" stroke={stroke || '#A0AEC0'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
