import { FC } from 'react';
import { IContactsItem } from '../../../../../../../services/projectsApi/types';
import styles from './ContactsModal.module.css';
import { timezone } from '../../../../../../../common/values/timezone';
import { ImageFallback } from '../../../../../../ui/ImageFallback/ImageFallback';

interface IContactsProfile {
  item: IContactsItem;
}
export const ContactsProfile: FC<IContactsProfile> = ({ item }) => {
  return (
    <div className={styles.profileWrapper}>
      <div className={styles.wrapperProfileIcon}>
        <ImageFallback className={styles.profileIcon} src='' />
      </div>
      <div className={styles.profileInfoWrapper}>
        <p className={styles.profileUserName}>{item.full_name}</p>
        <p className={styles.profileRole}>{item.position}</p>
        <a
          href={`mailto:${item.email}`}
          className={styles.profileEmail}>
          {item.email}
        </a>
        <p className={styles.profileTime}>
          {
            timezone.filter((e) => e.value === item.gmt_timezone)[0]
              .name
          }
        </p>
        <p className={styles.profileComment}>{item.comment}</p>
      </div>
    </div>
  );
};
