import clsx from 'clsx';
import { forwardRef, useState } from 'react';

import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';

import { InputProps } from './FormikInput.model';

import styles from './FormikInput.module.css';
import Typography from '../Typography';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      margin,
      component,
      inputClassName,
      fullWidth,
      error,
      EndIcon,
      StartIcon,
      type = 'text',
      className,
      mutedLabel,
      required,
      subTitle,
      boldLabel,
      wasRequest,
      mask,
      onChange,
      regex = /[^0-9a-zA-Z]/g,
      ...props
    },
    ref
  ) => {
    const Component = component || 'input';
    const classNames = clsx(styles.wrap, className, {
      [styles.margin]: margin,
      [styles.fullWidth]: fullWidth,
    });

    const formatInput = (input: string, mask: string) => {
      const inputChars = input.replace(regex, '').split('');
      let formattedInput = '';

      for (let i = 0; i < mask.length; i++) {
        if (inputChars.length === 0) break;

        const maskChar = mask[i];

        if (/[0-9a-zA-Z]/.test(maskChar)) {
          formattedInput += inputChars.shift();
        } else {
          formattedInput += maskChar;
        }
      }

      return formattedInput;
    };

    const [focused, setFocused] = useState(false);

    const [blurred, setBlurred] = useState(false);

    const isError = () => {
      return (wasRequest || blurred) && Boolean(error);
    };

    const absoluteWrapperStyles = clsx(
      styles.absolute,
      focused && styles.absoluteFocused,
      isError() && styles.absoluteError
    );

    return (
      <div className={styles.container}>
        {label && (
          <InputLabel marginSm muted={props.disabled}>
            <span className={clsx(boldLabel && styles.bold)}>
              {label}
            </span>
            {required && (
              <span
                className={clsx(
                  styles.required,
                  props.disabled && styles.requiredDisabled
                )}>
                *
              </span>
            )}
          </InputLabel>
        )}
        {subTitle && (
          <Typography
            variant={'mediumLabel'}
            className={`mBottom4 ${styles.subTitle}`}>
            {subTitle}
          </Typography>
        )}
        <div className={classNames}>
          <div className={absoluteWrapperStyles} />
          {StartIcon && (
            <div className={styles.startIcon}>{StartIcon}</div>
          )}
          <Component
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              setBlurred(true);
            }}
            ref={ref}
            type={type}
            aria-label='input'
            className={clsx(styles.root, inputClassName, {
              [styles.error]: isError() && error,
              [styles.withAdornments]: EndIcon,
              [styles.startIconContainer]: StartIcon,
            })}
            onChange={(e) => {
              if (mask) {
                e.target.value = formatInput(e.target.value, mask);
              }
              onChange && onChange(e);
            }}
            {...props}
          />
          {EndIcon && <div className={styles.endIcon}>{EndIcon}</div>}
        </div>
        {isError() ? (
          <InputError className={styles.errorText}>
            {error}
          </InputError>
        ) : (
          <div className={styles.errorPlug} />
        )}
      </div>
    );
  }
);

export default Input;
