import ReactPaginate from 'react-paginate';

import { searchParamsToObject } from '../../../helpers/object/searchParams-to-object.helper';

import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';

import styles from './TablePagination.module.css';

export type TablePaginationProps = {
  count?: number;
  params?: any;
  itemsPerPage?: number;
  currentStartPage?: number;
  setSearchParams?: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >;
  searchParams?: URLSearchParams;
};
const TablePagination = (
  {
    count = 0,
    params,
    itemsPerPage = 10,
    currentStartPage = params?.page || 1,
    setSearchParams,
    searchParams,
  }: TablePaginationProps) => {

  const pageCount = Math.ceil(count / itemsPerPage);

  const handleChangePage = (event: { selected: number }) => {
    const page = `${event.selected + 1}`;
    if (!searchParams?.get('page') && page === '1') return;
    if (searchParams?.get('page') !== page) {
      setSearchParams &&
      setSearchParams((prev) => {
        const params: any = searchParamsToObject(prev);
        if (page !== '1') return { ...params, page: page };
        delete params.page;
        return { ...params };
      });
    }
  };

  return (
    <>
      {count > itemsPerPage && (
        <ReactPaginate
          key={currentStartPage}
          breakLabel="..."
          nextLabel={<ChevronRightIcon />}
          className={styles.root}
          pageLinkClassName={styles.item}
          onPageChange={handleChangePage}
          pageRangeDisplayed={1}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          previousLabel={<ChevronLeftIcon />}
          renderOnZeroPageCount={null}
          initialPage={currentStartPage - 1}
        />
      )}
    </>
  );
};

export default TablePagination;
