import queryString from 'query-string';
import API from '../http';
import { IProjectsApi } from './types';

export const projectsApi: IProjectsApi = {
  getContacts: (data) =>
    API.get(`/project/contacts?${queryString.stringify(data)}`),
  postContacts: (data) => API.post('/project/contacts', data),
  editContacts: (data) => API.put('/project/contacts', data),
  deleteContacts: (data) =>
    API.delete('/project/contacts', { params: data }),
  getLinks: (data) =>
    API.get(`/project/links?${queryString.stringify(data)}`),
  postLinks: (data) => API.post('/project/links', data),
  editLinks: (data) => API.put('/project/links', data),
  deleteLinks: (data) =>
    API.delete('/project/links', { params: data }),
  getFilters: (data) => API.get('/project/filters', { params: data }),
  saveFilter: (data) => API.post('/project/filters', data),
  editFilter: (data) => API.put('/project/filters', data),
  deleteFilter: (name) =>
    API.delete('/project/filters', { params: { name: name } }),
  list: (data) =>
    API.get(`/projects_list?${queryString.stringify(data)}`),
  totalByStatuses: (data) =>
    API.get(
      `/project/total-by-statuses?${queryString.stringify(data)}`
    ),
  getProfileProject: (project_id) =>
    API.get('/project', { params: { project_id } }),
  editProjectProfile: (data) => API.put('/project', data),
  editProjectProfileInfo: (data) => API.put('/project_info', data),
  getProjectTags: (url, search) =>
    API.get(url, { params: { search } }),
  addProjectTag: (url, data) => API.post(url, data),
  deleteProjectTag: (url, data) => API.delete(url, { params: data }),
  clients_list: () => API.get('/project/filters/clients_list'),
  getProjectMember: (data) =>
    API.get(`/project/members?${queryString.stringify(data)}`),
  getProjectMemberTotalByDepartments: (data) =>
    API.get(
      `/project/members/total-by-departments?${queryString.stringify(
        data
      )}`
    ),

  editProjectMember: (data) => API.put('/project/members', data),
  createProjectMember: (data) => API.post('/project/members', data),
  deleteProjectMember: (project_member_id) =>
    API.delete('/project/members', { params: { project_member_id } }),
};
