import { useTable } from './useArchive';
import HumanResourcesHeader from '../../../sharedPages/HumanResourcesPage/components/Header/Header';
import styles from './Table.module.css';
import { DepartmentList } from '../../../sharedPages/HumanResourcesPage/components/DepartmentList/DepartmentList';
import { IHRListParams } from '../../../../services/hrApi/types';
import { ArchivePlug } from '../Plug/Plug';
import TableUI from '../../../ui/Table';
import TablePagination from '../../../ui/TablePagination/TablePagination';
import HumanResourcesTableRow from '../../../sharedPages/HumanResourcesPage/components/Table/TableRow/TableRow';
import TableRow from '../../../ui/TableRow/TableRow';
import TableCell from '../../../ui/TableCell/TableCell';
import { useGetQueryParams } from '../../../../utils/common';
import { useSearchParams } from 'react-router-dom';
import Default from '../../../Default';

export const Table = () => {
  const params: IHRListParams = useGetQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    filter,
    setFilter,
    searchValue,
    setSearchValue,
    data,
    total,
    positions,
    handleArchiveUser,
    handleRemoveUser,
    listIsLoading,
    listIsEmpty,
  } = useTable(searchParams, setSearchParams);

  return (
    <>
      <div className={styles.navContainer}>
        <DepartmentList
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          total={total}
          params={params}
          data={data}
          positions={positions}
        />
      </div>
      <HumanResourcesHeader
        filter={filter}
        setFilter={setFilter}
        onClickView={() => {
          console.log('clicked view');
        }}
      />
      {listIsLoading ? (
        <Default />
      ) : !listIsEmpty ? (
        <>
          <TableUI tableContainerClass={styles.table}>
            <thead>
              <TableRow className={styles.thead}>
                <TableCell
                  columnClass={styles.clear}
                  component='th'
                  sortBy='first_name'>
                  Name
                </TableCell>
                {/* <TableCell component='th'></TableCell> */}
                <TableCell component='th' sortBy='departments'>
                  Department
                </TableCell>
                <TableCell component='th' sortBy='positions'>
                  Position
                </TableCell>
                <TableCell component='th' sortBy='seniority_level'>
                  Seniority
                </TableCell>
                <TableCell component='th'>Email</TableCell>
                <TableCell component='th'>Phone</TableCell>
                <TableCell component='th'></TableCell>
              </TableRow>
            </thead>
            <tbody>
              {data?.items.map((item) => (
                <HumanResourcesTableRow
                  key={item.id}
                  item={item}
                  isArchive
                  handleArchiveUser={handleArchiveUser}
                  handleRemoveUser={handleRemoveUser}
                />
              ))}
            </tbody>
          </TableUI>
          <TablePagination
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            currentStartPage={data?.page}
            count={data?.total}
            itemsPerPage={data?.size}
          />
        </>
      ) : (
        <ArchivePlug />
      )}
    </>
  );
};
