export interface IListData{
    request_type: string
    requests_count: number
}


export const RequestStatus = [
    {
        name: 'Pending',
        value: 'pending',
        key: 1
    },
    {
        name: 'Approved',
        value: 'approved',
        key: 2
    },
    {
        name: 'Declined',
        value: 'declined',
        key: 3
    }
]
//
//
// export type toggleType = 'Work Summary' | 'Timesheet';
//
// export interface IRequestFilter {
//     status?: string[];
//     members?: string[];
// }
// export interface ITimeTrackingFilter
//     extends ITimeTrackingFilterOptions {
//     date_from?: string;
//     date_to?: string;
//     show_by?: string;
//     toggle?: toggleType;
//     search?: string;
// }
