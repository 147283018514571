import React from 'react';

import RestorePasswordForm from './components/RestorePasswordForm';
import AuthFormContainer from '../../AuthFormContainer';

const ForgotPasswordPage = () => {
  return (
    <AuthFormContainer>
      <RestorePasswordForm />
    </AuthFormContainer>
  );
};

export default ForgotPasswordPage;
