import { FC, ReactNode } from 'react';
import styles from './styles.module.css';

import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plus.svg';
import { ReactComponent as PencilIcon } from '../../../../../../../assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../assets/icons/trash-icon.svg';
import Button from '../../../../../../ui/Button/Button';
interface IProfileWrapper {
  children: ReactNode;
  onClose: () => void;
  onClickEdit: () => void;
  titleSaveButton?: string;
  onClickRemove?: () => void;
}
export const ProfileWrapper: FC<IProfileWrapper> = ({
  children,
  onClose,
  onClickEdit,
  titleSaveButton = 'Remove from Links',
  onClickRemove,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div />
        <div onClick={onClose} className={styles.btnIcon}>
          <PlusIcon />
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <Button
          onClick={onClickEdit}
          variant='outlined'
          color='black'
          className={styles.editBtn}
          startIcon={PencilIcon}>
          Edit
        </Button>
        <Button
          onClick={onClickRemove}
          className={styles.trashBtn}
          startIcon={TrashIcon}
          type='button'>
          {titleSaveButton}
        </Button>
      </div>
    </div>
  );
};
