import { useState } from 'react';
import styles from '../../pages/MedicalLeave/MedicalLeave.module.css';
import Breadcrumbs from '../ui/Breadcrumbs';
import routes from '../../constants/routes';
import Button from '../ui/Button';
import AbsenceDaysTitle from '../AbsenceDaysTitle/AbsenceDaysTitle';
import { HeartIcon, SunIcon } from '../../assets/icons/components';
import NumberOutOfNumber from '../NumberOutOfNumber/NumberOutOfNumber';
import DateMenu from '../DateMenu';
import Chip from '../ui/Chip';
import { isEmpty } from 'lodash';
import Typography from '../ui/Typography';
import Table from '../ui/Table';
import TableRow from '../ui/TableRow';
import TableCell from '../ui/TableCell';
import { medicalLeaveData } from '../Projects/Profile/ProfileAdditionalInfo/mocks';
import MedicalLeaveTable from '../../pages/MedicalLeave/MedicalLeaveTable/MedicalLeaveTable';
import Drawer from '../ui/Drawer/Drawer';
import MedicalLeaveDrawerContent from '../../pages/MedicalLeave/MedicalLeaveDrawerContent/MedicalLeaveDrawerContent';
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { EmployeeLeaveProps } from './EmployeeLeaveProps';
import DaysOffDrawerContent from '../../pages/DaysOff/DaysOffDrawerContent/DaysOffDrawerContent';

const EmployeeLeave = ({
  actionMenuOptions,
  type,
  drawerSaveClick,
  firstNumber = 0,
  secondNumber = 0,
}: EmployeeLeaveProps) => {
  const [drawerShow, setDrawerShow] = useState(false);
  const [value, setValue] = useState<any>({
    proposition_date_from: '',
    proposition_date_to: '',
  });

  const isMedicalLeaves = type === 'medicalLeaves'
  const title = isMedicalLeaves ? 'Medical Leaves' : 'Days Off';
  const icon = isMedicalLeaves ? <HeartIcon /> : <SunIcon />;
  const backgroundIcon = isMedicalLeaves ? styles.medicalLeaveSecondColor : styles.daysOffLeaveSecondColor;

  const onDrawerHandler = () => {
    setDrawerShow((prev) => !prev);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.menuWrapper}>
        <Breadcrumbs
          routeName={`Human Resources$(${routes.humanResources})/Profile$(${routes.employeeProfile})/Medical Leave`}
        />
        <div>
          <Button color='secondary' className={styles.buttonText}>
            <Pencil className={styles.iconStyle} />
            <p>Move to Archive</p>
          </Button>
          <Button onClick={onDrawerHandler} color='black'>
            <Plus className={styles.iconStyle} />
            <p>Add {title}</p>
          </Button>
        </div>
      </div>
      <div className={'row mTop24'}>
        <AbsenceDaysTitle
          icon={icon}
          title={title}
          secondColor={backgroundIcon}
        />
        <NumberOutOfNumber
          firstNumber={firstNumber}
          secondNumber={secondNumber}
          additionalStyle={'mLeft16'}
        />
      </div>
      <div className={'row mTop24'}>
        <DateMenu
          value={value}
          onDateSelected={() => ''}
          setValue={setValue}
        />
        {value.proposition_date_from && (
          <Chip
            className={'mLeft8'}
            variant='contained'
            color={!isEmpty(value) ? 'error' : 'primary'}
            background={'errorBg'}
            onClick={() => setValue({})}>
            <Typography variant={'smallText'} weight={'regular'}>
              Reset time period
            </Typography>
          </Chip>
        )}
      </div>
      <div>
        <Table>
          <thead>
            <TableRow>
              <TableCell component='th'>Period</TableCell>
              <TableCell align='center' component='th'>
                Self Paid
              </TableCell>
              <TableCell align='center' component='th'>
                Duration
              </TableCell>
              <TableCell align='center' component='th'>
                Status
              </TableCell>
              <TableCell component='th'>Comment</TableCell>
              <TableCell align='center' component='th'>
                File
              </TableCell>
              <TableCell component='th' />
            </TableRow>
          </thead>
          <tbody>
          {/*{medicalLeaveData?.map((e, i) => (*/}
          {/*  <MedicalLeaveTable*/}
          {/*    key={i}*/}
          {/*    data={e}*/}
          {/*    actionMenuOptions={actionMenuOptions}*/}
          {/*  />*/}
          {/*))}*/}
          </tbody>
        </Table>
      </div>
      <Drawer
        classNameIcon={styles.medicalLeaveSecondColor}
        headerIcon={icon}
        anchor='right'
        open={drawerShow}
        classNames={styles.drawer}
        onClose={() => setDrawerShow(false)}
        title={`Add ${title}`}>
        {isMedicalLeaves ? (
          <div/>
        ) : (
          <DaysOffDrawerContent />
        )}
      </Drawer>
    </div>
  )
}

export default EmployeeLeave
