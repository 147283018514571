import React, { useEffect} from 'react';
import clsx from 'clsx';
import classes from './DateMenu.module.css';
import DateRangePickerInput from '../ui/DateRangePickerInput';

const DateMenu = ({onDateSelected, value, setValue}: { onDateSelected?: any, value?:any, setValue?: any }) => {
    const handleChange = (date: any) => {
        setValue({proposition_date_from: date.startDate, proposition_date_to: date.endDate});
    };

    useEffect(() => {
        if (value.proposition_date_from && value.proposition_date_to && onDateSelected)
            onDateSelected({
                dateFrom: value.proposition_date_from.toISOString().replace('Z', ''),
                dateTo: value.proposition_date_to.toISOString().replace('Z', '')
            });
    }, [value.proposition_date_from, value.proposition_date_to]);

    return (
        <div className={clsx([classes.flexRow, classes.dateWrapper])}>
            <DateRangePickerInput
                startDate={value.proposition_date_from}
                endDate={value.proposition_date_to}
                className={classes.icon}
                onChange={handleChange}
            />
        </div>
    )
}

export default DateMenu;
