import { FC, useState } from 'react';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';

import styles from './DayOffConditionSelect.module.css';

import clsx from 'clsx';

type optionType = { label: string; value: string };

interface IDayOffConditionSelect {
  value?: optionType;
  onChangeValue: (e: optionType) => void;
  options?: optionType[];
}

const DayOffConditionSelect: FC<IDayOffConditionSelect> = ({
  value,
  options,
  onChangeValue,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const stylesOptions = clsx(
    styles.containerOptions,
    showOptions && styles.showOptions
  );

  const handleShowOptions = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  const handleSelectOption = (el: optionType) => {
    onChangeValue(el);
    setShowOptions(false);
  };

  const root = document.querySelector(styles.root);
  const containerOptions = document.querySelector(
    styles.containerOptions
  );

  document.addEventListener('click', (event) => {
    const target = event.target;
    const itsMenu =
      target == containerOptions ||
      (target instanceof Node && containerOptions?.contains(target));
    const itsSelect = target == root;

    if (!itsMenu && !itsSelect && showOptions) {
      setShowOptions(false);
    }
  });

  return (
    <div className={styles.relative}>
      {!!options?.length &&
        <div onClick={handleShowOptions} className={styles.select}>
          <p className={styles.selectTitle}>{value?.label}</p>
          <ChevronDownIcon />
        </div>
      }
      <div
        onClick={(e) => e.stopPropagation()}
        className={stylesOptions}>
        {options?.map((el, index) => (
          <div
            onClick={() => handleSelectOption(el)}
            key={index}
            className={`${styles.option} ${
              index !== 0 && styles.borderTop
            }`}>
            {el.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayOffConditionSelect;
