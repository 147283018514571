import { FC, useState } from 'react';
import clsx from 'clsx';
import styles from './TeamListCard.module.css';
import { MemberCard } from '../MemberCard/MemberCard';
import { ReactComponent as TriangleIcon } from '../../../../assets/icons/triangle.svg';
import { IProjectMember } from '../../../../services/projectsApi/types';

interface ITeamListCard {
  item: IProjectMember;
  index: number;
  array: ITeamListCard['item'][];
  handleNavigateToProfile: (id: number) => void;
}

export const TeamListCard: FC<ITeamListCard> = ({
  item,
  index,
  array,
  handleNavigateToProfile,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const stylesOptions = clsx(
    styles.containerOptions,
    showOptions && styles.showOptions
  );

  const handleShowOptions = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  const root = document.querySelector(styles.root);
  const containerOptions = document.querySelector(
    styles.containerOptions
  );

  document.addEventListener('click', (event) => {
    const target = event.target;
    const itsMenu =
      target == containerOptions ||
      (target instanceof Node && containerOptions?.contains(target));
    const itsSelect = target == root;

    if (!itsMenu && !itsSelect && showOptions) {
      setShowOptions(false);
    }
  });
  return (
    <div className={styles.relative}>
      <div
        key={index}
        className={clsx(
          styles.card,
          styles['z-3'],
          showOptions && styles.activeBc
        )}>
        <div onClick={handleShowOptions} className={styles.innerCard}>
          <p>+{array.length}</p>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className={stylesOptions}>
          <div className={styles.arrow}>
            <TriangleIcon />
          </div>
          {array.map((item, index) => (
            <>
              <div className={styles.row}>
                <MemberCard
                  handleNavigateToProfile={handleNavigateToProfile}
                  key={index}
                  item={item}
                />
              </div>
              {array.length - 1 !== index && (
                <div className={styles.divide} />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
