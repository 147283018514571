import React, {FC, useState} from 'react'
import Typography from '../ui/Typography'
import styles from '../EmployeeDocument/EmployeeDocument.module.css'
import {ReactComponent as FileIcon} from '../../assets/icons/files-placeholders.svg'
import {DownloadIcon, TrashIcon} from '../../assets/icons/components'
import {useDeleteDocumentMutation} from '../../store/documents/documentsApi'
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopLeft} from '../ui/Snackbar/constants';
import {DeleteModal} from '../HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';

interface Props {
    data?: any
    refetch?: any,
    setData?: any,
}

interface Info {
    id?: any,
    path?: any,
}

const EmployeeDocument: FC<Props> = (props) => {
    const {data, refetch, setData} = props
    const [deleteDocument] = useDeleteDocumentMutation()
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentID] = useState<Info>({
        id: '',
        path: '',
    });

    if (!data) {
        return null
    }

    type TransformedData = {
        [year: string]: {
            [month: string]: {
                [day: string]: Array<{
                    id: number;
                    created_at: string;
                    document_size_mb: number;
                    document_name: string;
                    document_path: string;
                }>;
            };
        };
    };

    const transformedData: TransformedData = data.reduce((result: any, item: any) => {
        const date = new Date(item.created_at);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', {month: 'long'});
        const day = date.getDate();
        // Create a key for the day
        const dayKey = `${day} ${month}`;

        result[year] ??= {};
        result[year][month] ??= {};
        result[year][month][dayKey] ??= [];

        result[year][month][dayKey].push(item);

        return result;
    }, {} as TransformedData);


    const downloadFile = (documentPath: string, documentName: string) => {
        const newWindow = window.open(documentPath, '_blank');
        if (newWindow) {
            newWindow.focus();
        } else {
            console.error('Unable to open a new window/tab. Please check your browser settings.');
        }
    }



    const onDeleteDoc = async (id: any, path: any) => {
        setOpen(false)
        if (path?.lastModified) {
            const updatedFiles = data.filter((file: any) => file?.document_path?.lastModified !== path?.lastModified);
            setData(updatedFiles);
        }
        if (id) {
            try {
                await deleteDocument(+id)
                enqueueSnackbar('File deleted successfully', {
                    variant: 'success',
                    anchorOrigin: snackbarPosTopLeft,
                });

            } catch (e) {
                console.log('error', e)
            }
            if (refetch) {
                refetch()
            }
        }
    }

    return (
        <div>
            {Object.keys(transformedData).map((year) => (
                <div key={year}>
                    {Object.keys(transformedData[year]).map((month) => (
                        <div key={month}>
                            <Typography className={Object.keys(transformedData)[0] === year ? undefined : 'mTop16'}
                                        variant={'subHeading2'}>
                                {month} {year}
                            </Typography>
                            {Object.keys(transformedData[year][month]).map((day) => (
                                <div key={day}>
                                    <div className={styles.dayWrapper}>
                                        <Typography variant={'mediumLabel'}>
                                            {day}
                                        </Typography>
                                    </div>
                                    {transformedData[year][month][day].map((document, index) => (
                                        <div key={document.id}>
                                            <div className={styles.fileSection}>
                                                <div className={`${styles.fileWrapper}`}>
                                                    <FileIcon/>
                                                    <div className={styles.fileInfoWrapper}>
                                                        <Typography variant={'smallBody'}>
                                                            {document.document_name}
                                                        </Typography>
                                                        <Typography variant={'mediumLabel'}
                                                                    className={styles.fileInfoName}>
                                                            {(document.document_size_mb).toFixed(3)} MB
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className={styles.fileOptionsWrapper}>
                                                    <div
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setCurrentID({id: document.id, path: document.document_path});
                                                        }}
                                                        className={`${styles.fileOptionsContainer} ${styles.trashBackgroundColor}`}>
                                                        <TrashIcon/>
                                                    </div>
                                                    <div
                                                        onClick={() => downloadFile(document.document_path, document.document_name)}
                                                        className={`${styles.fileOptionsContainer} ${styles.downloadBackgroundColor}`}>
                                                        <DownloadIcon/>
                                                    </div>
                                                    <DeleteModal isOpen={open} handleShowModal={() => setOpen(false)}
                                                                 handleSubmit={() => onDeleteDoc(currentId.id, currentId.path)}/>
                                                </div>
                                            </div>

                                            {index !== (transformedData[year][month][day].length - 1) &&
                                                <div className={styles.line}/>}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                    ))}
                </div>

            ))}
        </div>
    );
};

export default EmployeeDocument;

