import React, { memo } from 'react';
import clsx from 'clsx';

import { SwitchProps } from './Switch.model';

import classes from './Switch.module.css';

const Switch = ({size = 'medium', className, checked, disabled, onChange, ...props}: SwitchProps) => {
    const classNames = clsx(
        classes.root,
        className,
        {
            [classes[size]]: size,
            [classes.disabled]: disabled,
        },
    );

    const handleStopPropagation = (e: any) => {
        e.stopPropagation();
    };

    return (
        <label className={classNames} onClick={handleStopPropagation}>
            <input
                {...props}
                disabled={disabled}
                type="checkbox"
                className={classes.input}
                checked={checked}
                {...(onChange && {onChange})}
            />
            <span className={classes.track}/>
            <span className={classes.thumb}/>
        </label>
    );
};

export default memo(Switch);
