import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { pickBy } from 'lodash';
import routes from '../../../../../constants/routes';
import { authApi } from '../../../../../services/authApi';
import { RestorePasswordData } from '../../../../../types/auth';
import { EMAIL_VALIDATION_PATTERN } from '../../../../../utils/common';
import Button from '../../../../ui/Button';
import Divider from '../../../../ui/Divider';
import FormControl from '../../../../ui/FormControl';
import Input from '../../../../ui/Input';
import InputHelpText from '../../../../ui/InputHelpText';
import Typography from '../../../../ui/Typography';
import errorAirBalloonImage from '../../../../../assets/image/error-air-balloon.svg';
import styles from './RestorePasswordForm.module.css';
import useRegex from '../../../../../hooks/useReqex';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopRight} from '../../../../ui/Snackbar/constants';

const RestorePasswordForm = () => {
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = useForm<RestorePasswordData>();
    const memoizedUseRegex = useMemo(() => useRegex, []);

    const onSubmit = async (data: RestorePasswordData) => {
        try {
            const email_corporate = await memoizedUseRegex(data.email_corporate || '', EMAIL_VALIDATION_PATTERN);
            const cleanedData = pickBy({ ...data, email_corporate });
            await authApi.forgotPasswordRecovery(cleanedData);
            enqueueSnackbar('Password recovery link has been sent, please check your email inbox.', {
                anchorOrigin: snackbarPosTopRight,
                variant: 'success',
            });
            navigate(routes.signIn);
        } catch (error: any) {
            const errorMessage = error.response?.data?.detail || 'Please fill in your valid email address.';
            setError('email_corporate', {
                type: 'manual',
                message: errorMessage,
            });
        }
    };


    return (
        <>
            <Typography component="h4" align="center" variant="subHeading1">
                Restore Password
            </Typography>
            <Typography align="center" className={styles.description}>
                You will receive a secure link to reset your password.
            </Typography>
            <div className={styles.imageWrap}>
                <img src={errorAirBalloonImage} alt="" width={250} height={250} />
            </div>
            <Divider className={styles.divider} />
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                    <Controller
                        control={control}
                        name="email_corporate"
                        render={({ field }) => (
                            <>
                                <Input
                                    type="email"
                                    label="Work Email"
                                    placeholder="yourname@movadex.com"
                                    {...field}
                                />
                                {errors.email_corporate && (
                                    <InputHelpText hasError>
                                        {errors.email_corporate.message}
                                    </InputHelpText>
                                )}
                            </>
                        )}
                    />
                </FormControl>
                <Button fullWidth color="black" type="submit" disabled={isSubmitting}>
                    Reset Password
                </Button>
            </form>
        </>
    );
};

export default RestorePasswordForm;
