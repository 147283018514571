import { forwardRef, useState } from 'react';

import Input from '../../ui/Input';
import Button from '../../ui/Button';

import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eye-off.svg';

import styles from './PasswordInput.module.css';
import { InputProps } from '../../ui/Input/Input.model';

const PasswordInput = forwardRef<HTMLInputElement, InputProps>((
  { ...props },
  ref
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Input
      ref={ref}
      label="Password"
      type={showPassword ? 'text' : 'password'}
      EndIcon={
        <Button className={styles.icon} component="span" onClick={handleTogglePasswordVisibility}>
          {showPassword ? <EyeIcon width={24} /> : <EyeOffIcon width={24} />}
        </Button>
      }
      placeholder="****************"
      {...props}
    />
  );
});

export default PasswordInput;