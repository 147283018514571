import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: ApiBaseQuery({ baseUrl: '/user' }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({ url: '/login', method: 'post', data }),
    }),
    logout: builder.mutation({
      query: () => ({ url: '/logout', method: 'post', data: {}, params: {} }),
    })
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
