import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: ApiBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({ url: '/user/profile', method: 'get' }),
    }),
    updateProfile: builder.mutation({
      query: (data) => ({ url: '/user/profile', method: 'put', data }),
    }),
    createProfile: builder.mutation({
      query: (data) => ({ url: '/user/profile', method: 'post', data }),
    }),
    updateProfileAvatar: builder.mutation({
      query: (data) => ({ url: '/user/profile/avatar', method: 'post', data }),
    }),
    deleteProfileAvatar: builder.mutation({
      query: (data) => ({ url: '/user/profile/avatar', method: 'delete', data }),
    }),
    getPositions: builder.query({
      query: () => ({ url: '/positions', method: 'get' }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useCreateProfileMutation,
  useUpdateProfileAvatarMutation,
  useDeleteProfileAvatarMutation,
  useGetPositionsQuery
} = profileApi;
