import { createSlice } from '@reduxjs/toolkit';

import { humanResourcesApi } from './humanResourcesApi';

import {
  createFilter,
  deleteFilter,
  editFilter,
  editMemberProfile,
  getArchiveList,
  getFilters,
  getList,
  getMemberDaysOffCounts,
  getMemberProfile,
  totalByDepartments,
} from './thunks';
import {
  IHRListResponse,
  IHRTotalByDepartmentResponse,
  IMembersProfile,
} from '../../services/hrApi/types';

export interface IHRFilters {
  name?: string;
  created_at?: string;
  departments: string[];
  positions: string[];
  tech_stacks: string[];
  seniority_levels: string[];
  forms_of_coops: string[];
}
export type HumanResourcesState = {
  list: IHRListResponse;
  listIsLoading: boolean;
  listIsEmpty: boolean;
  archiveList: IHRListResponse;
  archiveListIsLoading: boolean;
  archiveListIsEmpty: boolean;
  totalAll: number;
  totalByDepartments: IHRTotalByDepartmentResponse[] | undefined;
  totalByDepartmentsArchive:
    | IHRTotalByDepartmentResponse[]
    | undefined;
  filters: IHRFilters[];
  filterCreateIsLoading: boolean;
  filterCreateError?: string;
  filterCreated: boolean;
  membersProfile?: IMembersProfile;
  membersProfileIsLoading: boolean;
  paidCounts: {
    total_days_off_count: number;
    used_days_off_count: number;
    total_sick_leaves_count: number;
    used_sick_leave_count: number;
  };
};

const initialState: HumanResourcesState = {
  list: {
    items: [],
    total: 0,
    pages: 0,
    size: 0,
    page: 1,
  },
  archiveList: {
    items: [],
    total: 0,
    pages: 0,
    size: 0,
    page: 1,
  },
  archiveListIsLoading: true,
  archiveListIsEmpty: false,
  listIsLoading: true,
  listIsEmpty: false,
  totalAll: 0,
  totalByDepartments: [],
  totalByDepartmentsArchive: [],
  filters: [],
  filterCreateIsLoading: false,
  filterCreateError: '',
  filterCreated: false,
  membersProfile: undefined,
  membersProfileIsLoading: true,
  paidCounts: {
    total_days_off_count: 0,
    used_days_off_count: 0,
    total_sick_leaves_count: 0,
    used_sick_leave_count: 0,
  },
};

const humanResourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteFilter.fulfilled, (state, action) => {
      state.filters = state.filters.filter(
        (e) => e.name !== action.payload
      );
    });
    builder.addCase(createFilter.fulfilled, (state) => {
      state.filterCreateError = '';
    });
    builder.addCase(createFilter.rejected, (state, action) => {
      state.filterCreateError = action.error.message;
    });
    builder.addCase(editFilter.fulfilled, (state, action) => {
      state.filterCreateError = '';
    });
    builder.addCase(editFilter.rejected, (state, action) => {
      state.filterCreateError = action.error.message;
    });
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
    });
    builder.addCase(getList.fulfilled, (state, action) => {
      state.list = action.payload;
      state.listIsLoading = false;
      state.listIsEmpty = false;
    });
    builder.addCase(getList.pending, (state, action) => {
      state.listIsLoading = true;
    });
    builder.addCase(getList.rejected, (state, action) => {
      state.listIsEmpty = true;
      state.listIsLoading = false;
    });
    builder.addCase(getArchiveList.fulfilled, (state, action) => {
      state.archiveList = action.payload;
      state.archiveListIsLoading = false;
      state.archiveListIsEmpty = false;
    });
    builder.addCase(getArchiveList.pending, (state, action) => {
      state.archiveListIsLoading = true;
    });
    builder.addCase(getArchiveList.rejected, (state, action) => {
      state.archiveListIsEmpty = true;
      state.archiveListIsLoading = false;
    });
    builder.addCase(totalByDepartments.fulfilled, (state, action) => {
      if (action.payload.isArchive) {
        state.totalByDepartmentsArchive = action.payload.data;
      } else {
        state.totalByDepartments = action.payload.data;
      }
    });
    builder.addCase(getMemberProfile.fulfilled, (state, action) => {
      state.membersProfile = action.payload;
      state.membersProfileIsLoading = false;
    });
    builder.addCase(editMemberProfile.fulfilled, (state, action) => {
      if (action.payload) state.membersProfile = action.payload;
    });
    builder.addCase(getMemberProfile.pending, (state, action) => {
      state.membersProfileIsLoading = true;
    });
    builder.addCase(getMemberProfile.rejected, (state, action) => {
      state.membersProfileIsLoading = false;
    });
    builder.addCase(
      getMemberDaysOffCounts.fulfilled,
      (state, action) => {
        if (action.payload) state.paidCounts = action.payload;
      }
    );
    builder.addMatcher(
      humanResourcesApi.endpoints.getTotalByDepartments
        .matchFulfilled,
      (state, { payload }) => {
        state.totalByDepartments = payload;
      }
    );
  },
});

export default humanResourcesSlice.reducer;
