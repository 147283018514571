import { ComponentPropsWithoutRef, forwardRef, useState, } from 'react';

import img from '../../../assets/icons/movadex-small.png';

type IImageFallback = {
  src: React.ImgHTMLAttributes<HTMLImageElement>['src'];
  fallbackSrc?: React.ImgHTMLAttributes<HTMLImageElement>['src'];
} & ComponentPropsWithoutRef<'img'>;

export const ImageFallback = forwardRef<
  HTMLImageElement,
  IImageFallback
>(({ src, fallbackSrc = img, ...props }, ref) => {
  const [error, setError] = useState(false);
  const Component = 'img';
  const handleError = () => {
    setError(true);
  };
  return (
    <Component
      ref={ref}
      src={error ? fallbackSrc : src ?? fallbackSrc}
      onError={handleError}
      {...props}
    />
  );
});
