import {FC, useState} from 'react';
import styles from './Tags.module.css';
import {CrossIcon} from '../../../../../../assets/icons/components';
import {ReactComponent as Search} from '../../../../../../assets/icons/search.svg';
import {SearchInput} from '../../../../../ui/SearchInput/SearchInput';

export const TagsComponent: FC<{
    onClick: (e: React.MouseEvent) => void;
    selectedItems?: { name: string }[];
    fullWidth?: boolean;
    bgColor?: string;
    color?: string;
}> = ({onClick, selectedItems, fullWidth, bgColor, color}) => {
    return (
        <div
            className={`${styles.container} ${
                fullWidth && styles.fullWidth
            } `}
            onClick={onClick}>
            {selectedItems?.map((item, index) => (
                <div key={index} className={styles.avatar}>
                    <div
                        className={styles.card}
                        style={
                            bgColor
                                ? {backgroundColor: bgColor, borderColor: bgColor}
                                : undefined
                        }>
                        <p
                            className={styles.cardText}
                            style={color ? {color} : undefined}>
                            {item.name}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export interface ITagsSelect {
    onClick?: () => void;
    menuTitle?: string;
    selectedItems?: { name: string }[];
    tag?: boolean;
    list?: { name: string }[];
    color?: string;
    bgColor?: string;
    onFetch?: (items: string[]) => void;
    clickListener?: (func?: () => void) => void;
    isOpen?: boolean;
    handleClickOutSide?: (event: any, callback?: () => void) => void;
    oneItem?: boolean;
}

export const TagsSelect: FC<ITagsSelect> = ({
                                                selectedItems = [],
                                                list,
                                                color,
                                                bgColor,
                                                onFetch,
                                                oneItem,
                                            }) => {
    const [selected, setSelected] = useState(selectedItems);

    const handleSelectItem = (item: { name: string }) => {
        if (selected.find((el) => el.name === item.name)) return;
        if (oneItem) return setSelected([item]);
        setSelected((prev) => [...prev, item]);
    };
    const handleRemoveItem = (item: { name: string }) => {
        setSelected((prev) => prev.filter((el) => el.name !== item.name));
    };

    const handleOnBlur = () => {
        if (
            onFetch &&
            JSON.stringify(selected) !== JSON.stringify(selectedItems)
        ) {
            onFetch(
                selected.map((item) => {
                    return item.name;
                })
            );
        }
    };

    const [searchValue, setSearchValue] = useState('');
    const filteredList = (list: { name: string }[], value: string) => {
        return list.filter((e) =>
            e.name.toLowerCase().includes(value.toLowerCase())
        );
    };

    const [isLeave, setIsLeave] = useState(false);

    return (
        <div
            tabIndex={Math.random()}
            onMouseLeave={() => setIsLeave(true)}
            onMouseEnter={() => setIsLeave(false)}
            onBlur={() => isLeave && handleOnBlur()}>
            <div className={styles.header}>
                <div className={styles.wrap}>
                    {selected?.map((item, index) => (
                        <div
                            onClick={() => handleRemoveItem(item)}
                            key={index}
                            className={styles.containerCard}>
                            <div
                                className={styles.card}
                                style={
                                    bgColor
                                        ? {
                                            backgroundColor: bgColor,
                                            borderColor: bgColor,
                                        }
                                        : undefined
                                }>
                                <p
                                    className={styles.cardText}
                                    style={color ? {color} : undefined}>
                                    {item.name}
                                </p>
                                <CrossIcon
                                    {...(color && {
                                        stroke: color,
                                    })}
                                />
                            </div>
                        </div>
                    ))}
                </div>
              <SearchInput
                  value={searchValue}
                  setValue={setSearchValue}
                  classNameInput={styles.search}
                  placeholder='Search'
              />
            </div>
            <div className={styles.main}>
                {list && filteredList(list, searchValue)?.length > 0 ? <>
                    {filteredList(list, searchValue)?.map((item, index) => (
                        <div
                            onClick={() => {
                                handleSelectItem(item);
                            }}
                            key={index}
                            className={styles.selectableCard}>
                            <div
                                className={styles.card}
                                style={
                                    bgColor
                                        ? {
                                            backgroundColor: bgColor,
                                            borderColor: bgColor,
                                        }
                                        : undefined
                                }>
                                <p
                                    className={styles.cardText}
                                    style={color ? {color} : undefined}>
                                    {item.name}
                                </p>
                            </div>
                        </div>
                    ))}
                </> : <span className={styles.noTags}><Search/> <p> No tags found based on your search</p></span>}
            </div>
        </div>
    );
};
