import clsx from 'clsx';

import styles from './ViewOptionDrawer.module.css';

import { useState } from 'react';
import { ViewOptionCard } from './ViewOptionCard/ViewOptionCard';

type itemsTypes = {
  id: number;
  text: string;
};
export const ViewOptionDrawer = () => {
  const [shownItems, setShownItems] = useState<itemsTypes[] | []>([
    { id: 1, text: 'Item 1' },
    { id: 2, text: 'Item 2' },
    { id: 3, text: 'Item 3' },
    { id: 4, text: 'Item 4' },
  ]);
  const [hiddenItems, setHiddenItems] = useState<itemsTypes[] | []>([
    { id: 10, text: 'Item 100' },
    { id: 11, text: 'Item 101' },
    { id: 12, text: 'Item 102' },
  ]);
  const [selectedItem, setSelectedItem] = useState<
    itemsTypes | undefined
  >(undefined);
  const [enteredItem, setEnteredItem] = useState<
    itemsTypes | undefined
  >(undefined);

  const handleEnterItem = (e: itemsTypes) => {
    setEnteredItem(JSON.parse(JSON.stringify(e)));
  };
  const handleLeaveItem = () => {
    setEnteredItem(undefined);
  };
  const handleDragStart = (
    e: React.DragEvent<HTMLLIElement>,
    item: itemsTypes,
    index: number
  ) => {
    e.dataTransfer.setData('text/plain', index.toString());
    setSelectedItem(item);

    e.currentTarget.classList.add(styles.dragging);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
    e.currentTarget.classList.remove(styles.dragging);
  };

  const handleDrop = (
    e: React.DragEvent<HTMLLIElement>,
    index: number,
    itemArray: itemsTypes[] | [],
    setItemArray: React.Dispatch<
      React.SetStateAction<[] | itemsTypes[]>
    >,
    setAnotherArray: React.Dispatch<
      React.SetStateAction<[] | itemsTypes[]>
    >
  ) => {
    const draggedIndex = e.dataTransfer.getData('text/plain');
    const targetIndex = index;

    if (!itemArray.find((el) => el.id == selectedItem?.id)?.id) {
      const itemsCopy = [...itemArray];
      selectedItem &&
        itemsCopy.splice(targetIndex + 1, 0, selectedItem);

      setAnotherArray((prev) =>
        prev.filter((_, index) => index !== Number(draggedIndex))
      );
      setItemArray(itemsCopy);
    } else {
      const itemsCopy = [...itemArray];
      const [draggedItem] = itemsCopy.splice(Number(draggedIndex), 1);
      itemsCopy.splice(targetIndex, 0, draggedItem);

      setItemArray(itemsCopy);
    }
    setSelectedItem(undefined);
    handleLeaveItem();
  };

  const handleMoveAllItems = (
    array: itemsTypes[],
    setItemArray: React.Dispatch<
      React.SetStateAction<[] | itemsTypes[]>
    >,
    setAnotherArray: React.Dispatch<
      React.SetStateAction<itemsTypes[]>
    >
  ) => {
    setItemArray([]);
    setAnotherArray((prev) => prev.concat(array));
  };

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.divide, styles.mB24)} />
      <ul>
        <div className={styles.header}>
          <p className={styles.title}>Shown</p>
          <p
            className={styles.link}
            onClick={() =>
              handleMoveAllItems(
                shownItems,
                setShownItems,
                setHiddenItems
              )
            }>
            Hide All
          </p>
        </div>
        <div>
          {shownItems.length ? (
            shownItems.map((item, index) => (
              <ViewOptionCard
                isShown={true}
                handleDrop={(e) =>
                  handleDrop(
                    e,
                    index,
                    shownItems,
                    setShownItems,
                    setHiddenItems
                  )
                }
                isDropped={item.id === enteredItem?.id}
                handleEnterItem={handleEnterItem}
                handleLeaveItem={handleLeaveItem}
                handleDragOver={(e) => handleDragOver(e)}
                key={item.id}
                item={item}
                handleDragStart={(e) =>
                  handleDragStart(e, item, index)
                }
              />
            ))
          ) : (
            <li
              className={styles.plug}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={(e) =>
                handleDrop(
                  e,
                  0,
                  shownItems,
                  setShownItems,
                  setHiddenItems
                )
              }>
              There are no items
            </li>
          )}
        </div>
      </ul>
      <div className={clsx(styles.divide, styles.mV24)} />
      <ul>
        <div className={styles.header}>
          <p className={styles.title}>Hidden</p>
          <p
            className={styles.link}
            onClick={() =>
              handleMoveAllItems(
                hiddenItems,
                setHiddenItems,
                setShownItems
              )
            }>
            Show All
          </p>
        </div>
        <div>
          {hiddenItems.length ? (
            hiddenItems.map((item, index) => (
              <ViewOptionCard
                handleDrop={(e) =>
                  handleDrop(
                    e,
                    index,
                    hiddenItems,
                    setHiddenItems,
                    setShownItems
                  )
                }
                isDropped={item.id === enteredItem?.id}
                handleEnterItem={handleEnterItem}
                handleLeaveItem={handleLeaveItem}
                handleDragOver={(e) => handleDragOver(e)}
                key={item.id}
                item={item}
                handleDragStart={(e) =>
                  handleDragStart(e, item, index)
                }
              />
            ))
          ) : (
            <li
              onDragOver={(e) => handleDragOver(e)}
              onDrop={(e) =>
                handleDrop(
                  e,
                  0,
                  hiddenItems,
                  setHiddenItems,
                  setShownItems
                )
              }>
              There are no items
            </li>
          )}
        </div>
      </ul>
    </div>
  );
};
