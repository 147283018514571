import { ProposalStatuses } from '../../../../services/proposalApi/types';

import styles from './ProposalStatus.module.css';

type ProposalStatusProps = {
  status: ProposalStatuses
}

export default function ProposalStatus({ status }: ProposalStatusProps) {
  return (
    <div className={`${styles.root} ${styles[status]}`}>
      {status}
    </div>
  );
}