import { ImageAndButtonProps } from './ImageAndButtonProps';
import Button from '../ui/Button';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import styles from './ImageAndButton.module.css';

const ImageAndButton = ({
  onClick,
  image,
  buttonText,
  disableBtn,
}: ImageAndButtonProps) => {
  return (
    <div>
      <div className={'center flexDirectionColumn'}>
        <div className={`mTop16 mBottom16 ${styles.imgWrapper}`}>
          {image}
        </div>
        {!disableBtn && (
          <Button
            color={'black'}
            startIcon={PlusIcon}
            onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImageAndButton;
