import { ProfileImgProps } from './ProfileImgProps'
import clsx from 'clsx'
import styles from './ProfileImg.module.css'


const ProfileImg = ({ firstName, lastName, image, className }: ProfileImgProps) => {
  return(
    <>
      {image ?
        <img src={image} className={styles.imgContainer} alt={'Profile Image'} />
        :
        <div className={clsx(styles.noImgContainer, className)}>
          <div className={styles.initialsWrapper}>
            <p className={styles.initialsText}>{firstName?.charAt(0)}</p>
            <p className={styles.initialsText}>{lastName?.charAt(0)}</p>
          </div>
        </div>
      }
    </>

  )
}

export default ProfileImg
