import { FC } from 'react';

import styles from './styles.module.css';
import { IMemberProject } from '../../../../../../services/projectsApi/types';
import { ImageFallback } from '../../../../../ui/ImageFallback/ImageFallback';

interface IListTeamMembers {
  onItemClick: (item: IMemberProject) => void;
  list: IMemberProject[];
}

export const ListTeamMembers: FC<IListTeamMembers> = ({
  onItemClick,
  list,
}) => {
  return (
    <div className={styles.gridList}>
      {list?.map((member) => (
        <div
          onClick={() => onItemClick(member)}
          key={member.id}
          className={styles.wrapperCard}>
          <ImageFallback
            className={styles.cardImg}
            src={member.user.avatar_url}
          />
          <p className={styles.cardTitle}>{member.user.full_name}</p>
        </div>
      ))}
    </div>
  );
};
