import React from 'react'

interface Props {
    stroke?: string
}

export const CalendarIcons: React.FC<Props> = (props) => {
    const { stroke } = props

    return (
        <svg width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 7.625V17.125M19 7.625V17.125M9.5 26.625H47.5M14.25 12.375H42.75C45.3734 12.375 47.5 14.5016 47.5 17.125V45.625C47.5 48.2484 45.3734 50.375 42.75 50.375H14.25C11.6266 50.375 9.5 48.2484 9.5 45.625V17.125C9.5 14.5016 11.6266 12.375 14.25 12.375ZM19 36.125H23.75V40.875H19V36.125Z" fill={'none'} stroke={stroke || '#A0AEC0'} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
