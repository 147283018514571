import { FC } from 'react';
import clsx from 'clsx';
import styles from './ProfileBreadCrumb.module.css';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron-right.svg';
import { useNavigate } from 'react-router-dom';
import { Emoji } from 'emoji-picker-react';
import { ImageFallback } from '../../../ui/ImageFallback/ImageFallback';

interface IProps {
  url?: string;
  title: string;
  subTitle?: string;
  isLastIndex?: boolean;
  clickable?: boolean;
  onClick?: () => void;
  isEmojiAvatar?: { emoji: string; color: string };
}
export const ProfileBreadCrumb: FC<IProps> = ({
  url,
  title,
  subTitle,
  isLastIndex,
  clickable,
  onClick,
  isEmojiAvatar,
}) => {
  const containerStyles = clsx(
    styles.container,
    clickable && styles.clickable
  );
  return (
    <div className={styles.wrapper}>
      <div
        onClick={() => clickable && onClick && onClick()}
        className={containerStyles}>
        {isEmojiAvatar ? (
          <div
            className={styles.icon}
            style={{ backgroundColor: isEmojiAvatar.color }}>
            <Emoji size={12} unified={isEmojiAvatar.emoji} />
          </div>
        ) : (
          <ImageFallback src={url} alt='' />
        )}
        <p>{title}</p>
        {subTitle && (
          <>
            <div className={styles.dot} />
            <p>{subTitle}</p>
          </>
        )}
      </div>
      {!isLastIndex && (
        <div className={styles.containerIcon}>
          <ChevronIcon />
        </div>
      )}
    </div>
  );
};
