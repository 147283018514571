import { FC } from 'react';

import Modal from '../../../ui/Modal';
import styles from './CreateCondition.module.css';
import { ConditionForm } from './Form/ConditionForm';
import { ICondition } from '../../../../services/policyApi/types';

interface CreateConditionModal {
  isOpen: boolean;
  onClose: () => void;
  data?: ICondition;
}
export const CreateConditionModal: FC<CreateConditionModal> = ({
  isOpen,
  onClose,
  data,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.profileModal}>
      <ConditionForm onClose={onClose} data={data} />
    </Modal>
  );
};
