// import {useState} from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import moment from 'moment';

import Button from '../ui/Button';
import FormikInput from '../ui/FormikInput';
import Typography from '../ui/Typography';
import DatePicker from '../ui/DatePicker/DatePicker';
import { CustomSelect } from '../ui/CustomSelect/CustomSelect';

import { getPositionsSelector } from '../../store/app/selectors';

import { seniorityLevels } from '../../common/values/values';
import {
  IHRListQuery,
  IMembersProfile,
  IRequestTeamMember,
} from '../../services/hrApi/types';
import { profileSchema } from './profileSchema';
import { Profile } from '../../types/profile';
import styles from './ProfileForm.module.css';

import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as Slack } from '../../assets/icons/slack.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { optionType } from '../ui/CustomSelect/useSelect';
import { ProfileImageEdit } from '../ui/ProfileImageEdit/ProfileImageEdit';

export type ProfileFormProps = {
  title: string;
  edit?: boolean;
  onSubmitMember: (
    data: IRequestTeamMember,
    onClose?: () => void
  ) => Promise<'error' | undefined>;
  onUploadAvatar?: (data: FormData) => void;
  user?: Profile;
  onClose?: () => void;
  callbackDataList?: IHRListQuery;
  initialData?: IMembersProfile;
};

const ProfileForm = ({
  title,
  edit,
  // user,
  onSubmitMember,
  // onUploadAvatar,
  onClose,
  // callbackDataList,
  initialData,
}: ProfileFormProps) => {
  const positions = useSelector(getPositionsSelector);
  interface IInitialValues {
    full_name: string;
    birthday?: Date | string;
    joined_at?: Date | string;
    departments: { name: string; emoji: string; color: string }[];
    positions: { name: string }[];
    seniority_level?: optionType;
    linked_in_nickname: string;
    telegram_nickname: string;
    slack_nickname: string;
    avatar?: File;
  }

  const initialValues: IInitialValues = {
    full_name: initialData?.full_name || '',
    birthday: initialData?.birthday || undefined,
    joined_at: initialData?.joined_at || undefined,
    departments: initialData?.departments || [],
    positions: initialData?.positions || [],
    seniority_level: initialData?.seniority_level
      ? { name: initialData?.seniority_level }
      : undefined,
    linked_in_nickname: initialData?.linked_in_nickname || '',
    telegram_nickname: initialData?.telegram_nickname || '',
    slack_nickname: initialData?.slack_nickname || '',
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        const object: any = { ...values };
        object.birthday = moment(object.birthday).format(
          'YYYY-MM-DD'
        );
        object.joined_at = moment(object.joined_at).format(
          'YYYY-MM-DD'
        );
        object.seniority_level = object.seniority_level?.name;
        object.departments = object.departments.map(
          (e: { name: string }) => e.name
        );
        object.positions = object.positions.map(
          (e: { name: string }) => e.name
        );
        const res = await onSubmitMember(object, onClose);

        if (res === 'error') formikHelpers.setSubmitting(false);
        //if res return error need give errors in inputs
      }}
      validationSchema={profileSchema}
      isInitialValid={edit}
      validateOnMount>
      {({
        values,
        setFieldValue,
        errors,
        submitForm,
        submitCount,
        isSubmitting,
        isValid,
      }) => (
        <Form>
          <div className={styles.header}>
            <p className={styles.title}>{title}</p>
            <div onClick={onClose} className={styles.btnIcon}>
              <PlusIcon />
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.imageContainer}>
              <ProfileImageEdit
                defaultImage={initialData?.avatar_url}
                selectedFile={values.avatar}
                setSelectedFile={(e) => setFieldValue('avatar', e)}
              />
            </div>
            <FormikInput
              required
              placeholder='Enter Full Name'
              label='Full Name'
              onChange={(e) =>
                setFieldValue('full_name', e.target.value)
              }
              value={values.full_name}
              error={errors.full_name}
              wasRequest={Boolean(submitCount)}
            />
            <DatePicker
              required
              dateProp={
                values.birthday
                  ? moment(values.birthday, 'YYYY-MM-DD').toDate()
                  : values.birthday
              }
              onChange={(e) => setFieldValue('birthday', e)}
              label='Birthday'
              isInput
              placeholder={'Choose Birthdate'}
              formatDate='DD MMMM YYYY'
              maxDate={new Date()}
              error={errors.birthday}
              wasRequest={Boolean(submitCount)}
            />
            <DatePicker
              required
              dateProp={
                values.joined_at
                  ? moment(values.joined_at, 'YYYY-MM-DD').toDate()
                  : values.joined_at
              }
              onChange={(e) => setFieldValue('joined_at', e)}
              label='Joined'
              isInput
              isWeekend={false}
              placeholder='Choose Join Date'
              formatDate='DD MMMM YYYY'
              maxDate={new Date()}
              error={errors.joined_at}
              wasRequest={Boolean(submitCount)}
            />
            <CustomSelect
              required
              label='Department'
              options={positions?.departments}
              multi
              multiAllTitle='All Departments'
              placeholder='Choose Department'
              detailedInput
              multiValue={values.departments || ''}
              defaultOptions={values.departments}
              onChangeMultiValue={(e) =>
                setFieldValue('departments', e)
              }
              notAutoHeight
              error={errors.departments as string}
              wasRequest={Boolean(submitCount)}
            />
            <CustomSelect
              required
              label='Positions'
              options={positions?.positions}
              placeholder='Choose Positions'
              multi
              multiAllTitle='All Positions'
              detailedInput
              multiValue={values.positions || ''}
              defaultOptions={values.positions}
              onChangeMultiValue={(e) =>
                setFieldValue('positions', e)
              }
              error={errors.positions as string}
              wasRequest={Boolean(submitCount)}
              notAutoHeight
            />
            <CustomSelect
              required
              label='Seniority'
              options={seniorityLevels}
              placeholder='Choose Seniority'
              value={values.seniority_level}
              detailedInput
              defaultOptions={
                values.seniority_level && [values.seniority_level]
              }
              onChangeValue={(e) =>
                setFieldValue('seniority_level', e)
              }
              error={errors.seniority_level as string}
              wasRequest={Boolean(submitCount)}
            />
            <FormikInput
              placeholder='Enter your LinkedIn'
              label={'Linkedin'}
              StartIcon={<Linkedin />}
              onChange={(e) =>
                setFieldValue('linked_in_nickname', e.target.value)
              }
              value={values.linked_in_nickname}
              error={errors.linked_in_nickname}
              wasRequest={Boolean(submitCount)}
            />
            <FormikInput
              placeholder='Enter your Telegram'
              label={'Telegram'}
              StartIcon={<Telegram />}
              onChange={(e) =>
                setFieldValue('telegram_nickname', e.target.value)
              }
              value={values.telegram_nickname}
              error={errors.telegram_nickname}
              wasRequest={Boolean(submitCount)}
            />
            <FormikInput
              placeholder='Enter your Slack'
              label={'Slack'}
              StartIcon={<Slack />}
              onChange={(e) =>
                setFieldValue('slack_nickname', e.target.value)
              }
              value={values.slack_nickname}
              error={errors.slack_nickname}
              wasRequest={Boolean(submitCount)}
            />
            <div style={{ marginBottom: '1.25rem' }} />
            <Button
              disabled={!isValid || isSubmitting}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                submitForm();
              }}
              color='black'
              fullWidth>
              <Typography variant='buttonText'>
                {edit
                  ? 'Save changes'
                  : ' Create new Member’s Profile'}
              </Typography>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
