import React, { FC } from 'react';
import styles from './Snackbar.module.css';

import { CrossIcon } from '../../../assets/icons/components';

import {
  SnackbarContent,
  CustomContentProps,
  closeSnackbar,
} from 'notistack';
import { iconsSnackbar } from './constants';

interface ICustomSnackbarContent {
  type: 'info' | 'error' | 'warning' | 'success';
  message: string;
  id: number | string;
}
const CustomSnackbarContent: FC<ICustomSnackbarContent> = ({
  type,
  message,
  id,
}) => {
  return (
    <div
      className={`${styles.snackbar} ${
        styles[`snackbar__type__${type}`]
      }`}>
      <div className={styles.snackbar__info}>
        <div className={styles.snackbar__info__icon}>
          {type && iconsSnackbar.find((e) => e.name === type)?.icon}
        </div>
        <div className={styles.snackbar__text}>{message}</div>
      </div>
      <div
        onClick={() => closeSnackbar(id)}
        className={styles.snackbar__close}>
        <CrossIcon />
      </div>
    </div>
  );
};

interface ReportCompleteProps extends CustomContentProps {
  type: 'info' | 'error' | 'warning' | 'success';
}

const CustomSnackBar = React.forwardRef<
  HTMLDivElement,
  ReportCompleteProps
>((props, ref) => {
  const { id, message, type, variant, ...other } = props;

  return (
    <SnackbarContent ref={ref} role='alert' {...other}>
      <CustomSnackbarContent
        type={variant as ICustomSnackbarContent['type']}
        message={message as string}
        id={id}
      />
    </SnackbarContent>
  );
});

export default CustomSnackBar;
