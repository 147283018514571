import { FC } from 'react';
import clsx from 'clsx';
import Table from '../../../../ui/Table';
import TableCell from '../../../../ui/TableCell';
import TableRow from '../../../../ui/TableRow';
import styles from './Table.module.css';
import { IMemberProject } from '../../../../../services/projectsApi/types';
import { ImageFallback } from '../../../../ui/ImageFallback/ImageFallback';
import { searchParamsToObject } from '../../../../../helpers/object/searchParams-to-object.helper';
interface ITableProjectMembers {
  data: IMemberProject[];
  setSearchParams: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >;
  showModal: () => void;
}
export const TableProjectMembers: FC<ITableProjectMembers> = ({
  data,
  setSearchParams,
  showModal,
}) => {
  const tableRowStyles = clsx(styles.rootTableRowTD);

  const rootCellStyles = clsx(styles.rootCell, styles.alignStart);
  return (
    <>
      <Table
        tableContainerClass={styles.tbody}
        className={styles.table}>
        <thead>
          <TableRow className={styles.rootTableRow}>
            <TableCell component='th'>Full Name</TableCell>
            <TableCell component='th'>Seniority</TableCell>
            <TableCell component='th'>Role</TableCell>
            <TableCell component='th'>Involvement</TableCell>
            <TableCell component='th'>Manager</TableCell>
          </TableRow>
        </thead>
        <tbody className={styles.tbody}>
          {data.map((member) => (
            <TableRow
              onClick={() => {
                setSearchParams((prev) => {
                  const params: any = searchParamsToObject(prev);
                  return { ...params, userId: member.user.id };
                });
                showModal();
              }}
              key={member.id}
              hover
              className={tableRowStyles}>
              <TableCell className={rootCellStyles}>
                <div className={styles.cardAvatar}>
                  <ImageFallback src={member.user.avatar_url} />
                  <p>{member.user.full_name}</p>
                </div>
              </TableCell>
              <TableCell className={styles.rootCell}>
                <p className={styles.subTitle}>
                  {member?.seniority_level}
                </p>
              </TableCell>
              <TableCell className={styles.rootCell}>
                <p className={styles.subTitle}>
                  {member?.position_name}
                </p>
              </TableCell>
              <TableCell className={styles.rootCell}>
                <p className={styles.subTitle}>
                  {member?.involvement_type}
                </p>
              </TableCell>
              <TableCell className={styles.rootCell}>
                <p className={styles.subTitle}>
                  {member.user.hr_manager?.full_name}
                </p>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};
