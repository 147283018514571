import React from 'react';
import styles from '../BenchPage.module.css';
import {ReactComponent as Vector} from '../../../assets/icons/vector.svg';
import {ReactComponent as Shape} from '../../../assets/icons/shape.svg';
import {ReactComponent as VectorDashed} from '../../../assets/icons/vectorDahsed.svg';
import ProgressBar from '@ramonak/react-progress-bar';
import moment from 'moment/moment';
import {IBenchStatus} from '../../../pages/Bench/types';

interface IData {
    data: IBenchStatus[];
}

export const BenchStatus: React.FC<IData> = ({data}) => {
    const active: IBenchStatus | undefined = data?.find(e => e.active)
    // First Bar
    const dateFrom: Date = new Date(data[0].date_from);
    const dateTo: Date = new Date(data[0].date_to);
    const differenceInMs: number = dateTo.getTime() - dateFrom.getTime();
    const differenceInDays: number = differenceInMs / (1000 * 60 * 60 * 24);
    // Second bar
    const dateFromTwo: Date = new Date(data[1].date_from);
    const dateToTwo: Date = new Date(data[1].date_to);
    const differenceInMsTwo: number = dateToTwo.getTime() - dateFromTwo.getTime();
    const differenceInDaysTwo: number = differenceInMsTwo / (1000 * 60 * 60 * 24);

    return (
        <>
            <div className={styles.rootStatus}>
                <div className={styles.benchStatus}>
                    <div>
                        <span className={styles.title}>Bench-50</span>
                        <div>
                            <span>Moved on: {moment(data[0].date_from).format('DD MMM YYYY')}</span>
                            <span>{Math.abs(data[0]?.days_from_start)} days ago</span>
                        </div>
                    </div>
                    <Vector/>
                    <div>
                        <span className={styles.title}>Bench-0</span>
                        <div>
                            <span className={(active?.overdue_days !== 0 && active?.type === 'Bench-50') ? styles.red : undefined}>Expected Transition: {moment(data[1].date_from).format('DD MMM YYYY')}</span>
                            {active?.type === 'Bench-50' ? <span>
                            In {Math.abs(data[1]?.days_from_start)} days
                            </span> :
                                <span>{active?.overdue_days !== 0 && active?.type === 'Bench-50' && 'Overdue by '} {Math.abs(data[1]?.days_from_start)} {active?.overdue_days !== 0 && active?.type === 'Bench-50' ? 'days' : 'days ago'}</span>
                            }
                        </div>
                    </div>
                    <VectorDashed/>
                    <div>
                        <span className={styles.title}>End of Bench</span>
                        <div>
                            <span className={(active?.overdue_days !== 0 && active?.type === 'Bench-0')? styles.red : undefined}>Expected End: {moment(data[1].date_to).format('DD MMM YYYY')}</span>
                            <span>{active?.overdue_days !== 0 && active?.type === 'Bench-0' ? 'Overdue by ' : 'In'} {Math.abs(data[2]?.days_from_start)} days</span>
                        </div>
                    </div>

                </div>
                <div className={styles.wrapper}>
                    <ProgressBar
                        completed={active?.type === 'Bench-50' ? data[0]?.days_from_start : 100}
                        maxCompleted={active?.type === 'Bench-50' ? differenceInDays : 100}
                        height={'12px'}
                        labelClassName={styles.label}
                        bgColor={'#0373FE'}
                        borderRadius={' 10px 0 0 10px '}
                        baseBgColor={'#F1F8FF'}
                        className={styles.progressBar}
                    />
                    <ProgressBar
                        completed={active?.type === 'Bench-0' ? data[1]?.days_from_start : 0}
                        maxCompleted={differenceInDaysTwo}
                        baseBgColor={'#FAE9EF'}
                        height={'12px'}
                        borderRadius={' 0 10px 10px 0'}
                        labelClassName={styles.label}
                        bgColor={'#D52F6D'}
                        completedClassName={styles.barCompleteds}
                        className={styles.progressBar} // You can define a class for common styles in your CSS module
                    />
                </div>
            </div>
            {active && active.overdue_days !==  0 &&
              <div className={styles.benchOverdue}>
                <Shape/>
                <span>End of bench overdue by {active?.overdue_days} days. Action needed.</span>
              </div>}
        </>

    );
};


