import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const HeartIcon: React.FC<Props> = (props) => {
  const { color, size } = props

  return (
    <svg width={size || '22'} height={size || '22'} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8747 12.4409L10.9997 19.2499L4.12471 12.4409C3.67124 11.9996 3.31405 11.4692 3.07563 10.8831C2.83721 10.297 2.72272 9.66792 2.73938 9.0354C2.75604 8.40288 2.90349 7.78067 3.17243 7.20794C3.44138 6.6352 3.826 6.12436 4.30207 5.70758C4.77814 5.29079 5.33535 4.97709 5.93862 4.78623C6.54188 4.59538 7.17813 4.5315 7.80729 4.59861C8.43646 4.66573 9.04491 4.86239 9.59434 5.17621C10.1438 5.49003 10.6223 5.91421 10.9997 6.42204C11.3788 5.9179 11.8578 5.49742 12.4069 5.18693C12.9559 4.87644 13.5632 4.68262 14.1906 4.6176C14.818 4.55259 15.452 4.61777 16.0531 4.80907C16.6541 5.00037 17.2092 5.31368 17.6836 5.72938C18.158 6.14508 18.5415 6.65422 18.8101 7.22495C19.0787 7.79567 19.2266 8.41569 19.2445 9.04619C19.2624 9.6767 19.15 10.3041 18.9143 10.8892C18.6786 11.4743 18.3247 12.0044 17.8747 12.4464"
        stroke={color || '#D52F6D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  )
}
