import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProfileInformation from '../../../../components/Projects/Profile/ProfileInformation/ProfileInformation';
import { ProfileAdditionalInfo } from '../../../../components/Projects/Profile/ProfileAdditionalInfo/ProfileAdditionalInfo';
import { TeamTable } from '../../../../components/Projects/Profile/TeamTable/TeamTable';
import Drawer from '../../../../components/ui/Drawer/Drawer';
import { Resources } from '../../../../components/Projects/Profile/Resources/Resources';
import { ProfileBreadCrumb } from '../../../../components/Projects/Profile/ProfileBreadCrumb/ProfileBreadCrumb';

import Breadcrumbs from '../../../../components/ui/Breadcrumbs/Breadcrumbs';
import Button from '../../../../components/ui/Button/Button';
import routes from '../../../../constants/routes';
import styles from './Profile.module.css';

// import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil.svg';
import { ReactComponent as FileIcon } from '../../../../assets/icons/file.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/icons/attach.svg';
import { UpdateProjectDataModal } from '../../../../components/Projects/Profile/ProfileInformation/UpdateProjectDataModal/UpdateProjectDataModal';
import { useAppDispatch } from '../../../../store';
import {
  editProfileProject,
  getProfileProject,
} from '../../../../store/project/thunks';
import { getProjectProfileSelector } from '../../../../store/project/selectors';
import { IEditProfileProjectRequest } from '../../../../services/projectsApi/types';

const ProjectProfile = () => {
  const { id } = useParams();

  const data = useSelector(getProjectProfileSelector);

  const dispatch = useAppDispatch();
  useEffect(() => {
    id && dispatch(getProfileProject(Number(id)));
  }, [id]);

  const [resourcesDrawer, setResourcesDrawer] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] =
    useState(false);

  const handleOpenResourcesDrawer = () => {
    setResourcesDrawer((prev) => !prev);
  };

  const handleOpenEditProfileModal = () => {
    setShowEditProfileModal((prev) => !prev);
  };

  const handleEditProjectModal = (
    data: IEditProfileProjectRequest
  ) => {
    dispatch(editProfileProject({ ...data, project_id: Number(id) }));
    handleOpenEditProfileModal();
  };

  return (
    <div className={styles.root}>
      {Number(id) === data?.id && data && (
        <>
          <div className={styles.header}>
            <Breadcrumbs
              itemComponent={
                <ProfileBreadCrumb
                  title={data.client_full_name}
                  url=''
                  isLastIndex={true}
                  subTitle={data.name}
                />
              }
              indexItemComponent={1}
              routeName={`projects$(${routes.projectsTable})/Profile$(${routes.projectsCreate})`}
            />
            <div className={styles.headerContainerButtons}>
              {/* <Button
                onClick={handleOpenResourcesDrawer}
                className={styles.resourcesBtn}
                variant='outlined'>
                <FileIcon />
                <p>Resources</p>
              </Button> */}
            </div>
          </div>
          <div className={styles.mainSection}>
            <ProfileInformation
              data={data}
              handleEdit={handleOpenEditProfileModal}
            />
            <ProfileAdditionalInfo />
          </div>
          <TeamTable />
          {/* <Drawer
            headerIcon={<AttachIcon />}
            anchor='right'
            open={resourcesDrawer}
            classNames={styles.rootDrawer}
            classNameIcon={styles.iconDrawer}
            onClose={handleOpenResourcesDrawer}
            title='Resources'>
            <Resources />
          </Drawer> */}
          <UpdateProjectDataModal
            profileInformationData={data}
            isOpen={showEditProfileModal}
            onEditProjectModal={handleEditProjectModal}
            onClose={handleOpenEditProfileModal}
          />
        </>
      )}
    </div>
  );
};

export default ProjectProfile;
