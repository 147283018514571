import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const DownloadIcon: React.FC<Props> = (props) => {
  const { color, size } = props

  return (
    <svg width={size || '22'} height={size || '22'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66666 15.5832V17.4165C3.66666 17.9027 3.85982 18.369 4.20363 18.7129C4.54745 19.0567 5.01377 19.2498 5.5 19.2498H16.5C16.9862 19.2498 17.4525 19.0567 17.7964 18.7129C18.1402 18.369 18.3333 17.9027 18.3333 17.4165V15.5832M6.41666 10.0832L11 14.6665M11 14.6665L15.5833 10.0832M11 14.6665V3.6665" stroke={color || '#0373FE'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </svg>
  )
}
