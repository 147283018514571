import React, {useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import styles from './Table.module.css';
import Table from '../../../components/ui/Table';
import TableRow from '../../../components/ui/TableRow';
import TableCell from '../../../components/ui/TableCell';
import {IListRequest} from '../Request.types';
import Drawer from '../../../components/ui/Drawer/Drawer';
import {ReactComponent as OneToOne} from '../../../assets/icons/OneToOneIcon.svg';
import {ReactComponent as SunIcon} from '../../../assets/icons/sunnyIcon.svg'
import {ReactComponent as HeartIcon} from '../../../assets/icons/heart.svg';
import ConfirmRequest from '../ConfirmReqest/ConfirmRequest';
import {useSelector} from 'react-redux';
import {getCanEditSelector} from '../../../store/profile/selectors';

interface RequestTableProps {
    data: IListRequest[];
    setUpdate?: any
}

interface Request {
    data?: IListRequest
    popup?: boolean
}


const initData: Request = {
    data: {},
    popup: false
}

const RequestTable: React.FC<RequestTableProps> = ({data,setUpdate}) => {
    const [currentRequest, setCurrentRequest] = useState(initData)
    const canEdit = useSelector(getCanEditSelector);
    const tableRowStyles = clsx(styles.rootTableRowTD);
    const rootCellStyles = clsx(styles.rootCell, styles.alignStart);
    const rootCellCardsStyles = clsx(styles.rootCard);

    const getBackgroundColor = (requestType: any) => {
        switch (requestType) {
            case 'Sick Leave':
            case 'Declined':
                return styles.sickLeave;
            case 'One-to-one':
            case 'Pending':
                return styles.oneToOne;
            case 'Day Off':
                return styles.dayOff;
            case 'Approved':
                return styles.approved;
        }
    };
    const handleRequest = (data: IListRequest) => {
        setCurrentRequest({
            data: data,
            popup: true
        })

    }
    return (
        <Table tableContainerClass={styles.tbody} className={styles.table}>
            <thead>
            <TableRow className={styles.thead}>
                <TableCell columnClass={styles.clear} component='th' sortBy='created_at'>Requested</TableCell>
                <TableCell component='th'>Requested by</TableCell>
                <TableCell component='th'>Type</TableCell>
                <TableCell component='th' sortBy='date_from'>Time</TableCell>
                <TableCell component='th'>Status</TableCell>
            </TableRow>
            </thead>
            <tbody className={styles.tbody}>
            {data?.map((el: IListRequest, index) => (
                <TableRow
                    key={index}
                    hover
                    onClick={() => canEdit && handleRequest(el)}
                    className={tableRowStyles}
                >
                    <TableCell className={rootCellStyles}>
                        <p>{moment(el.created_at).format('DD MMM YYYY')}</p>
                        <p className={styles.createTime}>{moment(el.created_at).format('HH:mm')}</p>
                    </TableCell>
                    <TableCell className={rootCellStyles}>
                        <p className={styles.projectName}>{el.user?.full_name}</p>
                    </TableCell>
                    <TableCell
                        className={clsx(rootCellStyles, rootCellCardsStyles, getBackgroundColor(el.request_type))}>
                        <p>{el.request_type}</p>
                    </TableCell>

                    <TableCell className={rootCellStyles}>
                        <p className={styles.projectName}>
                            {`${moment(el.date_from).format('DD MMMM YYYY')} ${el.date_from !== el.date_to ? '→ ' + moment(el.date_to).format('DD MMMM YYYY') : ''}`}
                            <p className={styles.createTime}>
                            {(el.request_type === 'One-to-one' && el.timeslot) ? moment(el.timeslot, 'HH:mm:ss').format('HH:mm') : null}
                            </p>

                        </p>
                    </TableCell>
                    <TableCell
                        className={clsx(rootCellStyles, rootCellCardsStyles, getBackgroundColor(el.request_status))}>
                        <p>{el.request_status}</p>
                    </TableCell>

                </TableRow>
            ))}
            </tbody>
            <Drawer
                title={
                    currentRequest.data?.request_type === 'Sick Leave'
                        ? 'Sick leave request'
                        : currentRequest.data?.request_type === 'Day Off'
                            ? 'Day off request'
                            : 'One-to-one request'
                }
                headerIcon={currentRequest.data?.request_type === 'Sick Leave' ?
                    <HeartIcon/> : currentRequest.data?.request_type === 'Day Off' ? <SunIcon/> : <OneToOne/>}
                anchor='right'
                open={!!currentRequest.popup}
                requestType
                onClose={() => setCurrentRequest(initData)}
            >
                <ConfirmRequest data={currentRequest?.data} setOpen={setCurrentRequest} setUpdate={setUpdate}/>
            </Drawer>
        </Table>
    );
};

export default RequestTable;
