import * as Yup from 'yup';

export const SharedResourcesSchema = Yup.object().shape({
  email: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
      })
    )
    .test(
      'haveItems',
      'Please select at least one recepient',
      (value) => {
        return value && Boolean(value[0]);
      }
    )
    .required('Required'),
});
