import { FC, useState } from 'react';

import styles from './FilterForm.module.css';

import FormControl from '../../../../ui/FormControl/FormControl';
import { QuickFilterWrapperModal } from '../../../../ui/QuickFilter/QuickFilterWrapperModal/QuickFilterWrapperModal';
import { CustomSelect } from '../../../../ui/CustomSelect/CustomSelect';
import {
  productIndustry,
  services,
  statuses,
} from '../../../../../common/values/values';
import { IProjectFilters } from '../../../../../services/projectsApi/types';
import { IGetUsersResponse } from '../../../../../services/commonApi/types';
import { useAppDispatch } from '../../../../../store';
import {
  createFilter,
  editFilter,
} from '../../../../../store/project/thunks';

interface IFilterForm {
  onCloseModal: () => void;
  data?: IProjectFilters;
  handleSetUpFilter?: (i: IProjectFilters) => void;
  additionalOptions?: {
    PM: IGetUsersResponse[];
    HR: IGetUsersResponse[];
    clientList?: { name: string }[];
  };
}

export const FilterForm: FC<IFilterForm> = ({
  onCloseModal,
  data,
  handleSetUpFilter,
  additionalOptions,
}) => {
  const [form, setForm] = useState<IProjectFilters>({
    project_statuses: [],
    project_services: data?.project_services ?? [],
    manager_ids: data?.manager_ids ?? [],
    client_names: data?.client_names ?? [],
    product_industries: data?.product_industries ?? [],
    member_ids: data?.manager_ids ?? [],
  });

  const isFilterValid = (filters: IProjectFilters) => {
    return Object.keys(filters).some((key) => {
      const value = filters[key as keyof IProjectFilters];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return false;
    });
  };

  const onClickResetBtn = () => {
    setForm((prev) => {
      return {
        ...prev,
        project_statuses: [],
        project_services: [],
        manager_ids: [],
        client_names: [],
        product_industries: [],
        member_ids: [],
      };
    });
  };

  const dispatch = useAppDispatch();

  const handleSaveFilter = async (
    text: string,
    func?: () => void
  ) => {
    const res = await dispatch(createFilter({ ...form, name: text }));
    if (res.type.includes('fulfilled')) {
      func && func();
    }
  };

  const handleEditFilter = async (
    text: string,
    func?: () => void
  ) => {
    const res = await dispatch(
      editFilter({
        ...form,
        new_name: form.name !== text ? text : undefined,
      })
    );
    if (res.type.includes('fulfilled')) {
      func && func();
    }
  };

  const useForm = <T extends keyof IProjectFilters>(name: T) => {
    const changeValue = (value: IProjectFilters[T]) => {
      setForm((prev) => {
        prev[name] = value;
        return { ...prev };
      });
    };
    return changeValue;
  };

  return (
    <QuickFilterWrapperModal
      showResetBtn={isFilterValid(form)}
      onCloseModal={onCloseModal}
      saveFilter={(e, func) =>
        data?.name
          ? handleEditFilter(e, func)
          : handleSaveFilter(e, func)
      }
      defaultFilterName={data?.name}
      onClickSetUpFilter={() =>
        handleSetUpFilter && handleSetUpFilter(form)
      }
      onClickResetBtn={onClickResetBtn}>
      <div>
        <div className={styles.row}>
          {/* <CustomSelect
            label={'Status'}
            options={statuses}
            detailedInput
            multi
            onChangeMultiValue={(e) =>
              useForm('project_statuses')(e.map((e) => e.name))
            }
            defaultOptions={form.project_statuses.map((e) => {
              return { name: e };
            })}
            multiAllTitle='All Statuses'
            placeholder='All Statuses'
          /> */}
          <CustomSelect
            label={'Clients'}
            placeholder='All Clients'
            classNameOptions={styles.containerOptions}
            multi
            options={additionalOptions?.clientList}
            multiAllTitle='All Clients'
            onChangeMultiValue={(e) =>
              useForm('client_names')(e.map((e) => e.name))
            }
            defaultOptions={form.client_names.map((e) => {
              return { name: e };
            })}
            detailedInput
          />
          <CustomSelect
            label={'Category'}
            options={productIndustry}
            multi
            placeholder='Categories'
            onChangeMultiValue={(e) =>
              useForm('product_industries')(e.map((e) => e.name))
            }
            defaultOptions={form.product_industries.map((e) => {
              return { name: e };
            })}
            multiAllTitle='All Categories'
            detailedInput
          />
        </div>
        <div className={styles.row}>
          <CustomSelect
            label={'Stage'}
            options={services}
            multi
            placeholder='Stages'
            onChangeMultiValue={(e) =>
              useForm('project_services')(e.map((e) => e.name))
            }
            defaultOptions={form.project_services.map((e) => {
              return { name: e };
            })}
            multiAllTitle='All Stages'
            detailedInput
          />
          <CustomSelect
            label={'Project Manager'}
            placeholder='All Project Managers'
            classNameOptions={styles.containerOptions}
            menuAbove
            detailed={{
              title: 'Select one or more project managers',
              multi: true,
              options: additionalOptions?.PM,
              selected: additionalOptions?.PM?.filter((e) =>
                form.manager_ids.includes(e.id)
              ),
              search: false,
              onChangeValue: (e) =>
                useForm('manager_ids')(e.map((e) => e.id)),
            }}
            detailedInput
          />
        </div>
        <div className={styles.row}>
          <CustomSelect
            label={'Team Member'}
            placeholder='All Team Members'
            classNameOptions={styles.containerOptions}
            menuAbove
            detailed={{
              title: 'Select one or more team members',
              multi: true,
              options: additionalOptions?.HR,
              selected: additionalOptions?.HR?.filter((e) =>
                form.member_ids.includes(e.id)
              ),
              search: true,
              onChangeValue: (e) =>
                useForm('member_ids')(e.map((e) => e.id)),
            }}
            detailedInput
          />
        </div>
      </div>
    </QuickFilterWrapperModal>
  );
};
