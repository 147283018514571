import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import styles from './TimeTracking.module.css';
import Typography from '../../components/ui/Typography';
import {TableHeader} from '../../components/TimeTracking/TableHeader/TableHeader';
import {WorkSummaryTable} from '../../components/TimeTracking/Tables/WorkSummaryTable/WorkSummaryTable';
import {TimeSheetTable} from '../../components/TimeTracking/Tables/TimeSheetTable/TimeSheetTable';
import Default from '../../components/Default';
import {useTimeTracking} from './useTimeTracking';
import {Submission} from '../../components/TimeTracking/Tables/Subsmission/Submission';
import Button from '../../components/ui/Button';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as Clock} from '../../assets/icons/clockIcon.svg';
import Drawer from '../../components/ui/Drawer/Drawer';
import SubmitHoursPopup from '../../components/TimeTracking/SubmitHoursPopup/SubmitHoursPopup';


interface IDataHours {
    open: boolean,
    data: any
}

const dataSubmit = {
    open: false,
    data: {}
}

const TimeTracking = () => {
    const [dataHours, setDataHours] = useState<IDataHours>(dataSubmit)
    const [searchParams, setSearchParams] = useSearchParams();
    const {globalLoading, listUsers, listProjects, params} = useTimeTracking(searchParams);

    // useEffect(() => {
    //     if (params.toggle === 'Submissions') {
    //         const searchParams = new URLSearchParams(window.location.search);
    //         searchParams.set('sort_by_type', 'desc');
    //         searchParams.set('sort_by', 'created_at');
    //         window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    //     }
    // }, [params.toggle]);

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <Typography variant='h5' weight='bold'>
                    Time Tracking
                </Typography>
                <Button color='black' startIcon={PlusIcon} onClick={() => setDataHours({open: true, data: {}})}>
                    Submit Hours
                </Button>
            </div>
            {globalLoading ? (
                <div className={styles.containerPlug}>
                    <Default/>
                </div>) : (
                <>
                    <TableHeader
                        listUsers={listUsers}
                        listProjects={listProjects}
                        params={params}
                        setSearchParams={setSearchParams as any}
                    />
                    {(!params.toggle || params.toggle === 'Work Summary') && <WorkSummaryTable filters={params}/>}
                    {(params.toggle === 'Timesheet') &&
                      <TimeSheetTable filters={params} setFilters={setSearchParams as any}/>}
                    {(params.toggle === 'Submissions') &&
                      <Submission filters={params} setFilters={setSearchParams as any}/>}
                </>
            )}

            <Drawer
                title={'Submit Hours'}
                headerIcon={<Clock/>}
                anchor='right'
                open={dataHours.open}
                onClose={() => setDataHours(dataSubmit)}
            >
              <SubmitHoursPopup members={listUsers}  onClose={() => setDataHours(dataSubmit)}/>
            </Drawer>
        </div>
    );
};

export default TimeTracking;
