import { useEffect, useState } from 'react';
import { Plug } from './Plug/Plug';
import { TeamMembersModal } from './TeamMembersModal/TeamMembersModal';
import styles from './TeamTable.module.css';
import { Header } from './Table/Header/Header';
import { TableProjectMembers } from './Table/Table';
import { useGetQueryParams } from '../../../../utils/common';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectProfileSelector } from '../../../../store/project/selectors';
import {
  IMemberProject,
  IMemberProjectTotalByDepartments,
} from '../../../../services/projectsApi/types';
import { projectsApi } from '../../../../services/projectsApi/projectsApi';
import { searchParamsToObject } from '../../../../helpers/object/searchParams-to-object.helper';
export const TeamTable = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleOnModal = () => {
    setShowModal(true);
  };

  const params: { department?: string; userId?: boolean } =
    useGetQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const data = useSelector(getProjectProfileSelector);

  const [list, setList] = useState<IMemberProject[]>([]);
  const [total, setTotal] = useState<
    IMemberProjectTotalByDepartments[]
  >([]);
  const getList = async () => {
    const res = await projectsApi.getProjectMember({
      project_id: Number(data?.id),
      ...params,
    });
    const total =
      await projectsApi.getProjectMemberTotalByDepartments({
        project_id: Number(data?.id),
      });
    setTotal(total);
    setList(res);
  };

  useEffect(() => {
    if (!showModal) {
      setSearchParams((prev) => {
        prev.delete('userId');
        const params = searchParamsToObject(prev);
        return { ...params };
      });
      getList();
    }
  }, [JSON.stringify(params.department), showModal]);

  return (
    <div className={styles.wrapper}>
      {list.length ? (
        <>
          <Header
            params={params}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            total={total}
            onShowModal={handleOnModal}
          />
          <TableProjectMembers
            setSearchParams={setSearchParams}
            data={list}
            showModal={handleOnModal}
          />
        </>
      ) : (
        <Plug onClick={handleOnModal} />
      )}
      <TeamMembersModal
        isOpen={showModal}
        onClose={handleCloseModal}
      />
    </div>
  );
};
