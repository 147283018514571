import queryString from 'query-string';

import API from '../http';
import { IProposalQueryParams } from './types';

export const proposalApi = {
  getProposal: (id: number) => API.get('/proposal', {
    params: {
      proposal_id: id
    }
  }),
  list: (params: IProposalQueryParams) => API.get(`/proposals?${queryString.stringify(params)}`),
  listCounts: (params: IProposalQueryParams) => API.get(`/proposals/count-by-status?${queryString.stringify(params)}`),
};