import { FC } from 'react';
import clsx from 'clsx';
import EmojiPickerComponent, {
  Emoji,
  EmojiClickData,
  EmojiStyle,
} from 'emoji-picker-react';

import { useSelect } from '../Profile/ProfileInformation/useSelect/useSelect';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';

import styles from './PaletteAvatar.module.css';

interface IPaletteAvatar {
  selectedColor?: string;
  setSelectedColor: (e: string) => void;
  setSelectedEmoji: (e: string) => void;
  selectedEmoji: string;
}

interface IEmojiSelect {
  onEmojiClick: (emoji: EmojiClickData, event: MouseEvent) => void;
}

const EmojiSelect: FC<IEmojiSelect> = ({ onEmojiClick }) => {
  return (
    <EmojiPickerComponent
      onEmojiClick={onEmojiClick}
      autoFocusSearch={false}
      searchDisabled
      skinTonesDisabled
      emojiStyle={EmojiStyle.NATIVE}
    />
  );
};

export const PaletteAvatar: FC<IPaletteAvatar> = ({
  selectedColor,
  setSelectedColor,
  setSelectedEmoji,
  selectedEmoji,
}) => {
  const palette = [
    '#F2F2F5',
    '#F7CADA',
    '#FAE3C9',
    '#FBF5E9',
    '#E6F7F6',
    '#72BB9A',
    '#4D9CFE',
    '#D1C1F5',
  ];

  const onEmojiClick = (emojiData: EmojiClickData) => {
    setSelectedEmoji(emojiData.unified);
  };
  const emojiSelect = useSelect(
    EmojiSelect as FC,
    { onEmojiClick },
    styles.cardInfoEmoji
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.relative}>
        <div
          onClick={emojiSelect.handleShowOptions}
          style={{ backgroundColor: selectedColor }}
          className={styles.icon}>
          {selectedEmoji ? (
            <Emoji
              unified={selectedEmoji}
              emojiStyle={EmojiStyle.APPLE}
              size={45}
            />
          ) : null}
        </div>
        {emojiSelect.component}
      </div>
      <p className={styles.title}>Choose Icon&Color</p>
      <div className={styles.containerPalette}>
        {palette.map((el: string, index, array) => (
          <div
            onClick={() => setSelectedColor(el)}
            key={index}
            className={clsx(
              styles.paletteItem,
              index === array.length - 1 && styles.marginNone
            )}
            style={{ backgroundColor: el }}>
            {selectedColor === el && <CheckIcon />}
          </div>
        ))}
      </div>
    </div>
  );
};
