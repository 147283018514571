import Button from '../../../components/ui/Button';
import Typography from '../../../components/ui/Typography';
import styles from './Conditions.module.css';
import { ReactComponent as Plus } from '../../../assets/icons/plusWhite.svg';
import { CustomSelect } from '../../../components/ui/CustomSelect/CustomSelect';
import { Card } from '../../../components/Policy/Conditions/Card/Card';
import { CreateConditionModal } from '../../../components/Policy/Conditions/Modal/CreateCondition';
import { DeleteModal } from '../../../components/HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';
import { useConditions } from './useConditions';
import NoDayOff from '../../../components/Policy/NoDayOff';
import Default from '../../../components/Default';
import {
  periodOfCooperationSelect,
  typeOfCooperationSelect,
} from '../../../common/values/values';

const Conditions = () => {
  const {
    handleCreateCondition,
    options,
    handleChangeValue,
    handleResetFilter,
    handleEditCondition,
    list,
    handleOpenDeleteCondition,
    filterList,
    data,
    isOpen,
    isOpenDelete,
    handleCloseModal,
    handleCloseDeleteModal,
    handleDeleteCondition,
    loading,
  } = useConditions();

  if (loading) return <Default />;
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Typography variant='h5' weight='bold'>
          Leave Conditions
        </Typography>
        {!loading && list.length ? (
          <Button
            onClick={handleCreateCondition}
            startIcon={Plus}
            color='black'>
            Add Condition
          </Button>
        ) : undefined}
      </div>
      {list.length ? (
        <div className={styles.containerFilter}>
          <CustomSelect
            rootClassName={styles.customSelect}
            defaultOptions={options.period}
            onChangeValue={(e) => handleChangeValue('period', [e])}
            options={typeOfCooperationSelect}
            detailedInput
            placeholder='Period of cooperation'
            placeholderStyles={styles.placeHolder}
          />
          <CustomSelect
            rootClassName={styles.customSelect}
            defaultOptions={options.type}
            onChangeMultiValue={(e) => handleChangeValue('type', e)}
            multi
            options={periodOfCooperationSelect}
            detailedInput
            placeholder='Type of cooperation'
            multiAllTitle='All Types'
            placeholderStyles={styles.placeHolder}
          />
          {options.period.length || options.type.length ? (
            <Button
              onClick={handleResetFilter}
              className={styles.rootResetBtn}>
              Reset
            </Button>
          ) : null}
        </div>
      ) : undefined}
      <div className={styles.containerItems}>
        {list.length ? (
          filterList(list).length ? (
            filterList(list).map((e, index) => (
              <Card
                handleEditCondition={handleEditCondition}
                handleDeleteCondition={handleOpenDeleteCondition}
                data={e}
                key={index}
              />
            ))
          ) : (
            <div className={styles.center}>
              <NoDayOff
                title='No conditions found'
                subTitle='Add a new condition or double-check filter parameters'
                btnTitle='Add Leave conditions'
                openModal={handleCreateCondition}
              />
            </div>
          )
        ) : (
          <div className={styles.center}>
            <NoDayOff openModal={handleCreateCondition} />
          </div>
        )}
      </div>
      <CreateConditionModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        data={data}
      />
      <DeleteModal
        isOpen={isOpenDelete}
        handleShowModal={handleCloseDeleteModal}
        handleSubmit={handleDeleteCondition}
        title='Do you want to delete this condition?'
        subTitle={
          <span className={styles.deleteSubTitle}>
            Are you sure you want to delete{' '}
            <span className={styles.deleteSubTitleBold}>
              Condition #{data?.id}
            </span>
            {`? This action is irreversible, and you won't be able to
            recover the condition after deleting it.`}
          </span>
        }
      />
    </div>
  );
};

export default Conditions;
