import React from 'react';
import {IBenchList} from '../../services/benchApi/types';
import {IModalInfo} from '../../pages/Bench/BenchMember';


export interface IFilterData {
    team: boolean;
    onlyBench: boolean;
    teamManager: string | any;
}

export interface IManager {
    options: { name: string; id: number; }[];
    selected?: { name: string; id: number; }[];
}

export interface IData {
    items: IBenchList[] | null;
    total: number;
    page: number;
    size: number;
    pages: number;
}


export interface IBenchModal {
    open: IModalInfo | null;
    setOpen: React.Dispatch<React.SetStateAction<IModalInfo | any>>;
    userData: any | null;
    fetchData?: () => void;
    fetchHistoryData?: () => void;
    activeStatus?: any;
}

export interface IModalData {
    comment: string;
    date_to: Date | any;
}

export const ActionStatus = [
    {
        name: 'Moved',
        value: 'moved',
        key: 1
    },
    {
        name: 'Prolonged',
        value: 'prolonged',
        key: 2
    },
    {
        name: 'Removed',
        value: 'removed',
        key: 3
    }
]

