import styles from './AdaptiveScreen.module.css'
import {ReactComponent as Balloon} from '../../assets/icons/ballon.svg';

const AdaptiveScreen = () => {

    return (
        <div className={styles.screen}>
            <Balloon/>
            <span>Oops! The admin panel is not available <br/> at this resolution</span>
            <p>Please resize your browser to be at least 960px wide</p>

        </div>
    );
};

export default AdaptiveScreen;
