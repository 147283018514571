import {FC, useState} from 'react';
import {useSelector} from 'react-redux';

import styles from './FilterModal.module.css';
import FormControl from '../../../../../ui/FormControl/FormControl';
import {QuickFilterWrapperModal} from '../../../../../ui/QuickFilter/QuickFilterWrapperModal/QuickFilterWrapperModal';
import {IHRFilters} from '../../../../../../services/hrApi/types';

import {getPositionsSelector} from '../../../../../../store/app/selectors';
import {CustomSelect} from '../../../../../ui/CustomSelect/CustomSelect';
import {formsOfCoops, seniorityLevels,} from '../../../../../../common/values/values';
import {useAppDispatch} from '../../../../../../store';
import {createFilter, editFilter,} from '../../../../../../store/humanResources/thunks';

interface IFilterForm {
  onCloseModal: () => void;
  data?: IHRFilters;
  handleSetUpFilter?: (i: IHRFilters) => void;
}

export const ModalFilterForm: FC<IFilterForm> = ({
  onCloseModal,
  data,
  handleSetUpFilter,
}) => {
  const positions = useSelector(getPositionsSelector);

  const [form, setForm] = useState<IHRFilters>({
    name: data ? data.name : '',
    departments: data ? data.departments : [],
    forms_of_coops: data ? data.forms_of_coops : [],
    positions: data ? data.positions : [],
    seniority_levels: data ? data.seniority_levels : [],
    tech_stacks: data ? data.tech_stacks : [],
  });

  const isHRFilterValid = (filters: IHRFilters) => {
    return Object.keys(filters).some((key) => {
      const value = filters[key as keyof IHRFilters];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return false;
    });
  };

  const useForm = <T extends keyof IHRFilters>(name: T) => {
    return (value: IHRFilters[T]) => {
      setForm((prev) => {
        prev[name] = value;
        return {...prev};
      });
    };
  };

  const handleResetButton = (
    setForm: React.Dispatch<React.SetStateAction<IHRFilters>>
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        departments: [],
        forms_of_coops: [],
        positions: [],
        seniority_levels: [],
        tech_stacks: [],
      };
    });
  };

  const dispatch = useAppDispatch();

  const handleSaveFilter = async (
    text: string,
    func?: () => void
  ) => {
    const res = await dispatch(createFilter({ ...form, name: text }));
    if (res.type.includes('fulfilled')) {
      func && func();
    }
  };

  const handleEditFilter = async (
    text: string,
    func?: () => void
  ) => {
    const res = await dispatch(
      editFilter({
        ...form,
        new_name: form.name !== text ? text : undefined,
      })
    );
    if (res.type.includes('fulfilled')) {
      func && func();
    }
  };

  return (
    <QuickFilterWrapperModal
      showResetBtn={isHRFilterValid(form)}
      onCloseModal={onCloseModal}
      saveFilter={(e, func) =>
        data?.name
          ? handleEditFilter(e, func)
          : handleSaveFilter(e, func)
      }
      defaultFilterName={data?.name}
      onClickSetUpFilter={() =>
        handleSetUpFilter && handleSetUpFilter(form)
      }
      onClickResetBtn={() => {
        handleResetButton(setForm);
      }}>
      <div className={styles.row}>
        <FormControl
          className={`${styles.fixedSizeInput} ${styles.m}`}>
          <CustomSelect
            label={'Department'}
            options={positions?.departments}
            multi
            multiValue={
              form.departments.map((e) => {
                return { name: e };
              }) || ''
            }
            placeholder='Departments'
            onChangeMultiValue={(e) =>
              useForm('departments')(e.map((e) => e.name))
            }
            multiAllTitle='All Departments'
            defaultOptions={form.departments.map((e) => {
              return { name: e };
            })}
            detailedInput
          />
        </FormControl>
        <FormControl className={styles.fixedSizeInput}>
          <CustomSelect
            label={'Seniority'}
            options={seniorityLevels}
            multi
            multiValue={
              form.seniority_levels.map((e) => {
                return { name: e };
              }) || ''
            }
            placeholder='Seniorities'
            onChangeMultiValue={(e) =>
              useForm('seniority_levels')(e.map((e) => e.name))
            }
            multiAllTitle='All Seniorities'
            defaultOptions={form.seniority_levels.map((e) => {
              return { name: e };
            })}
            detailedInput
          />
        </FormControl>
      </div>
      <div className={styles.row}>
        <FormControl
          className={`${styles.fixedSizeInput} ${styles.m}`}>
          <CustomSelect
            label={'Role'}
            options={positions?.positions}
            multi
            multiValue={
              form.positions.map((e) => {
                return { name: e };
              }) || ''
            }
            placeholder='Role'
            onChangeMultiValue={(e) =>
              useForm('positions')(e.map((e) => e.name))
            }
            multiAllTitle='All Roles'
            defaultOptions={form.positions.map((e) => {
              return { name: e };
            })}
            detailedInput
          />
        </FormControl>
        <FormControl className={styles.fixedSizeInput}>
          <CustomSelect
            label={'Form of cooperation'}
            options={formsOfCoops}
            multi
            multiValue={
              form.forms_of_coops.map((e) => {
                return { name: e };
              }) || ''
            }
            placeholder='Form of cooperation'
            onChangeMultiValue={(e) =>
              useForm('forms_of_coops')(e.map((e) => e.name))
            }
            multiAllTitle='All forms of cooperation'
            defaultOptions={form.forms_of_coops.map((e) => {
              return { name: e };
            })}
            detailedInput
          />
        </FormControl>
      </div>
      <div className={styles.row}>
        <FormControl
          className={`${styles.fixedSizeInput} ${styles.m}`}>
          <CustomSelect
            label={'Tech Stack'}
            options={positions?.tech_stacks}
            multi
            multiValue={
              form.tech_stacks.map((e) => {
                return { name: e };
              }) || ''
            }
            placeholder='Tech Stack'
            onChangeMultiValue={(e) =>
              useForm('tech_stacks')(e.map((e) => e.name))
            }
            multiAllTitle='All Tech Stacks'
            defaultOptions={form.tech_stacks.map((e) => {
              return { name: e };
            })}
            detailedInput
          />
        </FormControl>
        <FormControl className={styles.fixedSizeInput}></FormControl>
      </div>
      <div className={styles.row} />
    </QuickFilterWrapperModal>
  );
};
