import { useEffect, useState } from 'react';
import { optionType } from './useSelect';

interface IUseMultiSelect {
  showOptions: boolean;
  multi?: boolean;
  defaultOptions?: optionType[];
  onChangeMultiValue?: (e: optionType[]) => void;
  options?: optionType[];
  defaultAllSelected?: boolean;
}

export const useMulitSelect = ({
  showOptions,
  multi,
  defaultOptions,
  onChangeMultiValue,
  options,
  defaultAllSelected,
}: IUseMultiSelect) => {
  const [mapOptions, setMapOptions] = useState(
    new Map(
      defaultAllSelected && !defaultOptions?.length
        ? options?.map((el) => [el.name, el.name])
        : defaultOptions?.map((el) => [el.name, el.name])
    )
  );
  const [render, setRender] = useState(0);

  useEffect(() => {
    setMapOptions(
      new Map(
        defaultAllSelected && !defaultOptions?.length
          ? options?.map((el) => [el.name, el.name])
          : defaultOptions?.map((el) => [el.name, el.name])
      )
    );
  }, [defaultOptions]);
  useEffect(() => {
    if (render && !showOptions && multi) {
      const array: optionType[] = Array.from(
        mapOptions,
        ([value]) => {
          return { name: value };
        }
      );
      if (defaultAllSelected) {
        if (array.length !== options?.length && array.length !== 0) {
          onChangeMultiValue && onChangeMultiValue(array);
        }
        if (array.length === options?.length || array.length === 0) {
          onChangeMultiValue && onChangeMultiValue([]);
          options &&
            setMapOptions(
              new Map(options.map((el) => [el.name, el.name]))
            );
        }
      } else {
        onChangeMultiValue && onChangeMultiValue(array);
      }
    }
  }, [showOptions]);

  const handleChangeSelectItem = (
    el: optionType,
    isDelete?: boolean
  ) => {
    if (defaultAllSelected && mapOptions.size === options?.length) {
      mapOptions.clear();
      mapOptions.set(el.name, el.name);
      setRender((prev) => prev + 1);
      return;
    }

    if (!mapOptions.has(el.name)) {
      mapOptions.set(el.name, el.name);
    } else {
      mapOptions.delete(el.name);
    }
    if (isDelete)
      return (
        onChangeMultiValue &&
        onChangeMultiValue(
          Array.from(mapOptions, ([value]) => {
            return { name: value };
          })
        )
      );
    setRender((prev) => prev + 1);
  };
  const handleChangeAllSelectItem = () => {
    if (mapOptions.size === options?.length) {
      mapOptions.clear();
    } else {
      options?.forEach((el) => mapOptions.set(el.name, el.name));
    }
    setRender((prev) => prev + 1);
  };
  return {
    mapOptions,
    handleChangeSelectItem,
    handleChangeAllSelectItem,
  };
};
