import clsx from 'clsx';
import { ComponentProps, ElementType, ReactNode } from 'react';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/chevron-down.svg';
import styles from './AsideItem.module.css';

export type AsideItemProps<T extends ElementType> = {
  component?: T;
  className?: string;
  children?: ReactNode;
  label?: ReactNode;
  icon?: ReactNode;
  isActive?: boolean;
  isMenuOpen?: boolean;
  withDrop?: boolean;
  open?: boolean;
};

const AsideItem = <T extends ElementType>({
  component,
  className,
  children,
  label,
  isMenuOpen,
  icon,
  withDrop,
  open,
  isActive,
  ...props
}: AsideItemProps<T> &
  Omit<ComponentProps<T>, keyof AsideItemProps<T>>) => {
  const Component = component || 'div';

  const classNames = clsx(
    styles.root,
    { [styles.active]: isActive },
    className
  );


  return (
    <Component className={classNames} {...props}>
      {children ? (
        children
      ) : (
        <div className={styles.inner}>
          {icon && <div className={styles.iconWrap}>{icon}</div>}
          {label}
          {isMenuOpen && withDrop && (
            <div className={styles.containerIcon}>
              <ChevronDownIcon
                className={open ? styles.iconActive : styles.icon}
              />
            </div>
          )}
        </div>
      )}
    </Component>
  );
};

export default AsideItem;
