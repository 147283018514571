import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { getTokenSelector } from '../store/auth/selectors';

import Layout from './Layout';

const PrivateOutlet = () => {
  const token = useSelector(getTokenSelector);

  return token ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default PrivateOutlet;

