import React, { useState } from 'react';

import { TooltipProps } from './Tooltip.model';

import classes from './Tooltip.module.css';

const Tooltip = (props: TooltipProps) => {
    let timeout: NodeJS.Timeout | undefined;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div
            className={classes.tooltipWrapper}
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {props.children}
            {active && (
                <div className={`${classes.tooltipTip} ${classes[props?.direction || 'top']}`}>
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
