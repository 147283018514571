import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import Button from '../../Button/Button';
import styles from './DetailedSelect.module.css';

import { ReactComponent as Plus } from '../../../../assets/icons/plusWhite.svg';
import { CrossIcon } from '../../../../assets/icons/components';
import { IDetailedSelect, detailedOptionType } from './types';
import { ImageFallback } from '../../ImageFallback/ImageFallback';

export const DetailedSelect: FC<IDetailedSelect> = ({
  detailed,
  showOptions,
  handleShowOptions,
  setSearchValue,
  searchValue,
}) => {
  const cardStyles = clsx(styles.avatar, styles.bgGray);

  const [mapOptions] = useState(
    new Map(detailed.selected?.map((el) => [el.id, el]))
  );
  const [, setRender] = useState(0);

  useEffect(() => {
    if (!showOptions) {
      const array = Array.from(mapOptions, (el) => ({
        id: el[0],
        object: el[1],
      }));
      detailed.onChangeValue && detailed.onChangeValue(array);
      setSearchValue('');
    }
  }, [showOptions]);

  const handleChangeSelectItem = (
    el: detailedOptionType,
    mouse?: React.MouseEvent
  ) => {
    if (detailed.multi) {
      if (!mapOptions.has(el.id)) {
        mapOptions.set(el.id, el);
      } else {
        mapOptions.delete(el.id);
      }
    } else {
      mapOptions.clear();
      mapOptions.set(el.id, el || '');
    }
    if (detailed.fastClick && mouse) return handleShowOptions(mouse);
    setRender((prev) => prev + 1);
  };

  const selected = Array.from(mapOptions, (el) => ({
    id: el[0],
    object: el[1],
  }));

  const filtered = (
    value: string,
    array?: IDetailedSelect['detailed']['options']
  ) => {
    return array?.filter((e) =>
      e.full_name.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <>
      {(detailed.multi || detailed.search) && (
        <div className={styles.header}>
          {detailed.multi && !!selected.length && (
            <div className={styles.wrap}>
              {selected.map((manager, index) => (
                <div
                  onClick={() =>
                    handleChangeSelectItem(manager.object)
                  }
                  key={index}
                  className={styles.containerCard}>
                  <div className={styles.avatar}>
                    <ImageFallback
                      className={styles.avatarImg}
                      src={manager.object.avatar_url}
                      alt=''
                    />
                    <p>{manager.object.full_name}</p>
                  </div>
                  <CrossIcon />
                </div>
              ))}
            </div>
          )}
          {/* {detailed.search && (
            <input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className={styles.search}
              placeholder='Search'
              type='text'
            />
          )} */}
        </div>
      )}
      <div className={styles.main}>
        <p className={styles.title}>{detailed.title}</p>
        {filtered(searchValue, detailed.options)?.length ? (
          filtered(searchValue, detailed.options)?.map(
            (manager, index) => (
              <div
                onClick={(e) => {
                  handleChangeSelectItem(manager, e);
                }}
                key={index}
                className={clsx(
                  styles.selectableCard,
                  selected.find((i) => i.id === manager.id) &&
                    styles.focused
                )}>
                <p className={cardStyles}>
                  <ImageFallback
                    className={styles.avatarImg}
                    src={manager.avatar_url}
                    alt=''
                  />
                  <p>{manager.full_name}</p>
                </p>
              </div>
            )
          )
        ) : (
          <div className={styles.plug}>Not Found</div>
        )}
        {detailed.btn && (
          <>
            <div className={styles.divide} />
            <div className={styles.containerButton}>
              <Button
                className={styles.btn}
                fullWidth
                startIcon={Plus}
                variant='outlined'>
                {detailed.btnTitle}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
