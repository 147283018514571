import React from 'react';
import styles from './Card.module.css';
import Button from '../../ui/Button';
import {ReactComponent as SunIcon} from '../../../assets/icons/sunnyIcon.svg'
import {ReactComponent as SunIconHalf} from '../../../assets/icons/sunnyIcon-half.svg'
import {ReactComponent as Cat} from '../../../assets/icons/cat.svg';
import {ReactComponent as Birthday} from '../../../assets/icons/birthdayIcon.svg';
import {ReactComponent as Holidays} from '../../../assets/icons/holidaysWait.svg';
import {ReactComponent as Heart} from '../../../assets/icons/heart.svg';
import {ReactComponent as HeartHalf} from '../../../assets/icons/heart-half.svg';
import defaultAvatar from '../../../assets/icons/movadex.png';
import {CardProps, CardTableProps, ILeaveToday} from '../types';
import moment from 'moment';
import routes from '../../../constants/routes';
import clsx from 'clsx';
import {IBirthday} from '../../../services/hrApi/types';
import ProgressBar from '@ramonak/react-progress-bar';


export const renderCard = ({title, icon, valueSecond, iconSecond, value}: CardProps) => (
        <div className={styles.card}>
            <span className={styles.cardTitle}>{title}</span>
            <span className={styles.cardSubtitle}>{icon}<span>
        <span>{value}</span><p>total</p></span>
    </span><span className={styles.cardSubtitle}>{iconSecond}<span>
        <span>{valueSecond !== 0 && '+'}{valueSecond}</span><p>this month</p></span></span></div>
    )
;

export const renderCardTable = ({title, subtitle, navigate, possibility, list, type, onClick}: CardTableProps) => {
    const renderCard = (e: any, i: React.Key | null | undefined) => (
        <div className={styles.cardTableInner} key={i}>
            <span className={e.department_name === 'Other' ? styles.cardTableInnerNameLast : ''}
                  onClick={() => navigate(routes.humanResources + `/?page=1&department=${(e.department_name === 'All') ? '' : e.department_name}`)}>{e.department_name}</span>
            <span>{e.users_count}</span>
        </div>
    );
    return (
        <div className={styles.cardTable}>
            <div className={styles.card}>
      <span className={styles.cardTitle}>
        {title} <p>{subtitle}</p>
      </span>
                {type === 'department' ? (
                    <>
                        {list && Array.isArray(list) && list.length > 0 ? (
                            [
                                ...list
                                    .filter((e: any) => e.department_name !== 'Other')
                                    .sort((a: any, b: any) => b.users_count - a.users_count)
                                    .map((e: any, i: React.Key | null | undefined) => renderCard(e, i)),
                                ...list
                                    .filter((e: any) => e.department_name === 'Other')
                                    .map((e: any, i: React.Key | null | undefined) => renderCard(e, i)
                                    )
                            ]
                        ) : null}
                    </>
                ) : (
                    <>
                        {list && Array.isArray(list) && list.length > 0 ? (
                            list.map((e: any, i: React.Key | null | undefined) => (
                                <div className={styles.cardTableHolidayInner} key={i}>
                                    <span>{e.name}</span>
                                    <span>{moment(e.date_to).format('DD MMM YYYY')}</span>
                                </div>
                            ))
                        ) : (
                            <div className={styles.cardTableInnerWait}>
                                <Holidays/>
                                <p>No holidays have been added</p>
                            </div>
                        )}
                    </>
                )}

            </div>
            {possibility && <>
                {type === 'holiday' && (
                    <Button fullWidth color={'secondary'} className={styles.cardTableBtn} onClick={onClick}>
                        {list?.length > 0 ? 'View all' : 'Add Holidays'}
                    </Button>
                )}
            </>}
        </div>
    );
}


export const renderCardTableLeave = ({title, subtitle, possibility, list, navigate, type, onClick}: CardTableProps) => (
    <div className={styles.cardTableLeave}>
        <div className={styles.card}>
      <span className={styles.cardTitle}>
        {title}
          {type === 'requests' ? <p>{list.reduce((sum: any, e: any) => sum + e.requests_count, 0)}</p> :
              <p>{subtitle}</p>}
      </span>
            {type !== 'leave' ? <>{list && list.map((e: ILeaveToday, i: number) => (
                <div
                    className={clsx(styles.cardTableInner, type === 'requests' && styles.cardTableInnerRequests)}
                    key={i}
                    onClick={() => type === 'requests' ? navigate(routes.requests + `/?page=1&sort_by_type=desc&request_type=${e.request_type}`) : possibility && navigate(routes.employeeProfile + `/${e?.user.id}`)}>
                    {type !== 'requests' && <img src={e.user?.avatar_url || defaultAvatar} alt="avatar"/>}
                    {type === 'requests' ? (
                        <span>{e.request_type}</span>
                    ) : (
                        <span>
                                  <span>{e.user?.full_name}</span>
                                  <span>{e.user?.positions.map((position: {
                                      name: any
                                  }) => position.name).join(', ')}</span>
                                  </span>)}
                    {type === 'requests' ?
                        <span className={clsx(styles.counts,
                            e.request_type === 'Day Off' && styles.off,
                            e.request_type === 'Sick Leave' && styles.leave,
                            e.request_type !== 'Day Off' && e.request_type !== 'Sick Leave' && styles.oneOne
                        )}>
                                {e.requests_count}</span>
                        :
                        (e.day_off_type === 'Day Off' ? (e.half_day ? <SunIconHalf/> : <SunIcon/>) : (e.half_day ? <HeartHalf/> : <Heart/>))}
                </div>
            ))}
            </> : <div className={styles.availableLeave}>
                <div className={styles.availableLeaveOff}>
                            <span>
                                <p>{list?.total_days_off_count - list?.used_days_off_count} <b>days</b></p>
                                <p>{list?.total_days_off_count} <b>days</b></p>
                            </span>
                    <span>
                            <p>Remaining days off</p>
                            <p>Total</p>
                        </span>
                    <ProgressBar isLabelVisible={false}
                                 completed={list?.total_days_off_count - list?.used_days_off_count}
                                 maxCompleted={list?.total_days_off_count} customLabel={''}
                                 animateOnRender={true} className={styles.availableLeaveBar} borderRadius={'22px'}
                                 baseBgColor={'#FBF5E9'}
                                 bgColor={'#E0B457'} height={'12px'}/>
                </div>
                <div className={styles.availableLeaveSick}>
                            <span>
                           <p>{list?.total_sick_leaves_count - list?.used_sick_leave_count} <b>days</b></p>
                                <p>{list?.total_sick_leaves_count} <b>days</b></p>
                            </span>
                    <span>
                            <p>Remaining sick leave</p>
                            <p>Total</p>
                        </span>
                    <ProgressBar isLabelVisible={false}
                                 completed={list?.total_sick_leaves_count - list?.used_sick_leave_count}
                                 animateOnRender={true} maxCompleted={list?.total_sick_leaves_count}
                                 className={styles.availableLeaveBar} borderRadius={'22px'} bgColor={'#D52F6D'}
                                 baseBgColor={'#FAE9EF'}
                                 height={'12px'}/>
                </div>

            </div>}
            {(list?.length === 0 && type !== 'requests') &&
              <div className={styles.cardTableInnerWait}>
                <Cat/>
                <p>No one has requested a day off today</p>
              </div>}

        </div>
        {(possibility || type === 'requests') &&
          <Button fullWidth color="secondary" className={styles.cardTableBtn} onClick={onClick}>
            View all
          </Button>}
    </div>
);

export const renderCardTableBirthday = ({title, list, navigate, type}: CardTableProps) => {
    return (
        <div className={styles.cardTableLeave}>
            <div className={styles.card}>
      <span className={styles.cardTitle}>
        {title}
      </span>
                <>{list && list?.map((e: IBirthday, i: number) => (
                    <div
                        className={clsx(styles.cardTableInner, type === 'requests' && styles.cardTableInnerRequests)}
                        key={i}
                        onClick={() => navigate(routes.employeeProfile + `/${e?.id}`)}>
                        <img src={e?.avatar_url || defaultAvatar} alt="avatar"/>
                        <span>{e?.full_name}</span>
                        <div className={styles.birthdayInfo} key={i}>
                    <span className={e.birthday === 'Today' || e.birthday === 'Tomorrow' ? styles.birthdayDay : ''}>
                        {e.birthday === 'Today' || e.birthday === 'Tomorrow' ? <span>🎁</span> : ''}
                        {e.birthday}
                    </span>
                        </div>
                    </div>
                ))}
                </>
                {(list?.length === 0 && type !== 'requests') &&
                  <div className={styles.cardTableBirthdayWait}>
                    <Birthday/>
                    <p>No birthdays in the coming days</p>
                  </div>}

            </div>
        </div>
    );
}

