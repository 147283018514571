import styles from './CommentView.module.css'
import Typography from '../ui/Typography'
import { ReactComponent as CommentIcon } from '../../assets/icons/message-circle.svg'
import { CommentViewProps } from './CommentViewProps'


const CommentView = ({ comment }: CommentViewProps) => {
  return(
    <div className={`mTop16 ${styles.commentWrapper}`}>
      <div className={'row alignItemsCenter'}>
        <CommentIcon/>
        <Typography className={'mLeft4'} variant={'mediumLabel'} color={'gray'}>Comment</Typography>
      </div>
      <Typography className={'mTop4'} variant={'mediumLabel'} color={'darkGray'}>{comment}</Typography>
    </div>
  )
}

export default CommentView
