export const searchParamsToObject = (prev: URLSearchParams) => {
  const data = Array.from(new Set(prev));
  const result: any = {};
  data.forEach(([key, value]) => {
    if (!result[key]) {
      if (Array.isArray(result[key])) {
        result[key].push(value);
      } else {
        result[key] = value;
      }
    } else if (Array.isArray(result[key])) {
      result[key].push(value);
    } else {
      result[key] = [result[key], value];
    }
  });

  return result;
};
