import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const PaperclipIcon: React.FC<Props> = (props) => {
  const { color, size } = props

  return (
    <svg width={size || '20'} height={size || '20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.34802 10.6823L5.34806 10.6824L5.35056 10.6799L10.6545 5.37588C10.6546 5.37587 10.6546 5.37587 10.6546 5.37587C10.9007 5.1298 11.1929 4.93462 11.5144 4.80148C11.836 4.66833 12.1806 4.59982 12.5287 4.59986C12.8767 4.59991 13.2214 4.66851 13.5429 4.80174C13.8644 4.93497 14.1566 5.13022 14.4026 5.37636C14.6487 5.62249 14.8439 5.91468 14.977 6.23625C15.1102 6.55781 15.1787 6.90246 15.1786 7.2505C15.1786 7.59854 15.11 7.94316 14.9768 8.26469C14.8435 8.58622 14.6483 8.87837 14.4021 9.12444L14.4021 9.12445L8.03814 15.4884C7.82231 15.7043 7.52958 15.8255 7.22435 15.8255C6.91912 15.8255 6.62639 15.7043 6.41056 15.4884C6.19473 15.2726 6.07347 14.9799 6.07347 14.6747C6.07347 14.3694 6.19473 14.0767 6.41056 13.8609L12.0676 8.20387L12.0676 8.20391L12.07 8.20133C12.2202 8.0402 12.3019 7.82708 12.298 7.60687C12.2941 7.38666 12.2049 7.17656 12.0492 7.02082C11.8934 6.86509 11.6833 6.77588 11.4631 6.772C11.2429 6.76811 11.0298 6.84985 10.8687 7L10.8686 6.99995L10.8661 7.00245L5.20914 12.6594C4.67454 13.194 4.3742 13.9191 4.3742 14.6752C4.3742 15.4312 4.67454 16.1563 5.20914 16.6909C5.74374 17.2255 6.46881 17.5258 7.22485 17.5258C7.98088 17.5258 8.70596 17.2255 9.24056 16.6909L9.24057 16.6909L15.6036 10.3259L15.6044 10.325C16.4045 9.50609 16.8494 8.40472 16.8428 7.25983C16.8361 6.11493 16.3783 5.01882 15.5688 4.20924C14.7592 3.39966 13.6631 2.9419 12.5182 2.93523C11.3733 2.92856 10.2719 3.37353 9.45296 4.17363L9.45214 4.17445L4.14914 9.47745L4.14909 9.4774L4.14669 9.47998C3.99654 9.64112 3.9148 9.85423 3.91869 10.0744C3.92257 10.2947 4.01178 10.5048 4.16751 10.6605C4.32325 10.8162 4.53335 10.9054 4.75356 10.9093C4.97341 10.9132 5.18663 10.8327 5.34802 10.6823Z" fill={color || '#28293D'} stroke={color || '#28293D'} strokeWidth="0.2"/>
    </svg>
  )
}
