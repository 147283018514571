import { ReactComponent as CloseIcon } from '../../../assets/icons/close-modal.svg'
import { CloseModalButtonProps } from './CloseModalButtonProps'
import styles from './CloseModalButton.module.css'

const CloseModalButton = ({ onClick }: CloseModalButtonProps) => {
  return(
    <div className={styles.closeButtonWrapper} onClick={onClick}>
      <CloseIcon/>
    </div>
  )
}

export default CloseModalButton
