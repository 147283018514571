import React, { ReactNode } from 'react';

import styles from './AuthFormContainer.module.css';

export interface AuthFormContainerProps {
  children: ReactNode;
}

const AuthFormContainer = ({ children }: AuthFormContainerProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default AuthFormContainer;
