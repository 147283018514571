import { useModal } from '../../hooks/useModal';

import Button from '../ui/Button';

import { ReactComponent as Plus } from '../../assets/icons/plusWhite.svg';
import {
  IHRListParams,
  IRequestTeamMember,
} from '../../services/hrApi/types';
import { FC } from 'react';
import Modal from '../ui/Modal/Modal';
import ProfileForm from '../ProfileForm/ProfileForm';

import styles from './AddTeamMemberButton.module.css';
import { hrApi } from '../../services/hrApi/hrApi';
import { useAppDispatch } from '../../store';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../ui/Snackbar/constants';
import { jsonToFormData } from '../../helpers/formData/jsonToFormData.helper';
interface IAddTeamMemberButton {
  data?: IHRListParams;
}
const AddTeamMemberButton: FC<IAddTeamMemberButton> = ({ data }) => {
  const { isOpen, open, close } = useModal();
  const dispatch = useAppDispatch();
  const handleSubmit = async (
    newMember: IRequestTeamMember,
    onClose?: () => void
  ) => {
    try {
      const res = await hrApi.createMembersProfile(
        jsonToFormData(newMember)
      );
      enqueueSnackbar('New team member created', {
        anchorOrigin: snackbarPosTopRight,
        variant: 'success',
      });
      onClose && onClose();
    } catch (e: any) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
      console.log(e);
      return 'error';
    }
  };

  return (
    <>
      <Button color='black' startIcon={Plus} onClick={open}>
        Add Team Member
      </Button>
      <Modal
        className={styles.profileModal}
        isOpen={isOpen}
        onClose={close}>
        <ProfileForm
          callbackDataList={data}
          onClose={close}
          onSubmitMember={handleSubmit}
          title='Create Member’s Profile'
        />
      </Modal>
    </>
  );
};

export default AddTeamMemberButton;
