import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './Languages.module.css';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import { commonApi } from '../../../../../../services/commonApi/commonApi';
import { ILanguagesResponse } from '../../../../../../services/commonApi/types';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/check.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../../assets/icons/chevron-right.svg';
import { languagesLevels } from '../../../../../../common/values/values';
import { IMembersProfileInfoEditLanguage } from '../../../../../../services/hrApi/types';
import Default from '../../../../../Default';

export interface ILanguagesSelect {
  onClick?: () => void;
  onFetch?: (data: IMembersProfileInfoEditLanguage[]) => void;
  isOpen?: boolean;
  selectedItems?: IMembersProfileInfoEditLanguage[];
}
export interface ILanguagesComponent {
  onClick: (e: React.MouseEvent) => void;
  languages?: IMembersProfileInfoEditLanguage[];
}

export const LanguagesComponent: FC<ILanguagesComponent> = ({
                                                              onClick,
                                                              languages,
                                                            }) => {
  return (
      <div className={styles.container} onClick={onClick}>
        <p className={styles.text}>
          {languages
              ?.map((e) => `${e.language_name} (${e.language_level})`)
              .join(', ')}
        </p>
      </div>
  );
};
export const LanguagesSelect: FC<ILanguagesSelect> = ({
                                                        onFetch,
                                                        isOpen,
                                                        selectedItems = [],
                                                      }) => {
  const selectOpen = useDebounce(isOpen, 50);

  const [list, setList] = useState<ILanguagesResponse[]>();

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const getList = async () => {
    const response = await commonApi.languages({
      search: searchValue,
    });
    setList(response);
    setLoading(false);
  };
  useEffect(() => {
    selectOpen && getList();
  }, [debouncedSearchValue]);

  const [showSelect, setShowSelect] = useState<string | boolean>(
      false
  );

  const selectLevelStyles = clsx(
      styles.containerSelectLevel,
      showSelect && styles.showSelect
  );

  const [mapOptions, setMapOptions] = useState(
      new Map(
          selectedItems?.map((el) => [
            el.language_name,
            el.language_level,
          ])
      )
  );
  const handleAddItem = (el: IMembersProfileInfoEditLanguage) => {
    mapOptions.set(el.language_name, el.language_level);
    setShowSelect(false);
  };
  const handleRemoveItem = (name: string) => {
    mapOptions.delete(name);
  };

  const handleOnBlur = () => {
    const data = Array.from(mapOptions, ([key, value]) => {
      return { language_name: key, language_level: value };
    });
    if (
        onFetch &&
        JSON.stringify(data) !== JSON.stringify(selectedItems)
    ) {
      onFetch(data);
    }
  };

  const [isLeave, setIsLeave] = useState(false);
  const height =
      40.5 *
      ((list?.length && (list?.length > 5 ? 5 : list?.length)) || 1);
  return (
      <div
          tabIndex={Math.random()}
          onMouseLeave={() => setIsLeave(true)}
          onMouseEnter={() => setIsLeave(false)}
          onBlur={() => isLeave && handleOnBlur()}
          className={styles.containerSelect}>
        <div className={selectLevelStyles}>
          <p className={styles.levelLanguageText}>
            Select level of language
          </p>
          <div className={styles.containerList}>
            {languagesLevels.map((item, index) => (
                <div
                    onClick={() =>
                        handleAddItem({
                          language_level: item.name,
                          language_name: showSelect.toString(),
                        })
                    }
                    key={index}
                    className={styles.card}>
                  <p className={styles.cardText}>{item.name}</p>
                </div>
            ))}
          </div>
        </div>
        <input
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            className={styles.search}
            placeholder='Search'
            type='text'
        />
        <div style={{ height }} className={styles.main}>
          {!loading ? (
              list?.length ? (
                  list.map((item, index) => (
                      <div
                          onClick={() => {
                            setShowSelect((prev) => {
                              if (prev === item.name) return false;
                              return item.name;
                            });
                          }}
                          key={index}
                          className={clsx(
                              styles.card,
                              showSelect === item.name && styles.cardOn
                          )}>
                        <div className={styles.cardInfo}>
                          <div className={styles.mainInfo}>
                            <div
                                className={clsx(
                                    styles.containerCardIcon,
                                    mapOptions.has(item.name) &&
                                    styles.containerCardIconOn
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveItem(item.name);
                                }}>
                              <CheckIcon />
                            </div>
                            <p className={styles.cardText}>{item.name}</p>
                          </div>
                          {mapOptions.has(item.name) && (
                              <p className={styles.cardSubText}>
                                {mapOptions.get(item.name)}
                              </p>
                          )}
                        </div>
                        <div className={styles.containerChevron}>
                          <ChevronRightIcon />
                        </div>
                      </div>
                  ))
              ) : (
                  <div className={styles.plug}>Not Found</div>
              )
          ) : (
              <div className={styles.plug}>
                <Default />
              </div>
          )}
        </div>
      </div>
  );
};
