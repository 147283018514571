import Typography from '../ui/Typography';
import { DragAndDropProps } from './DragAndDropProps';

import { ReactComponent as CloudUpload } from '../../assets/image/cloud-upload.svg';
import { UploadFile } from '../../assets/icons/components';

import styles from '../ImageUploader/ImageUploader.module.css';

const DragAndDropComponent = (
  {
    handleClickUploadField,
    handleDrop,
    handleDragOver,
    compact
  }: DragAndDropProps) => {
  return (
    <div
      className={styles.uploader}
      onClick={handleClickUploadField}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!compact && <div><CloudUpload /></div>}
      <Typography component="div" variant="smallText">
        <div className={styles.uploaderTextWrapper}>
          <UploadFile />
          <p className={`${styles.uploaderText} ${styles.boldText}`}>Drag&Drop</p>
          <p className={styles.uploaderText}>files or</p>
          <p className={`${styles.uploaderText} ${styles.underlinedText}`}>Browse</p>
        </div>
      </Typography>
    </div>
  );
};

export default DragAndDropComponent;
