import clsx from 'clsx';
import { memo, ReactNode } from 'react';

import styles from './Badge.module.css';

export type BadgeProps = {
  color?: 'default' | 'primary' | 'error';
  rounded?: boolean;
  className?: string;
  children?: ReactNode;
}

const Badge = ({ color = 'primary', className, children, rounded }: BadgeProps) => {
  const classNames = clsx(
    styles.root,
    {
      [styles[color]]: color,
      [styles.rounded]: rounded,
    },
    className,
  );
  return (
    <span className={classNames}>
      {children}
    </span>
  );
};

export default memo(Badge);
