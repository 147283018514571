export const timezone = [
  {
    name: '(GMT -11)',
    value: -11,
  },
  {
    name: '(GMT -10)',
    value: -10,
  },
  {
    name: '(GMT -9)',
    value: -9,
  },
  {
    name: '(GMT -8)',
    value: -8,
  },
  {
    name: '(GMT -7)',
    value: -7,
  },
  {
    name: '(GMT -6)',
    value: -6,
  },
  {
    name: '(GMT -5)',
    value: -5,
  },
  {
    name: '(GMT -4:30)',
    value: -3.5,
  },
  {
    name: '(GMT -4)',
    value: -4,
  },
  {
    name: '(GMT -3:30)',
    value: -2.5,
  },
  {
    name: '(GMT -3)',
    value: -3,
  },
  {
    name: '(GMT -2)',
    value: -2,
  },
  {
    name: '(GMT -1)',
    value: -1,
  },
  {
    name: '(GMT +0)',
    value: 0,
  },
  {
    name: '(GMT +1)',
    value: 1,
  },
  {
    name: '(GMT +2)',
    value: 2,
  },
  {
    name: '(GMT +3)',
    value: 3,
  },
  {
    name: '(GMT +3:30)',
    value: 3.5,
  },
  {
    name: '(GMT +4)',
    value: 4,
  },
  {
    name: '(GMT +4:30)',
    value: 4.5,
  },
  {
    name: '(GMT +5)',
    value: 5,
  },
  {
    name: '(GMT +5:30)',
    value: 5.5,
  },
  {
    name: '(GMT +5:45)',
    value: 5.75,
  },
  {
    name: '(GMT +6)',
    value: 6,
  },
  {
    name: '(GMT +6:30)',
    value: 6.5,
  },
  {
    name: '(GMT +7)',
    value: 7,
  },
  {
    name: '(GMT +8)',
    value: 8,
  },
  {
    name: '(GMT +9)',
    value: 9,
  },
  {
    name: '(GMT +9:30)',
    value: 9.5,
  },
  {
    name: '(GMT +10)',
    value: 10,
  },
  {
    name: '(GMT +11)',
    value: 11,
  },
  {
    name: '(GMT +12)',
    value: 12,
  },
  {
    name: '(GMT +13)',
    value: 13,
  },
];
