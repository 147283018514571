import React, {useState} from 'react';
import styles from '../Leave.module.css';
import Timeline, {CustomMarker, DateHeader, SidebarHeader, TimelineHeaders} from 'react-calendar-timeline';
import {Keys} from './keys';
import {ICalendarList, ItemRenderProps, ModalInfo} from '../types';
import moment from 'moment/moment';
import {DeleteModal} from '../../../HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';


function CalendarTable(props: ICalendarList) {
    const [openModal, setOpenModal] = useState(ModalInfo)
    const {items, groups, startDate, endDate, handleDeleteLeave, handleDeleteHoliday, handleEditLeave} = props
    const startDateVisible = moment(startDate).valueOf();
    const endDateVisible = moment(endDate).valueOf();
    const today = moment(new Date()).valueOf();
    const ItemRenders = (props: ItemRenderProps) => {
        const {item, itemContext, getItemProps} = props
        const dataFrom = moment(item.start_time);
        const dataTo = moment(item.end_time);
        const dayDifference = dataTo.diff(dataFrom, 'days');
        const handleOpen = (user: any, item: any) => {
            setOpenModal((prev) => ({...prev, info: user, open: true, user: item}))
        }
        return (
            <div
                {...getItemProps({
                    style: {
                        zIndex: 'initial',
                        background: item.bgColor,
                        color: item.color || 'none',
                        border: 'none',
                        position: 'relative',
                        display: 'grid'
                    },
                    onMouseDown: () => {
                        handleOpen(item.user, item)
                    }
                })}>
                <div
                    className={styles.ripple}
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>
                    {(dayDifference > 3 || itemContext.width > 150) ? itemContext?.title : itemContext?.title.props.children[0]}
                </div>
            </div>
        )
    }
    return (
        <div className={styles.tableLeave}>
            <Timeline
                keys={Keys}
                groups={groups}
                items={items}
                lineHeight={40}
                itemRenderer={ItemRenders}
                visibleTimeStart={startDateVisible}
                visibleTimeEnd={endDateVisible}
                defaultTimeStart={startDate}
                defaultTimeEnd={endDate}
                itemHeightRatio={1}
                sidebarWidth={230}
                canMove={false}
                canResize={false}
            >
                <TimelineHeaders>
                    <CustomMarker date={today}/>
                    <SidebarHeader>
                        {({getRootProps}: any) => (
                            <div className={styles.tableTitle} {...getRootProps()}>
                                <div/>
                                <div>Member</div>
                            </div>
                        )}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader"/>
                    <DateHeader unit="day" labelFormat="DD"/>
                </TimelineHeaders>
            </Timeline>
            <DeleteModal isOpen={openModal.open}
                         handleShowModal={() => setOpenModal((prev) => ({...prev, open: false}))}
                         data={openModal.info}
                         userInfo={openModal.user}
                         leave
                         handleEditLeave={handleEditLeave}
                         handleDelete={handleDeleteLeave}
                         handleDeleteHoliday={handleDeleteHoliday}
            />
        </div>
    );
}

export default CalendarTable;
