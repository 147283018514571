import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { FC } from 'react';
import styles from './ProfileMainInfo.module.css';
import { IProfileProjectResponse } from '../../../../../services/projectsApi/types';
// import {ProfileUpdates} from '../ProfileUpdates/ProfileUpdates';
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg';
import { StatusComponent } from '../ProfileCards/Status/Status';
import { ProfileCardInfo } from '../ProfileCardInfo/ProfileCardInfo';
import { ImageFallback } from '../../../../ui/ImageFallback/ImageFallback';

interface IProfileMainInfo {
  profileInfo: IProfileProjectResponse;
  handleEdit: () => void;
  statusSelect?: any;
}

export const ProfileMainInfo: FC<IProfileMainInfo> = ({
  profileInfo,
  handleEdit,
  statusSelect,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div
          style={{
            backgroundColor: profileInfo?.color || '',
          }}
          className={styles.icon}>
          <Emoji
            unified={profileInfo?.emoji || ''}
            emojiStyle={EmojiStyle.APPLE}
            size={45}
          />
        </div>
        <div className={styles.additionalInfo}>
          <div className={styles.row}>
            <p className={styles.projectTitle}>
              {profileInfo?.name || ''}
            </p>
            <div className={styles.cardAvatar}>
              <ImageFallback src='' alt='' />
              <p>{profileInfo.client_full_name}</p>
            </div>
          </div>
          <p className={styles.black500}>
            {profileInfo.services?.join(', ')}
          </p>
          <div className={styles.status}>
            <ProfileCardInfo
              onClickPlug={statusSelect.handleShowOptions}
              plugTitle='Add Status'
              component={
                profileInfo.status && (
                  <StatusComponent
                    onClick={statusSelect.handleShowOptions}
                    status={profileInfo.status}
                  />
                )
              }>
              {statusSelect.component}
            </ProfileCardInfo>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <PencilIcon onClick={handleEdit} />
      </div>
    </div>
  );
};
