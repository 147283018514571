import { AbsenceDaysProps } from './AbsenceDaysProps';
import styles from './AbsenceDays.module.css';
import Button from '../ui/Button';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import AbsenceDaysTitle from '../AbsenceDaysTitle/AbsenceDaysTitle';
import Typography from '../ui/Typography/Typography';

const AbsenceDays = ({
  icon,
  title,
  usedDays = 0,
  daysLeft,
  mainColor,
  secondColor,
  onViewAllClick,
  onAddNewClick,
  canEdit,
}: AbsenceDaysProps) => {
  return (
    <div className={`padding16 ${styles.wrapper}`}>
      <AbsenceDaysTitle
        title={title}
        icon={icon}
        secondColor={secondColor}
        onClick={onViewAllClick}
      />
      <div className={'row alignItemsEnd'}>
        <Typography
          variant={'h3'}
          weight={'bold'}
          className={mainColor}>
          {usedDays}
        </Typography>
        <p className={`mTop24 ${styles.daysLeft}`}>of {daysLeft}</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          fullWidth
          onClick={onViewAllClick}
          className={styles.addNewText}>
          View All
        </Button>
        {canEdit ? (
          <Button
            fullWidth
            color={'black'}
            startIcon={PlusIcon}
            onClick={onAddNewClick}>
            Add
          </Button>
        ) : (
          <Button fullWidth color={'black'} onClick={onAddNewClick}>
            Request
          </Button>
        )}
      </div>
    </div>
  );
};

export default AbsenceDays;
