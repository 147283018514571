import Button from '../../../components/ui/Button/Button';
import Typography from '../../../components/ui/Typography/Typography';
import {TableHeader} from '../../../components/Projects/Table/TableHeader/TableHeader';
import Default from '../../../components/Default';
import styles from './Table.module.css';
import {ReactComponent as Plus} from '../../../assets/icons/plusWhite.svg';
import {useSearchParams} from 'react-router-dom';
import {useTable} from './useTable';
import {TableList} from '../../../components/Projects/Table/Table/Table';
import TablePagination from '../../../components/ui/TablePagination';
import Plug from '../../../components/sharedPages/HumanResourcesPage/components/Plug';

const ProjectsTable = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        handleNavigateToCreateProject,
        filter,
        setFilter,
        searchValue,
        setSearchValue,
        data,
        listIsEmpty,
        listIsLoading,
        total,
        params,
    } = useTable(searchParams, setSearchParams);
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <Typography variant='h5' weight='bold'>
                    Projects
                </Typography>
                <Button
                    onClick={handleNavigateToCreateProject}
                    startIcon={Plus}
                    color='black'>
                    Add Project
                </Button>
            </div>
            <TableHeader
                params={params}
                total={total}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                filter={filter}
                setFilter={setFilter}/>
            {listIsLoading ? <Default/> : <>
                {!listIsEmpty ? (
                    <>
                        <TableList data={data}/>
                        <TablePagination
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            currentStartPage={data?.page}
                            count={data?.total}
                            itemsPerPage={data?.size}
                        />
                    </>
                ) : (
                    <Plug
                        title='🧭 Nothing was found'
                        subTitle={''}
                        btn={<div/>}
                    />
                )}
            </>}

        </div>
    );
};

export default ProjectsTable;
