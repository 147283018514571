import API from '../http';
import {IRequestApi} from './types';
import queryString from 'query-string';


export const requestApi: IRequestApi = {
    getCountType: (data) => API.get(`/requests/count-by-type?${queryString.stringify(data)}`),
    getRequests: (data) => API.get(`/requests?${queryString.stringify(data)}`),
    postRequests: (data) => API.post('/requests/status', data),
    postRequest: (data) => API.post('/requests', data)

};
