import { createAsyncThunk } from '@reduxjs/toolkit';
import { hrApi } from '../../services/hrApi/hrApi';
import {
  IHRArchiveMember,
  IHRFilters,
  IHRListParams,
  IHRListQuery,
  IHRTotalByDepartment,
  IRequestMemberProfile,
  IRequestTeamMember,
} from '../../services/hrApi/types';
import { enqueueSnackbar } from 'notistack';

import { RootState } from '../index';
import { snackbarPosTopRight } from '../../components/ui/Snackbar/constants';

export const createFilter = createAsyncThunk(
  'createFilter',
  async (data: IHRFilters, thunkAPI) => {
    try {
      await hrApi.saveFilter(data);
      thunkAPI.dispatch(getFilters());
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);

export const totalByDepartments = createAsyncThunk(
  'totalByDepartments',
  async (data: IHRListQuery) => {
    try {
      const res = await hrApi.totalByDepartment(data);
      return { data: res, isArchive: data.is_archived };
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);

export const editFilter = createAsyncThunk(
  'editFilter',
  async (data: IHRFilters, thunkAPI) => {
    try {
      await hrApi.editFilter(data);
      thunkAPI.dispatch(getFilters());
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);
export const deleteFilter = createAsyncThunk(
  'deleteFilter',
  async (name: string) => {
    await hrApi.deleteFilter(name);
    return name;
  }
);

export const getFilters = createAsyncThunk('getFilters', async () => {
  const res = await hrApi.filters();
  return res;
});

export const getList = createAsyncThunk(
  'getList',
  async (data: IHRListQuery) => {
    const res = await hrApi.list(data);
    if (!res?.items?.length) throw 'no items';
    return res;
  }
);

export const getArchiveList = createAsyncThunk(
  'getArchiveList',
  async (data: IHRListQuery) => {
    const res = await hrApi.list(data);
    if (!res?.items?.length) throw 'no items';
    return res;
  }
);

export const archiveMember = createAsyncThunk(
  'getList',
  async (
    {
      data,
      getListData,
    }: { data: IHRArchiveMember; getListData?: IHRListQuery },
    thunkAPI
  ) => {
    try {
      await hrApi.archiveMember(data);
      if (data.is_archived && getListData) {
        thunkAPI.dispatch(getList(getListData));
      }
      if (!data.is_archived && getListData) {
        thunkAPI.dispatch(getArchiveList(getListData));
      }

      const userName = (thunkAPI.getState() as RootState)
        .humanResources.membersProfile?.full_name;
      enqueueSnackbar(
        userName
          ? userName + ' was archived'
          : 'Archived successfully',
        {
          anchorOrigin: snackbarPosTopRight,
          variant: 'success',
        }
      );
    } catch (e: any) {
      throw e.response.data.detail;
    }
  }
);

export const createTeamMember = createAsyncThunk(
  'getList',
  async (
    { data, listData }: { data: FormData; listData?: IHRListQuery },
    thunkAPI
  ) => {
    try {
      await hrApi.createMembersProfile(data);
      listData && thunkAPI.dispatch(getList(listData));
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);

export const getMemberProfile = createAsyncThunk(
  'getMemberProfile',
  async (data: IRequestMemberProfile, thunkAPI) => {
    try {
      const res = await hrApi.getMembersProfile(data);
      return res;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);
export const getMemberDaysOffCounts = createAsyncThunk(
  'getMemberDaysOffCounts',
  async (data: IRequestMemberProfile, thunkAPI) => {
    try {
      const res = await hrApi.getPaidCount(data);
      return res;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);

export const editMemberProfile = createAsyncThunk(
  'editMemberProfile',
  async (data: FormData, thunkAPI) => {
    try {
      const res = await hrApi.membersProfileEdit(data);
      return res;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }
);
