import React, {FC, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import Button from '../../ui/Button';
import {SearchInput} from '../../ui/SearchInput/SearchInput';
import styles from './TableHeader.module.css';
import DateRangePicker from '../../ui/DateRangePicker';
import {CustomSelect} from '../../ui/CustomSelect/CustomSelect';
import Chip from '../../ui/Chip';
import Typography from '../../ui/Typography';
import Badge from '../../ui/Badge/Badge';
import {optionType} from '../../ui/CustomSelect/useSelect';
import {IRequest, IRequestFilter} from '../../../pages/Requests/Request.types';
import {IListData, RequestStatus} from './types';
import {useDebounce} from '../../../hooks/useDebounce';
import {useSelector} from 'react-redux';
import {getCanEditSelector} from '../../../store/profile/selectors';

interface ITableHeader {
    listData: IListData[];
    listUsers: optionType[];
    setSearchParams: React.Dispatch<React.SetStateAction<IRequestFilter>>;
    params: IRequestFilter;
}

export const TableHeader: FC<ITableHeader> = ({
                                                  params,
                                                  listData,
                                                  listUsers,
                                                  setSearchParams,
                                              }) => {
    const [searchValue, setSearchValue] = useState(params.search || '');
    const debouncedValue = useDebounce<string>(searchValue, 400);
    const [rendered, setRendered] = useState(false);
    const canEdit = useSelector(getCanEditSelector);
    useEffect(() => {
        if (rendered) {
            setSearchParams(() => {
                params.search = searchValue;
                if (!params.search) delete params.search;
                return {...params};
            });
        } else {
            setRendered(true);
        }
    }, [debouncedValue]);

    const handleChangeSelectData = (nameKey: string, data: optionType[]) => {
        const selectedValues = data.map((e) => e.name);
        if (
            JSON.stringify(params[nameKey as keyof IRequest]) ===
            JSON.stringify(selectedValues) ||
            (!params[nameKey as keyof IRequest] && selectedValues.length === 0)
        ) {
            console.log('none');
        } else {
            setSearchParams(() => ({
                ...params,
                [nameKey as keyof IRequest]: selectedValues,
            }));
        }
    };
    const handleChangeDateRange = (el: Date[]) => {
        const dateToYmd = (date: Date) => moment(date).format('YYYY-MM-DD');

        setSearchParams(() => {
            const updatedParams = {...params};

            if (el[0]) {
                updatedParams.date_from = dateToYmd(el[0]);
            } else {
                delete updatedParams.date_from;
            }

            if (el[1]) {
                updatedParams.date_to = dateToYmd(el[1]);
            } else {
                delete updatedParams.date_to;
            }

            return updatedParams;
        });
    };

    const handleResetPeriod = () => {
        setSearchParams(() => {
            delete params.date_from;
            delete params.date_to;
            delete params.members;
            delete params.status;
            return {...params};
        });
    };

    const handleSetDepartment = (type?: string) => () => {
        if (setSearchParams) {
            setSearchParams((prev) => ({
                ...prev,
                page: 1,
                size: 15,
                request_type: type || '',
            }));
        }
    };

    const memoizedTotal = useMemo(
        () =>
            listData
                ? listData.reduce(
                    (total, item) => total + (item.requests_count || 0),
                    0
                )
                : 0,
        [listData]
    );

    return (
        <>
            <div className={styles.navContainer}>
                <div>
                    <Button
                        variant="text"
                        onClick={handleSetDepartment('')}
                        className={clsx(styles.tabItem, {
                            [styles.tabItemActive]: !params.request_type,
                        })}
                    >
                        All
                        <Badge color="default" className={styles.tabItemBadge}>
                            {memoizedTotal}
                        </Badge>
                    </Button>
                    {listData?.map((e, index) =>
                        e ? (
                            <Button
                                key={e.request_type + index}
                                variant="text"
                                onClick={handleSetDepartment(e.request_type)}
                                className={clsx(styles.tabItem, {
                                    [styles.tabItemActive]: params.request_type?.includes(
                                        e.request_type
                                    ),
                                })}
                            >
                                <div className={styles.tabItemContainer}>
                                    {e.request_type}
                                    <Badge color="default" className={styles.tabItemBadge}>
                                        {e.requests_count}
                                    </Badge>
                                </div>
                            </Button>
                        ) : null
                    )}
                </div>
                <div>
                    <SearchInput
                        value={searchValue}
                        setValue={setSearchValue}
                        placeholder="Search"
                        disableBottomBorder
                        disableRightBorder
                    />
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.leftSide}>
                    <DateRangePicker
                        isWeekend={true}
                        startDate={params?.date_from}
                        endDate={params?.date_to}
                        onChange={handleChangeDateRange}
                    />
                    {canEdit &&
                    <CustomSelect
                        multiSearch
                        multiSearchPlaceholder="Type to search"
                        detailedInputStyle={styles.customInput}
                        disableAllSubTitles
                        defaultAllSelected
                        detailedInput
                        customTitle={`Members${
                            Array.isArray(params?.members)
                                ? ` (${params?.members.length})`
                                : ` (${params?.members ? 1 : 0})`
                        }`}
                        options={listUsers}
                        defaultOptions={(Array.isArray(params?.members)
                                ? params.members
                                : params?.members
                                    ? [params.members]
                                    : []
                        )?.map((e: string) => ({
                            name: e,
                            label: listUsers.find((el) => el.name === e)?.label,
                        })) || []}
                        onChangeMultiValue={(e) => handleChangeSelectData('members', e)}
                        multi
                        placeholder="Members"
                        multiAllTitle="All Members"
                    />}

                    <CustomSelect
                        detailedInputStyle={styles.customInput}
                        disableAllSubTitles
                        detailedInput
                        defaultAllSelected
                        options={RequestStatus}
                        customTitle={`Status${
                            params?.status
                                ? Array.isArray(params.status)
                                    ? ` (${params.status.length})`
                                    : ` (${[params.status].length})`
                                : ''
                        }`}
                        defaultOptions={(params?.status
                            ? Array.isArray(params.status)
                                ? params.status
                                : [params.status]
                            : [])?.map((e) => ({
                            name: e,
                            label: RequestStatus.find((el) => el.name === e)?.name,
                        }))}
                        onChangeMultiValue={(e) => handleChangeSelectData('status', e)}
                        multi
                        placeholder="Status"
                        multiAllTitle="Select All Statuses"
                    />

                    {(params.date_from || params.members || params.status) && (
                        <Chip
                            className={styles.chip}
                            variant="contained"
                            color={'error'}
                            background={'errorBg'}
                            onClick={handleResetPeriod}
                        >
                            <Typography variant={'smallText'} weight={'regular'}>
                                Reset
                            </Typography>
                        </Chip>
                    )}
                </div>
            </div>
        </>
    );
};
