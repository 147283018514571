import React, { ReactNode, useEffect, useState } from 'react';
import Aside from './Aside';
import styles from './Layout.module.css';
import AdaptiveScreen from '../../pages/AdaptiveScreen/AdaptiveScreen';

export interface LayoutProps {
  children: ReactNode;
}

const Layout = React.memo(({ children }: LayoutProps) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <div className={styles.root}>
        {windowWidth > 960 ? (
            <>
              <Aside />
              <main className={styles.main}>
                {/*<Header />*/}
                {children}
              </main>
            </>
        ) : (
            <AdaptiveScreen />
        )}
      </div>
  );
});

export default Layout;
