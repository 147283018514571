import { FC, useState } from 'react';

import { ProjectUpdateData } from '../../../ProjectUpdateData/ProjectUpdateData';

import IconButton from '../../../../ui/IconButton/IconButton';
import Modal from '../../../../ui/Modal/Modal';
import Typography from '../../../../ui/Typography/Typography';

import styles from './UpdateProjectDataModal.module.css';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/plusWhite.svg';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../constants/routes';
import { ICreateNewProjectFormData } from '../../../../../pages/Projects/ProjectProfile/CreateNewProfile/types';

interface IUpdateProjectDataModal {
  isOpen: boolean;
  onClose: () => void;
  onEditProjectModal?: (data: ICreateNewProjectFormData) => void;
  profileInformationData: {
    emoji: string;
    color: string;
    services: string[];
    name: string;
    client_full_name: string;
  };
}
export const UpdateProjectDataModal: FC<IUpdateProjectDataModal> = (
  props
) => {
  const {
    isOpen,
    onClose,
    onEditProjectModal,
    profileInformationData,
  } = props;

  return (
    <Modal
      className={styles.saveModal}
      classNameOverlay={styles.overlay}
      isOpen={isOpen}
      onClose={onClose}>
      <div className={styles.header}>
        <Typography variant='subHeading1'>Edit Project</Typography>
        <IconButton
          onClick={onClose}
          className={styles.closeIcon}
          icon={CloseIcon}
        />
      </div>
      <ProjectUpdateData
        initialData={profileInformationData}
        handleSubmitForm={onEditProjectModal}
        buttonTitle='Save Updates'
      />
    </Modal>
  );
};
