import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import PrivateOutlet from '../components/PrivateOutlet';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import PasswordRecovery from '../pages/PasswordRecovery';
import ProjectsTable from '../pages/Projects/Table';
import ProjectsTimeTracking from '../pages/Projects/TimeTracking';
import BookKepingAnalytics from '../pages/HR/BookKeping/Analytics/Analytics';
import Invoices from '../pages/HR/Invoices/Invoices';
import Dashboard from '../pages/Dashboard';
import ComplaintsProposition from '../pages/ComplaintsProposition/ComplaintsProposition';
import Holidays from '../pages/Policy/Holidays/Holidays';
import TimeTracking from '../pages/TimeTracking/TimeTracking';
import HumanResources from '../pages/HumanResources';
import Leave from '../pages/Leave';
import DashboardInfo from '../pages/DashboardInfo';
import HumanResourcesArchive from '../pages/Archive/Archive';
import EmployeeProfile from '../pages/EmployeeProfile/EmployeeProfile';
import MedicalLeave from '../pages/MedicalLeave/MedicalLeave';
import EmployeeDocuments from '../pages/EmployeeDocuments/EmployeeDocuments';
import DaysOff from '../pages/DaysOff/DaysOff';
import Error from '../pages/Error/Error';
import Conditions from '../pages/Policy/Conditions/Conditions';
import CreateNewProfile from '../pages/Projects/ProjectProfile/CreateNewProfile';
import ProjectProfile from '../pages/Projects/ProjectProfile/Profile';
import Requests from '../pages/Requests/Requests';
import {useSelector} from 'react-redux';
import {
    getCanEditSelector,
} from '../store/profile/selectors';
import Bench from '../pages/Bench/Bench';
import Proposals from '../pages/Proposals';
import Proposal from '../pages/Proposal';
import routes from '../constants/routes';
import BenchMember from '../pages/Bench/BenchMember';

const MainRouter = () => {
    const canEdit = useSelector(getCanEditSelector);

    const customerRoutes = (
        <>
            <Route
                path={routes.dashboardInfo}
                element={<DashboardInfo/>}
            />

            <Route
                path={routes.humanResources}
                element={<HumanResources/>}
            />
            <Route path={routes.requests} index element={<Requests/>}/>
            <Route path={routes.myProfile} element={<EmployeeProfile/>}/>
            <Route path={routes.mySickLeave} element={<MedicalLeave/>}/>
            <Route path={routes.sickLeave} element={<MedicalLeave/>}/>
            <Route path={routes.dayOff} element={<MedicalLeave/>}/>
            <Route path={routes.myDayOff} element={<MedicalLeave/>}/>
        </>
    );

    const adminRoutes = (
        <>
            <Route
                path={routes.projectsTable}
                element={<ProjectsTable/>}
            />
            <Route
                path={routes.projectsCreate}
                element={<CreateNewProfile/>}
            />
            <Route
                path={routes.projectsProfile + '/:id'}
                element={<ProjectProfile/>}
            />
            <Route
                path={routes.projectsTimeTracking}
                element={<ProjectsTimeTracking/>}
            />
            <Route path={routes.hrBKAnalytics} element={<BookKepingAnalytics/>}/>
            <Route path={routes.hrInvoices} element={<Invoices/>}/>
            <Route index element={<Dashboard/>}/>
            <Route path={routes.complaints} element={<ComplaintsProposition/>}/>
            <Route path={routes.policyHolidays} element={<Holidays/>}/>
            <Route path={routes.policyConditions} element={<Conditions/>}/>
            <Route path={routes.dashboardInfo} element={<DashboardInfo/>}/>
            <Route index path={routes.timeTracking} element={<TimeTracking/>}/>
            <Route path={routes.proposalsPage} element={<Proposals/>}/>
            <Route path={routes.proposalsPage + '/:id'} element={<Proposal/>}/>
            <Route path={routes.dashboard} element={<Dashboard/>}>

                <Route path={routes.requests} index element={<Requests/>}/>
                <Route
                    path={routes.humanResources}
                    element={<HumanResources/>}
                />

                <Route path={routes.leave} element={<Leave/>}/>
                <Route path={routes.bench} element={<Bench/>}/>
                <Route path={routes.benchMember + '/:id'} element={<BenchMember/>}/>
                <Route
                    path={routes.humanResourcesArchive}
                    element={<HumanResourcesArchive/>}
                />
                <Route
                    path={routes.employeeProfile + '/:id'}
                    element={<EmployeeProfile/>}
                />

                <Route path={routes.sickLeave} element={<MedicalLeave/>}/>
                <Route path={routes.dayOff} element={<MedicalLeave/>}/>
                <Route
                    path={routes.employeeDocuments}
                    element={<EmployeeDocuments/>}
                />
                <Route path={routes.daysOff} element={<DaysOff/>}/>
            </Route>
        </>
    );

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path={routes.signIn} element={<SignIn/>}/>
                    <Route
                        path={routes.forgotPassword}
                        element={<ForgotPassword/>}
                    />
                    <Route
                        path={routes.passwordRecovery}
                        element={<PasswordRecovery/>}
                    />
                    <Route path={routes.home} element={<PrivateOutlet/>}>
                        {canEdit ? adminRoutes : customerRoutes}
                    </Route>
                    <Route path='*' element={<Error/>}/>
                </Routes>
                <ToastContainer hideProgressBar theme='colored'/>
            </BrowserRouter>
        </div>
    );
};

export default MainRouter;
