import axios, { AxiosRequestConfig } from 'axios';
import helper from '../constants/helper';
import { store } from '../store';
import { logoutFinish } from '../store/auth/reducer';

axios.interceptors.response.use(undefined, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    const message =
      error.message && error.message === 'Network Error'
        ? error.message
        : 'An unexpected error occurred!';
    console.log(message);
    return Promise.reject(error);
  }

  const message = (def: string) => error.response.data.message || def;

  switch (error.response.status) {
    case 400:
      console.log('Bad Request', 'error');
      break;
    case 401:
      store.dispatch(logoutFinish());
      console.log(message('Authorize please'), 'error');
      break;
    case 403:
      console.log(message('Forbidden'), 'error');
      break;
    case 404:
      console.log('Not found', 'error');
      break;
    case 409:
      console.log(
        message('Conflict with the current state.'),
        'error'
      );
      break;
    default:
  }
  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  config.baseURL = process.env.REACT_APP_API_URL;
  config.headers = {Authorization: 'Bearer ' + localStorage.getItem(helper.JWT_TOKEN),};
  config.params = {
    ...config.params,
  };
  return config;
});

const API = {
  get: (url: string, config?: AxiosRequestConfig) =>
    axios.get(url, config).then(({ data }) => data),
  post: <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    axios.post(url, data, config).then(({ data }) => data),
  put: <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    axios.put(url, data, config).then(({ data }) => data),
  delete: (url: string, config?: AxiosRequestConfig) =>
    axios.delete(url, config).then(({ data }) => data),
};

export default API;
