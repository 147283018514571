import { FC, MouseEvent, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import Typography from '../../../ui/Typography';
import Stack from '../../../ui/Stack';
import Button from '../../../ui/Button';
import { snackbarPosTopLeft } from '../../../ui/Snackbar/constants';

import HolidayCard from '../../HolidayCard/HolidayCard';
import { IRequestHoliday } from '../../../../services/policyApi/types';
import { policyApi } from '../../../../services/policyApi/policyApi';

import classes from './AddHoliday.module.css';
import { ReactComponent as Plus } from '../../../../assets/icons/plusWhite.svg';

type AddHolidayProps = {
  closeDrawer: () => void;
  setRender: React.Dispatch<React.SetStateAction<number>>;
};

const AddHoliday: FC<AddHolidayProps> = ({
  closeDrawer,
  setRender,
}) => {
  const initialHoliday = { name: '', date_to: '', date_from: '' };
  const addHoliday = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setHolidays((prev) => [...prev, initialHoliday]);
  };

  const [holidays, setHolidays] = useState<IRequestHoliday[]>([
    initialHoliday,
  ]);

  const onSumbit = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    closeDrawer();

    const validated = holidays.filter(
      (e) => e.name && e.date_from && true
    );
    try {
      await policyApi.createHoliday(validated);
      enqueueSnackbar('Holiday added', {
        variant: 'success',
        anchorOrigin: snackbarPosTopLeft,
      });
      setRender((prev) => prev + 1);
      setHolidays([initialHoliday]);
    } catch (e: any) {
      if (e.response.data.detail) {
        enqueueSnackbar(e.response.data.detail, {
          variant: 'error',
          anchorOrigin: snackbarPosTopLeft,
        });
      }
      setRender((prev) => prev + 1);
      setHolidays([initialHoliday]);
    }
  };

  const validate = (holidays: IRequestHoliday[]) => {
    return holidays
      .map((el) => el.name && el.date_from && true)
      .includes(true);
  };
  const isValid = validate(holidays);

  return (
    <Stack className={classes.formPopupBody}>
      <div className={classes.form}>
        <Stack className={classes.holidayForm} direction='column'>
          {holidays.map((el: any, index: number) => (
            <HolidayCard
              key={index}
              ind={index}
              item={el}
              setData={setHolidays}
            />
          ))}
          <Button
            className={classes.buttonAddHoliday}
            onClick={addHoliday}>
            <Plus />
            <Typography variant='buttonText'>
              Add Public Holidays
            </Typography>
          </Button>
        </Stack>
        <Stack className={classes.containerBtn}>
          <Button
            disabled={!isValid}
            onClick={onSumbit}
            color='black'
            fullWidth>
            <Typography variant='buttonText'>Save</Typography>
          </Button>
        </Stack>
      </div>
    </Stack>
  );
};

export default AddHoliday;
