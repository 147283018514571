import { FC } from 'react';
import clsx from 'clsx';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { useNavigate } from 'react-router-dom';

import Table from '../../../ui/Table/Table';
import TableCell from '../../../ui/TableCell/TableCell';
import TableRow from '../../../ui/TableRow/TableRow';

import styles from './Table.module.css';
import { CategoryCard } from '../../../HR/Bookkeping/CategoryCard/CategoryCard';
import StageCard from '../../../ui/StageCard/StageCard';
import routes from '../../../../constants/routes';
import { IListResponse } from '../../../../services/projectsApi/types';
import TeamCard from '../../TeamCard/TeamCard';
import { ImageFallback } from '../../../ui/ImageFallback/ImageFallback';

export interface IProjectData {
  data?: IListResponse;
}

export const TableList: FC<IProjectData> = (props) => {
  const navigate = useNavigate();
  const { data } = props;

  const tableRowStyles = clsx(styles.rootTableRowTD);

  const rootCellStyles = clsx(styles.rootCell, styles.alignStart);

  return (
    <>
      <Table
        tableContainerClass={styles.tbody}
        className={styles.table}>
        <thead>
          <TableRow className={styles.rootTableRow}>
            <TableCell component='th' sortBy='name'>
              project/ Last Update
            </TableCell>
            <TableCell component='th' sortBy='client_full_name'>
              Client
            </TableCell>
            <TableCell component='th' sortBy='status'>
              Status
            </TableCell>
            <TableCell component='th'>PM</TableCell>
            <TableCell component='th' sortBy='product_type'>
              Stage
            </TableCell>
            <TableCell component='th'>Category</TableCell>
            <TableCell component='th'>Team</TableCell>
          </TableRow>
        </thead>
        <tbody className={styles.tbody}>
          {data?.items && data?.items.length !== 0
            ? data?.items?.map((el) => (
                <TableRow
                  onClick={() =>
                    navigate(routes.projectsProfile + `/${el.id}`)
                  }
                  key={el.id}
                  hover
                  className={tableRowStyles}>
                  <TableCell className={rootCellStyles}>
                    <div className={styles.containerDateCell}>
                      <div className={styles.row}>
                        <div
                          style={{ backgroundColor: el.color }}
                          className={styles.emojiWrapper}>
                          <Emoji
                            unified={el.emoji}
                            emojiStyle={EmojiStyle.APPLE}
                            size={20}
                          />
                        </div>
                        <p className={styles.projectName}>
                          {el.name}
                        </p>
                        {/* <div className={styles.dot} />
                        <p className={styles.projectName}>
                          {el.project?.task}
                        </p> */}
                      </div>
                      {/*<p className={styles.projectDate}>*/}
                      {/*  UPD:*/}
                      {/*  {moment(el.updated_at).format(*/}
                      {/*    ' DD MMMM YYYY HH:mm'*/}
                      {/*  )}*/}
                      {/*</p>*/}
                    </div>
                  </TableCell>
                  <TableCell className={styles.rootCell}>
                    <div className={styles.cardAvatar}>
                      <p>{el.client_full_name}</p>
                    </div>
                  </TableCell>
                  <TableCell className={styles.rootCell}>
                    <CategoryCard type={el.status as 'In Progress'} />
                  </TableCell>
                  <TableCell className={styles.rootCell}>
                    <div className={styles.containerManagers}>
                      {el?.managers.map(({ user }, index) => (
                        <div
                          key={index}
                          className={styles.cardAvatar}>
                          {user.avatar_url && (
                            <ImageFallback
                              src={user.avatar_url}
                              alt=''
                            />
                          )}
                          <p>{user.full_name}</p>
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell className={styles.rootCell}>
                    <div className={styles.wrapperStageCards}>
                      {el.services?.map((el: any) => (
                        <StageCard
                          key={el}
                          type={el}
                          className={styles.stageCard}
                        />
                      ))}
                    </div>
                  </TableCell>
                  <TableCell className={styles.rootCell}>
                    {el.product_industry && (
                      <div className={styles.card}>
                        {el.product_industry}
                      </div>
                    )}
                  </TableCell>
                  <TableCell className={styles.rootCellTeam}>
                    {el.members?.length ? (
                      <TeamCard array={el.members} />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </tbody>
      </Table>
    </>
  );
};
