import {FC, useEffect, useState} from 'react';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';
import {enqueueSnackbar} from 'notistack';

import styles from './styles.module.css';
import {CustomSelect} from '../../../../../ui/CustomSelect/CustomSelect';
import Button from '../../../../../ui/Button';
import {optionType} from '../../../../../ui/CustomSelect/useSelect';
import {commonApi} from '../../../../../../services/commonApi/commonApi';
import {IGetUsersResponse} from '../../../../../../services/commonApi/types';

import {getPositionsSelector} from '../../../../../../store/app/selectors';
import {hrApi} from '../../../../../../services/hrApi/hrApi';
import {addTeamMemberSchema} from './schemas/addTeamMember.schema';
import {IMembersProfile} from '../../../../../../services/hrApi/types';
import {getProjectProfileSelector} from '../../../../../../store/project/selectors';
import {projectsApi} from '../../../../../../services/projectsApi/projectsApi';
import {snackbarPosTopRight} from '../../../../../ui/Snackbar/constants';
import {
    ICreateMemberProject,
    IMemberProject,
} from '../../../../../../services/projectsApi/types';
import {ImageFallback} from '../../../../../ui/ImageFallback/ImageFallback';
import fallbackImg from '../../../../../../assets/icons/movadex.png';

interface IAddTeamMembers {
    isEdit?: boolean;
    data?: IMemberProject;
    setRender?: React.Dispatch<React.SetStateAction<number>>;
    handleOnClickItem?: (data: IMemberProject) => void;
    onClose?: () => void;
}

export const AddTeamMembers: FC<IAddTeamMembers> = ({
                                                        isEdit,
                                                        data,
                                                        setRender,
                                                        handleOnClickItem,
                                                        onClose,
                                                    }) => {
    const profileData = useSelector(getProjectProfileSelector);
    const positions = useSelector(getPositionsSelector);

    const [users, setUsers] = useState<IGetUsersResponse[] | undefined>(
        undefined
    );

    const getUsers = async () => {
        const res = await commonApi.getUsers();
        setUsers(res);
    };
    useEffect(() => {
        getUsers();
    }, []);

    const [user, setUser] = useState<IMembersProfile | undefined>(
        undefined
    );

    interface IInitialValues {
        user?: IGetUsersResponse;
        positions?: optionType;
        tech_stacks?: optionType[];
        departments?: optionType[];
        involvement_type: string;
    }

    const initialValues: IInitialValues = {
        user: isEdit ? data?.user : undefined,
        positions:
            isEdit && data ? {name: data?.position_name} : undefined,
        tech_stacks: isEdit && data ? data.tech_stacks : undefined,
        departments:
            isEdit && data
                ? data.departments.map((e) => {
                    return {name: e.name};
                })
                : undefined,
        involvement_type:
            isEdit && data?.involvement_type
                ? data?.involvement_type
                : 'Full time',
    };

    return (
        <div className={styles.wrapper}>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    const obj: ICreateMemberProject = {
                        project_id: Number(profileData?.id),
                        user_id: Number(values.user?.id),
                        position_name: values.positions?.name || '',
                        seniority_level: isEdit
                            ? data?.seniority_level
                            : user?.seniority_level,
                        // hr_manager_id: 16,
                        involvement_type: values.involvement_type,
                        tech_stacks: values.tech_stacks || [],
                        departments: values.departments || [],
                    };

                    try {
                        if (!isEdit) {
                            const res = await projectsApi.createProjectMember(obj);
                            setRender && setRender((prev) => prev + 1);
                            handleOnClickItem && handleOnClickItem(res);
                            return;
                        }

                        if (!obj.seniority_level) {
                            const res = await hrApi.getMembersProfile({
                                user_id: Number(values.user?.id),
                            });
                            if (res.seniority_level) {
                                obj.seniority_level = res.seniority_level;
                            }
                        }
                        obj.project_member_id = data?.id;
                        const res = await projectsApi.editProjectMember(obj);
                        setRender && setRender((prev) => prev + 1);
                        handleOnClickItem && handleOnClickItem(res);
                    } catch (e: any) {
                        if (e.response.data.detail) {
                            enqueueSnackbar(e.response.data.detail, {
                                variant: 'error',
                                anchorOrigin: snackbarPosTopRight,
                            });
                            return;
                        }
                        enqueueSnackbar('Error', {
                            variant: 'error',
                            anchorOrigin: snackbarPosTopRight,
                        });
                    }
                }}
                validationSchema={addTeamMemberSchema}
                validateOnMount
                enableReinitialize>
                {({
                      values,
                      setFieldValue,
                      errors,
                      submitForm,
                      submitCount,
                      isSubmitting,
                  }) => {
                    const getProfile = async (id: number) => {
                        const res = await hrApi.getMembersProfile({user_id: id});
                        setFieldValue('tech_stacks', res.tech_stacks);
                        setFieldValue('departments', res.departments.map((e) => {
                            return {name: e.name}
                        }));
                        setUser(res);
                    };
                    return (
                        <>
                            <div className={styles.container}>
                                {isEdit ? (
                                    <div className={styles.editAvatar}>
                                        <ImageFallback
                                            fallbackSrc={fallbackImg}
                                            src={values.user?.avatar_url}
                                        />
                                        <div className={styles.editAvatarInfo}>
                                            <p className={styles.editAvatarTitle}>
                                                {values.user?.full_name}
                                            </p>
                                            <p className={styles.editAvatarSubTitle}>
                                                jessy@movadex.com
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <CustomSelect
                                        label='Choose team member'
                                        placeholder='Enter team member'
                                        classNameOptions={styles.containerOptions}
                                        detailed={{
                                            title: 'Select team member',
                                            multi: false,
                                            search: true,
                                            options: users,
                                            fastClick: true,
                                            selected: values.user && [values.user],
                                            onChangeValue: (e) => {
                                                e[0] && setFieldValue('user', e[0].object);
                                                e[0] && getProfile(e[0].object.id);
                                            },
                                        }}
                                        detailedInput
                                    />
                                )}

                                <CustomSelect
                                    optionImage
                                    positions
                                    multiSearchPlaceholder={'Search position'}
                                    label='Positions'
                                    options={positions?.positions}
                                    placeholder='Choose positions'
                                    value={values.positions}
                                    detailedInput
                                    disabled={!values.user}
                                    defaultOptions={
                                        values.positions && [values.positions]
                                    }
                                    limitedViewOption
                                    onChangeValue={(e) => setFieldValue('positions', e)}
                                    error={errors.positions as string}
                                    wasRequest={Boolean(submitCount)}
                                />
                                <CustomSelect
                                    disabled={!values.user}
                                    label={'Tech Stack'}
                                    options={positions?.tech_stacks}
                                    placeholder='Choose Tech Stack'
                                    multi
                                    multiAllTitle='All Tech Stack'
                                    limitedViewOption
                                    detailedInput
                                    additionalBlock
                                    multiValue={values.tech_stacks}
                                    defaultOptions={values.tech_stacks}
                                    onChangeMultiValue={(e) =>
                                        setFieldValue('tech_stacks', e)
                                    }
                                    error={errors.tech_stacks as string}
                                    wasRequest={Boolean(submitCount)}
                                    notAutoHeight
                                />
                                <CustomSelect
                                    disabled={!values.user}
                                    label={'Departments'}
                                    options={positions?.departments}
                                    placeholder='Choose Departments'
                                    multi
                                    multiAllTitle='All Departments'
                                    limitedViewOption
                                    detailedInput
                                    additionalBlock
                                    multiValue={values.departments}
                                    defaultOptions={values.departments}
                                    onChangeMultiValue={(e) =>
                                        setFieldValue('departments', e)
                                    }
                                    error={errors.departments as string}
                                    wasRequest={Boolean(submitCount)}
                                    notAutoHeight
                                />
                                <p className={styles.addInvolvementTitle}>
                                    Involvement
                                </p>
                                <div className={styles.addInvolvementContainer}>
                                    {['Full time', 'Part time', 'Support'].map(
                                        (el, index) => (
                                            <div
                                                onClick={() =>
                                                    setFieldValue('involvement_type', el)
                                                }
                                                key={index}
                                                className={styles.containerRadio}>
                                                <div
                                                    className={`${styles.radio} ${
                                                        values.involvement_type === el &&
                                                        styles.radioOn
                                                    }`}
                                                />
                                                <p className={styles.radioTitle}>{el}</p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <Button
                                    variant='outlined'
                                    color='black'
                                    className={styles.editBtn}
                                    onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={submitForm}
                                    disabled={isSubmitting || !values.user}
                                    color='black'>
                                    Save Changes
                                </Button>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};
