import { DragEvent, FC, useEffect, useState } from 'react';
import styles from './ProfileImageEdit.module.css';
import DragAndDropComponent from '../../DragAndDropComponent/DragAndDropComponent';
import { filesToArray } from '../../../helpers/file/fileToObject.helper';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopLeft } from '../Snackbar/constants';
interface IProfileImageEdit {
  defaultImage?: string;
  selectedFile?: File;
  setSelectedFile: (e: IProfileImageEdit['selectedFile']) => void;
}
export const ProfileImageEdit: FC<IProfileImageEdit> = ({
  defaultImage,
  selectedFile,
  setSelectedFile,
}) => {
  const [defaultImg, setDefaultImg] = useState(defaultImage);
  const handleClickUploadField = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = handleFileInputChange;
    fileInput.click();
  };

  const [base64Image, setBase64Image] = useState<string | undefined>(
    undefined
  );

  const handleFileInputChange = async (event: any) => {
    const file: File = event.target?.files[0];

    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg')
    ) {
      //   const obj = await filesToArray([file]);
      setSelectedFile(file);
      return;
    }
    enqueueSnackbar(
      'Ops, i only accept png, jpg and jpeg image formats',
      { variant: 'error', anchorOrigin: snackbarPosTopLeft }
    );
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      //   const obj = await filesToArray([file]);

      setSelectedFile(file);
    }
  };

  const onImageDeleteHandler = () => {
    setSelectedFile(undefined);
    setDefaultImg(undefined);
    setBase64Image(undefined);
  };

  const getBase64: (
    file: File
  ) => Promise<undefined | string> = async (file: File) => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onloadend = function () {
        setBase64Image(
          reader.result?.toString().replace(/^data:(.*,)?/, '')
        );
        resolve('success');
      };
      reader.onerror = function () {
        reject(undefined);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (selectedFile) {
      fetchBase64();
    }
  }, [selectedFile]);

  const fetchBase64 = async () => {
    return selectedFile && (await getBase64(selectedFile));
  };
  return (
    <>
      {selectedFile || defaultImg ? (
        <div className={styles.pickedImageWrapper}>
          <div
            className={styles.pickedImage}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            <img
              src={
                selectedFile && base64Image
                  ? 'data:image/jpg;base64,' + base64Image
                  : defaultImg
              }
              className={styles.image}
              onClick={handleClickUploadField}
              alt='profile-avatar'
            />
            <p
              onClick={
                defaultImage
                  ? handleClickUploadField
                  : onImageDeleteHandler
              }
              className={`${styles.size14} ${styles.mTop4} ${styles.redText} ${styles.cursorPointer}`}>
              {defaultImage ? 'Change Image' : 'Delete Image'}
            </p>
          </div>
        </div>
      ) : (
        <DragAndDropComponent
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleClickUploadField={handleClickUploadField}
        />
      )}
    </>
  );
};
