import { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './Description.module.css';

export const DescriptionComponent: FC<{
  onClick?: (e: React.MouseEvent) => void;
  text?: string;
  fullSize?: boolean;
  isEditable?: boolean;
  onBlur?: (text: string) => void;
}> = ({ onClick, text, fullSize, isEditable, onBlur }) => {
  const handleOnBlur = (innerText: string) => {
    if (innerText !== text && onBlur) {
      onBlur(innerText);
    }
  };

  return (
    <pre
      onBlur={(e) => {
        typeof e.target.textContent === 'string' &&
          isEditable &&
          handleOnBlur(e.target.innerText);
      }}
      contentEditable={isEditable}
      className={clsx(styles.container, fullSize && styles.fullSize)}
      onClick={onClick}>
      {text}
    </pre>
  );
};

export interface IDescriptionSelect {
  onClick?: () => void;
  text?: string;
  onFetch?: (i: string) => void;
  isOpen?: boolean;
}
export const DescriptionSelect: FC<IDescriptionSelect> = ({
  text,
  isOpen,
  onFetch,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const autoResize = (
    textarea: React.FormEvent<HTMLTextAreaElement>
  ) => {
    textarea.currentTarget.style.height = 'auto';
    textarea.currentTarget.style.height =
      textarea.currentTarget.scrollHeight + 'px';
  };
  const [value, setValue] = useState(text || '');
  useEffect(() => {
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.setSelectionRange(
          value.length,
          value.length
        );
        inputRef.current?.focus();
      }, 0);
    }
  }, [isOpen, inputRef.current]);

  return (
    <div className={styles.select}>
      <textarea
        ref={inputRef}
        autoFocus
        // onKeyUp={(e) => autoResize(e)}
        onInput={(e) => autoResize(e)}
        onFocus={(e) => autoResize(e)}
        className={styles.input}
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          onFetch &&
          value !== (text === null ? '' : text) &&
          onFetch(value)
        }
      />
    </div>
  );
};
