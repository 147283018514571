import clsx from 'clsx';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';

import styles from './TableCell.module.css';

export type TableCellProps = {
  component?: 'td' | 'th';
  align?: 'left' | 'center' | 'right';
  color?: 'error' | 'muted' | 'primary';
  sortBy?: string;
  className?: string;
  columnClass?: string;
  children?: ReactNode;
  onClick?: any;
} & ComponentPropsWithoutRef<'td' | 'th'>;

const TableCell = ({
  sortBy = '',
  component = 'td',
  color,
  align = 'left',
  className,
  columnClass,
  children,
  ...props
}: TableCellProps) => {
  const Component = component;
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const sortByType = queryParams.get('sort_by_type');
  const classNames = clsx(
    styles.root,
    color && styles[color],
    {
      [styles[align]]: align,
      [styles.sortable]: sortBy,
    },
    className
  );

  const handleSort = () => {
    let type;
    if (queryParams.get('sort_by') !== sortBy) {
      type = 'asc';
    } else {
      type =
        queryParams.get('sort_by_type') === 'desc' ? 'asc' : 'desc';
    }
    queryParams.set('sort_by_type', type);
    queryParams.set('sort_by', sortBy);
    navigate(`?${queryParams}`);
  };

  return (
    <Component align='left' {...props} className={columnClass}>
      <div
        className={clsx([classNames])}
      onClick={() => sortBy && handleSort()}
      >
        {children}
        {sortBy && (
          <span
            className={clsx(styles.icon, {
              [styles.iconSorted]:
                sortByType === 'asc' &&
                sortBy === queryParams.get('sort_by'),
            })}>
            <ChevronDownIcon />
          </span>
        )}
      </div>
    </Component>
  );
};

export default TableCell;
