import { createSlice } from '@reduxjs/toolkit';
import { appApi } from './appApi';

export type appState = {
  positions: IPositions | undefined;
};

export interface IPositions {
  departments: { name: string }[];
  positions: { name: string }[];
  tech_stacks: { name: string }[];
}

const initialState: appState = {
  positions: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      appApi.endpoints.getPositions.matchFulfilled,
      (state, { payload }) => {
        state.positions = payload;
      }
    );
  },
});

// export const {} = appSlice.actions;

export default appSlice.reducer;
