import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const timeTrackingApi = createApi({
    reducerPath: 'timeTrackingApi',
    baseQuery: ApiBaseQuery({baseUrl: '/time-tracking'}),
    endpoints: (builder) => ({
        getTimeTrackingDays: builder.query({
            query: (data) => ({url: '/values', method: 'get', data: data, params: data.params})
        }),
        getTimeTrackingUsers: builder.query({
            query: (data) => ({url: '/users', method: 'get', data: {}, params: data.params})
        }),
        getTimeTrackingProjects: builder.query({
            query: (data) => ({url: '/projects', method: 'get', data: {}, params: data.params})
        }),
    }),
});

export const {
    useGetTimeTrackingDaysQuery,
    useGetTimeTrackingUsersQuery,
    useGetTimeTrackingProjectsQuery
} = timeTrackingApi;
