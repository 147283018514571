import React, { SetStateAction, useState } from 'react';
import moment from 'moment';
import { Calendar } from 'react-date-range';
import { useMenu } from '../../../hooks/useMenu';
import Button from '../Button/Button';
import Menu from '../Menu';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import { DatePickerInputProps } from './DatePickerInput.model';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import classes from './DatePickerInput.module.css';

const DatePickerInput = ({
  variant = 'text',
  label,
  date,
  name,
  error,
  onChange,
  hideIcon,
  className,
  classNameMenu,
  maxDate,
  minDate,
}: DatePickerInputProps) => {
  const d = new Date();

  const {
    isOpen,
    anchorEl,
    handleMenuClose,
    handleMenuOpen,
    topMargin,
  } = useMenu();
  const [value, setValue] = useState(
    date ? moment(date, 'YYYY-MM-DDTh:mm:ss').toDate() : undefined
  );

  const handleChange = (date: SetStateAction<Date | undefined>) => {
    setValue(date);
    const normalizeDate = (date: any) =>
      moment(date)
        .set('hours', d.getHours())
        .set('minutes', d.getMinutes());
    onChange(name, normalizeDate(date).format());
    handleMenuClose();
  };

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <Button
        fullWidth
        component='span'
        variant={variant}
        className={className}
        startIcon={hideIcon ? undefined : CalendarIcon}
        onClick={handleMenuOpen}>
        <div
          className={isOpen ? classes.format : classes.formatActive}>
          {date
            ? moment(date, 'YYYY-MM-DDTh:mm:ss').format(
                'DD MMMM YYYY'
              )
            : ''}
        </div>
        {/*<Calendar date={value} onChange={handleChange}/>*/}
      </Button>

      <Menu
        isOpen={isOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        className={classNameMenu}
        position={'bottomRight'}
        topMargin={topMargin}>
        <Calendar
          date={value}
          onChange={handleChange}
          maxDate={maxDate}
          minDate={minDate}
        />
      </Menu>
      {error && <InputError>{error}</InputError>}
    </div>
  );
};

export default DatePickerInput;
