import Button from '../../../../../ui/Button';
import styles from './Header.module.css';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/plus.svg';
import { FC } from 'react';
import clsx from 'clsx';
import Badge from '../../../../../ui/Badge';
import {
  IMemberProjectTotalByDepartments,
  IMembersProject,
} from '../../../../../../services/projectsApi/types';
import { useSelector } from 'react-redux';
import { getPositionsSelector } from '../../../../../../store/app/selectors';
import { searchParamsToObject } from '../../../../../../helpers/object/searchParams-to-object.helper';
interface IHeader {
  params: { department?: string };
  onShowModal: () => void;
  total: IMemberProjectTotalByDepartments[];
  searchParams?: URLSearchParams;
  setSearchParams?: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >;
}
export const Header: FC<IHeader> = ({
  onShowModal,
  total,
  setSearchParams,
  searchParams,
  params,
}) => {
  const positions = useSelector(getPositionsSelector);
  const handleSetDepartment = (type?: string) => {
    return () => {
      if (type) {
        setSearchParams &&
          setSearchParams((prev) => {
            prev.delete('department');
            const params: any = searchParamsToObject(prev);
            return { department: type, ...params };
          });
      } else {
        setSearchParams &&
          setSearchParams((prev) => {
            prev.delete('department');
            const params: any = searchParamsToObject(prev);
            return { ...params };
          });
      }
    };
  };

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Team</p>
        <Button
          onClick={onShowModal}
          color='black'
          startIcon={PlusIcon}>
          Add Team Member
        </Button>
      </div>
      <div className={styles.departmentsList}>
        <Button
          variant='text'
          onClick={handleSetDepartment('')}
          className={clsx([
            styles.tabItem,
            { [styles.tabItemActive]: !params.department },
          ])}>
          All
          <Badge color='default' className={styles.tabItemBadge}>
            {total &&
              total.find((i) => i.department_name.includes('All'))
                ?.users_count}
          </Badge>
        </Button>
        {positions?.departments.map((department) => {
          const item =
            total &&
            total.find((i) =>
              i.department_name.includes(department.name)
            );
          return (
            item && (
              <Button
                key={department.name}
                variant='text'
                onClick={handleSetDepartment(item.department_name)}
                className={clsx([
                  styles.tabItem,
                  {
                    [styles.tabItemActive]:
                      params.department === item.department_name,
                  },
                ])}>
                <div className={styles.tabItemContainer}>
                  {department.name}
                  <Badge
                    color='default'
                    className={styles.tabItemBadge}>
                    {item.users_count}
                  </Badge>
                </div>
              </Button>
            )
          );
        })}
      </div>
    </>
  );
};
