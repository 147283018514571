import  {ReactNode} from 'react';
import moment from 'moment';

export interface GroupImageProps {
    full_name: string;
    avatar_url: string;
    id: number
    period?: any
    data?: any
    setOpenModal?: any
}

export interface ItemRenderProps {
    item: any;
    itemContext: any;
    getItemProps: any;
}

export interface ICalendarList {
    items: ResultObject[];
    groups: any[];
    startDate: any;
    endDate: any;
    handleDeleteLeave?: any
    handleDeleteHoliday?: any
    handleEditLeave?: any
}

export interface ResultObject {
    id: number;
    group: number;
    user: UserObject;
    start_time: moment.Moment;
    end_time: moment.Moment;
}

export interface UserObject {
    avatar_url: string;
    days_off: any[]; // You might want to specify a more precise type
    email_corporate: string;
    first_name: string;
    half_day?: boolean;
    full_name: string;
    id: number;
    last_name: string;
}

export interface Period {
    name: string
}


export const Options: Period[] = [
    {name: 'Month'},
    {name: 'Week'}
];

export interface DataGroupItem {
    id: number;
    title: JSX.Element;

}

export interface IKeys {
    groupIdKey: string;
    groupTitleKey: string;
    groupRightTitleKey: string;
    itemIdKey: string;
    itemTitleKey: string;
    itemDivTitleKey: any;
    itemGroupKey: string;
    itemTimeStartKey: string;
    itemTimeEndKey: string;
    groupLabelKey: string;
}
export interface IPropsModal {
    isOpen: boolean;
    handleShowModal: () => void;
    handleDelete?: (id: any) => void;
    handleDeleteHoliday?: (id: any) => void;
    handleSubmit?: () => void;
    title?: string;
    data?: any;
    userInfo?: any;
    subTitle?: ReactNode;
    leave?: boolean
    deleteModal?: boolean
    handleEditLeave?: any
}

export const ModalInfo = {
    open: false,
    info: null,
    user: null
}
