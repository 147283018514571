import styles from './Equipment.module.css';
import Typography from '../ui/Typography';
import StageCard from '../ui/StageCard';
import { EquipmentProps } from './EquipmentProps';
import CommentView from '../CommentView/CommentView';
import moment from 'moment';
import { FC } from 'react';
import { IEquipmentsItem } from '../../services/hrApi/types';

interface IEquipment {
  data: IEquipmentsItem;
}
const Equipment: FC<IEquipment> = ({ data }) => {
  const formatYMDToDMY = (date: string) => {
    return moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
  };

  const showPeriod = () => {
    const dateFrom = formatYMDToDMY(data.date_from);
    const dateTo = formatYMDToDMY(data.date_to);
    return dateFrom !== dateTo ? `${dateFrom} → ${dateTo}` : dateFrom;
  };

  const showStatus: (
    from: string,
    to: string
  ) => 'Pending' | 'In Progress' | 'Done' = (from, to) => {
    const fromYMDToDate = (date: string) => {
      return moment(date, 'YYYY-MM-DD').toDate();
    };
    const currentDate = fromYMDToDate(
      moment(new Date()).format('YYYY-MM-DD')
    );
    if (fromYMDToDate(from) > new Date()) return 'Pending';
    if (fromYMDToDate(to) < currentDate) return 'Done';

    return 'In Progress';
  };
  return (
    <div className={`padding16 ${styles.wrapper}`}>
      <Typography
        variant={'body'}
        weight={'semiBold'}
        color={'darkGray'}>
        {data?.name}
      </Typography>
      <Typography
        className={'mTop8'}
        variant={'smallBody'}
        color={'gray'}>
        {showPeriod()}
      </Typography>
      <div className={`row ${styles.statusWrapper}`}>
        <StageCard
          className={`mTop16 center ${styles.stageCardWrapper}`}
          type={showStatus(data.date_from, data.date_to)}
        />
        <div className={`mLeft4 ${styles.accessWrapper}`}>
          <Typography variant={'mediumLabel'}>
            {data.own_type}
          </Typography>
        </div>
      </div>
      <CommentView comment={data?.comment} />
    </div>
  );
};

export default Equipment;
