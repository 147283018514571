import React, { memo } from 'react';
import clsx from 'clsx';

import { MenuItemProps } from './MenuItem.model';

import classes from './MenuItem.module.css';

const MenuItem = ({ color = 'muted', active, hover, className, component, children, ...props }: MenuItemProps) => {
  const Component = component || 'div';
  const classNames = clsx(
    classes.root,
    classes[color],
    className,
    {
      [classes.active]: active,
      [classes.hover]: hover,
    },
  );

  return (
    <Component className={classNames} {...props}>
      {children}
    </Component>
  );
};

export default memo(MenuItem);
