import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './ProfileCardInfo.module.css';
import { ProfileCardPlug } from './ProfileCardPlug/ProfileCardPlug';

interface IProfileCardInfo {
  isCalendarIcon?: boolean;
  title?: string;
  plugTitle: string;
  onClick?: () => void;
  onClickPlug: (e: React.MouseEvent) => void;
  children?: ReactNode;
  component?: ReactNode;
  isDescription?: boolean;
  customTitle?: string;
  customStyles?: string;
  canEdit?: boolean;
  fullWidth?: boolean;
  isAssignIcon?: boolean;
  disableClick?: boolean;
}

export const ProfileCardInfo: FC<IProfileCardInfo> = ({
  isCalendarIcon,
  isAssignIcon,
  title,
  plugTitle,
  onClickPlug,
    canEdit,
  children,
  component,
  isDescription,
  customTitle,
  customStyles,
  fullWidth,
  disableClick,
}) => {
  const containerStyles = clsx(
    styles.container,
    isDescription && styles.column,
    disableClick && styles.disableClick,
    customStyles
  );

  const titleStyles = clsx(styles.title, customTitle);

  const containerChildren = clsx(
    styles.relative,
    fullWidth && styles.fullWidth
  );

  return (
    <div className={containerStyles}>
      {title && <div className={titleStyles}>{title}</div>}
      <div className={containerChildren}>
        {component ? (
          component
        ) : !isDescription ? (
          <ProfileCardPlug
            isCalendar={isCalendarIcon}
            isAssign={isAssignIcon}
            onClick={onClickPlug}
            canEdit={canEdit}
            title={plugTitle}
          />
        ) : (
          <div onClick={onClickPlug} className={styles.description}>
            Add Description
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
