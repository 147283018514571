import React, {useState} from 'react';
import styles from '../BenchPage.module.css';
import Button from '../../ui/Button';
import FormikTextArea from '../../ui/FormikTextArea';
import {benchApi} from '../../../services/benchApi';
import DatePicker from '../../ui/DatePicker';
import moment from 'moment';
import clsx from 'clsx';
import {IBenchModal, IModalData} from '../types';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopRight} from '../../ui/Snackbar/constants';


export const BenchModal: React.FC<IBenchModal> = ({
                                                      userData,
                                                      activeStatus,
                                                      open,
                                                      fetchHistoryData,
                                                      fetchData,
                                                      setOpen,
                                                  }) => {
        const [modalData, setModalData] = useState<IModalData>({comment: '', date_to: new Date(activeStatus?.date_to)});
        const handleMove = async () => {
            try {
                if (!userData || !activeStatus || !open || !modalData.date_to) {
                    const response = await benchApi.createProposal({
                        user_id: userData.id,
                        type: 'Bench-50',
                        comment: modalData.comment,
                    });
                    if (response) enqueueSnackbar(`${userData.full_name} moved to Bench-50`, {
                        anchorOrigin: snackbarPosTopRight,
                        variant: 'success',
                    });
                    if (response) {
                        fetchData?.();
                        fetchHistoryData?.();
                    }
                } else {
                    let res;
                    switch (open.type) {
                        case 'move':
                            res = await benchApi.createProposal({
                                user_id: userData.id,
                                type: (activeStatus?.type === 'Bench-50' ? (activeStatus?.action === 'Removed' ? 'Bench-50' : 'Bench-0') : 'Bench-50'),
                                comment: modalData.comment,
                            });
                           if (res) enqueueSnackbar(`${userData.full_name} moved to ${(activeStatus.type === 'Bench-50' && activeStatus.action !== 'Removed') ? 'Bench-0' : 'Bench-50'}`, {
                                anchorOrigin: snackbarPosTopRight,
                                variant: 'success',
                            });
                            break;
                        case 'end':
                            res = await benchApi.deleteBench({
                                user_id: userData.id,
                                comment: modalData.comment,
                            });
                            if (res) enqueueSnackbar(`${userData.full_name} removed from ${activeStatus.type}`, {
                                anchorOrigin: snackbarPosTopRight,
                                variant: 'success',
                            });
                            break;
                        case 'prolong':
                            res = await benchApi.prolongBench({
                                user_id: userData.id,
                                comment: modalData.comment,
                                date_to: moment(modalData.date_to).format('YYYY-MM-DD'),
                            });
                            if (res) enqueueSnackbar(`Prolonged ${activeStatus.type} for ${userData.full_name}`, {
                                anchorOrigin: snackbarPosTopRight,
                                variant: 'success',
                            });
                            break;
                        default:
                            break;
                    }

                    if (res){
                        fetchData?.()
                        fetchHistoryData?.()
                    }
                }

                setOpen(null);
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };



        const getTypeText = () => {
            if (!open || !userData || !activeStatus) {
                switch (open?.type) {
                    case 'move':
                        return 'Move to Bench-50'
                }
            } else {
                switch (open.type) {
                    case 'move':
                        return `Move to ${activeStatus?.type === 'Bench-50' ? (activeStatus?.action === 'Removed' ? 'Bench-50' : 'Bench-0') : 'Bench-50'}`;
                    case 'end':
                        return 'End Bench';
                    case 'prolong':
                        return `Prolong ${activeStatus.type}`;
                    default:
                        return '';
                }
            }

        };

        const getSubtitle = () => {
            if (!open || !userData || !activeStatus) {
                switch (open?.type) {
                    case 'move':
                        return `Are you sure you want to move ${userData.full_name} to Bench-50 effective today?`;
                    default:
                        return '';
                }
            } else {
                switch (open.type) {
                    case 'move':
                        return `Are you sure you want to move ${userData.full_name} to ${activeStatus?.type === 'Bench-50' ? (activeStatus?.action === 'Removed' ? 'Bench-50' : 'Bench-0') : 'Bench-50'} effective today?`;
                    case 'end':
                        return `Are you sure you want to remove ${userData.full_name} from Bench effective today?`;
                    case 'prolong':
                        return `After confirming this action, ${userData.full_name} ${
                            activeStatus.type
                        } status will be prolonged until the date you specify below`;
                    default:
                        return '';
                }
            }
        };

        return (
            <div className={clsx(styles.benchModal, open?.type === 'prolong' && styles.benchModalProlong)}>
                <div className={styles.title}>{getTypeText()}</div>
                <div className={styles.subtitle}>{getSubtitle()}</div>
                {open?.type === 'prolong' &&
                  <div className={styles.datePicker}>
                    <DatePicker
                      dateProp={modalData.date_to}
                      onChange={(date: any) => setModalData({...modalData, date_to: date || null})}
                      label='Prolong to'
                      formatDate={'DD MMM YYYY'}
                      isInput
                      isWeekend
                      placeholder='Choose prolong to'
                      minDate={new Date(activeStatus?.date_to)}
                    />
                  </div>}
                <div className={styles.comment}>
                    <span>Comment</span>
                    <FormikTextArea
                        placeholder='Comment for action'
                        rows={5}
                        value={modalData.comment}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            setModalData({...modalData, comment: e.target.value})
                        }
                    />
                </div>
                <div className={styles.buttons}>
                    <Button color='blackBorder' onClick={() => setOpen(null)}>
                        Cancel
                    </Button>
                    <Button color='black' onClick={handleMove}>
                        Confirm
                    </Button>
                </div>
            </div>
        )
            ;
    }
;
