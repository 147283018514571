import { FC } from 'react';
import { Formik } from 'formik';

import styles from './LinksModal.module.css';
import Input from '../../../../../../ui/FormikInput/FormikInput';
import { CustomSelect } from '../../../../../../ui/CustomSelect/CustomSelect';
import FormikTextArea from '../../../../../../ui/FormikTextArea';
import Button from '../../../../../../ui/Button';
import {
  ILinksItem,
  ILinksItemCreate,
} from '../../../../../../../services/projectsApi/types';
import { categoriesLinks } from '../../../../../../../common/values/values';
import { LinksSchema } from './Links.schema';
import { useSelector } from 'react-redux';
import { getProjectProfileSelector } from '../../../../../../../store/project/selectors';
import { projectsApi } from '../../../../../../../services/projectsApi/projectsApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../../../ui/Snackbar/constants';

interface ILinksAdd {
  isEdit?: boolean;
  data?: ILinksItem;
  setRender?: React.Dispatch<React.SetStateAction<number>>;
  handleOnClickItem?: (data: ILinksItem) => void;
  onClose?: () => void;
}
export const LinksAdd: FC<ILinksAdd> = ({
  data,
  isEdit,
  setRender,
  handleOnClickItem,
  onClose,
}) => {
  const profileData = useSelector(getProjectProfileSelector);
  const initialValues = {
    url: data?.url || '',
    name: data?.name || '',
    category: data?.category || '',
    comment: data?.comment || '',
  };
  return (
    <div className={styles.wrapper}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          try {
            if (!isEdit) {
              const obj: ILinksItemCreate = {
                ...values,
                project_id: Number(profileData?.id),
              };
              const res = await projectsApi.postLinks(obj);
              setRender && setRender((prev) => prev + 1);
              handleOnClickItem && handleOnClickItem(res);
              return;
            }
            const obj: ILinksItem = {
              ...values,
              project_link_id: data?.id,
            };
            const res = await projectsApi.editLinks(obj);
            setRender && setRender((prev) => prev + 1);
            handleOnClickItem && handleOnClickItem(res);
          } catch (e) {
            enqueueSnackbar('Error', {
              variant: 'error',
              anchorOrigin: snackbarPosTopRight,
            });
          }
          //   console.log(user);
          //   const obj: ICreateMemberProject = {
          //     project_id: Number(profileData?.id),
          //     user_id: Number(values.user?.id),
          //     position_name: values.positions?.name || '',
          //     seniority_level: isEdit
          //       ? data?.seniority_level
          //       : user?.seniority_level,
          //     // hr_manager_id: 16,
          //     involvement_type: values.involvement_type,
          //     tech_stacks: values.tech_stacks || [],
          //     departments: values.departments || [],
          //   };
          //   try {
          //     if (!isEdit) {
          //       const res = await projectsApi.createProjectMember(obj);
          //       setRender && setRender((prev) => prev + 1);
          //       handleOnClickItem && handleOnClickItem(res);
          //       return;
          //     }
          //     if (!obj.seniority_level) {
          //       const res = await hrApi.getMembersProfile({
          //         user_id: Number(values.user?.id),
          //       });
          //       if (res.seniority_level) {
          //         obj.seniority_level = res.seniority_level;
          //       }
          //     }
          //     obj.project_member_id = data?.id;
          //     const res = await projectsApi.editProjectMember(obj);
          //     setRender && setRender((prev) => prev + 1);
          //     handleOnClickItem && handleOnClickItem(res);
          //   } catch (e) {
          //     enqueueSnackbar('Error', {
          //       variant: 'error',
          //       anchorOrigin: snackbarPosTopRight,
          //     });
          //   }
        }}
        validationSchema={LinksSchema}
        validateOnMount
        enableReinitialize
        isInitialValid={false}>
        {({
          values,
          setFieldValue,
          errors,
          submitForm,
          submitCount,
          isSubmitting,
          isValid,
        }) => {
          return (
            <>
              <div className={styles.container}>
                <Input
                  value={values.url}
                  onChange={(e) =>
                    setFieldValue('url', e.target.value)
                  }
                  placeholder='Enter Link'
                  label='Link'
                  error={errors.url as string}
                  wasRequest={Boolean(submitCount)}
                />
                <Input
                  value={values.name}
                  onChange={(e) =>
                    setFieldValue('name', e.target.value)
                  }
                  placeholder='Enter Link Name'
                  label='Link Name'
                  error={errors.name as string}
                  wasRequest={Boolean(submitCount)}
                />
                <CustomSelect
                  placeholder='Enter Category'
                  label='Category'
                  detailedInput
                  options={categoriesLinks}
                  value={
                    values.category
                      ? { name: values.category }
                      : undefined
                  }
                  defaultOptions={
                    values.category
                      ? [{ name: values.category }]
                      : undefined
                  }
                  limitedViewOption
                  onChangeValue={(e) =>
                    setFieldValue('category', e.name)
                  }
                  error={errors.category as string}
                  wasRequest={Boolean(submitCount)}
                />
                <FormikTextArea
                  label='Comment'
                  value={values.comment}
                  onChange={(e) =>
                    setFieldValue('comment', e.target.value)
                  }
                  placeholder='Enter comment'
                  error={errors.comment as string}
                  wasRequest={Boolean(submitCount)}
                />
              </div>
              <div className={styles.footer}>
                <Button
                  variant='outlined'
                  color='black'
                  onClick={onClose}
                  className={styles.editBtn}>
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  color='black'>
                  Save Changes
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
