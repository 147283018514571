import clsx from 'clsx';
import {lazy, useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';
import {useMenu} from '../../../hooks/useMenu';
import {useModal} from '../../../hooks/useModal';
import {authApi} from '../../../services/authApi';
import {logoutFinish} from '../../../store/auth/reducer';
import {
    useGetPositionsQuery,
    useGetProfileQuery,
} from '../../../store/profile/profileApi';
import {
    getCanEditSelector,
    getProfileSelector,
} from '../../../store/profile/selectors';
import AsideItem from './AsideItem';
import Avatar from '../../Avatar';
import IconButton from '../../ui/IconButton';
import Menu from '../../ui/Menu';
import Stack from '../../ui/Stack';
import {ReactComponent as ChevronLeftIcon} from '../../../assets/icons/chevron-left.svg';
import {ReactComponent as ChevronRightIcon} from '../../../assets/icons/chevron-right.svg';
import {ReactComponent as Logo} from '../../../assets/image/logo.svg';
import {ReactComponent as LogoSm} from '../../../assets/image/logo-sm.svg';
import {ReactComponent as LogoutIcon} from '../../../assets/icons/logout.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/user.svg';
import {
    asideNavList,
    asideNavListUser,
} from './Aside.constants';
import styles from './Aside.module.css';
import {ActionMenuItem} from '../../ActionMenu/ActionMenu';
import {useGetPositionsQuery as useGPQ} from '../../../store/app/appApi';
import {AsideItemDrop} from './AsideItemDrop/AsideItemDrop';
import routes from '../../../constants/routes';

const ProfilePage = lazy(
    () => import('../../sharedPages/ProfilePage')
);

const Aside = () => {
    const navigate = useNavigate();
    useGetProfileQuery({}, {refetchOnMountOrArgChange: true});
    useGetPositionsQuery({});
    useGPQ({});
    const canEdit = useSelector(getCanEditSelector);
    const dispatch = useDispatch();
    const user = useSelector(getProfileSelector);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
    const [isMenuOverlayOpen, setIsMenuOverlayOpen] =
        useState<boolean>(false);
    const {isOpen, anchorEl, handleMenuClose, handleMenuOpen} =
        useMenu();
    const {isOpen: isOpenProfile, close: handleCloseProfile} =
        useModal();
    // TODO remove this after fix menu position styles
    const footer = useRef<HTMLDivElement>(null);
    // const [openDrop, setOpenDrop] = useState<boolean>(false);
    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleOnMenuOver = useCallback(() => {
        if (!isMenuOverlayOpen) {
            setIsMenuOverlayOpen(true);
        }
    }, [isMenuOverlayOpen]);
    const handleOnMenuLeave = useCallback(() => {
        if (isMenuOverlayOpen) {
            setIsMenuOverlayOpen(false);
        }
    }, [isMenuOverlayOpen]);

    const handleLogout = useCallback(() => {
        authApi.logout().then(() => dispatch(logoutFinish()));
    }, []);
    const handleOpenProfileModal = () => {
        handleMenuClose();
        if (canEdit) {
            navigate(routes.employeeProfile + `/${user?.id}`);
        } else {
            navigate(routes.myProfile);
        }
    };

    return (
        <nav
            className={clsx(styles.root, {
                [styles.small]: !isMenuOpen,
            })}>
            <div
                className={clsx(styles.inner, {
                    [styles.innerOpen]: isMenuOverlayOpen,
                })}
                onMouseOver={handleOnMenuOver}
                onMouseLeave={handleOnMenuLeave}>
                <div className={styles.header}>
                    {isMenuOpen || isMenuOverlayOpen ? <Logo/> : <LogoSm/>}
                    <IconButton
                        round
                        className={clsx(styles.menuButton, {
                            [styles.menuButtonTranslate]: isMenuOverlayOpen,
                        })}
                        size='small'
                        icon={ChevronLeftIcon}
                        onClick={handleToggleMenu}
                    />
                </div>
                {canEdit ? (
                    <div className={styles.body}>
                        {asideNavList.map((item, index) => (
                            <div key={index}>
                                {item.type === 'link' ? (
                                    <AsideItem
                                        // onClick={() => setOpenDrop(false)}
                                        key={item.label + index}
                                        component={NavLink}
                                        to={item.to}
                                        isMenuOpen={isMenuOpen}
                                        icon={item.icon}
                                        label={item.label}
                                        className={clsx({
                                            [styles.smallMenuItem]:
                                            !isMenuOpen && !isMenuOverlayOpen,
                                        })}
                                    />
                                ) : item.type === 'drop' && item.submenu ? (
                                    <AsideItemDrop
                                        isMenuOpen={isMenuOpen}
                                        isMenuOverlayOpen={isMenuOverlayOpen}
                                        item={item}
                                    />
                                ) : (
                                    <div className={styles.divide}/>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={styles.body}>
                        {asideNavListUser.map((item, index) => (
                            <div key={index}>
                                {item.type === 'link' ? (
                                    <AsideItem
                                        // onClick={() => setOpenDrop(false)}
                                        key={item.label + index}
                                        component={NavLink}
                                        to={item.to}
                                        isMenuOpen={isMenuOpen}
                                        icon={item.icon}
                                        label={item.label}
                                        className={clsx({
                                            [styles.smallMenuItem]:
                                            !isMenuOpen && !isMenuOverlayOpen,
                                        })}
                                    />
                                ) : item.type === 'drop' && item.submenu ? (
                                    <AsideItemDrop
                                        isMenuOpen={isMenuOpen}
                                        isMenuOverlayOpen={isMenuOverlayOpen}
                                        item={item}
                                    />
                                ) : (
                                    <div className={styles.divide}/>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                <div className={styles.footer} ref={footer}>
                    <AsideItem
                        className={clsx(styles.profileMenuItem, {
                            [styles.smallMenuItem]:
                            !isMenuOpen && !isMenuOverlayOpen,
                        })}
                        onClick={handleMenuOpen}>
                        <Stack space={1} align='center'>
                            {user && (
                                <Avatar
                                    image={user?.avatar_url}
                                    name={`${user?.first_name} ${user?.last_name}`}
                                />
                            )}

                              <ChevronRightIcon
                                className={styles.footerIcon}
                                width={14}
                                height={14}
                              />
                        </Stack>
                    </AsideItem>
                    <Menu
                        node={footer?.current}
                        position='bottomRight'
                        isOpen={isOpen}
                        anchorEl={anchorEl}
                        className={styles.footerMenu}
                        onClose={handleMenuClose}>
                        <ActionMenuItem
                            name='Profile'
                            onClick={handleOpenProfileModal}
                            icon={<UserIcon className={styles.gray}/>}
                            textColor='dark'
                        />
                        <ActionMenuItem
                            name='Log Out'
                            onClick={handleLogout}
                            icon={<LogoutIcon className={styles.pink}/>}
                            lastElement
                            textColor='dark'
                        />
                    </Menu>
                </div>
            </div>
            <ProfilePage
                isOpen={isOpenProfile}
                onClose={handleCloseProfile}
            />
        </nav>
    );
};

export default Aside;
