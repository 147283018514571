import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../../constants/routes';
import { useAppDispatch } from '../../../store';
import {
  getFilters,
  getList,
  totalByStatuses as tbS,
} from '../../../store/project/thunks';
import { searchParamsToObject } from '../../../helpers/object/searchParams-to-object.helper';
import { useSelector } from 'react-redux';
import { getProjectsSelector } from '../../../store/project/selectors';
import { IProjectFilters } from '../../../services/projectsApi/types';
import { useDebounce } from '../../../hooks/useDebounce';

export const useTable = (
  searchParams: URLSearchParams,
  setSearchParams: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >
) => {
  const params = searchParamsToObject(searchParams);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { list, listIsEmpty, listIsLoading, totalByStatuses } =
    useSelector(getProjectsSelector);

  const defaultFilter = () => {
    const newObject: IProjectFilters = {
      product_industries: params.product_industries || [],
      project_services: params.project_services || [],
      manager_ids: params.manager_ids || [],
      member_ids: params.member_ids || [],
      client_names: [],
    };
    return newObject;
  };

  const [filter, setFilter] = useState<IProjectFilters | undefined>(
    defaultFilter()
  );
  const [searchValue, setSearchValue] = useState('');
  const [rendered, setRendered] = useState(false);

  const debouncedValue = useDebounce<string>(searchValue, 400);

  useEffect(() => {
    rendered &&
      setSearchParams((prev) => {
        prev.delete('page');
        prev.delete('project_statuses');
        const params = searchParamsToObject(prev);
        return { page: 1, ...params, ...filter };
      });
  }, [filter]);

  const listParams = (
    pageOne?: boolean,
    removeStatuses?: boolean
  ) => {
    if (removeStatuses) delete params.project_statuses;
    return {
      ...params,
      size: 10,
      page: pageOne
        ? 1
        : Number(params.page)
        ? Number(params.page)
        : 1,
      search: debouncedValue,
    };
  };
  const getData = (pageOne?: boolean, getTotal?: boolean) => {
    dispatch(getList(listParams(pageOne)));
    dispatch(tbS(listParams(getTotal, true)));
  };

  useEffect(() => {
    getData(undefined, true);
    if (!rendered) setRendered(true);
  }, [JSON.stringify(params)]);

  useEffect(() => {
    if (rendered) getData(true);
  }, [debouncedValue]);

  useEffect(() => {
    dispatch(getFilters());
  }, []);

  const handleNavigateToCreateProject = () => {
    navigate(routes.projectsCreate);
  };

  return {
    handleNavigateToCreateProject,
    filter,
    setFilter,
    searchValue,
    setSearchValue,
    data: list,
    listIsEmpty,
    listIsLoading,
    total: totalByStatuses,
    listParams,
    params,
  };
};
