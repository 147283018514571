import { FC } from 'react';
import styles from './ContactsModal.module.css';

import Modal from '../../../../../../ui/Modal/Modal';
import { ContactsWrapperCRUD } from './ContactsWrapperCRUD';

interface IContactsModal {
  isOpen: boolean;
  onClose: () => void;
}

export const ContactsModal: FC<IContactsModal> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      className={styles.modal}
      classNameOverlay={styles.overlay}
      isOpen={isOpen}
      onClose={onClose}>
      <ContactsWrapperCRUD onClose={onClose} />
    </Modal>
  );
};
