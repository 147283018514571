import { useEffect, useState } from 'react';

import { TimeSheetUser } from '../../../../services/timeTrackingApi/types';
import { timeTrackingApi } from '../../../../services/timeTrackingApi/timeTrackingApi';
import { ITimeTrackingFilter } from '../../../../pages/TimeTracking/TimeTracking.types';
import { useConvertingDate } from './useConvertingDate';

export const useTimeSheet = (filters: ITimeTrackingFilter) => {
  const [list, setList] = useState<TimeSheetUser[]>([]);
  const [loading, setLoading] = useState(true);

  const [sortedDate, setSortedDate] = useState<any>();

  const [sortBy, setSortBy] = useState(filters.show_by);

  const { convertingDate, parseHeadCellDate } =
    useConvertingDate(filters);
  const fetchList = async () => {
    try {
      const obj = {
        date_from: filters?.date_from,
        date_to: filters?.date_to,
        project_ids:
            typeof filters?.projects === 'string'
                ? [Number(filters.projects)]
                : filters?.projects?.map((e) => Number(e)),
        user_ids:
            typeof filters?.members === 'string'
                ? [Number(filters.members)]
                : filters?.members?.map((e) => Number(e)),
        search: filters?.search,
      };
      if (
          !filters?.show_by ||
          filters?.show_by === 'Show by Project'
      ) {
        const res = await timeTrackingApi.getTimeSheetProjects(obj);
        setList(res);
        setSortedDate(convertingDate(res));

      } else {
        const res = await timeTrackingApi.getTimeSheetUsers(obj);
        setList(res);
        setSortedDate(convertingDate(res));

      }
      setLoading(false);

    }
   catch (e) {
      if (sortBy !== filters?.show_by) {
        setSortBy(filters?.show_by);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return { list, sortedDate, parseHeadCellDate, loading };
};
