import { FC, useState } from 'react';
import Table from '../../../../components/ui/Table';
import TableRow from '../../../../components/ui/TableRow';
import TableCell from '../../../../components/ui/TableCell';
import Typography from '../../../../components/ui/Typography';
import PropositionType from '../../../../components/PropositionType';
import PropositionStatus from '../../../../components/PropositionStatus';
import { useSelector } from 'react-redux';
import { getPropositionsSelector } from '../../../../store/propositions/selectors';
import classes from './RequestsTable.module.css';
import moment from 'moment/moment';
import { ReactComponent as NoPropositions } from '../../../../assets/image/Property 1=Puzzle.svg';
import Drawer from '../../../../components/ui/Drawer/Drawer';
import { ReactComponent as ChartLine } from '../../../../assets/icons/chart-line.svg';
import { SupportInformationDrawer } from '../../../../components/Support/DrawerSupportInformation/SupportInformation';
import { TableRows } from './TableRow';
import TablePagination from '../../../../components/ui/TablePagination';
import Default from '../../../../components/Default';
const PROPOSITION_TYPE = {
  all: '',
  proposition: 'Proposition',
  complaint: 'Complaint',
};

interface IRequestsTable {
  searchParams: URLSearchParams;
  setSearchParams: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >;
  selectedType?: string;
  isFetching: boolean;
  refetch: () => void;
}
const RequestsTable: FC<IRequestsTable> = ({
  selectedType,
  isFetching,
  refetch,
  searchParams,
  setSearchParams,
}) => {
  const propositions = useSelector(getPropositionsSelector);

  const [showDrawer, setShowDrawer] = useState(false);
  const onCloseDrawer = () => {
    setShowDrawer((prev) => !prev);
  };
  const onShowDrawer = (item: any) => {
    setShowDrawer(true);
    setDrawerInfo(item);
  };
  const [drawerInfo, setDrawerInfo] = useState(undefined);

  return (
    <div>
      {Object.keys(propositions.items).length == 0 ? (
        <div className={classes.noInformation}>
          <NoPropositions />
          {selectedType === PROPOSITION_TYPE.proposition ? (
            <Typography variant={'subHeading1'}>
              No Propositions from employees for now
            </Typography>
          ) : (
            <Typography variant={'subHeading1'}>
              No Complaints from employees for now
            </Typography>
          )}
        </div>
      ) : (
        <>
          {isFetching ? (
            <Default />
          ) : (
            <>
              <Table>
                <tbody>
                  <TableRows />
                  {propositions.items.map(
                    (item: any, index: number) => {
                      return (
                        <TableRow
                          onClick={() => onShowDrawer(item)}
                          key={index}
                          className={`${classes.tableBordered} ${
                            index === 0 && classes.firstTableBorder
                          }`}>
                          <TableCell
                            columnClass={classes.tableBordered}>
                            <Typography
                              variant={'largeLabel'}
                              color={'inherit'}>
                              {moment
                                .utc(item.created_at)
                                .add(2, 'hours')
                                .format('D MMMM YYYY')
                                .toString()}
                            </Typography>
                            <Typography
                              variant={'largeLabel'}
                              color={'muted'}>
                              {moment
                                .utc(item.created_at)
                                .add(2, 'hours')
                                .format('HH:mm')
                                .toString()}
                            </Typography>
                          </TableCell>
                          <TableCell
                            columnClass={classes.tableBorderedLeft}>
                            <PropositionType
                              type={
                                item.proposition_type || 'No data'
                              }
                            />
                          </TableCell>
                          <TableCell
                            columnClass={classes.tableBorderedRight}
                            className={classes.tableCell}>
                            <Typography
                              className={'textBlack600'}
                              variant={'smallBody'}>
                              {item.text}
                            </Typography>
                          </TableCell>
                          <TableCell
                            columnClass={classes.tableBordered}>
                            <PropositionStatus
                              type={item.proposition_status}
                              handleMenuOpen={() => {
                                console.log('item');
                              }}
                            />
                          </TableCell>
                          <TableCell
                            columnClass={classes.tableBorderedFont}>
                            <span className={classes.nameFrom}>{`${
                              item.user_first_name
                            } ${item.user_last_name || ''}`}</span>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </tbody>
              </Table>
              <TablePagination
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                currentStartPage={propositions?.page}
                count={propositions?.total}
                itemsPerPage={propositions?.size}
              />
            </>
          )}
        </>
      )}
      <Drawer
        headerIcon={<ChartLine />}
        anchor='right'
        open={showDrawer}
        onClose={onCloseDrawer}
        onlyBackButton>
        <SupportInformationDrawer
          item={drawerInfo}
          refetch={refetch}
          onClose={onCloseDrawer}
        />
      </Drawer>
    </div>
  );
};

export default RequestsTable;
