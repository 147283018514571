import React, {FC, useEffect, useState} from 'react';
import moment from 'moment/moment';
import {Formik} from 'formik';
import clsx from 'clsx';
import CheckBox from '../../../components/ui/Checkbox';
import Typography from '../../../components/ui/Typography';
import FormControl from '../../../components/ui/FormControl';
import Button from '../../../components/ui/Button';
import FileUploader from '../../../components/FileUploader/FileUploader';
import DateRangePicker from '../../../components/ui/DateRangePicker/DateRangePicker';
import styles from './MedicalLeaveDrawerContent.module.css';
import {PaperclipIcon} from '../../../assets/icons/components';
import {hrApi} from '../../../services/hrApi/hrApi';
import FormikTextArea from '../../../components/ui/FormikTextArea/FormikTextArea';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopLeft} from '../../../components/ui/Snackbar/constants';
import {jsonToFormData} from '../../../helpers/formData/jsonToFormData.helper';
import {medicalLeaveError} from './MedicalLeaveError';
import {IMedicalLeaveDrawerContent, IState, optionLeave} from './types';
import {IDayOffListItem} from '../../../services/hrApi/types';
import {CustomSelect} from '../../../components/ui/CustomSelect/CustomSelect';
import {useSelector} from 'react-redux';
import {getCanEditSelector} from '../../../store/profile/selectors';
import {requestApi} from '../../../services/request/requestApi';

const MedicalLeaveDrawerContent: FC<IMedicalLeaveDrawerContent> = ({
                                                                       type,
                                                                       isEdit,
                                                                       userId,
                                                                       data,
                                                                       updateTable,
                                                                       handleClose,
                                                                       setTypeLeave,
                                                                       setId,
                                                                       members,
                                                                       used,
                                                                       total,
                                                                       leave,
                                                                   }) => {
    const [state, setState] = useState<IState>({
        types: null,
        person: null,
    });
    const currentYear: number = moment().year();

    const canEdit = useSelector(getCanEditSelector);
    const [deleteFiles, setDeleteFiles] = useState([]);
    const [shopUploader, setShowUploader] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File[]>([]);
    const isSickLeave = (location.pathname.includes('sick-leave') || state?.types?.name === 'Sick Leave' || data?.day_off_type === 'Sick Leave');
    const onAttachmentsClick = () => {
        setShowUploader((prev) => !prev);
    };

    useEffect(() => {
        if (leave) {
            setId(state.person?.id);
            setTypeLeave(state.types?.name);
        }
    }, [state.person, state.types]);

    const initialValues = {
        user_id: leave ? state.person?.id : Number(userId),
        is_self_paid: data?.is_self_paid || false,
        half_day: data?.half_day || false,
        date_from: data?.date_from || undefined,
        date_to: data?.date_from !== data?.date_to ? data?.date_to : undefined,
        comment: data?.comment || '',
        day_off_type: leave ? state.types?.name : type,
        request_type: leave ? state.types?.name : type,
        documents: isEdit
            ? ((data?.documents ||
                (Array.isArray(selectedFile) && selectedFile.length > 0
                    ? selectedFile
                    : undefined)) as File | File[] | undefined)
            : undefined,
    };

    useEffect(() => {
        if (initialValues.documents) {
            setShowUploader(true)
            const files = Array.isArray(initialValues.documents)
                ? initialValues.documents
                : [initialValues.documents];
            setSelectedFile(files);
        } else {
            setSelectedFile([])
        }
    }, [initialValues.documents, handleClose]);
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values, formikHelpers) => {
                const object: any = {...values};
                object.date_from = moment(object.date_from).format('YYYY-MM-DD');
                object.date_to = object.date_to
                    ? moment(object.date_to).format('YYYY-MM-DD')
                    : object.date_from;
                object.documents = selectedFile;

                const afterExecution = () => {
                    updateTable();
                    handleClose();
                    formikHelpers.resetForm();
                };

                const fetchCreate = async () => {
                    const formData = jsonToFormData(object);
                    const apiFunction = canEdit ? hrApi.createDayOff : requestApi.postRequest;
                    try {
                        await apiFunction(formData);
                        setState({person: null, types: null});
                        enqueueSnackbar('Successfully created', {
                            variant: 'success',
                            anchorOrigin: snackbarPosTopLeft,
                        });
                        afterExecution();
                    } catch (e: any) {
                        enqueueSnackbar(e.response?.data?.detail || 'Error occurred', {
                            variant: 'error',
                            anchorOrigin: snackbarPosTopLeft,
                        });
                        return 'error';
                    }
                };

                const fetchEdit = async () => {
                    try {
                        const formData = jsonToFormData({
                            ...object,
                            id: data?.id,
                            documents_ids_to_delete: Array.isArray(deleteFiles) ? deleteFiles : [deleteFiles],
                        });
                        await hrApi.editDayOff(formData);
                        setState((prev) => ({...prev, deleteFiles: []}));
                        enqueueSnackbar('Successfully edited', {
                            variant: 'success',
                            anchorOrigin: snackbarPosTopLeft,
                        });
                        afterExecution();
                    } catch (e: any) {
                        enqueueSnackbar(e.response.data.detail, {
                            variant: 'error',
                            anchorOrigin: snackbarPosTopLeft,
                        });
                        return 'error';
                    }
                };

                isEdit ? await fetchEdit() : await fetchCreate();
            }}
            validationSchema={medicalLeaveError}
            enableReinitialize
            validateOnMount
        >
            {({
                  // isValid,
                  values,
                  setFieldValue,
                  errors,
                  submitForm,
                  submitCount,
                  // isSubmitting,
              }) => {
                const handleOnChangeDateRange = async (e: Date[]) => {
                    await setFieldValue('date_from', e[0]);
                    await setFieldValue('date_to', e[1] || e[0]);
                };

                const showDuration = (data: IDayOffListItem) => {
                    return moment(data.date_to).diff(data.date_from, 'days') + 1;
                };

                const usedDays = () => {
                    const startDate = moment(values.date_from);
                    const endDate = moment(values.date_to || values.date_from);
                    let totalDays = endDate.diff(startDate, 'days') + 1;
                    const weekendDays = countWeekendDays(startDate, endDate);
                    if (data) {
                        totalDays -= Math.min(weekendDays, totalDays); // Subtract minimum of weekendDays and totalDays
                        totalDays -= showDuration(data); // Subtract the days in the specified range
                    }
                    return totalDays - weekendDays;
                };

                const countWeekendDays = (startDate: any, endDate: any) => {
                    let weekendDays = 0;
                    for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, 'days')) {
                        const dayOfWeek = date.day();
                        if (dayOfWeek === 0 || dayOfWeek === 6) {
                            weekendDays++;
                        }
                    }
                    return weekendDays;
                };

                const yearFromDate: number = moment(values.date_from).year();
                const yearToDate: number = moment(values.date_to).year();
                const requestOnNextYear: boolean = currentYear === yearFromDate && currentYear === yearToDate;

                const counterStyles = clsx(
                    styles.counter,
                    {[styles.showCounter]: values.date_from && values.date_to},
                    {[styles.counterError]: (used && total) && used + usedDays() > total},
                    {[styles.counterError]: usedDays() > (total || 0)}
                );

                return (
                    <div className={styles.wrapper}>
                        <div className={styles.scroll}>
                            {leave &&
                              <>
                                <div>
                                  <CustomSelect
                                    pointer={true}
                                    optionImage
                                    value={state.types || ''}
                                    onChangeValue={(e) => setState((prev) => ({...prev, types: e ?? null}))}
                                    options={optionLeave}
                                    label="Type"
                                    placeholder="Choose type"
                                  />
                                </div>
                                <div>
                                  <CustomSelect
                                    multiSearchPlaceholder='Type to search'
                                    optionImage
                                    value={state.person || ''}
                                    onChangeValue={(e) => setState((prev) => ({...prev, person: e ?? null}))}
                                    options={members}
                                    label="Member"
                                    placeholder="Choose Member"
                                    className={styles.membersOption}
                                  /></div>
                              </>}
                            <div className={styles.checkBox}>
                                <CheckBox
                                    onClick={() => setFieldValue('is_self_paid', !values.is_self_paid)}
                                    checked={values.is_self_paid}
                                    label={<Typography variant={'smallText'} className={styles.checkBoxWrapperText}>
                                        Self Paid
                                    </Typography>}/>

                                <CheckBox
                                    onClick={() => setFieldValue('half_day', !values.half_day)}
                                    disabled={values.date_from !== values.date_to || values.date_from === undefined}
                                    checked={values.half_day}
                                    label={<Typography variant={'smallText'} className={styles.checkBoxWrapperText}>
                                        Half-day
                                    </Typography>}/>
                            </div>
                            <DateRangePicker
                                startDate={values.date_from}
                                endDate={values.date_to}
                                isInput
                                isWeekend={true}
                                disabled={leave ? !(state.person && state.types) : false}
                                onChange={handleOnChangeDateRange}
                                label="Period"
                                placeholder="Enter period"
                                error={errors.date_to}
                                wasRequest={Boolean(submitCount)}
                            />
                            {(!values.is_self_paid && requestOnNextYear) && (
                                <div className={counterStyles}>
                                    {used ? used + usedDays() : usedDays()}/{total || 0} used
                                </div>
                            )}
                            <FormikTextArea
                                className={styles.mBottom24}
                                label={'Comment'}
                                placeholder={'Type here...'}
                                value={values.comment}
                                onChange={(e) => setFieldValue('comment', e.target.value)}
                            />
                            {isSickLeave &&
                              <>
                                  {!shopUploader ? (
                                      <FormControl>
                                          <Button
                                              className={styles.paperclipWrapper}
                                              onClick={onAttachmentsClick}
                                              fullWidth
                                          >
                                              <PaperclipIcon size={'20'}/>
                                              <Typography className={'mLeft8'} variant={'textLabel'}>
                                                  Add Attachments
                                              </Typography>
                                          </Button>
                                      </FormControl>
                                  ) : (
                                      <FileUploader
                                          deleteFiles={deleteFiles}
                                          setDeleteFiles={setDeleteFiles}
                                          selectedFile={selectedFile}
                                          setSelectedFile={setSelectedFile}
                                      />
                                  )}
                              </>
                            }
                        </div>
                        <div className={styles.containerSaveBtn}>
                            <Button
                                onClick={async (e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    submitForm();
                                }}
                                disabled={!(values?.date_from)}
                                fullWidth
                                color="black"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default MedicalLeaveDrawerContent;
