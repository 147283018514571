import EmployeeLeave from '../../components/EmployeeLeave/EmployeeLeave'
import { ReactComponent as ArchiveIcon } from '../../assets/icons/archive.svg'
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg'

const DaysOff = () => {
  const actionMenuOptions = [
    {
      icon: <PencilIcon />,
      name: 'Edit Account',
      onClick: handleEditAccount,
    },
    {
      icon: <ArchiveIcon />,
      name: 'Archive Account',
      onClick: handleEditAccount,
    },
    {
      icon: <TrashIcon />,
      name: 'Delete Account',
      onClick: handleEditAccount,
    },
  ]

  function handleEditAccount() {
    //
  }

  return(
    <EmployeeLeave
      actionMenuOptions={actionMenuOptions}
      drawerSaveClick={() => ''}
      type={'daysOff'}
      firstNumber={15}
      secondNumber={20}
    />
  )
}

export default DaysOff
