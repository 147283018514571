import { FC } from 'react';
import clsx from 'clsx';

import styles from './ViewOptionCard.module.css';
import { ReactComponent as EyeIcon } from '../../../../../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../../../../assets/icons/eye-off.svg';
import { ReactComponent as GripVerticalIcon } from '../../../../../../assets/icons/grip-vertical.svg';

type itemsTypes = {
  id: number;
  text: string;
};
interface IViewOptionCard {
  handleDragStart: (e: React.DragEvent<HTMLLIElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLLIElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLLIElement>) => void;
  handleEnterItem: (e: IViewOptionCard['item']) => void;
  handleLeaveItem: () => void;
  item: itemsTypes;
  isShown?: boolean;
  isDropped: boolean;
}
export const ViewOptionCard: FC<IViewOptionCard> = ({
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleEnterItem,
  handleLeaveItem,
  item,
  isShown,
  isDropped,
}) => {
  return (
    <li
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnter={() => handleEnterItem(item)}
      onDragLeave={handleLeaveItem}
      onDrop={handleDrop}
      className={clsx(
        styles.cardContainer,
        isDropped && styles.drop
      )}>
      <div className={styles.cardInfo}>
        <GripVerticalIcon />
        <p className={styles.cardTitle}>{item.text}</p>
      </div>
      <div className={styles.containerIcon}>
        {isShown ? <EyeIcon width={16} /> : <EyeOffIcon width={16} />}
      </div>
    </li>
  );
};
