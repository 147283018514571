import { useEffect, useState } from 'react';
import { IHRFilters } from '../../../../../services/hrApi/types';
import { useSelector } from 'react-redux';
import { getHRSelector } from '../../../../../store/humanResources/selectors';
import { getPositionsSelector } from '../../../../../store/app/selectors';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { useAppDispatch } from '../../../../../store';
import {
  // archiveMember,
  getList,
  totalByDepartments as tByD,
} from '../../../../../store/humanResources/thunks';
import { searchParamsToObject } from '../../../../../helpers/object/searchParams-to-object.helper';
import { hrApi } from '../../../../../services/hrApi/hrApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../ui/Snackbar/constants';

export const useTable = (
  searchParams: URLSearchParams,
  setSearchParams: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >,
  // handleShowArchiveModal?: () => void
) => {
  const params = searchParamsToObject(searchParams);

  const { list, listIsEmpty, listIsLoading, totalByDepartments } =
    useSelector(getHRSelector);
  const dispatch = useAppDispatch();

  const defaultFilter = () => {
    const newObject: IHRFilters = {
      departments: params.departments || [],
      positions: params.positions || [],
      tech_stacks: params.tech_stacks || [],
      seniority_levels: params.seniority_levels || [],
      forms_of_coops: params.forms_of_coops || [],
    };
    return newObject;
  };

  const [filter, setFilter] = useState<IHRFilters | undefined>(
    defaultFilter()
  );
  const [searchValue, setSearchValue] = useState('');
  const [totalAvailability, setTotalAvailability] = useState<
    undefined | number
  >(undefined);

  const positions = useSelector(getPositionsSelector);

  const debouncedValue = useDebounce<string>(searchValue, 400);

  useEffect(() => {
      setSearchParams((prev) => {
        prev.delete('page');
        prev.delete('department');
        const params = searchParamsToObject(prev);
        return { page: 1, ...params, ...filter };
      });
  }, [filter]);

  const listParams = (pageOne?: boolean) => {
    return {
      ...params,
      size: 10,
      page: pageOne
        ? 1
        : Number(params.page)
        ? Number(params.page)
        : 1,
      search: debouncedValue,
    };
  };

  const handleChangeActivity = async (
    id: number,
    isAvailable: boolean
  ) => {
    setTotalAvailability((prev) => {
      return isAvailable ? (prev || 0) + 1 : (prev || 0) - 1;
    });
    try {
      await hrApi.updateUserAvailability({
        users_ids: [id],
      });
    } catch (e) {
      console.log('c');
    }
  };

  const getTotalAvailability = async () => {
    try {
      const res = await hrApi.isAvailableTotal();
      setTotalAvailability(res.total_users);
    } catch (e) {
      console.log('red');
    }
  };
  const getData = (pageOne?: boolean, getTotal?: boolean) => {
    dispatch(tByD(listParams(getTotal)));
    dispatch(getList(listParams(pageOne)));
    getTotalAvailability();
  };

  useEffect(() => {
    getData(undefined, true);

  }, [searchParams]);

  useEffect(() => {
        getData(true);
  }, [debouncedValue]);

  const handleArchiveUser = async (
    user_id: number,
    is_archived: boolean,
    name?: string
  ) => {
    try {
      const res = await hrApi.archiveMember({ user_id, is_archived });
      enqueueSnackbar(
        name ? name + ' was archived' : 'Archived successfully',
        {
          anchorOrigin: snackbarPosTopRight,
          variant: 'success',
        }
      );
      if (res.msg === 'ok') await getData();
      //   handleShowArchiveModal && (await handleShowArchiveModal());
    } catch (e: any) {
      enqueueSnackbar(e.response.data.detail, {
        anchorOrigin: snackbarPosTopRight,
        variant: 'error',
      });
    }
  };

  const handleRemoveUser = async (
    user_id: number,
    confirm: boolean
  ) => {
    try {
      const res = await hrApi.deleteMember({ user_id, confirm });
      enqueueSnackbar('Deleted successfully', {
        anchorOrigin: snackbarPosTopRight,
        variant: 'success',
      });
      if (res.msg === 'ok') getData();
    } catch (e: any) {
      enqueueSnackbar(e.response.data.detail, {
        anchorOrigin: snackbarPosTopRight,
        variant: 'error',
      });
    }
  };

  return {
    filter,
    setFilter,
    searchValue,
    setSearchValue,
    positions,
    handleArchiveUser,
    handleRemoveUser,
    data: list,
    listIsEmpty,
    listIsLoading,
    total: totalByDepartments,
    listParams,
    totalAvailability,
    handleChangeActivity,
  };
};
