import { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import styles from './DateRangePicker.module.css';
import inputStyles from '../DatePicker/DatePicker.module.css';
import Button from '../Button/Button';
import { useDateRange } from './useDateRange';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { Calendar } from '../Calendar/Calendar';
import InputLabel from '../InputLabel/InputLabel';

import './../../Projects/Profile/ProfileInformation/useSelect/useSelect.css';

interface IDateRangePicker {
  startDate?: string;
  endDate?: string;
  showTimeIntervals?: boolean;
  onChange: (el: Date[]) => void;
  isInput?: boolean;
  label?: string;
  disabled?: boolean;
  error?: string;
  wasRequest?: boolean;
  isWeekend?: boolean;
  required?: boolean;
  placeholder?: string;
  autoIndents?: boolean;
}
const DateRangePicker: FC<IDateRangePicker> = ({
  startDate,
  endDate,
  onChange,
                                                 isWeekend,
  showTimeIntervals,
  isInput,
  label,
  disabled,
  error,
  wasRequest,
  required,
  placeholder,
  autoIndents,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const [blurred, setBlurred] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) setMounted(true);
    if (!showOptions && mounted) setBlurred(true);
  }, [showOptions]);

  const heightSelect = 465 + (showTimeIntervals ? 116 : 0);

  const initialRange = [
    moment(startDate, 'YYYY-MM-DD').toDate(),
    moment(endDate, 'YYYY-MM-DD').toDate(),
  ].filter((el) => moment(el).isValid());

  const {
    date,
    handlePrevMonthButtonClick,
    handleNextMonthButtonClick,
    monthData,
    handleDayClick,
    includedInDateRange,
    handleOnClickLastTwoWeek,
    range,
    currentDate,
    handleClearRange,
    handleOnClickLastWeek,
    handleOnClickLastMonthWeek,
    handleOnClickAllTime,
    setDate,
  } = useDateRange(initialRange);

  const stylesOptions = clsx(
    styles.containerOptions,
    showOptions && styles.showOptions,
    isInput ? styles.marginTop16 : styles.marginTop4
  );

  const isError = () => {
    return (wasRequest || blurred) && Boolean(error);
  };

  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const absoluteWrapperStyles = clsx(
    inputStyles.absolute,
    isOpen && inputStyles.absoluteFocused,
    isError() && inputStyles.absoluteError
  );

  const handleShowOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const handleCloseOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const handleClickOutside = (event: any) => {
    if (
      rootRef.current &&
      !rootRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const [plusPosition, setPlusPosition] = useState(0);

  const rootRef = useRef<HTMLDivElement>(null);

  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => e.stopPropagation();

  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  useEffect(() => {
    const positionElement =
      rootRef.current?.getBoundingClientRect().bottom;
    if (autoIndents && positionElement) {
      const result = heightSelect - (windowHeight - positionElement);
      if (result > 0) {
        setPlusPosition(result + 30);
      }
    }
  }, [rootRef.current]);

  return (
    <div ref={rootRef} className={styles.relative}>
      {isInput ? (
        <>
          {label && (
            <InputLabel marginSm muted={disabled}>
              <span>{label}</span>
              {required && (
                <span
                  className={clsx(
                    inputStyles.required,
                    disabled && inputStyles.requiredDisabled)}>
                  *
                </span>
              )}
            </InputLabel>
          )}
          <div
            onClick={() => !disabled && handleShowOptions()}
            className={clsx(
              inputStyles.input,
              isOpen && inputStyles.focused,
              isError() && inputStyles.redBorder,
              disabled && inputStyles.disabledInput
            )}>
            <div className={absoluteWrapperStyles} />
            <CalendarIcon />
            {!initialRange.length ? (
              <p className={inputStyles.placeholder}>{placeholder}</p>
            ) : (
              <p className={inputStyles.inputTitle}>
                {`${
                  initialRange[0]
                    ? moment(initialRange[0]).format('DD.MM.YYYY')
                    : 'All Time'
                } ${
                  initialRange[1]
                    ? moment(initialRange[1]).format('- DD.MM.YYYY')
                    : ''
                }`}
              </p>
            )}
          </div>
          <p className={inputStyles.errorLabel}>
            {isError() && error}
          </p>
        </>
      ) : (
        <Button
          variant='text'
          className={styles.button}
          onClick={() => handleShowOptions()}>
          <CalendarIcon />
          <span>{`${
            initialRange[0]
              ? moment(initialRange[0]).format('DD.MM.YYYY')
              : 'All Time'
          } ${
            initialRange[1]
              ? moment(initialRange[1]).format('- DD.MM.YYYY')
              : ''
          }`}</span>
        </Button>
      )}
      <div
        ref={selectRef}
        style={
          plusPosition ? { marginTop: -plusPosition } : undefined
        }
        className={stylesOptions}
        onClick={handleStopPropagation}>
        <div className={styles.wrapper}>
          {showTimeIntervals && (
            <>
              <div className={styles.wrapperButtons}>
                <Button
                  onClick={handleOnClickAllTime}
                  className={styles.buttonEvents}>
                  All Time
                </Button>
                <Button
                  onClick={handleOnClickLastWeek}
                  className={styles.buttonEvents}>
                  Last week
                </Button>
                <Button
                  onClick={handleOnClickLastTwoWeek}
                  className={styles.buttonEvents}>
                  Last two weeks
                </Button>
                <Button
                  onClick={handleOnClickLastMonthWeek}
                  className={styles.buttonEvents}>
                  Last month
                </Button>
              </div>
              <div className={styles.divide} />
            </>
          )}
          <Calendar
            date={date}
            handlePrevMonthButtonClick={handlePrevMonthButtonClick}
            handleNextMonthButtonClick={handleNextMonthButtonClick}
            monthData={monthData}
            handleDayClick={handleDayClick}
            setDate={setDate}
            isWeekend={isWeekend}
            currentDate={currentDate}
            additionalStyles={(date: Date) =>
              includedInDateRange(date, range, true)
            }
          />
          <div className={styles.footer}>
            <div className={styles.periodContainer}>
              Period:
              <span className={styles.period}>
                {range?.[0] && moment(range[0]).format('D MMM YYYY')}
                {range?.[1] &&
                  ` - ${moment(range[1]).format('D MMM YYYY')}`}
              </span>
            </div>
            <div className={styles.containerButtons}>
              <Button
                onClick={handleClearRange}
                variant='outlined'
                className={`${styles.outlinedBtn} ${styles.baseBtn}`}>
                Сlear
              </Button>
              <Button
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onChange(range);
                  handleCloseOptions();
                }}
                className={styles.baseBtn}
                color='black'>
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
