import {FC, ReactNode, useEffect} from 'react';

import classes from './Drawer.module.css';
import Stack from '../Stack/Stack';
import IconButton from '../IconButton/IconButton';
import Typography from '../Typography/Typography';
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-narrow-right.svg';

type anchorType = 'left' | 'right';

interface IDrawer {
    open: boolean;
    anchor: anchorType;
    onClose: () => void;
    children: ReactNode;
    title?: string;
    headerIcon?: ReactNode;
    onlyBackButton?: boolean;
    requestType?: boolean;
    classNames?: string;
    classNameIcon?: string;
}

const Drawer: FC<IDrawer> = ({
                                 open,
                                 anchor,
                                 onClose,
                                 children,
                                 title,
                                 requestType,
                                 headerIcon,
                                 onlyBackButton,
                                 classNames,
                                 classNameIcon,
                             }) => {
    const {
        drawer,
        animate,
        hidden,
        overlay,
        overlayOpen,
        overlayHidden,
        // header,
    } = classes;
    useEffect(() => {
        if (open) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [open]);
    return (
        <>
            <div
                className={`${overlay} ${!open && overlayHidden} ${
                    open && overlayOpen
                }`}
                onClick={onClose}
                aria-hidden='true'
            />

            <div
                tabIndex={-1}
                className={`${drawer} ${open && animate} ${!open && hidden} ${
                    classes[anchor]
                } ${classNames}`}>
                {requestType && <Stack className={classes.header_infoArrow} justify='between'>
                  <IconButton
                    iconClassName={classes.icon}
                    icon={ArrowRight}
                    className={`${classes.btnBase} ${classes.rotate180}`}
                    onClick={onClose}
                  />
                </Stack>}
                {title && (
                    <>
                        <Stack className={`${classes.header} ${requestType ? classes.header_info : ''}`} justify='between'>
                            <Stack align='center' space={2}>
                                <div
                                    className={`${classes.btnBase} ${classNameIcon}`}>
                                    {headerIcon}
                                </div>
                                <Typography variant='subHeading1'>{title}</Typography>
                            </Stack>

                            {!requestType &&    <IconButton
                                iconClassName={classes.icon}
                                icon={ArrowRight}
                                className={`${classes.btnBase} ${classes.arrowBack}`}
                                onClick={onClose}
                            /> }
                        </Stack>
                    </>
                )}
                {onlyBackButton && (
                    <Stack className={classes.header} justify='between'>
                        <IconButton
                            iconClassName={classes.icon}
                            icon={ArrowRight}
                            className={`${classes.btnBase} ${classes.rotate180}`}
                            onClick={onClose}
                        />
                    </Stack>
                )}
                {children}
            </div>
        </>
    );
};

export default Drawer;
