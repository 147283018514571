import {useEffect, useState} from 'react';
import {searchParamsToObject} from '../../helpers/object/searchParams-to-object.helper';
import {requestApi} from '../../services/request/requestApi';
import {timeTrackingApi} from '../../services/timeTrackingApi/timeTrackingApi';
import {IRequestFilter, IRequestList} from './Request.types';
import {optionType} from '../../components/ui/CustomSelect/useSelect';

export const useRequest = (searchParams: URLSearchParams) => {
    const params: IRequestFilter = searchParamsToObject(searchParams);
    const [loading, setLoading] = useState(true)
    const [listData, setListData] = useState([]);
    const [listUsers, setListUsers] = useState<optionType[]>([]);
    const [tableData, setTableData] = useState<IRequestList>()
    const [update, setUpdate] = useState(false)
    const fetchLists = async () => {
        try {
            const res = await requestApi.getCountType({});
            setListData(res);
            const resRequests = await requestApi.getRequests({
                date_from: params.date_from,
                date_to: params.date_to,
                sort_by: params.sort_by,
                sort_by_type: params.sort_by_type,
                search: params.search,
                page: params.page,
                size: 10,
                user_ids: ([] as string[]).concat(params.members || []),
                ...(params.request_type !== '' && { request_type: params.request_type }),
                request_statuses: params.status || []
            });
            setTableData({
                items: resRequests.items || [],
                total: resRequests.total || 0,
                page: resRequests.page || 1,
                size: resRequests.size || 10, // Adjust the default size as needed
                pages: resRequests.pages || 1,
            });
            setUpdate(false)
            setLoading(false)

            const resUsers = await timeTrackingApi.getTimeTrackingUsers();
            setListUsers(
                resUsers.map((e) => ({
                    name: e.id.toString(),
                    label: e.full_name,
                }))
            );
        } catch (e) {
            console.log('error', e);
        }
    };

    useEffect(() => {
        fetchLists();
    }, [searchParams, update]);

    return {
        tableData,
        params,
        listUsers,
        loading,
        setUpdate,
        listData,
    };
};
