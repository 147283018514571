import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from '../components/Projects/Profile/ProfileInformation/useSelect/useSelect.module.css';

export interface IUseSelect {
  <T extends React.HTMLAttributes<JSX.Element>>(
    Children: FC<T>,
    props: T,
    classNameOption?: string,
    animated?: boolean,
    listenerClick?: boolean
  ): {
    component: React.ReactNode;
    handleShowOptions: (e?: React.MouseEvent) => void;
    isOpen?: boolean;
    ref?: React.MutableRefObject<null>;
    style?: string;
  };
}

export const useSelect: IUseSelect = (
  Children,
  props,
  classNameOption,
  animated
) => {
  const [showOptions, setShowOptions] = useState<boolean | undefined>(
    false
  );
  const handleClickOutSide = () => {
    showOptions && setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);
    return () =>
      document.removeEventListener('click', handleClickOutSide);
  }, [showOptions]);

  const stylesOptions = clsx(
    styles.containerOptions,
    showOptions &&
      (animated ? styles.animatedShowOptions : styles.showOptions),
    classNameOption
  );

  const handleShowOptions = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  const Component = () => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className={stylesOptions}>
        <Children
          {...props}
          isOpen={showOptions}
          handleShowOptions={handleShowOptions}
        />
      </div>
    );
  };
  return {
    component: <Component />,
    handleShowOptions,
    isOpen: showOptions,
    style: styles.root,
  };
};
