import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './useSelect.module.css';
import './useSelect.css';

export interface IUseSelect {
  <T extends React.HTMLAttributes<JSX.Element>>(
      Children: FC<T>,
      props: T,
      classNameOption?: string,
      animated?: boolean,
      listenerClick?: boolean
  ): {
    component: React.ReactNode;
    handleShowOptions: (e?: React.MouseEvent) => void;
    handleCloseOptions: (e?: React.MouseEvent) => void;
    isOpen?: boolean;
    ref?: React.MutableRefObject<HTMLDivElement | null>;
    style?: string;
  };
}

export const useSelect: IUseSelect = (
    Children,
    props,
    classNameOption
) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(true);
  let closeTimeout: NodeJS.Timeout | null = null;

  const closeSelect = () => {
    setIsOpen(true);
    rootRef.current?.classList.remove('openSelect');
  };

  const closeOtherSelects = () => {
    const openSelects = document.querySelectorAll('.openSelect');
    openSelects.forEach((select) => {
      if (select !== rootRef.current) {
        select.classList.remove('openSelect');
      }
    });
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (rootRef.current && !rootRef.current.contains(e.target as Node)) {
      closeOtherSelects();

      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }

      closeTimeout = setTimeout(() => {
        closeSelect();
      }, 100);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
    };
  }, []);

  const stylesOptions = clsx(styles.containerOptions, classNameOption);

  const handleShowOptions = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    closeOtherSelects();
    setIsOpen(true);
    rootRef.current?.classList.add('openSelect');
  };

  const handleCloseOptions = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    closeSelect();
  };

  const Component: FC = () => (
      <div ref={rootRef} onClick={handleShowOptions} className={stylesOptions}>
        <Children {...props} isOpen={isOpen} handleShowOptions={handleShowOptions} />
      </div>
  );

  return {
    component: <Component />,
    handleShowOptions,
    handleCloseOptions,
    isOpen,
    ref: rootRef,
    style: styles.root,
  };
};
