import { FC, ReactNode } from 'react';
import Modal from '../../Modal';
import styles from './FilterModal.module.css';

interface IFilterModal {
  showModal: boolean;
  onCloseModal: () => void;
  data: any;
  handleSetUpFilter: (e: any) => void;
  additionalOptions: any;
  ModalComponent: FC<any>;
}
export const FilterModal: FC<IFilterModal> = ({
  showModal,
  onCloseModal,
  data,
  handleSetUpFilter,
  additionalOptions,
  ModalComponent,
}) => {
  return (
    <Modal
      className={styles.maxWidthModal}
      isOpen={showModal}
      onClose={onCloseModal}>
      <ModalComponent
        data={data}
        handleSetUpFilter={handleSetUpFilter}
        onCloseModal={onCloseModal}
        additionalOptions={additionalOptions}
      />
    </Modal>
  );
};
