import moment from 'moment';
import clsx from 'clsx';
import {FC, useState} from 'react';

import TableRow from '../../../components/ui/TableRow';
import TableCell from '../../../components/ui/TableCell';
import styles from './MedicalLeaveTable.module.css';
import CheckBox from '../../../components/ui/Checkbox';
import StageCard from '../../../components/ui/StageCard';
import {FileIcon} from '../../../assets/icons/components';
import Typography from '../../../components/ui/Typography';
import { IDayOffListItem} from '../../../services/hrApi/types';

import {ReactComponent as MoreOptions} from '../../../assets/icons/more-options.svg';
import {ReactComponent as UploadIcon} from '../../../assets/icons/upload.svg';
import {ReactComponent as PencilIcon} from '../../../assets/icons/pencil.svg';
import {ReactComponent as TrashIcon} from '../../../assets/icons/trash-icon.svg';
import {useSelect} from '../../../components/Projects/Profile/ProfileInformation/useSelect/useSelect';
import {ContainerOptions} from '../../../components/sharedPages/HumanResourcesPage/components/Table/TableRow/TableRow';
import {DeleteModal} from '../../../components/HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';

export type FileData = {
    id: number;
    name: string;
    created_at: Date;
    created_by: number;
    day_off_id: number;
    path: string;
};
interface IMedicalLeaveTable {
    data: IDayOffListItem;
    key: number;
    isSickLeave: boolean,
    handleEdit: (item: IDayOffListItem) => void;
    handleDelete: (id: number) => void;
    handleUploadFile: (file: FileData[]) => void
}

const MedicalLeaveTable: FC<IMedicalLeaveTable> = ({
                                                       data,
                                                       key,
                                                       isSickLeave,
                                                       handleEdit,
                                                       handleDelete,
                                                       handleUploadFile,
                                                   }) => {
    const formatYMDToDMY = (date: string) => {
        return moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
    };
  const [open, setOpen] = useState(false)
    const showPeriod = () => {
        const dateFrom = formatYMDToDMY(data.date_from);
        const dateTo = formatYMDToDMY(data.date_to);
        return dateFrom !== dateTo ? `${dateFrom} - ${dateTo}` : dateFrom;
    };

    const showDuration = () => {
        const days =
            moment(data.date_to).diff(data.date_from, 'days') + (data.half_day ? 0.5 : 1);
        return (days === 1 || days === 0.5) ? days + ' day' : days + ' days';
    };


    const showStatus: (
        from: string,
        to: string
    ) => 'Pending' | 'In Progress' | 'Done' = (from, to) => {
        const fromYMDToDate = (date: string) => {
            return moment(date, 'YYYY-MM-DD').toDate();
        };
        const currentDate = fromYMDToDate(
            moment(new Date()).format('YYYY-MM-DD')
        );
        if (fromYMDToDate(from) > new Date()) return 'Pending';
        if (fromYMDToDate(to) < currentDate) return 'Done';

        return 'In Progress';
    };

    const [mouseHover, setMouseHover] = useState(false);

    const onMouseHoverHandle = (event: boolean) => {
        setMouseHover(event);
        if (options.isOpen) options.handleShowOptions();
    };
    const actionMenuOptions = [
        data.documents.length && {
            icon: <UploadIcon/>,
            name: 'Download file',
            onClick: () => handleUploadFile(data.documents)
        },
        {
            icon: <PencilIcon/>,
            name: 'Edit',
            onClick: () => handleEdit(data),
        },
        {
            icon: <TrashIcon/>,
            name: 'Delete',
            onClick: () => setOpen(true)
        },
    ];

    const options = useSelect(
        ContainerOptions as FC,
        {options: actionMenuOptions},
        styles.optionsContainerStyle
    );

    const deleteFile =  () => {
        handleDelete(data.id)
        setOpen(false)
    }
    return (
        <TableRow
            className={styles.rowWrapper}
            key={key}
            onMouseEnter={() => onMouseHoverHandle(true)}
            onMouseLeave={() => onMouseHoverHandle(false)}>
            <TableCell align='left'>
                <Typography variant={'textLabel'}>{showPeriod()}</Typography>
            </TableCell>
            <TableCell align='center'>
                <CheckBox disabled checked={data.is_self_paid}/>
            </TableCell>
            <TableCell align='center'>
                <Typography variant={'textLabel'}>
                    {showDuration()}
                </Typography>
            </TableCell>
            <TableCell align='center'>
                <StageCard
                    className={`center ${styles.stageCard}`}
                    type={showStatus(data.date_from, data.date_to)}
                />
            </TableCell>
            <TableCell align='left'>
                <Typography variant={'smallBody'}>{data?.comment}</Typography>
            </TableCell>
            {isSickLeave &&
            <TableCell align='center'>
                {data.documents.length ? (
                    <p className={styles.containerFile}>
                        <FileIcon/>
                        <p className={styles.fileText}> {data.documents.length > 1 ? `Doctor’s notes (${data.documents.length})` : 'Doctor’s note'}</p>
                    </p>
                ) : null}
            </TableCell>}
            <TableCell align='right'>
                <div
                    className={clsx(
                        styles.tableCellOptions,
                        mouseHover && styles.tableCellActive
                    )}>
                    {mouseHover && (
                        <div
                            onClick={options.handleShowOptions}
                            className={styles.optionsWrapper}>
                            <MoreOptions/>
                            {options.component}
                        </div>
                    )}
                </div>
            </TableCell>
            <DeleteModal isOpen={open} handleShowModal={() => setOpen(false)} handleSubmit={deleteFile} title={`Do you want to delete this ${isSickLeave ? 'sick leave' : 'day off'}?`}
            subTitle={`Are you sure you want to delete this ${isSickLeave ? 'sick Leave' : 'day off'}? You won’t be able to restore it.`}
            />
        </TableRow>
    );
};

export default MedicalLeaveTable;
