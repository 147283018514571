import React from 'react';
import Chip from '../ui/Chip';
import Typography from '../ui/Typography';
import classes from './PopositionType.module.css'

export interface PropositionTypeProps {
    type: 'Proposition' | 'Complaint';
}

const PropositionType = (props: PropositionTypeProps) => {
    const {type} = props;
    const types = {
        Proposition: proposition,
        Complaint: complaint,
    };
    const selectedType = types[type];
    return <div>{selectedType ? selectedType() : null}</div>;
};

const proposition = () => {
    return (
        <Chip
            className={classes.chipClass}
            variant='contained'
            color='success'
            background={'successBg'}
        >
            <Typography variant={'mediumLabel'}>Propositions</Typography>
        </Chip>
    )
}

const complaint = () => {
    return (
        <Chip
            className={classes.chipClass}
            variant='contained'
            color='error'
            background={'errorBg'}
        >
            <Typography variant={'mediumLabel'}>Complaints</Typography>
        </Chip>
    )
}

export default PropositionType;
