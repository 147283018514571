import React from 'react';
import styles from './DashboardPage.module.css'
import Card from './Card/Card';
const DashboardPage = () => {
    return (
        <div className={styles.title}>
            <Card />
        </div>
    );
};

export default DashboardPage;
