import { createSlice } from '@reduxjs/toolkit';

import { getList, getProposal, getProposalCounts } from './thunks';
import { IProposal } from '../../services/proposalApi/types';
import { ApiListResponse } from '../../types/api';

export interface IInitialState {
  list: ApiListResponse<IProposal>;
  counts: Array<{
    count: number;
    status: string;
  }>;
  proposal: IProposal | null;
  isLoading: boolean;
  isEmpty: boolean;
}

const initialState: IInitialState = {
  list: {
    page: 1,
    pages: null,
    size: 50,
    total: 0,
    items: [],
  },
  counts: [],
  proposal: null,
  isLoading: true,
  isEmpty: false
};

export const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    clearProposal: (state) => ({
      ...state,
      proposal: null
    }),
    deleteProposalReducer: (state, action) =>  {
      const id = action.payload;
      state.list.items = state.list.items.filter(item => item.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getList.fulfilled, (state, action) => {
      state.list.items = action.payload.items;
      state.list.size = action.payload.size;
      state.list.total = action.payload.total;
      state.list.page = action.payload.page;
      state.list.pages = action.payload.pages;
      state.isLoading = false;
      state.isEmpty = false;
    });
    builder.addCase(getProposalCounts.fulfilled, (state, action) => {
      state.counts = action.payload
    });
    builder.addCase(getList.pending, (state) => {
      state.isEmpty = false;
      state.isLoading = true;
    });
    builder.addCase(getList.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProposal.fulfilled, (state, action) => {
      state.proposal = action.payload;
    });
  }
});

export const { clearProposal, deleteProposalReducer } = proposalSlice.actions;

export default proposalSlice.reducer;