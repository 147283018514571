import Typography from '../ui/Typography';
import {ReactComponent as LochNessMonster} from '../../assets/image/lochness-monster.svg';
import ImageAndButton from '../ImageAndButton/ImageAndButton';
import EmployeeRecentProjectsTable from './EmployeeRecentProjectsTable/EmployeeRecentProjectsTable';
import styles from './EmployeeRecentProjects.module.css';
import Button from '../ui/Button';
import {FC} from 'react';

import {IRecentProject} from '../../services/hrApi/types';
import {useNavigate} from 'react-router-dom';
import routes from '../../constants/routes';

import clsx from 'clsx';

interface EmployeeRecentProjects {
    list?: IRecentProject[];
    memberId: number;
    canEdit: boolean;
}

const EmployeeRecentProjects: FC<EmployeeRecentProjects> = ({
                                                                list,
                                                                memberId,
                                                                canEdit,
                                                            }) => {
    const navigate = useNavigate();

    const handleNavigateToAll = (memberId?: number) => {
        navigate(
            memberId
                ? routes.projectsTable + `?page=1&member_ids=${memberId}`
                : routes.projectsTable
        );
    };
    return (
        <div
            className={clsx(
                styles.wrapper,
                !canEdit && styles.disableClick
            )}>
            <div className={styles.header}>
                <Typography variant={'subHeading1'}>
                    Recent Projects
                </Typography>
                {canEdit && list?.length ? (
                    <Button
                        fullWidth
                        onClick={() => handleNavigateToAll(memberId)}
                        className={styles.addNewText}>
                        View All
                    </Button>
                ) : null}
            </div>
            {!list?.length ? (
                <ImageAndButton
                    disableBtn={!canEdit}
                    buttonText={'Assign Projects'}
                    image={<LochNessMonster/>}
                    onClick={() => handleNavigateToAll()}
                />
            ) : (
                <EmployeeRecentProjectsTable data={list}/>
            )}
        </div>
    );
};

export default EmployeeRecentProjects;
