import {FC} from 'react';
import moment from 'moment';
import ProfileImg from '../../../../ProfileImg/ProfileImg';
import {IMembersProfile} from '../../../../../services/hrApi/types';
import {ReactComponent as Cake} from '../../../../../assets/icons/cake.svg';
import styles from './ProfileMainInfo.module.css';
import Status from '../../../../ui/Status';
import defaultAvatar from '../../../../../assets/icons/movadex.png';
import EmployeeEditProfile from '../../../../EmployeeEditProfile/EmployeeEditProfile';
import {ReactComponent as Linkedin} from '../../../../../assets/icons/linkedin.svg';
import {ReactComponent as Telegram} from '../../../../../assets/icons/telegram.svg';
import {ReactComponent as Slack} from '../../../../../assets/icons/slack.svg';
import {ReactComponent as BenchIcon} from '../../../../../assets/icons/menu/BenchIcon.svg';
import Tooltip from '../../../../ui/Tooltip';

interface IProfileMainInfo {
    membersProfile: IMembersProfile;
    canEdit: boolean;
}

export const ProfileMainInfo: FC<IProfileMainInfo> = ({
                                                          membersProfile,
                                                          canEdit,
                                                      }) => {
    const splitedFullName = membersProfile.full_name
        ? membersProfile.full_name.split(' ')
        : [];

    const getBirthDay = () => {
        const birthDay = moment(membersProfile.birthday, 'YYYY-MM-DD');
        const formattedToYMD = birthDay.format('DD MMMM YYYY');

        const daysSinceUpdate = moment().diff(birthDay, 'years');
        return membersProfile.birthday
            ? `${formattedToYMD} (${daysSinceUpdate} years)`
            : 'Not specified';
    };

    const socialItems = [
        {
            icon: <Linkedin/>,
            baseUrl: 'http://linkedin.com/in/',
            url: membersProfile.linked_in_nickname,
        },
        {
            icon: <Telegram/>,
            baseUrl: 'https://t.me/',
            url: membersProfile.telegram_nickname,
        },
        {
            icon: <Slack/>,
            baseUrl: 'https://movadex.slack.com/team/',
            url: membersProfile.slack_nickname,
        },
    ];

    const posItems = ['CFO', 'CEO', 'Creative Director', 'COO', 'CPO', 'CTO'];

    const filterSeniority = () => {
        return membersProfile.seniority_level
            ? membersProfile?.positions
                ?.map((item) => item.name)
                .some((element) => !posItems.includes(element)) &&
            Boolean(
                membersProfile?.positions.filter(
                    (e) => !posItems.includes(e.name)
                )[0]
            )
            : '';
    };
    return (
        <div className={styles.mainInfoWrapper}>
            <ProfileImg
                image={membersProfile.avatar_url || defaultAvatar}
                firstName={splitedFullName[0]}
                lastName={splitedFullName[1]}
            />
            <div className={styles.mainInfoContainer}>
                <div className={styles.containerName}>
                    <p className={styles.userName}>
                        {membersProfile.full_name}
                        {membersProfile?.last_bench &&
                          <Tooltip delay={100} content={
                              <div className={styles.userBench}>
                                  <span>On {membersProfile.last_bench.type}</span>
                                  <span>{moment(membersProfile?.last_bench?.date_from).format('DD MMM YYYY')} {'->'} {moment(membersProfile?.last_bench?.date_to).format('DD MMM YYYY')}</span>
                              </div>} direction={'bottom'}>
                            <BenchIcon/>
                          </Tooltip>}
                    </p>
                    {canEdit && <EmployeeEditProfile/>}
                </div>
                <div className={styles.containerRole}>
                    <div className={styles.containerStatuses}>
                        {membersProfile?.departments?.map((item, index) => (
                            <Status key={index} item={item}/>
                        ))}
                    </div>
                    <p className={styles.userSelectText}>
                        {filterSeniority()
                            ? membersProfile.seniority_level + ' '
                            : ''}
                        {membersProfile?.positions
                            ?.map((item) => item.name)
                            .filter((e) => e)
                            .join(', ')}
                    </p>
                </div>
                <div className={styles.birthdayContainer}>
                    <Cake/>
                    <p className={styles.birthDayTextColor}>{getBirthDay()}</p>
                </div>
                <div className={styles.socialContainer}>
                    {socialItems.map(
                        (item, index) =>
                            item.url && (
                                <a
                                    key={index}
                                    href={item.baseUrl + item.url}
                                    className={styles.socialItem}>
                                    {item.icon}
                                </a>
                            )
                    )}
                </div>
            </div>
        </div>
    );
};
