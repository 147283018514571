import { createSlice } from '@reduxjs/toolkit';
import { profileApi } from './profileApi';
import { Profile } from '../../types/profile';
import { PositionItem } from '../../types/positions';

export type ProfileState = {
  profile?: Profile;
  canEdit: boolean;
  positions: {
    departments: Array<PositionItem>;
    positions: Array<PositionItem>;
    tech_stacks: Array<PositionItem>;
  };
};

const initialState: ProfileState = {
  profile: undefined,
  canEdit: false,
  positions: {
    departments: [],
    positions: [],
    tech_stacks: [],
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      state.profile = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, { payload }) => {
      const isManagement = payload.departments.some((e: { name: string }) =>
          ['Management', 'Founders', 'Human Resources'].includes(e.name)
      );
      state.profile = payload;
      state.canEdit = isManagement;
    });

    builder.addMatcher(profileApi.endpoints.getPositions.matchFulfilled, (state, { payload }) => {
      state.positions = payload;
    });
  },
});


export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
