import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const humanResourcesApi = createApi({
  reducerPath: 'humanResourcesApi',
  baseQuery: ApiBaseQuery({ baseUrl: '/human_resources' }),
  endpoints: (builder) => ({
    getHumanResources: builder.query({
      query: (data) => ({
        url: '',
        method: 'get',
        data,
        params: data.params,
      }),
      keepUnusedDataFor: 1,
    }),
    getHumanResourcesWithParams: builder.query({
      query: (data) => ({
        url: '',
        method: 'get',
        data,
        params: data.params,
      }),
      keepUnusedDataFor: 1,
    }),
    getTotalByDepartments: builder.query({
      query: () => ({ url: '/total-by-departments', method: 'get' }),
      keepUnusedDataFor: 1,
    }),
    getResourcesTime: builder.query({
      query: (data) => ({
        url: '/email/send_date',
        method: 'get',
        data,
        params: data.params,
      }),
    }),
    changeDate: builder.mutation({
      query: (data) => ({
        url: '/email/change_date',
        method: 'post',
        data: {},
        params: data,
      }),
    }),
    updateUserAvailability: builder.mutation({
      query: (data) => ({
        url: '/user_availability',
        method: 'put',
        data: {},
        params: data,
      }),
    }),
  }),
});

export const {
  useGetHumanResourcesQuery,
  useGetHumanResourcesWithParamsQuery,
  useGetTotalByDepartmentsQuery,
  useGetResourcesTimeQuery,
  useChangeDateMutation,
  useUpdateUserAvailabilityMutation,
} = humanResourcesApi;
