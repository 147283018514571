import { createSlice } from '@reduxjs/toolkit';
import helper from '../../constants/helper';

const token = localStorage.getItem(helper.JWT_TOKEN);
const user_data = localStorage.getItem(helper.USER_DATA);

const initialState: { user: any; token: any; message: string } = {
  user: user_data ?? null,
  token,
  message: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      localStorage.setItem(helper.JWT_TOKEN, payload.access_code);
      localStorage.setItem(helper.USER_DATA, JSON.stringify(payload));

      state.token = payload.access_code;
      state.user = payload;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
    logoutFinish: (state) => {
      state.token = null;
      state.user = null;
      state.message = '';
      localStorage.removeItem(helper.JWT_TOKEN);
      localStorage.removeItem(helper.USER_DATA);
      localStorage.removeItem(helper.EMPLOYEES_IS_AVAILABLE);
    },
  },
});

export const { loginSuccess, setMessage, logoutFinish } =
  authSlice.actions;

export default authSlice.reducer;
