import * as Yup from 'yup';

export const profileSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  birthday: Yup.date().required('Required'),
  joined_at: Yup.date().required('Required'),
  departments: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
      })
    )
    .test('haveItems', 'Enter departments', (value) => {
      return value && Boolean(value[0]);
    })
    .required('Required'),
  positions: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
      })
    )
    .test('haveItems', 'Enter positions', (value) => {
      return value && Boolean(value[0]);
    })
    .required('Required'),
  seniority_level: Yup.object()
    .shape({
      name: Yup.string(),
    })
    .test('haveItems', 'Enter seniority level', (value) => {
      return Boolean(value.name);
    })
    .required('Required'),
  linked_in_nickname: Yup.string(),
  telegram_nickname: Yup.string(),
  slack_nickname: Yup.string()
    .min(11, 'ID must be 11 characters long')
    .max(11, 'ID must be 11 characters long'),
});
