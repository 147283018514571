import { ReactNode } from 'react';
import routes from '../../../constants/routes';
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/checkbox-2.svg';
import { ReactComponent as HelpIcon } from '../../../assets/icons/help.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import { ReactComponent as Chart } from '../../../assets/icons/menu/Chart.svg';
import { ReactComponent as CodeIcon } from '../../../assets/icons/code.svg';
import { ReactComponent as Smile } from '../../../assets/icons/menu/Smile.svg';
import { ReactComponent as Flag } from '../../../assets/icons/menu/Flag.svg';
import { ReactComponent as Clock } from '../../../assets/icons/menu/Clock.svg';
import { ReactComponent as LeaveIcon } from '../../../assets/icons/menu/CalendarLeave.svg';
import { ReactComponent as BenchIcon } from '../../../assets/icons/menu/BenchIcon.svg'
import { ReactComponent as HolidaysIcon } from '../../../assets/icons/holidays.svg';
import { ReactComponent as StackIcon } from '../../../assets/icons/stack.svg';
import { ReactComponent as ConditionsIcon } from '../../../assets/icons/conditions.svg';

interface NavItemBase {
  label: string;
  to: string;
  isOpen?: boolean;
  icon: ReactNode;
  type: 'link' | 'drop' | 'divide';
  keyLabel?: string;
}

export interface NavItem extends NavItemBase {
  submenu?: NavItemBase[];
}

export const asideNavListUser: Array<NavItem> = [
  {
    label: 'Dashboard',
    type: 'link',
    to: `${routes.dashboardInfo}?page=1`,
    icon: <Chart />,
  },
  {
    label: 'My Profile',
    type: 'link',
    to: routes.myProfile,
    icon: <Smile />,
  },
  {
    label: 'Requests',
    type: 'link',
    to: `${routes.requests}?page=1&sort_by_type=desc&sort_by=created_at`,
    icon: <Flag />,
  },
  { type: 'divide', label: '', to: '', icon: <div /> },
];

export const asideNavList: Array<NavItem> = [
  {
    label: 'Dashboard',
    type: 'link',
    to: `${routes.dashboardInfo}?page=1`,
    icon: <Chart />,
  },
  {
    label: 'Human Resources',
    type: 'drop', // Corrected type to 'drop'
    to: `${routes.humanResources}?page=1`,
    isOpen: true,
    icon: <UsersIcon />,
    keyLabel: 'human-resources',
    submenu: [
      {
        label: 'Employees',
        type: 'drop',
        to: `${routes.humanResources}?page=1`,
        icon: <Smile />,
      },
      {
        label: 'Leave',
        type: 'drop',
        to: `${routes.leave}?page=1`,
        icon: <LeaveIcon />,
      },
      {
        label: 'Bench',
        type: 'drop',
        to: `${routes.bench}?page=1&sort_by_type=asc&sort_by=user_full_name`,
        icon: <BenchIcon />,
      },
      {
        label: 'Requests',
        type: 'drop',
        to: `${routes.requests}?page=1&sort_by_type=desc&sort_by=created_at`,
        icon: <Flag />,
      },
    ],
  },

  {
    label: 'Time Tracking',
    type: 'link',
    to: routes.timeTracking,
    icon: <Clock />,
  },
  {
    label: 'Projects',
    type: 'link',
    to: `${routes.projectsTable}?page=1`,
    icon: <CodeIcon />,
  },
  {
    label: 'Proposals',
    type: 'link',
    to: `${routes.proposalsPage}?size=10&page=1`,
    icon: <StackIcon />,
  },
  {
    label: 'Policy',
    type: 'drop',
    to: routes.policyConditions,
    keyLabel: 'policy',
    icon: <CheckboxIcon />,
    submenu: [
      {
        label: 'Conditions',
        type: 'drop',
        to: routes.policyConditions,
        icon: <ConditionsIcon />,
      },
      {
        label: 'Holidays',
        type: 'drop',
        to: routes.policyHolidays,
        icon: <HolidaysIcon />,
      },
    ],
  },
  { type: 'divide', label: '', to: '', icon: <div /> },
  {
    label: 'Support',
    type: 'link',
    to: routes.complaints,
    icon: <HelpIcon />,
  },
];

// export const asideNavListHR: Array<NavItem> = [
//   {
//     label: 'Dashboard',
//     type: 'link',
//     to: routes.hrDashboard,
//     icon: <UsersIcon />,
//   },
//   {
//     label: 'Bookkeping',
//     type: 'link',
//     to: routes.hrBookKeping,
//     icon: <CheckboxIcon />,
//   },
// {
//     label: 'Invoices',
//     type: 'link',
//     to: routes.hrInvoices,
//     icon: <HelpIcon/>,
// },
// {key: 1, type: 'divider'},
// ];
