import {
  FC,
  useState,
} from 'react';
import styles from './Managers.module.css';
import { CrossIcon } from '../../../../../../assets/icons/components';
import { IGetUsersResponse } from '../../../../../../services/commonApi/types';
import { IManager } from '../../../../../../services/projectsApi/types';
import { useManagers } from './useManagers';
import Default from '../../../../../Default';

export const ManagersComponent: FC<{
  onClick: (e: React.MouseEvent) => void;
  selectedItems?: IManager[];
  tag?: boolean;
  fullWidth?: boolean;
  row?: boolean;
}> = ({ onClick, selectedItems, tag, fullWidth, row }) => {
  return (
    <div
      className={`${styles.container} ${
        fullWidth && styles.fullWidth
      } ${row && styles.row}`}
      onClick={onClick}>
      {selectedItems?.map(
        ({ user }, index) =>
          user && (
            <div key={index} className={styles.avatar}>
              {!tag && <img src={user.avatar_url} alt='' />}
              <div>
                <p>{user.full_name}</p>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export interface IManagersSelect {
  onClick?: () => void;
  menuTitle?: string;
  selectedItems: IGetUsersResponse[];
  tag?: boolean;
  isOpen?: boolean;
  onFetch?: (items: IGetUsersResponse[]) => void;
  position?: string;
  positions?: string[];
  department?: string;
  departments_or_positions?: string[];
  oneItem?: boolean;
}
export const ManagersSelect: FC<IManagersSelect> = ({
  menuTitle = 'Select one or more person',
  selectedItems,
  isOpen,
  onFetch,
  position,
  positions,
  department,
  oneItem,
  departments_or_positions,
}) => {
  const {
    handleOnBlur,
    handleRemoveItem,
    handleSelectItem,
    selected,
    setSearchValue,
    searchValue,
    loading,
    list,
    cardStyles,
    height,
  } = useManagers(
    selectedItems,
    isOpen,
    onFetch,
    oneItem,
    position,
    positions,
    department,
    departments_or_positions
  );

  const [isLeave, setIsLeave] = useState(false);

  return (
    <div
      tabIndex={Math.random()}
      onMouseLeave={() => setIsLeave(true)}
      onMouseEnter={() => setIsLeave(false)}
      onBlur={() => isLeave && handleOnBlur()}>
      <div className={styles.header}>
        <div className={styles.wrap}>
          {selected?.map(
            (manager, index) =>
              manager && (
                <div
                  onClick={() => handleRemoveItem(manager)}
                  key={index}
                  className={styles.containerCard}>
                  <div className={styles.avatar}>
                    {manager.avatar_url && (
                      <img src={manager.avatar_url} alt='' />
                    )}
                    <p className={''}>{manager.full_name}</p>
                  </div>
                  <CrossIcon />
                </div>
              )
          )}
        </div>
        <input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          className={styles.search}
          placeholder='Search'
          type='text'
        />
      </div>
      <div
        style={{
          height,
        }}
        className={styles.main}>
        <p className={styles.title}>{menuTitle}</p>
        {!loading ? (
          list?.length ? (
            list?.map((manager, index) => (
              <div
                onClick={() => handleSelectItem(manager)}
                key={index}
                className={styles.selectableCard}>
                <div className={cardStyles}>
                  {manager.avatar_url && (
                    <img src={manager.avatar_url} alt='' />
                  )}
                  <p>{manager.full_name}</p>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.plug}>Not Found</div>
          )
        ) : (
          <div className={styles.plug}>
            <Default />
          </div>
        )}
      </div>
    </div>
  );
};
