import { FC } from 'react';
import styles from './LinksModal.module.css';

import { ReactComponent as DefaultIcon } from '../../../../../../../assets/icons/favicons/default-link.svg';
import { ReactComponent as FigmaIcon } from '../../../../../../../assets/icons/favicons/figma.svg';
import { ReactComponent as GitHubIcon } from '../../../../../../../assets/icons/favicons/github.svg';
import { ReactComponent as SlackIcon } from '../../../../../../../assets/icons/favicons/slack.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/copy.svg';
import { getDomainFromURL } from '../../../../../../../helpers/url/get-domain-from-url.helper';
import { ILinksItem } from '../../../../../../../services/projectsApi/types';

const icons = {
  'slack.com': <SlackIcon />,
  'www.figma.com': <FigmaIcon />,
  'github.com': <GitHubIcon />,
};
type linksTypes = 'slack.com' | 'www.figma.com' | 'github.com';

interface IProfileLink {
  item: ILinksItem;
}
export const LinksProfile: FC<IProfileLink> = ({ item }) => {
  const handleCopyText = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={styles.profileWrapper}>
      <p className={styles.profileTitle}>{item.category}</p>
      <div className={styles.profileMainSection}>
        <div className={styles.profileContainerSubTitle}>
          <div className={styles.profileIcon}>
            {icons[getDomainFromURL(item.url) as linksTypes] || (
              <DefaultIcon />
            )}
          </div>
          <p className={styles.profileBoldTitle}>{item.name}</p>
        </div>
        <div className={styles.profileContainerLink}>
          <a href={item.url} className={styles.profileLinkTitle}>
            {item.url}
          </a>
          <div
            onClick={(e) => handleCopyText(e, item.url)}
            className={styles.copyIcon}>
            <CopyIcon />
          </div>
        </div>
        <p className={styles.profileComment}>{item.comment}</p>
      </div>
    </div>
  );
};
