import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: ApiBaseQuery({ baseUrl: '/documents' }),
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (data) => {
        return { url: '', method: 'get', data: {params: data} };
      },
    }),
    deleteDocument: builder.mutation({
      query: (id: number) => ({url: `?document_id=${id}`, method: 'delete'}),
    }),
  })
});

export const {
  useGetDocumentsQuery,
  useDeleteDocumentMutation
} = documentsApi;
