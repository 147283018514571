const DAYS_IN_WEEK = 7;

const DAYS_IN_MONTH = [
  31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Now',
  'Dec',
];
export const weeksDaysNames = [
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
  'Su',
];

const WEEK_DAYS_FROM_MONDAY = [6, 0, 1, 2, 3, 4, 5];

export const areEqual = (a: Date, b: Date) => {
  if (!a || !b) return false;

  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

export const isLeapYear = (year: number) => {
  return !(year % 4 || (!(year % 100) && year % 400));
};

export const getDaysInMonth = (date: Date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  const daysInMonth = DAYS_IN_MONTH[month];

  if (isLeapYear(year) && month === 1) {
    // Month February = 1
    return daysInMonth + 1;
  } else {
    return daysInMonth;
  }
};

export const getDayOfWeek = (date: Date) => {
  const dayOfWeek = date.getDay();

  return WEEK_DAYS_FROM_MONDAY[dayOfWeek];
};

export const getMonthData = (
  year: number,
  month: number,
  minDate?: Date,
  maxDate?: Date
) => {
  const result: [] | [{ notThisMonth?: true; date: Date }][] | [][] =
    [];
  const date = new Date(year, month);
  const daysInMonth = getDaysInMonth(date);
  const monthStartsOn = getDayOfWeek(date);

  let day = 1;

  let daysAfter = 1;
  let daysBefore =
    getDaysInMonth(new Date(year, month - 1)) - monthStartsOn;
  for (
    let i = 0;
    i < (daysInMonth + monthStartsOn) / DAYS_IN_WEEK;
    i++
  ) {
    result[i] = [];
    for (let j = 0; j < DAYS_IN_WEEK; j++) {
      if (
        (i === 0 && j < monthStartsOn) ||
        day > daysInMonth ||
        (maxDate && new Date(year, month, day) > maxDate)
      ) {
        if (day > daysInMonth) {
          result[i][j] = {
            notThisMonth: true,
            date: new Date(year, month + 1, daysAfter++),
          };
        } else {
          result[i][j] = {
            notThisMonth: true,
            date: new Date(year, month - 1, ++daysBefore),
          };
        }
      } else {
        if (minDate && new Date(year, month, day + 1) < minDate) {
          result[i][j] = {
            notThisMonth: true,
            date: new Date(year, month, day++),
          };
        } else {
          result[i][j] = { date: new Date(year, month, day++) };
        }
      }
    }
  }

  return result;
};
