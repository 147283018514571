import {FC, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import clsx from 'clsx';
import styles from './DatePicker.module.css';
import Button from '../Button/Button';
import {ReactComponent as CalendarIcon} from '../../../assets/icons/calendar.svg';
import {useDate} from './useDate';
import {Calendar} from '../Calendar/Calendar';
import InputLabel from '../InputLabel/InputLabel';

interface IDateRangePicker {
    dateProp?: string | Date;
    onChange: (el?: Date) => void;
    isInput?: boolean;
    label?: string;
    formatDate?: string;
    minDate?: Date;
    maxDate?: Date;
    showFooter?: boolean;
    placeholder?: string;
    error?: string;
    wasRequest?: boolean;
    isWeekend?: boolean;
    disabled?: boolean;
    showAvailable?: boolean;
    required?: boolean;
    classNames?: string;
}

const DatePicker: FC<IDateRangePicker> = ({
                                              dateProp,
                                              onChange,
                                              showAvailable,
                                              isInput,
                                              isWeekend,
                                              label,
                                              formatDate = 'YYYY-MM-DD',
                                              minDate,
                                              maxDate,
                                              showFooter,
                                              placeholder,
                                              error,
                                              wasRequest,
                                              disabled,
                                              required,
                                              classNames,
                                          }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [blurred, setBlurred] = useState(false);
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (!mounted) setMounted(true);
        if (!showFooter && !showOptions) onChange(selectedDate);
        if (!showOptions && mounted) setBlurred(true);
    }, [showOptions]);

    const isError = () => {
        return (wasRequest || blurred) && Boolean(error);
    };

    const initialDate =
        typeof dateProp === 'string'
            ? moment(dateProp, formatDate).toDate()
            : dateProp;

    const handleShowOptions = () => {
        setShowOptions((prev) => !prev);
    };

    const handleCloseOptions = () => {
        setShowOptions((prev) => !prev);
    };

    const {
        date,
        handlePrevMonthButtonClick,
        handleNextMonthButtonClick,
        monthData,
        handleDayClick,
        includedDate,
        selectedDate,
        currentDate,
        handleClearRange,
        setDate,
    } = useDate(
        initialDate,
        minDate,
        maxDate,
        dateProp,
        handleCloseOptions
    );

    const stylesOptions = clsx(
        styles.containerOptions,
        showOptions && styles.showOptions,
        showAvailable && styles.showAvailable,
        classNames && classNames
    );

    const rootRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (
            rootRef.current &&
            !rootRef.current.contains(event.target as Node)
        ) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleStopPropagation = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => e.stopPropagation();

    const absoluteWrapperStyles = clsx(
        styles.absolute,
        showOptions && styles.absoluteFocused,
        isError() && styles.absoluteError
    );
    return (
        <div
            ref={rootRef}
            className={`${!showAvailable && styles.relative}`}>
            {isInput ? (
                <>
                    {label && (
                        <div onClick={handleCloseOptions}>
                            <InputLabel marginSm muted={disabled}>
                                <span>{label}</span>
                                {required && (
                                    <span
                                        className={clsx(
                                            styles.required,
                                            disabled && styles.requiredDisabled
                                        )}>
                    *
                  </span>
                                )}
                            </InputLabel>
                        </div>
                    )}
                    <div
                        onClick={() => !disabled && handleShowOptions()}
                        className={clsx(
                            styles.input,
                            showOptions && styles.focused,
                            isError() && styles.redBorder,
                            disabled && styles.disabledInput
                        )}>
                        <div className={absoluteWrapperStyles}/>
                        <CalendarIcon/>
                        {!dateProp ? (
                            <p className={styles.placeholder}>{placeholder}</p>
                        ) : (
                            <p className={styles.inputTitle}>
                                {moment(dateProp).format(formatDate)}
                            </p>
                        )}
                    </div>
                    {isError() && <p className={styles.errorLabel}>{error}</p>}
                </>
            ) : (
                <Button
                    variant='text'
                    className={styles.button}
                    onClick={() => handleShowOptions()}>
                    <CalendarIcon/>
                    <span>{moment(selectedDate).format(formatDate)}</span>
                </Button>
            )}
            <div className={stylesOptions} onClick={handleStopPropagation}>
                <p className={styles.wrapper}>
                    <Calendar
                        date={date}
                        setDate={setDate}
                        isWeekend={isWeekend}
                        handlePrevMonthButtonClick={handlePrevMonthButtonClick}
                        handleNextMonthButtonClick={handleNextMonthButtonClick}
                        monthData={monthData}
                        handleDayClick={handleDayClick}
                        currentDate={currentDate}
                        additionalStyles={(date: Date) =>
                            includedDate(date, selectedDate)
                        }
                    />
                    {showFooter && (
                        <div className={styles.footer}>
                            <div className={styles.periodContainer}>
                                Period:
                                <span className={styles.period}>
                  {moment(selectedDate).format('D MMM YYYY')}
                </span>
                            </div>
                            <div className={styles.containerButtons}>
                                <Button
                                    onClick={handleClearRange}
                                    variant='outlined'
                                    className={`${styles.outlinedBtn} ${styles.baseBtn}`}>
                                    Сlear
                                </Button>
                                <Button
                                    onClick={() => {
                                        onChange(selectedDate);
                                        handleCloseOptions();
                                    }}
                                    className={styles.baseBtn}
                                    color='black'>
                                    Apply
                                </Button>
                            </div>
                        </div>
                    )}
                </p>
            </div>
        </div>
    );
};

export default DatePicker;
