import React, {useEffect, useState} from 'react';
import styles from './ConfirmRequest.module.css';
import moment from 'moment';
import UploadedFile from '../../../components/FileUploader/UploadedFile/UploadedFile';
import {requestApi} from '../../../services/request/requestApi';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopLeft} from '../../../components/ui/Snackbar/constants';
import routes from '../../../constants/routes';
import {useNavigate} from 'react-router-dom';

enum RequestStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Declined = 'Declined',
}

interface User {
    avatar_url?: string;
    full_name?: string;
}

interface IConfirmRequestProps {
    data?: any;
    setOpen?: any;
    setUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmRequest: React.FC<IConfirmRequestProps> = ({data = {}, setOpen, setUpdate}) => {
    const navigate = useNavigate();
    const {
        user,
        user_id,
        created_at,
        date_from,
        date_to,
        half_day,
        timeslot,
        request_type,
        is_self_paid,
        documents,
        history,
        comment
    } = data;
    const [type, setType] = useState<RequestStatus | undefined>(data?.request_status);
    const formatDateTime = (dateTime: string): string => moment(dateTime).format('DD MMMM YYYY, HH:mm');
    const formatDate = (date: string): string => moment(date).format('DD MMM YYYY');

    const formattedDateFrom = formatDate(date_from);
    const formattedDateTo = formatDate(date_to);
    useEffect(() => {
        setType(data?.request_status)
    }, [data]);

    const renderUser = (user: User) => (
        <>
            <img src={user?.avatar_url} alt={user?.avatar_url}/>
            <p>{user?.full_name}</p>
        </>
    );

    const handleRequest = async (id: string, status: RequestStatus) => {
        try {
            setType(status);
            await requestApi.postRequests({id, status});
            enqueueSnackbar(`Status has been changed to ${status}` || 'An error occurred', {
                variant: 'success',
                anchorOrigin: snackbarPosTopLeft,
            });
            setUpdate?.(true);
            setOpen?.(false);

        } catch (e: any) {
            enqueueSnackbar(e.response?.data?.detail || 'An error occurred', {
                variant: 'error',
                anchorOrigin: snackbarPosTopLeft,
            });
        }
    };


    return (
        <div className={styles.root}>
            <ul className={styles.head}>
                <li>From<span onClick={() => navigate(routes.employeeProfile + `/${user_id}`)}>{renderUser(user)}</span>
                </li>
                <li>Submitted<span>{formatDateTime(created_at)}</span></li>
                <li>Date<span>{`${formattedDateFrom}${date_from !== date_to ? ` → ${formattedDateTo}` : ''}`}</span>
                </li>
                {request_type !== 'One-to-one' ? (
                    <>
                        <li>Self-paid<span>{is_self_paid ? 'Yes' : 'No'}</span></li>
                        <li>Half-day<span>{half_day ? 'Yes' : 'No'}</span></li>
                    </>
                ) : (
                    <li>Time<span>{timeslot ? moment(timeslot, 'hh:mm:ss').format('HH:mm') : 'None'}</span></li>
                )}
                <li className={styles.liList}>
                    Status<span>
                        {Object.values(RequestStatus).map((status) => (
                            <span
                                key={status}
                                onClick={() => handleRequest(data?.id || '', status)}
                                className={status === type ? styles[`active${status}`] : ''}>{status}</span>))}
                    </span>
                </li>
                <li>Comment:<span>{comment || 'No comment'}</span></li>
                <li>Activity Log:
                  <div className={styles.blockHistory}>
                      {history?.length > 0 ? <>
                          {history?.map((e: any) => (
                              <div className={styles.history} key={e.id}>
                                  <div className={styles.history_left}>
                            <span>
                                <img src={e?.history_creator.avatar_url} alt={'avatar'}/>
                                <span>{e?.history_creator.full_name}</span>
                            </span>
                                      <span className={styles.history_rightTime}>
                            {moment(e.created_at).format('DD MMM YYYY, HH:mm')}
                        </span>
                                  </div>
                                  <div className={styles.history_right}>Updated status to
                                <span className={e.new_value ? styles[`active${e.new_value}`] : ''}>{e.new_value}</span>
                            </div>

                              </div>
                          ))}
                      </> : <span className={styles.historyEmpty}>No activity</span>}
                  </div>
                </li>
            </ul>
            {request_type === 'Sick Leave' && documents?.length > 0 && (
                <div>
                    {documents?.length !== 0 && documents.map((file: any) => (
                        <UploadedFile key={file.id} file={file} requests/>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ConfirmRequest;
