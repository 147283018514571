import React, { ElementType, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './Typography.module.css';

export interface TypographyProps {
  color?:
    | 'inherit'
    | 'base'
    | 'muted'
    | 'primary'
    | 'error'
    | 'info'
    | 'success'
    | 'lightMuted'
    | 'gray'
    | 'gray400'
    | 'darkGray';
  align?: 'left' | 'center' | 'right';
  weight?: 'regular' | 'medium' | 'semiBold' | 'bold';
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subHeading1'
    | 'subHeading2'
    | 'semiBoldBody'
    | 'body'
    | 'smallBody'
    | 'buttonText'
    | 'smallText'
    | 'tinyText'
    | 'smallLabel'
    | 'mediumLabel'
    | 'boldLabel'
    | 'textLabel'
    | 'largeLabel';
  component?: ElementType;
  gutterBottom?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  className?: string;
  children?: ReactNode;
}

const Typography = ({
  color = 'inherit',
  align = 'left',
  weight,
  variant = 'body',
  component,
  gutterBottom,
  className,
  children,
  underline,
  uppercase,
  ...props
}: TypographyProps) => {
  const Component = component || 'p';
  const classNames = clsx(
    styles.root,
    className,
    {
      [styles[align]]: align,
      [styles[color]]: color,
      [styles.gutterBottom]: gutterBottom,
      [styles[variant]]: variant,
      [styles.underline]: underline,
      [styles.uppercase]: uppercase,
    },
    weight && [styles[weight]]
  );

  return (
    <Component className={classNames} {...props}>
      {children}
    </Component>
  );
};

export default Typography;
