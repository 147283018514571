import { useEffect, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { IProposalEstimate } from '../../../../services/proposalApi/types';

import Checkbox from '../../../ui/Checkbox/Checkbox';
import Button from '../../../ui/Button';
import Typography from '../../../ui/Typography';
import ProposalEstimateItem from './ProposalEstimateItem';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

import styles from './ProposalEstimates.module.css';

import { ProposalFormData } from '../../../../pages/Proposal';

type NewProposalEstimatesListProps = {
  data?: Array<IProposalEstimate>;
  register: UseFormRegister<ProposalFormData>;
  remove: (i: number) => void;
  append: (data: IProposalEstimate) => void;
}

export default function ProposalEstimates(
  {
    register,
    data = [],
    append,
    remove,
  }: NewProposalEstimatesListProps) {
  const [isEstimatesVisible, setIsEstimatesVisible] = useState({
    Developer: false,
    Design: false,
    Other: false,
  });

  useEffect(() => {
    setIsEstimatesVisible({
      Developer: data.findIndex((i) => i.category === 'Developer') >= 0,
      Design: data.findIndex((i) => i.category === 'Design') >= 0,
      Other: data.findIndex((i) => i.category === 'Other') >= 0,
    });
  }, [data.length]);

  const handleAddItem = (category: IProposalEstimate['category']) => () => {
    append({
      category,
      title: '',
      description: '',
      estimate_type: '',
      time: '',
      rate: '',
      cost: '',
    });
  };

  const handleSetEstimateVisibility = (key: IProposalEstimate['category'], value: boolean) => {
    setIsEstimatesVisible((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <div className={styles.root}>
      <div className={styles.formSectionHeader}>
        <Typography weight="bold">Estimates</Typography>
        <Typography color="gray400">Check items to include on proposal page</Typography>
      </div>
      <div>
        <Checkbox
          checked={isEstimatesVisible.Developer}
          label={<Typography variant="smallText" weight="bold">Developer’s estimate</Typography>}
          onChange={(e) => handleSetEstimateVisibility('Developer', e.target.checked)}
        />
        {isEstimatesVisible.Developer && (
          <div className={styles.inner}>
            {data.map((item, index) => (
              item.category === 'Developer' && <ProposalEstimateItem
                key={index}
                index={index}
                register={register}
                onRemove={() => remove(index)}
              />
            ))}
            <div>
              <Button
                color="black"
                component="span"
                startIcon={PlusIcon}
                onClick={handleAddItem('Developer')}
              >
                Add Item
              </Button>
            </div>
          </div>
        )}
      </div>
      <div>
        <Checkbox
          checked={isEstimatesVisible.Design}
          label={<Typography variant="smallText" weight="bold">Design estimate</Typography>}
          onChange={(e) => handleSetEstimateVisibility('Design', e.target.checked)}
        />
        {isEstimatesVisible.Design && (
          <div className={styles.inner}>
            {data.map((item, index) => (
              item.category === 'Design' && <ProposalEstimateItem
                key={index}
                index={index}
                register={register}
                onRemove={() => remove(index)}
              />
            ))}
            <div>
              <Button
                color="black"
                component="span"
                startIcon={PlusIcon}
                onClick={handleAddItem('Design')}
              >
                Add Item
              </Button>
            </div>
          </div>
        )}
      </div>
      <div>
        <Checkbox
          checked={isEstimatesVisible.Other}
          label={<Typography variant="smallText" weight="bold">Other services</Typography>}
          onChange={(e) => handleSetEstimateVisibility('Other', e.target.checked)}
        />
        {isEstimatesVisible.Other && (
          <div className={styles.inner}>
            {data.map((item, index) => (
              item.category === 'Other' && <ProposalEstimateItem
                key={index}
                index={index}
                register={register}
                onRemove={() => remove(index)}
              />
            ))}
            <div>
              <Button
                color="black"
                component="span"
                startIcon={PlusIcon}
                onClick={handleAddItem('Other')}
              >
                Add Item
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}