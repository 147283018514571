import { useSearchParams } from 'react-router-dom';

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const EMAIL_VALIDATION_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const isValidPhone = (phone: string) => {
  return PHONE_REGEX.test(phone);
}

export const isValidEmail = (email: string) => {
  return EMAIL_REGEX.test(email);
}

export const useGetQueryParams = () => {
  const [queryParams] = useSearchParams();
  // TODO remove ts-ignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.fromEntries([...queryParams]);
};
