import { FC, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import TableRow from '../../../../../ui/TableRow/TableRow';
import TableCell from '../../../../../ui/TableCell';
import CheckBox from '../../../../../ui/Checkbox';
import Avatar from '../../../../../Avatar';
import Typography from '../../../../../ui/Typography';

import { ReactComponent as MoreOptions } from '../../../../../../assets/icons/more-options.svg';
import styles from './TableRow.module.css';
import { ActionMenuItem } from '../../../../../ActionMenu/ActionMenu';

import { useSelect } from '../../../../../Projects/Profile/ProfileInformation/useSelect/useSelect';
import { IHRListItem } from '../../../../../../services/hrApi/types';

import { ReactComponent as ArchiveIcon } from '../../../../../../assets/icons/archive.svg';
import { ReactComponent as PencilIcon } from '../../../../../../assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash-icon.svg';
import defaultAvatar from '../../../../../../assets/icons/movadex.png';
import routes from '../../../../../../constants/routes';
import Status from '../../../../../ui/Status/Status';
import { DeleteModal } from '../../../../../HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';

interface IContainerOptions {
  options: { icon: ReactNode; name: string; onClick?: () => void }[];
}

export const ContainerOptions: FC<IContainerOptions> = ({
  options,
}) => {
  return (
    <div>
      {options.map((item, index) => {
        if (item.name)
          return <ActionMenuItem key={index} {...item} />;
      })}
    </div>
  );
};

interface IHRTableRow {
  onSelectedTeamMates?: (user_id: number) => void;
  handleArchiveUser: (
    user_id: number,
    is_archived: boolean,
    name?: string
  ) => void;
  handleRemoveUser: (user_id: number, confirm: boolean) => void;
  isArchive?: boolean;
  item: IHRListItem;
  handleChangeActivity?: (id: number, isAvailable: boolean) => void;
  isAvailable?: boolean;
}

const HumanResourcesTableRow: FC<IHRTableRow> = ({
  item,
  // onSelectedTeamMates,
  handleArchiveUser,
  handleRemoveUser,
  isArchive,
  isAvailable,
  handleChangeActivity,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleEditAccount = () => {
    console.log('employeeProfile',routes.employeeProfile)
    console.log('item',routes.employeeProfile + `/${item.id}`)
    navigate(routes.employeeProfile + `/${item.id}`);
  };
  const handleRemoveUsers = () => {
    handleRemoveUser(item.id, true);
    setOpen(false);
  };
  const actionMenuOptions = [
    !isArchive && {
      icon: <PencilIcon />,
      name: 'Edit Account',
      onClick: handleEditAccount,
    },
    {
      icon: <ArchiveIcon />,
      name: isArchive ? 'Unarchive Account' : 'Archive Account',
      onClick: () => {
        isAvailable &&
          handleChangeActivity &&
          handleChangeActivity(item.id, false);
        handleArchiveUser(item.id, !isArchive, item.full_name);
      },
    },
    {
      icon: <TrashIcon />,
      name: 'Delete Account',
      onClick: () => setOpen(true),
    },
  ];

  const [mouseHover, setMouseHover] = useState(false);
  const onMouseHoverHandle = (event: boolean) => {
    setMouseHover(event);
    if (options.isOpen) options.handleShowOptions();
  };
  const options = useSelect(
    ContainerOptions as FC,
    { options: actionMenuOptions },
    styles.optionsContainerStyle
  );

  return (
    <>
      <TableRow
        className={styles.root}
        key={item.id}
        onMouseEnter={() => onMouseHoverHandle(true)}
        onMouseLeave={() => onMouseHoverHandle(false)}>
        {!isArchive && (
          <TableCell>
            <div className={styles.wrapperCell}>
              <CheckBox
                // value={}
                checked={isAvailable}
                onClick={(value: any) => {
                  const data =
                    value.nativeEvent.target.labels[0].children[1]
                      .children[0].outerHTML;
                  handleChangeActivity &&
                    handleChangeActivity(
                      item.id,
                      data.includes('rule')
                    );
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell
          columnClass={!isArchive ? styles.clearLP : undefined}>
          <div
            onClick={() => {
              !isArchive && handleEditAccount();
            }}
            className={`${styles.wrapperCell} ${
              !isArchive && styles.wrapperCellActive
            } ${isArchive && styles.addedLP}`}>
            <Avatar
              size='small'
              image={item.avatar_url || defaultAvatar}
              name={`${item.first_name} ${item.last_name}`}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className={styles.containerStatuses}>
            {item.departments.map((item, index) => (
              <Status key={index} item={item} />
            ))}
          </div>
        </TableCell>
        <TableCell>
          <div className={styles.column}>
            {item.positions.length
              ? item.positions.map((e, index) => (
                  <p key={index}>
                    {e.name}
                    {index + 1 < item.positions.length && ','}
                  </p>
                ))
              : null}
          </div>
        </TableCell>
        <TableCell>
          <div className={styles.wrapperCell}>
            <Typography className='textBlack600' variant='smallBody'>
              {item.seniority_level?.split('_', 3)?.join(' ') ||
                'No data'}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <div className={styles.wrapperCell}>
            <Typography className='textBlack600' variant='smallBody'>
              {item.email_corporate}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <div className={styles.wrapperCell}>
            <Typography className='textBlack600' variant='smallBody'>
              {item.phone}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <div
            className={clsx(
              styles.tableCellOptions,
              mouseHover && styles.tableCellActive
            )}>
            {mouseHover && (
              <div
                onClick={options.handleShowOptions}
                className={styles.optionsWrapper}>
                <MoreOptions />
                {options.component}
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <DeleteModal
        isOpen={open}
        handleShowModal={() => setOpen(false)}
        handleSubmit={handleRemoveUsers}
        title={'Do you want to delete this team member?'}
        subTitle={
          <span>
            Are you sure you want to delete{' '}
            <span className={styles.boldText}>{item.full_name}</span>?
            This action is irreversible, and you won`t be able to
            recover the user`s profile after deleting it.
          </span>
        }
      />
    </>
  );
};

export default HumanResourcesTableRow;
