import clsx from 'clsx';
import { forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import IconButton from '../IconButton';

import { TextareaProps } from './TextArea.model';
import classes from './TextArea.module.css';

export type TextareaVariant = 'contained' | 'outlined';

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  {
    fullWidth,
    placeholder = 'Type here...',
    className,
    loading = false,
    disabled,
    hasError,
    variant = 'contained',
    controlClassName,
    icon: Icon,
    iconClassName,
    iconColor = 'inherit',
    iconOnClick,
    onChange,
    value,
    ...props
  },
  ref
) {
  return (
    <div
      className={clsx(classes.root, className, {
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: disabled,
        [classes.hasError]: hasError,
        [classes.hasIcon]: !!Icon
      })}
    >
      <TextareaAutosize
        ref={ref}
        className={classes.input}
        placeholder={placeholder}
        value={value}
        minRows={2}
        onChange={onChange}
      />
      {Icon && (
        <div className={classes.iconContainer}>
          {iconOnClick ? (
            <IconButton
              icon={Icon}
              className={classes.iconButton}
              iconClassName={clsx(classes.icon, iconClassName)}
            />
          ) : (
            <div className={classes.iconFrame}>
              <Icon color={iconColor} className={clsx(classes.icon, iconClassName)} />
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Textarea;
