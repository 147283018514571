import clsx from 'clsx';
import { ReactNode } from 'react';

import Typography from '../Typography';

import classes from './InputHelpText.module.css';

export type FieldHelpTextProps = {
  children: ReactNode;
  hasError?: boolean;
  disabled?: boolean;
};

function InputHelpText({ children, hasError, disabled }: FieldHelpTextProps) {
  return (
    <Typography
      component="div"
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
        [classes.error]: hasError
      })}
    >
      {children}
    </Typography>
  );
}

export default InputHelpText;
