import { FC, ReactNode, useEffect, useState } from 'react';

import styles from './CRUDList.module.css';

import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/plus.svg';
import { SearchInput } from '../../../../../ui/SearchInput/SearchInput';

import { AddWrapper } from './Wrappers/AddWrapper';
import { ProfileWrapper } from './Wrappers/ProfileWrapper';
import { PlugWrapper } from './Wrappers/PlugWrapper';
import { EditWrapper } from './Wrappers/EditWrapper';

type typeDisplays = 'add' | 'edit' | 'not' | 'profile' | 'loading';
interface ICRUDList {
  ListComponent: FC<any>;
  EditComponent: FC<{
    isEdit?: boolean;
    data: any;
    setRender?: React.Dispatch<React.SetStateAction<number>>;
    handleOnClickItem?: (data: any) => void;
    onClose?: () => void;
  }>;
  AddComponent: FC<{
    isEdit?: boolean;
    setRender?: React.Dispatch<React.SetStateAction<number>>;
    handleOnClickItem?: (data: any) => void;
    onClose?: () => void;
  }>;
  ProfileComponent: FC<{ item: any }>;
  onClose: () => void;
  list: any;
  addTitle: string;
  editTitle: string;
  title: string;
  listBtn?: ReactNode;
  disableEditHeader?: boolean;
  disableLeftSectionHeader?: boolean;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  setRender?: React.Dispatch<React.SetStateAction<number>>;
  titleProfileSaveButton?: string;
  onRemoveItem?: (
    data: any,
    setData: React.Dispatch<React.SetStateAction<typeDisplays>>
  ) => void;
  rightSectionView: typeDisplays;
  setRightSectionView: React.Dispatch<
    React.SetStateAction<typeDisplays>
  >;
  currentItem: any;
  setCurrentItem: React.Dispatch<React.SetStateAction<any>>;
}

export const CRUDList: FC<ICRUDList> = ({
  ListComponent,
  AddComponent,
  EditComponent,
  ProfileComponent,
  onClose,
  list,
  addTitle,
  editTitle,
  title,
  listBtn,
  disableEditHeader,
  disableLeftSectionHeader,
  setSearchValue,
  searchValue,
  setRender,
  titleProfileSaveButton,
  onRemoveItem,
  rightSectionView = 'add',
  setRightSectionView,
  currentItem,
  setCurrentItem,
}) => {
  const [prevRightSectionView, setPrevRightSectionView] =
    useState<typeDisplays>('add');
  let rightSection = undefined;

  const handleOnClickItem = (item: any) => {
    setCurrentItem(item);
    setRightSectionView('profile');
  };
  const handleAddItem = () => {
    setRightSectionView('add');
  };
  const handleEditItem = () => {
    setRightSectionView('edit');
  };
  switch (rightSectionView) {
    case 'add': {
      rightSection = (
        <AddWrapper onClose={onClose} title={addTitle}>
          <AddComponent
            handleOnClickItem={handleOnClickItem}
            setRender={setRender}
            isEdit={false}
            onClose={onClose}
          />
        </AddWrapper>
      );
      break;
    }
    case 'edit': {
      rightSection = (
        <EditWrapper
          disableHeader={disableEditHeader}
          onClose={onClose}
          title={editTitle}>
          <EditComponent
            setRender={setRender}
            data={currentItem}
            isEdit={true}
            handleOnClickItem={handleOnClickItem}
            onClose={onClose}
          />
        </EditWrapper>
      );
      break;
    }
    case 'not': {
      rightSection = <PlugWrapper onClose={onClose} />;
      break;
    }
    case 'profile': {
      rightSection = (
        <ProfileWrapper
          onClickEdit={handleEditItem}
          onClose={onClose}
          onClickRemove={() =>
            onRemoveItem &&
            onRemoveItem(currentItem, setRightSectionView)
          }
          titleSaveButton={titleProfileSaveButton}>
          <ProfileComponent item={currentItem} />
        </ProfileWrapper>
      );
      break;
    }
    case 'loading': {
      rightSection = <PlugWrapper onClose={onClose} isLoading />;
      break;
    }
  }

  useEffect(() => {
    if (!list?.length && searchValue) {
      rightSectionView !== 'not' &&
        setPrevRightSectionView(rightSectionView);
      setRightSectionView('not');
    } else if (list?.length && rightSectionView === 'not') {
      prevRightSectionView !== 'not' &&
        setRightSectionView(prevRightSectionView);
    }
  }, [!list?.length]);

  return (
    <>
      <div className={styles.leftSection}>
        {!disableLeftSectionHeader && (
          <>
            <div className={styles.leftHeader}>
              <p className={styles.headerTitle}>{title}</p>
              <div onClick={handleAddItem} className={styles.btnIcon}>
                <PlusIcon />
              </div>
            </div>
            {listBtn && listBtn}
            <div className={styles.contaienrInput}>
              <SearchInput
                classNameInput={styles.searchInput}
                placeholder='Search'
                value={searchValue}
                setValue={setSearchValue}
              />
            </div>
          </>
        )}
        <div className={styles.scrollSection}>
          <ListComponent
            onItemClick={handleOnClickItem}
            list={list}
          />
        </div>
      </div>
      <div className={styles.rightSection}>{rightSection}</div>
    </>
  );
};
