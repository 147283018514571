import { useEffect, useState } from 'react';
import { useGetQueryParams } from '../../../utils/common';
import { IPublicHoliday } from '../../../services/policyApi/types';
import { policyApi } from '../../../services/policyApi/policyApi';

export const useHolidays = () => {
  const params: { sort_by_type?: string; sort_by?: string } =
    useGetQueryParams();
  const [list, setList] = useState<IPublicHoliday[]>([]);

  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);

  const fetchList = async () => {
    const res = await policyApi.getHolidays(params);
    setList(res);
    setLoading(false);
  };
  useEffect(() => {
    fetchList();
  }, [render, JSON.stringify(params)]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleCloseDrawer = () => setOpenDrawer(false);
  const handleOpenDrawer = () => setOpenDrawer(true);

  return {
    list,
    loading,
    setRender,
    openDrawer,
    handleCloseDrawer,
    handleOpenDrawer,
  };
};
