import Typography from '../ui/Typography';
import Equipment from '../Equipment/Equipment';
import styles from './EmployeeEquipment.module.css';
import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import ImageAndButton from '../ImageAndButton/ImageAndButton';
import { ReactComponent as Laptop } from '../../assets/image/laptop.svg';
import Button from '../ui/Button';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as LaptopDevice } from '../../assets/icons/device-laptop.svg';
import Drawer from '../ui/Drawer/Drawer';
import EmployeeEquipmentDrawer from './EmployeeEquipmentDrawer/EmployeeEquipmentDrawer';
import { IEquipmentsItem } from '../../services/hrApi/types';
import { useParams } from 'react-router-dom';
import { hrApi } from '../../services/hrApi/hrApi';
import {useSelector} from 'react-redux';
import {getProfileSelector} from '../../store/profile/selectors';

interface IEmployeeEquipment {
  canEdit: boolean;
}
const EmployeeEquipment: FC<IEmployeeEquipment> = ({ canEdit }) => {
  const user: any = useSelector(getProfileSelector);
  const { id } = useParams();
  const ids = id || user.id;

  const [list, setList] = useState<IEquipmentsItem[]>([]);

  const [render, setRender] = useState(0);

  useEffect(() => {
    const getList = async (id: number) => {
      const res = await hrApi.getEquipments(id);
      setList(res);
    };
    user && getList(Number(ids));
  }, [ids, render]);

  const handleRender = () => {
    setRender((prev) => prev + 1);
  };

  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [showEditDrawer, setShowEditDrawer] = useState(false);

  const [selectedData, setSelectedData] = useState<IEquipmentsItem>();

  const handleShowCreateDrawer = () => {
    setShowCreateDrawer((prev) => !prev);
  };
  const handleShowEditDrawer = () => {
    setShowEditDrawer((prev) => !prev);
  };

  return (
    <div>
      {!list.length ? (
        <>
          <Typography variant={'subHeading1'}>Equipment</Typography>
          <ImageAndButton
            buttonText={'Add Equipment '}
            disableBtn={!canEdit}
            image={<Laptop />}
            onClick={handleShowCreateDrawer}
          />
        </>
      ) : (
        <>
          <div className={'row spaceBetween alignItemsCenter'}>
            <Typography variant={'subHeading1'}>Equipment</Typography>
            {canEdit && (
              <Button
                color='black'
                startIcon={PlusIcon}
                onClick={handleShowCreateDrawer}>
                <p>Add New</p>
              </Button>
            )}
          </div>
          <div
            className={clsx(
              !canEdit && styles.disableClick,
              styles.equipmentWrapper
            )}>
            {list.map((equipment, index) => (
              <div
                onClick={() => {
                  handleShowEditDrawer()
                  setSelectedData(equipment);
                }}
                key={index}>
                <Equipment data={equipment} />
              </div>
            ))}
          </div>
        </>
      )}
      {list.length
        ? ids && (
            <Drawer
              headerIcon={<LaptopDevice />}
              anchor='right'
              open={showEditDrawer}
              classNames={styles.drawer}
              onClose={handleShowEditDrawer}
              title='Edit Equipment'>
              <EmployeeEquipmentDrawer
                userId={Number(ids)}
                updateTable={handleRender}
                handleClose={handleShowEditDrawer}
                data={selectedData}
                isEdit
              />
            </Drawer>
          )
        : null}
      {ids && (
        <Drawer
          headerIcon={<LaptopDevice />}
          anchor='right'
          open={showCreateDrawer}
          classNames={styles.drawer}
          onClose={handleShowCreateDrawer}
          title='Add Equipment'>
          {showCreateDrawer && (
            <EmployeeEquipmentDrawer
              userId={Number(ids)}
              updateTable={handleRender}
              handleClose={handleShowCreateDrawer}
            />
          )}
        </Drawer>
      )}
    </div>
  );
};

export default EmployeeEquipment;
