import { createSlice } from '@reduxjs/toolkit'
import { dayOffsPolicyApi } from './dayOffPolicyApi'

export interface DayOffPolicy {
  id?: number
}

const initialState: { dayOffPolicyData: Array<DayOffPolicy> } = {
  dayOffPolicyData: [],
}

const dayOffPolicySlice = createSlice(({
  name: 'dayOffPolicy',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(dayOffsPolicyApi.endpoints.getDayOffPolicy.matchFulfilled, (state, {payload}) => {
      state.dayOffPolicyData = payload
    })
  },
}))

export default dayOffPolicySlice.reducer
