import React from 'react';
import Chip from '../ui/Chip';
import Typography from '../ui/Typography';
import classes from './PropositionStatus.module.css';

interface PropositionStatusProps {
  type: 'Resolved' | 'Pending' | 'In Progress';
  handleMenuOpen: any;
  fullWidth?: boolean;
}

const PropositionStatus = (props: PropositionStatusProps) => {
  const { type, handleMenuOpen, fullWidth } = props;
  const types = {
    Resolved: resolved,
    Pending: pending,
    'In Progress': in_progress,
  };
  const selectedType = types[type];
  return selectedType
    ? selectedType({ handleMenuOpen, fullWidth })
    : null;
};

const resolved = ({
  handleMenuOpen,
  fullWidth,
}: {
  handleMenuOpen: any;
  fullWidth?: boolean;
}) => {
  return (
    <Chip
      color={'success'}
      onClick={handleMenuOpen}
      className={`${classes.typeClass} ${
        fullWidth && classes.fullWidth
      }`}
      background={'successBg'}>
      <Typography variant={'mediumLabel'}>Resolved</Typography>
    </Chip>
  );
};

const pending = ({
  handleMenuOpen,
  fullWidth,
}: {
  handleMenuOpen: any;
  fullWidth?: boolean;
}) => {
  return (
    <Chip
      color={'primary'}
      className={`${classes.typeClass} ${
        fullWidth && classes.fullWidth
      }`}
      onClick={handleMenuOpen}
      background={'primaryBg'}>
      <Typography variant={'mediumLabel'}>Pending</Typography>
    </Chip>
  );
};

const in_progress = ({
  handleMenuOpen,
  fullWidth,
}: {
  handleMenuOpen: any;
  fullWidth?: boolean;
}) => {
  return (
    <Chip
      color={'info'}
      background={'infoBg'}
      onClick={handleMenuOpen}
      className={`${classes.typeClass} ${
        fullWidth && classes.fullWidth
      }`}>
      <Typography variant={'mediumLabel'}>In progress</Typography>
    </Chip>
  );
};

export default PropositionStatus;
