import TableRow from '../../../../components/ui/TableRow';
import TableCell from '../../../../components/ui/TableCell';
import Typography from '../../../../components/ui/Typography/Typography';
import classes from './RequestsTable.module.css';

export const TableRows = () => {
  return (
    <TableRow className={classes.rowBackground}>
      <TableCell sortBy={'created_at'}>
        <Typography uppercase variant='mediumLabel'>
          Date
        </Typography>
      </TableCell>
      <TableCell sortBy={'proposition_type'}>
        <Typography uppercase variant='mediumLabel'>
          Type
        </Typography>
      </TableCell>
      <TableCell>
        <Typography uppercase variant='mediumLabel'>
          Title
        </Typography>
      </TableCell>
      <TableCell sortBy={'proposition_status'}>
        <Typography uppercase variant='mediumLabel'>
          Status
        </Typography>
      </TableCell>
      <TableCell sortBy={'user_first_name'}>
        <Typography uppercase variant='mediumLabel'>
          From
        </Typography>
      </TableCell>
    </TableRow>
  );
};
