import React, {FC, useEffect, useState} from 'react';
import { useSearchParams} from 'react-router-dom';
import Table from '../../../ui/Table';
import TableCell from '../../../ui/TableCell';
import TableRow from '../../../ui/TableRow';
import {ITimeTrackingFilter} from '../../../../pages/TimeTracking/TimeTracking.types';
import styles from './Submission.module.css';
import Default from '../../../Default';
import {timeTrackingApi} from '../../../../services/timeTrackingApi/timeTrackingApi';
import moment from 'moment';
import {Emoji} from 'emoji-picker-react';
import {ReactComponent as Trash} from '../../../../assets/icons/trash-icon.svg';
import {ReactComponent as Clock} from '../../../../assets/icons/clockIcon.svg';
import Tooltip from '@mui/material/Tooltip';
import {Plug} from '../Plug/Plug';
import {ITimeTrackingSubmissions} from '../../../../services/timeTrackingApi/types';
import {DeleteModal} from '../../../HR/Bookkeping/Modals/DeleteAccount/DeleteAccount';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopRight} from '../../../ui/Snackbar/constants';
import TablePagination from '../../../ui/TablePagination';
import Drawer from '../../../ui/Drawer/Drawer';
import CurrentSubmission from './CurrentSubmission';
import defaultAvatar from '../../../../assets/icons/movadex.png';
import {IRequestFilter} from '../../../../pages/Requests/Request.types';
import {searchParamsToObject} from '../../../../helpers/object/searchParams-to-object.helper';


interface ITimeSheetTable {
    filters: ITimeTrackingFilter;
    setFilters?: React.Dispatch<
        React.SetStateAction<ITimeTrackingFilter>
    >;
}

interface IDeleteSubmission {
    open: boolean;
    data: any
}

const deleteData = {
    open: false,
    data: null
}

export const Submission: FC<ITimeSheetTable> = ({filters}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params: IRequestFilter = searchParamsToObject(searchParams);
    const [loading, setLoading] = useState<boolean>(true)
    const [isOpenDelete, setIsDeleteModal] = useState<IDeleteSubmission>(deleteData)
    const [data, setData] = useState<any>(null)
    const [currentSubmission, setCurrentSubmission] = useState<IDeleteSubmission>(deleteData)
    const projectIdsStringArray = Array.isArray(filters.projects) ? filters.projects : [filters.projects];
    const projectIdsNumberArray = projectIdsStringArray.map(e => Number(e)).filter(Boolean);
    const membersIdsStringArray = Array.isArray(filters.members) ? filters.members : [filters.members];
    const membersIdsNumberArray = membersIdsStringArray.map(e => Number(e)).filter(Boolean);

    const fetchData = async () => {
        try {
            const requestData = {
                advanced_search: filters.search,
                date_from: filters.date_from,
                size: 15,
                sort_by: params.sort_by,
                sort_by_type: params.sort_by_type,
                page: filters?.page,
                date_to: filters.date_to,
                ...(projectIdsNumberArray.length > 0 && {project_ids: projectIdsNumberArray}),
                ...(membersIdsNumberArray.length > 0 && {user_ids: membersIdsNumberArray}),
            } as ITimeTrackingSubmissions

            const res = await timeTrackingApi.getTimeTrackingSubmissions(requestData);
            setData(res);
            setLoading(false);
        } catch (err) {
            console.log('err', err);
        }
    };


    useEffect(() => {
        fetchData?.()
    }, [filters]);

    const handleDeleteCondition = async () => {
        const res = await timeTrackingApi.deleteTimeTrackingSubmission(isOpenDelete?.data.id)
        if (res) {
            fetchData?.()
            setIsDeleteModal(deleteData)
            enqueueSnackbar(`Submission ${isOpenDelete?.data?.project.name} successfully deleted `, {
                anchorOrigin: snackbarPosTopRight,
                variant: 'success',
            });

        }
    }

    return (
        <div className={styles.wrapper_submission}>
            {!loading ?
                <>
                    {data && data?.total >= 1 ?
                        <>
                            <Table tableContainerClass={styles.tbody} className={styles.table}>
                                <thead>
                                <TableRow className={styles.thead}>
                                    <TableCell columnClass={styles.clear} component='th'
                                                sortBy='created_at'>Submitted</TableCell>
                                    <TableCell component='th'>Submitted by</TableCell>
                                    <TableCell component='th'>Project</TableCell>
                                    <TableCell component='th' sortBy='date'>date</TableCell>
                                    <TableCell component='th'>Time</TableCell>
                                    <TableCell component='th'>Work description</TableCell>
                                    <TableCell component='th'></TableCell>
                                </TableRow>
                                </thead>
                                <tbody className={styles.tbody}>
                                {data?.items?.map((el: any) => (
                                    <TableRow
                                        key={el.id}
                                        hover

                                    >
                                        <TableCell   onClick={() => setCurrentSubmission({ open: true, data: el })}>
                                            <p>{moment(el.created_at).format('DD MMM YYYY')}</p>
                                            <p className={styles.createTime}>{moment(el.created_at).format('HH:mm')}</p>
                                        </TableCell>
                                        <TableCell   onClick={() => setCurrentSubmission({ open: true, data: el })}>
                                            <img className={styles.profileImage} src={el.user?.avatar_url || defaultAvatar} alt={'user_image'} />
                                            <p className={styles.projectName}>{el.user?.full_name}</p>
                                        </TableCell>
                                        <TableCell   onClick={() => setCurrentSubmission({ open: true, data: el })}>
                                            <Emoji size={24} unified={el?.project?.emoji} />
                                            <p>{el?.project?.name}</p>
                                        </TableCell>
                                        <TableCell   onClick={() => setCurrentSubmission({ open: true, data: el })}>
                                            <p>{moment(el.date).format('DD MMM YYYY')}</p>
                                        </TableCell>
                                        <TableCell   onClick={() => setCurrentSubmission({ open: true, data: el })}>
                                            <p>{(el.time).slice(0, 5)}</p>
                                        </TableCell>
                                        <TableCell   onClick={() => setCurrentSubmission({ open: true, data: el })}>
                                            <Tooltip title={el.description}>
                <span className={styles.tableRowsLast}>
                    {el.description}
                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className={styles.wrapper_submissionOpens}>
            <span className={styles.tableRowsLastDelete} onClick={() => setIsDeleteModal({ open: true, data: el })}>
                <Trash />
            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                </tbody>

                            </Table>
                            <TablePagination
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                currentStartPage={data?.page}
                                count={data?.total}
                                itemsPerPage={data?.size}
                            />
                        </>
                        : <Plug/>}
                </> : (
                    <Default/>
                )}
            <DeleteModal
                deleteModal
                isOpen={isOpenDelete?.open}
                handleShowModal={() => setIsDeleteModal(deleteData)}
                handleSubmit={handleDeleteCondition}
                title='Do you want to delete this submission?'
                subTitle={
                    <span
                        className={styles.deleteSubTitle}>Are you sure you want to delete {isOpenDelete?.data?.user?.full_name}`s<br/>
                        <span
                            className={styles.deleteSubTitleBold}> submission of {moment(isOpenDelete?.data?.created_at).format('DD MMM YYYY')} for {isOpenDelete?.data?.project.name}?</span><br/>
                        <span>This action can’t be undone.</span>
          </span>
                }
            />
            <Drawer
                title={'Submission'}
                headerIcon={<Clock/>}
                anchor='right'
                open={currentSubmission.open}
                onClose={() => setCurrentSubmission(deleteData)}
            >
                <CurrentSubmission data={currentSubmission?.data} handleClose={() => setCurrentSubmission(deleteData)}/>
            </Drawer>
        </div>
    );
};
