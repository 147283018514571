import Typography from '../../../ui/Typography';

import {ReactComponent as EmptyImage} from '../../../../assets/image/proposals-empty.svg';

import styles from './ProposalsEmpty.module.css'

export default function ProposalsEmpty() {
  return (
    <div className={styles.root}>
      <EmptyImage />
      <Typography weight="bold" variant="subHeading1">No proposals found</Typography>
      <Typography color="muted" variant="smallText">
        Create a new proposal or double-check filter parameters
      </Typography>
    </div>
  )
}