import { createSlice } from '@reduxjs/toolkit';
import { propositionApi } from './propositionApi';

export interface Item {
    id: number;
    user_id: number;
    user_first_name: string;
    user_last_name: string;
    created_at: Date;
    text: string;
    proposition_type: 'proposition' | 'complaint';
    proposition_status: 'pending' | 'resolved' | 'in_progress';
}

export interface Proposition {
    items: Item[];
    total: number;
    page: number;
    size: number;
}

const initialState: { proposition: Proposition } = {
    proposition: {
        items: [],
        total: 0,
        page: 0,
        size: 0
    }
}

const propositionSlice = createSlice(({
    name: 'propositions',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(propositionApi.endpoints.getPropositions.matchFulfilled, (state, {payload}) => {
            state.proposition = payload;
        })
    }
}));

export default propositionSlice.reducer;
