import { useState } from 'react';

export const useCalendar = (
    initialDate: Date,
    setDate?: React.Dispatch<React.SetStateAction<Date>>
) => {
  const [date, setDateState] = useState<Date>(initialDate);
  const [showing, setShowing] = useState<'day' | 'month' | 'year'>('day');

  const handleShowMonths = () => setShowing('month');

  const handleShowYears = () => setShowing('year');

  const handleShowDay = () => setShowing('day');

  const handleSwitchShowing = () => {
    setShowing((prev) => (prev === 'day' ? 'month' : 'day'));
  };

  const handleChangeMonth = (index: number) => {
    const newDate = new Date(date.getFullYear(), index);
    setDateState(newDate);
    setDate && setDate(newDate);
    handleShowDay();
  };

  const handleChangeYear = (year: number) => {
    const newDate = new Date(year, date.getMonth());
    setDateState(newDate);
    setDate && setDate(newDate);
    handleShowDay();
  };

  const getCurrentYear = new Date().getFullYear() + 5;
  const listOfYears = Array.from(
      { length: 69 },
      (_, i) => getCurrentYear - i
  );

  return {
    date,
    handleShowMonths,
    handleChangeYear,
    handleShowYears,
    handleSwitchShowing,
    handleChangeMonth,
    listOfYears,
    showing,
  };
};
