import { CommentInputProps } from './CommentInputProps'
import Typography from '../ui/Typography'
import styles from './CommentInput.module.css'

const CommentInput = ({ title,optional, placeHolder, className, value, setValue, required, subTitle, boldLabel, error }: CommentInputProps) => {

  return(
    <div>
      {title &&
      <Typography {...(boldLabel && {weight: 'bold'})} color={'darkGray'} variant={'smallBody'} className={`mBottom4 ${styles.title}`}>
        {title}
        {optional && <span className={styles.optional}>(optional)</span>}
        {required && <span className={styles.required}>*</span>}
      </Typography>}
      {subTitle &&
        <Typography variant={'mediumLabel'} className={`mBottom4 ${styles.subTitle}`}>
          {subTitle}
        </Typography>
      }
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeHolder}
        className={`${className} ${styles.textAreaWrapper} ${error && styles.errorBorder}`}
      />
      {error && <Typography variant={'mediumLabel'} color={'error'}>{error}</Typography>}
    </div>
  )
}

export default CommentInput
