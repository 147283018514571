import API from '../services/http';

export const ApiBaseQuery = ({ baseUrl = '' }) =>
  async ({ url, method = 'get', data, params }: { url: string; method: string; data?: any; params?: any; }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = await API[method](baseUrl + url, data, params);
      return { data: result };
    } catch (axiosError) {
      const err: any = axiosError;

      return {
        error: {
          status: err?.response?.status,
          data: err?.response?.data || err?.message,
        },
      };
    }
  };
