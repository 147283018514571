import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { useDispatch } from 'react-redux';

import authReducer from './auth/reducer';
import profileReducer from './profile/reducer';
import projectReducer from './project/reducer';
import proposalReducer from './proposal/reducer';
import propositionReducer from './propositions/reducer';
import timeTrackingReducer from './timeTracking/reducer';
import humanResourcesApiReducer from './humanResources/reducer';
import accountsApiReducer from './accounts/reducer';
import appReducer from './app/reducer';
import dayOffPolicyApiReducer from './dayOffPolicy/reducer';
import documentsApiReducer from './documents/reducer';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { projectApi } from './project/projectApi';
import { proposalApi } from './proposal/proposalApi';
import { propositionApi } from './propositions/propositionApi';
import { humanResourcesApi } from './humanResources/humanResourcesApi';
import { timeTrackingApi } from './timeTracking/timeTrackingApi';
import { authApi } from './auth/authApi';
import { profileApi } from './profile/profileApi';
import { accountsApi } from './accounts/accountsApi';
import { dayOffsPolicyApi } from './dayOffPolicy/dayOffPolicyApi';
import { documentsApi } from './documents/documentsApi';
import { appApi } from './app/appApi';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  profile: profileReducer,
  [profileApi.reducerPath]: profileApi.reducer,
  project: projectReducer,
  [projectApi.reducerPath]: projectApi.reducer,
  proposal: proposalReducer,
  [proposalApi.reducerPath]: proposalApi.reducer,
  proposition: propositionReducer,
  [propositionApi.reducerPath]: propositionApi.reducer,
  humanResources: humanResourcesApiReducer,
  [humanResourcesApi.reducerPath]: humanResourcesApi.reducer,
  timeTracking: timeTrackingReducer,
  [timeTrackingApi.reducerPath]: timeTrackingApi.reducer,
  accounts: accountsApiReducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  app: appReducer,
  [appApi.reducerPath]: appApi.reducer,
  dayOffPolicy: dayOffPolicyApiReducer,
  [dayOffsPolicyApi.reducerPath]: dayOffsPolicyApi.reducer,
  documents: documentsApiReducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
      },
    }).concat([
      profileApi.middleware,
      projectApi.middleware,
      proposalApi.middleware,
      propositionApi.middleware,
      humanResourcesApi.middleware,
      timeTrackingApi.middleware,
      authApi.middleware,
      accountsApi.middleware,
      dayOffsPolicyApi.middleware,
      documentsApi.middleware,
      appApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const persistor = persistStore(store);
