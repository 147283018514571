import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiBaseQuery } from '../ApiBaseQuery'

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: ApiBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getAccount: builder.query({
      query: (data) => ({ url: '/accounts', method: 'get', data}),
    }),
  }),
});

export const {
  useGetAccountQuery,
} = accountsApi;
