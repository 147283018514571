import { useSelector } from 'react-redux';
import styles from './ContactsModal.module.css';
import { getProjectProfileSelector } from '../../../../../../../store/project/selectors';
import { FC } from 'react';
import { Formik } from 'formik';
import Input from '../../../../../../ui/FormikInput/FormikInput';
import { CustomSelect } from '../../../../../../ui/CustomSelect/CustomSelect';
import FormikTextArea from '../../../../../../ui/FormikTextArea';
import Button from '../../../../../../ui/Button';
import {
  IContactsItem,
  IContactsItemCreate,
} from '../../../../../../../services/projectsApi/types';
import { timezone } from '../../../../../../../common/values/timezone';
import { ContactsSchema } from './Contacts.schema';
import { projectsApi } from '../../../../../../../services/projectsApi/projectsApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../../../ui/Snackbar/constants';

interface IContactsAdd {
  isEdit?: boolean;
  data?: IContactsItem;
  setRender?: React.Dispatch<React.SetStateAction<number>>;
  handleOnClickItem?: (data: IContactsItem) => void;
  onClose?: () => void;
}
export const ContactsAdd: FC<IContactsAdd> = ({
  data,
  isEdit,
  setRender,
  handleOnClickItem,
  onClose,
}) => {
  const profileData = useSelector(getProjectProfileSelector);
  const initialValues = {
    full_name: data?.full_name || '',
    position: data?.position || '',
    email: data?.email || '',
    gmt_timezone: data?.gmt_timezone || 2,
    comment: data?.comment || '',
  };
  return (
    <div className={styles.wrapper}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          try {
            if (!isEdit) {
              const obj: IContactsItemCreate = {
                ...values,
                project_id: Number(profileData?.id),
              };
              const res = await projectsApi.postContacts(obj);
              setRender && setRender((prev) => prev + 1);
              handleOnClickItem && handleOnClickItem(res);
              return;
            }
            const obj: IContactsItem = {
              ...values,
              project_contact_id: data?.id,
            };
            const res = await projectsApi.editContacts(obj);
            setRender && setRender((prev) => prev + 1);
            handleOnClickItem && handleOnClickItem(res);
          } catch (e) {
            enqueueSnackbar('Error', {
              variant: 'error',
              anchorOrigin: snackbarPosTopRight,
            });
          }
        }}
        validationSchema={ContactsSchema}
        validateOnMount
        enableReinitialize
        isInitialValid={false}>
        {({
          values,
          setFieldValue,
          errors,
          submitForm,
          submitCount,
          isSubmitting,
          isValid,
        }) => {
          return (
            <>
              <div className={styles.container}>
                <Input
                  value={values.full_name}
                  onChange={(e) =>
                    setFieldValue('full_name', e.target.value)
                  }
                  placeholder='Enter Full Name'
                  label='Full Name'
                  error={errors.full_name as string}
                  wasRequest={Boolean(submitCount)}
                />
                <Input
                  value={values.position}
                  onChange={(e) =>
                    setFieldValue('position', e.target.value)
                  }
                  placeholder='Enter Position'
                  label='Position'
                  error={errors.position as string}
                  wasRequest={Boolean(submitCount)}
                />
                <Input
                  value={values.email}
                  onChange={(e) =>
                    setFieldValue('email', e.target.value)
                  }
                  placeholder='Enter Email'
                  label='Email'
                  error={errors.email as string}
                  wasRequest={Boolean(submitCount)}
                />
                <CustomSelect
                  placeholder='Select Time Zone'
                  label='Time Zone'
                  detailedInput
                  options={timezone}
                  defaultOptions={timezone.filter(
                    (el) => el.value === values.gmt_timezone
                  )}
                  limitedViewOption
                  onChangeValue={(e) =>
                    setFieldValue(
                      'gmt_timezone',
                      timezone.filter((el) => el.name === e.name)[0]
                        .value
                    )
                  }
                  error={errors.gmt_timezone as string}
                  wasRequest={Boolean(submitCount)}
                />
                <FormikTextArea
                  label='Comment'
                  value={values.comment}
                  onChange={(e) =>
                    setFieldValue('comment', e.target.value)
                  }
                  placeholder='Enter comment'
                  error={errors.comment as string}
                  wasRequest={Boolean(submitCount)}
                />
              </div>
              <div className={styles.footer}>
                <Button
                  variant='outlined'
                  color='black'
                  className={styles.editBtn}
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  color='black'>
                  Save Changes
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
