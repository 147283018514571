import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Section.module.css';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';

interface ISection {
  title: string;
  component?: ReactNode;
  disableBB?: boolean;
  onClickPlus?: () => void;
}
export const Section: FC<ISection> = ({
  title,
  component,
  disableBB,
  onClickPlus,
}) => {
  const sectionStyles = clsx(
    styles.section,
    disableBB && styles.disableBB
  );
  return (
    <div className={sectionStyles}>
      <div className={styles.sectionHeader}>
        <p className={styles.title}>{title}</p>
        <div onClick={onClickPlus} className={styles.btnIcon}>
          <PlusIcon />
        </div>
      </div>
      {component}
    </div>
  );
};
