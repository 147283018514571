import React, {FC, useState} from 'react';
import { Emoji, EmojiStyle } from 'emoji-picker-react';

import { ImageFallback } from '../../../../ui/ImageFallback/ImageFallback';
import TableCell from '../../../../ui/TableCell';
import TableRow from '../../../../ui/TableRow';
import { TimeSheetUser } from '../../../../../services/timeTrackingApi/types';
import styles from './TableSheetRow.module.css';
import { secondsToHM } from '../../../../../helpers/date/seconds-to-hm.helper';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../constants/routes';
import {timeTrackingApi} from '../../../../../services/timeTrackingApi/timeTrackingApi';
import {Popover} from '@mui/material';
import {toHoursAndMinutes} from '../../../../../utils/toHoursAndMinutes';
import defaultAvatar from '../../../../../assets/icons/movadex.png';

interface ITableSheetRow {
  sortedDate?: { sortedDate?: string[]; format: string };
  data: TimeSheetUser;
  showByProject: boolean;
}
export const TableSheetRow: FC<ITableSheetRow> = ({
  sortedDate,
  data,
  showByProject,
}) => {
  const [dataSheet, setDataSheet] = useState<any>(null)
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchor(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };
  const open = Boolean(anchor)
  const id = open ? 'simple-popup' : undefined;
  const amountInRange = (
    dates: { [key: string]: number },
    sortedDate: string,
    sliceLastWords?: number
  ) => {
    const spittedDate = sortedDate.split(' - ');
    let sum = 0;
    Object.keys(dates).forEach((date) => {
      if (
        date >= spittedDate[0].slice(0, sliceLastWords) &&
        date <= spittedDate[1].slice(0, sliceLastWords)
      ) {
        sum += dates[date];
      }
    });
    return sum !== 0 ? sum : '-';
  };

  const parseBodyCell = (date: string) => {
    if (sortedDate?.format === 'day') {
      return date && data.dates ? data.dates[date] ?? '-' : '-';
    }
    if (sortedDate?.format === 'year') {
      return date && data.dates
        ? amountInRange(
            data.dates as { [key: string]: number },
            date,
            -3
          ) ?? '-'
        : '-';
    }
    return date && data.dates
      ? amountInRange(
          data.dates as { [key: string]: number },
          date
        ) ?? '-'
      : '-';
  };
  const handleOpen = async (id: any, e: string ) => {
    const [startDate, endDate] = e.split(' - ');
    if(showByProject) {
      const res = await timeTrackingApi.getTimeTrackingCurrentProjects({
        project_ids: id,
        date_from: startDate,
        date_to: endDate
      })
      setDataSheet(res)
    } else {
      const res = await timeTrackingApi.getTimeTrackingCurrentUser({
        user_ids: id,
        date_from: startDate,
        date_to: endDate
      })
      setDataSheet(res)
    }
  }

  const navigate = useNavigate();
  const handleRedirectToProfile = () => {
    if (!showByProject) {
      navigate(routes.employeeProfile + `/${data.id}`);
      return;
    }
    navigate(routes.projectsProfile + `/${data.id}`);
  };
  return (
    <TableRow className={styles.tBodyRow}>
      <TableCell
        onClick={handleRedirectToProfile}
        className={styles.wrapperMember}>
        <div className={styles.containerMember}>
          {data.emoji ? (
            <div
              style={{ backgroundColor: data.color }}
              className={styles.emojiWrapper}>
              <Emoji
                unified={data.emoji}
                emojiStyle={EmojiStyle.APPLE}
                size={20}
              />
            </div>
          ) : (
            <ImageFallback src={data.avatar_url} />
          )}
          {showByProject ? (
            <span>
              {data.name}
              {data.product_type && (
                <>
                  <div className={styles.dot} />
                  <span>{data.product_type}</span>
                </>
              )}
            </span>
          ) : (
            <span>
              {data.first_name} {data.last_name}
            </span>
          )}
        </div>
      </TableCell>
      {sortedDate?.sortedDate?.map((e) => (
          <>
        <TableCell key={e + 'row'} align='center' onClick={(event: any) => {
          handleOpen?.(data?.id, e)
          !anchor ? handleClick(event) : setAnchor(null)
        }}
        aria-describedby={id} >
          {secondsToHM(parseBodyCell(e))}

          {dataSheet?.length > 0 &&
          <Popover
              id={id}
              open={open}
              anchorEl={anchor}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
          >
            <div className={styles.popup}>
              {dataSheet?.map((e: any, index: number) => (
                  <div key={e + index}>
                <span>
                  {showByProject ? <img src={e.avatar_url || defaultAvatar} alt={'avatar'}/> :
                  <Emoji unified={e.emoji} size={24}/>}
                  {showByProject ? e.first_name + ' ' + e.last_name :  e.name }
                </span>
                    <span>{toHoursAndMinutes(e.total_time)}</span>
                  </div>
              ))}
            </div>
          </Popover>}
        </TableCell>

          </>

      ))}
      <TableCell align='center'>
        {data.total_time ? secondsToHM(data.total_time) : ''}
      </TableCell>
    </TableRow>
  );
};
