import { useState } from 'react';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => {
    document.body.classList.add('modal-open');
    setIsOpen(true);
  }
  const close = () => {
    setIsOpen(false);
    document.body.classList.remove('modal-open')
  }
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
    document.body.classList.toggle('modal-open')
  }

  return {
    isOpen,
    open,
    close,
    toggle,
    handleToggleModal: toggle
  };
};