import { FC, ReactNode, useEffect, useState } from 'react';

import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import IconButton from '../../IconButton/IconButton';
import Typography from '../../Typography/Typography';

import { SaveFilter } from './SaveFilter/SaveFIlter';

import styles from './QuickFilterWrapperModal.module.css';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/plusWhite.svg';

interface IQuickFilterWrapperModal {
  children: ReactNode;
  onCloseModal: () => void;
  saveFilter: (e: string, func?: () => void) => void;
  updateFilter?: () => void;
  showResetBtn?: boolean;
  onClickResetBtn?: () => void;
  defaultFilterName?: string;
  filterNames?: { name: string }[];
  onClickSetUpFilter?: () => void;
}

export const QuickFilterWrapperModal: FC<
  IQuickFilterWrapperModal
> = ({
  children,
  onCloseModal,
  saveFilter,
  updateFilter,
  onClickResetBtn,
  showResetBtn,
  defaultFilterName,
  filterNames,
  onClickSetUpFilter,
}) => {
  const [showSaveModal, setShowSaveModal] = useState(false);
  const onShowSaveModal = () => {
    setShowSaveModal(true);
  };
  const onCloseSaveModal = () => {
    setShowSaveModal(false);
  };

  return (
    <div>
      <div className={styles.header}>
        <Typography variant='subHeading1'>Filter</Typography>
        <IconButton
          onClick={onCloseModal}
          className={styles.closeIcon}
          icon={CloseIcon}
        />
      </div>
      {children}
      <div className={styles.positionButtons}>
        <div>
          <Button
            onClick={onShowSaveModal}
            variant='outlined'
            className={`${styles.outlinedBtn} ${styles.baseBtn}`}>
            Save Filter
          </Button>
          <Button
            onClick={onClickSetUpFilter}
            className={styles.baseBtn}
            color='black'>
            Set Up Filter
          </Button>
        </div>
        {showResetBtn && (
          <Button
            onClick={onClickResetBtn}
            variant='contained'
            className={`${styles.resetBtn} ${styles.baseBtn}`}>
            Reset Filter
          </Button>
        )}
      </div>
      <Modal
        className={styles.saveModal}
        classNameOverlay={styles.overlay}
        isOpen={showSaveModal}
        onClose={onCloseSaveModal}>
        <SaveFilter
          defaultFilterName={defaultFilterName}
          filterNames={filterNames}
          onCloseSaveModal={onCloseSaveModal}
          onCloseParentModal={onCloseModal}
          handleSaveFilter={saveFilter}
        />
      </Modal>
    </div>
  );
};
