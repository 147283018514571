import React from 'react';
import moment, {Moment} from 'moment';
import styles from './Leave.module.css';
import heart from '../../../assets/icons/favicons/heart.svg';
import sunny from '../../../assets/icons/favicons/sunny.svg';
import defaultAvatar from '../../../assets/icons/movadex.png';
import {UserObject, ResultObject} from './types';
import routes from '../../../constants/routes';
import {useNavigate} from 'react-router-dom';
import {GroupImageProps} from './types';


const Medical = (
    <div className={styles.medical}>
        <img src={heart} alt="heart 1" width="20" height="20"/>
        <span>Medical Leave</span>
    </div>
);

const Off = (
    <div className={styles.off}>
        <img src={sunny} alt="sunny" width="20" height="20"/>
        <span>Day off</span>
    </div>
);

const Holiday = (
    <div className={styles.work}>
        <span></span>
    </div>
);

const MedicalLeave = {
    title: Medical,
    className: styles.MedicalLeave,
}
const MedicalLeaveHalf = {
    title: Medical,
    className: styles.MedicalLeaveHalf,
}

const DayOffHalf = {
    title: Off,
    className: styles.DayOffHalf,
};
const DayOff = {
    title: Off,
    className: styles.DayOff,
};

const HolidayDay = {
    title: Holiday,
    className: styles.HolidayDay,
};


export function GroupImage({full_name, data, avatar_url, period, id, setOpenModal}: GroupImageProps) {
    const navigate = useNavigate();
    return (
        <div className={styles.members}
             onClick={() => period.name === 'Week' ? navigate(routes.employeeProfile + `/${id}`) :
                 setOpenModal((prev: any) => ({...prev, info: data, open: true}))
        }>
            <img src={avatar_url || defaultAvatar} alt={full_name} width="20" height="20"/>
            <span>{full_name}</span>
        </div>
    );
}

export function createGroups(groups: any[], period: any, setOpenModal: any) {
    return groups.map((e, index) => ({
        id: e.id,
        group: e.id,
        title: <GroupImage data={e} full_name={e.full_name} avatar_url={e.avatar_url} id={e.id} period={period} setOpenModal={setOpenModal}/>,
        key: index,
    }));
}

export function CalendarData(groups: UserObject[]): ResultObject[] {
    return groups.flatMap((group: UserObject) => {
        return group.days_off.map((dayOff) => {
            let dayOffData;
            if (dayOff.day_off_type === 'Sick Leave') {
                dayOffData = dayOff.half_day ? { ...MedicalLeaveHalf } : { ...MedicalLeave };
            } else if (dayOff.day_off_type === 'Holiday') {
                dayOffData = { ...HolidayDay };
            } else {
                dayOffData = dayOff.half_day ? { ...DayOffHalf } : { ...DayOff };
            }
            const ids = dayOff.day_off_type === 'Holiday' ? dayOff.id * group.id : dayOff.id;
            const start_time: Moment = moment(dayOff.date_from);
            const end_time: Moment = moment(dayOff.date_to);
            end_time.add(1, 'day');
            return {
                id: ids,
                group: group.id,
                ...dayOffData,
                user: { ...dayOff },
                start_time: start_time,
                end_time: end_time,
            };
        });
    });
}

