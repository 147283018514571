import API from './http';

type IChangeStatusData = {
  proposition_id: number;
  proposition_status: 'Resolved' | 'Pending' | 'In Progress';
};

export const propositionsApi = {
  changeStatus: (data: IChangeStatusData) => {
    return API.post(
      `/propositions/change-status?proposition_id=${data.proposition_id}&proposition_status=${data.proposition_status}`
    );
  },
};
