// import { createSelector } from 'reselect';
// createSelector is included in RTK
import { createSelector } from '@reduxjs/toolkit'

const getUserData = (state: any) => state.auth.user;
export const getUserDataSelector = createSelector(getUserData, user => user);

const getToken = (state: any) => state.auth.token;
export const getTokenSelector = createSelector(getToken, token => token);

const getMessage = (state: any) => state.auth.message;
export const getMessageSelector = createSelector(getMessage, message => message);


