import { FC } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import DateRangePicker from '../../../../components/ui/DateRangePicker';
import Typography from '../../../../components/ui/Typography';
import { searchParamsToObject } from '../../../../helpers/object/searchParams-to-object.helper';
import classes from './TitleWithCalendar.module.css';
import Chip from '../../../../components/ui/Chip';
import Button from '../../../../components/ui/Button';

interface IPROPOSITION_TYPE {
  all: undefined;
  proposition: string;
  complaint: string;
}
const PROPOSITION_TYPE: IPROPOSITION_TYPE = {
  all: undefined,
  proposition: 'Proposition',
  complaint: 'Complaint',
};

interface ITitleWithCalendar {
  setSearchParams: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >;
  params: {
    proposition_statuses?: string;
    proposition_date_from?: string;
    proposition_date_to?: string;
    proposition_type?: string;
    page?: number;
  };
}

const TitleWithCalendar: FC<ITitleWithCalendar> = ({
  setSearchParams,
  params,
}) => {
  const handleSetDepartment = (type?: string) => {
    return () => {
      if (type) {
        setSearchParams &&
          setSearchParams((prev) => {
            prev.delete('page');
            const params: any = searchParamsToObject(prev);
            return { page: '1', ...params, proposition_type: type };
          });
      } else {
        setSearchParams &&
          setSearchParams((prev) => {
            prev.delete('page');
            prev.delete('proposition_type');
            const params: any = searchParamsToObject(prev);
            return { ...params };
          });
      }
    };
  };

  const handleClearDate = () => {
    setSearchParams((prev) => {
      prev.delete('proposition_date_from');
      prev.delete('proposition_date_to');
      const params: any = searchParamsToObject(prev);
      return {
        ...params,
      };
    });
  };

  const dateToYMD = (date: Date | string) => {
    return moment(date).format('YYYY-MM-DD');
  };
  const handleChangeDataRange = (date: Date[]) => {
    if (!date.length) {
      handleClearDate();
      return;
    }
    setSearchParams((prev) => {
      if (!date[1]) {
        prev.delete('proposition_date_to');
      }
      let params: any = searchParamsToObject(prev);
      if (!date[1]) {
        params = {
          ...params,
          proposition_date_to: dateToYMD(date[0]) + 'T23:59:59.999',
        };
      }
      if (date[1]) {
        params = {
          ...params,
          proposition_date_to: dateToYMD(date[1]) + 'T23:59:59.999',
        };
      }
      return {
        ...params,
        page: 1,
        proposition_date_from: dateToYMD(date[0]) + 'T00:00:01.001',
      };
    });
  };

  const handleShowDateTo = () => {
    if (params.proposition_date_to && params.proposition_date_from) {
      return dateToYMD(params.proposition_date_to) ===
        dateToYMD(params.proposition_date_from)
        ? undefined
        : params.proposition_date_to;
    }
    return;
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Typography variant='h5' weight='bold'>
          Support
        </Typography>
      </div>
      <div className={classes.navContainerMain}>
        <div className={classes.navContainer}>
          {Object.keys(PROPOSITION_TYPE).map((e, index) => (
            <Button
              key={index}
              variant='text'
              onClick={handleSetDepartment(
                PROPOSITION_TYPE[e as keyof IPROPOSITION_TYPE]
              )}
              className={clsx([
                classes.tabItem,
                {
                  [classes.tabItemActive]:
                    params?.proposition_type ===
                    PROPOSITION_TYPE[e as keyof IPROPOSITION_TYPE],
                },
              ])}>
              <p
                className={clsx([
                  {
                    [classes.font500]:
                      params?.proposition_type ===
                      PROPOSITION_TYPE[e as keyof IPROPOSITION_TYPE],
                  },
                ])}>
                {e.charAt(0).toUpperCase() + e.slice(1)}
              </p>
            </Button>
          ))}
        </div>
      </div>
      <>
        <div className={classes.dateReset}>
          <DateRangePicker
            autoIndents
            showTimeIntervals
            startDate={params.proposition_date_from}
            endDate={handleShowDateTo()}
            onChange={handleChangeDataRange}
          />
          {params.proposition_date_from && (
            <Chip
              variant='contained'
              color={'error'}
              className={classes.chipContainer}
              background={'errorBg'}
              onClick={handleClearDate}>
              <Typography variant={'smallText'} weight={'regular'}>
                Reset time period
              </Typography>
            </Chip>
          )}
        </div>
      </>
    </div>
  );
};

export default TitleWithCalendar;
