import {FC, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {ReactComponent as ChevronDownIcon} from '../../../assets/icons/chevron-down.svg';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import defaultAvatar from '../../../assets/icons/movadex.png';
import styles from './CustomSelect.module.css';
import Input from '../Input/Input';
import {MultiSelectItem} from './MultiSelect/MultiSelect';
import {optionType, useSelect} from './useSelect';
import {useMulitSelect} from './useMultiSelect';
import {DetailedSelect} from './DetailedSelect/DetailedSelect';
import {IDetailedSelect} from './DetailedSelect/types';
import {DetailedInput} from './DetailedInput/DetailedInput';
import {Emoji} from 'emoji-picker-react';

interface ICustomSelect {
    className?: string;
    value?: optionType;
    label?: string;
    onChangeValue?: (e: optionType) => void;
    options?: optionType[];
    smallPadding?: boolean;
    placeholder?: string;
    classNameOptions?: string;
    multi?: boolean;
    multiAllTitle?: string;
    multiSearch?: boolean;
    pointer?: boolean;
    multiSearchPlaceholder?: string;
    defaultOptions?: optionType[];
    multiValue?: optionType[];
    onChangeMultiValue?: (e: optionType[]) => void;
    detailed?: IDetailedSelect['detailed'];
    detailedInput?: boolean;
    notAutoHeight?: boolean;
    error?: string;
    wasRequest?: boolean;
    menuAbove?: boolean;
    isOpen?: boolean;
    onOpen?: () => void;
    limitedViewOption?: boolean;
    disabled?: boolean;
    additionalBlock?: boolean;
    optionImage?: boolean;
    subTitle?: string;
    required?: boolean;
    disableAllSubTitles?: boolean;
    detailedInputStyle?: string;
    inputEmoji?: boolean;
    customTitle?: string;
    placeholderStyles?: string;
    rootClassName?: string;
    defaultAllSelected?: boolean;
    positions?: boolean;
    withoutAnimation?: boolean;
}

export const CustomSelect: FC<ICustomSelect> = ({
                                                    className,
                                                    label,
                                                    value,
                                                    inputEmoji,
                                                    options,
                                                    optionImage,
                                                    positions,
                                                    onChangeValue,
                                                    onChangeMultiValue,
                                                    smallPadding,
                                                    placeholder,
                                                    classNameOptions,
                                                    multi,
                                                    multiAllTitle,
                                                    defaultOptions,
                                                    multiValue,
                                                    detailed,
                                                    detailedInput,
                                                    notAutoHeight,
                                                    error,
                                                    wasRequest,
                                                    menuAbove,
                                                    limitedViewOption,
                                                    disabled,
                                                    additionalBlock,
                                                    subTitle,
                                                    required,
                                                    disableAllSubTitles,
                                                    detailedInputStyle,
                                                    multiSearch,
                                                    multiSearchPlaceholder,
                                                    customTitle,
                                                    placeholderStyles,
                                                    pointer,
                                                    rootClassName,
                                                    defaultAllSelected,
                                                    withoutAnimation,
                                                }) => {
    // avatar
    const rootRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const getHeight = () => {
        return notAutoHeight
            ? undefined
            : detailed
                ? undefined
                : multi
                    ? options && options?.length * 41 + (multiAllTitle ? 40 : 0)
                    : undefined;
    };
    const {
        classNames,
        handleShowOptions,
        stylesOptions,
        handleSelectOneOption,
        handleCloseOptions,
        showOptions,
        blurred,
    } = useSelect({
        smallPadding,
        className,
        classNameOptions,
        onChangeValue,
        rootRef,
        selectRef,
        height: getHeight(),
        menuAbove,
        withoutAnimation,
    });

    const {
        mapOptions,
        handleChangeSelectItem,
        handleChangeAllSelectItem,
    } = useMulitSelect({
        showOptions,
        multi,
        defaultOptions,
        onChangeMultiValue,
        options,
        defaultAllSelected,
    });

    const [searchValue, setSearchValue] = useState('');
    const isError = () => {
        return (wasRequest || blurred) && Boolean(error);
    };
    const filtered = (value: string, array?: optionType[]) => {
        if (!multiSearch) return array;
        return array?.filter((e) =>
            e.label?.toLowerCase().includes(value.toLowerCase())
        );
    };

    useEffect(() => {
        if (!showOptions) {
            searchValue && setSearchValue('');
        }
    }, [showOptions]);
    return (
        <div
            ref={rootRef}
            onSelect={() => 'asd'}
            className={clsx(styles.relative, rootClassName)}>
            {!detailedInput ? (
                <Input
                    required={required}
                    readOnly
                    pointer={pointer}
                    showOptions={showOptions}
                    disabled={disabled}
                    label={label}
                    value={
                        multi
                            ? multiValue?.map((item) => item.name).join(', ')
                            : value && value.name
                    }
                    placeholder={placeholder}
                    inputClassName={classNames}
                    inputEmoji={inputEmoji && value?.avatar}
                    inputImage={
                        optionImage &&
                        value?.name &&
                        (value?.avatar || defaultAvatar)
                    }
                    EndIcon={<ChevronDownIcon/>}
                    onClick={handleShowOptions}
                />
            ) : (
                <DetailedInput
                    smallPadding={smallPadding}
                    detailedInputStyle={detailedInputStyle}
                    disableAllSubTitles={disableAllSubTitles}
                    required={required}
                    label={label}
                    subTitle={subTitle}
                    disabled={disabled}
                    onClick={handleShowOptions}
                    handleCloseOptions={handleCloseOptions}
                    isDetailed={Boolean(detailed)}
                    showOptions={showOptions}
                    isError={isError()}
                    showSearch={detailed?.search}
                    customTitle={customTitle}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    detailedSelected={detailed?.selected}
                    defaultOptions={
                        !additionalBlock ? defaultOptions : undefined
                    }
                    placeholder={placeholder}
                    placeholderStyles={placeholderStyles}
                />
            )}
            <div
                onClick={(e) => e.stopPropagation()}
                className={stylesOptions}
                ref={selectRef}>
                {detailed ? (
                    <DetailedSelect
                        handleShowOptions={handleShowOptions}
                        detailed={detailed}
                        showOptions={showOptions}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                    />
                ) : multi ? (
                    <>
                        {multiSearch && (
                            <input
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                className={styles.multiSearch}
                                placeholder={multiSearchPlaceholder}
                            />
                        )}
                        <div
                            className={clsx(
                                styles.multiSelect,
                                limitedViewOption && styles.limitedViewOption
                            )}>
                            {multiAllTitle && (
                                <MultiSelectItem
                                    title={multiAllTitle}
                                    checked={mapOptions.size !== options?.length}
                                    isAll
                                    onChange={handleChangeAllSelectItem}
                                />
                            )}
                            {filtered(searchValue, options)?.length ? (
                                filtered(searchValue, options)?.map((el, index) => {
                                    return (
                                        <MultiSelectItem
                                            key={index}
                                            title={el.label ? el.label : el.name}
                                            checked={!mapOptions.has(el.name)}
                                            onChange={() => handleChangeSelectItem(el)}
                                        />
                                    );
                                })
                            ) : (
                                <div className={styles.plug}>Not Found</div>
                            )}
                        </div>
                    </>
                ) : (
                    <div
                        className={
                            limitedViewOption
                                ? styles.limitedViewOption
                                : '' || optionImage
                                    ? styles.optionImages
                                    : ''
                        }>
                        {((optionImage && !pointer) ||( inputEmoji && !pointer) ) && <input
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          className={styles.multiSearch}
                          placeholder={multiSearchPlaceholder}
                        />}
                        {options && options?.filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase())).length > 0 ? (
                            options
                                .filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase()))
                                .map((el, index) => (
                                    <div
                                        onClick={() => handleSelectOneOption(el)}
                                        key={index}
                                        className={`${styles.option} ${index !== 0 && styles.borderTop}`}>
                                        {optionImage && !positions && (
                                            <img src={el.avatar || defaultAvatar} alt={el.avatar}
                                                 className={styles.avatar}/>
                                        )}
                                        {inputEmoji && (
                                            <span className={styles.avatar}>
                                           <Emoji unified={el.avatar} size={24} />
                                            </span>
                                        )}
                                        {el.name}
                                    </div>
                                ))
                        ) :
                            <div className={styles.noResults}>No search results</div>
                        }

                    </div>
                )}
            </div>
            {multi && additionalBlock && (
                <div className={styles.additionalBlockWrapper}>
                    {defaultOptions?.map((el, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                handleChangeSelectItem(el, true);
                            }}
                            className={styles.additionalBlockContainer}>
                            <p>{el.name}</p>
                            <PlusIcon/>
                        </div>
                    ))}
                </div>
            )}
            {!disableAllSubTitles && (
                <p className={styles.errorLabel}>{isError() && error}</p>
            )}
        </div>
    );
};
