import { FC, useEffect, useState } from 'react';
import { ILink } from '../../../types';
import { CRUDList } from '../../CRUDList/CRUDList';
import { links } from '../../../mocks';
import { LinksList } from './LInksList';
import { LinksAdd } from './LinksAdd';
import { LinksProfile } from './LinksProfile';
import { useSelector } from 'react-redux';
import { getProjectProfileSelector } from '../../../../../../../store/project/selectors';
import { ILinksItem } from '../../../../../../../services/projectsApi/types';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { projectsApi } from '../../../../../../../services/projectsApi/projectsApi';
import { useGetQueryParams } from '../../../../../../../utils/common';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../../../ui/Snackbar/constants';

interface ILinksWrapperModal {
  onClose: () => void;
}
export const LinksWrapperModal: FC<ILinksWrapperModal> = ({
  onClose,
}) => {
  type typeDisplays = 'add' | 'edit' | 'not' | 'profile' | 'loading';
  const [rightSectionView, setRightSectionView] =
    useState<typeDisplays>('add');

  const [currentItem, setCurrentItem] = useState<any | undefined>(
    undefined
  );
  const profileData = useSelector(getProjectProfileSelector);

  const [list, setList] = useState<ILinksItem[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [render, setRender] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const debouncedValue = useDebounce<string>(searchValue, 400);
  const getList = async (
    id: number,
    func?: (data: ILinksItem[]) => void
  ) => {
    try {
      setIsLoading(true);
      const res = await projectsApi.getLinks({
        project_id: id,
        search: debouncedValue,
      });
      setList(res);
      if (func) func(res);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar('Error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
      setIsLoading(false);
    }
  };

  const handleRemoveLink = async (
    data: ILinksItem,
    setData: React.Dispatch<React.SetStateAction<typeDisplays>>
  ) => {
    try {
      await projectsApi.deleteLinks({
        project_link_id: Number(data.id),
      });
      profileData?.id && (await getList(profileData?.id));
      setData('add');
      enqueueSnackbar('Successfully removed', {
        variant: 'success',
        anchorOrigin: snackbarPosTopRight,
      });
    } catch (e) {
      enqueueSnackbar('Error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
    }
  };

  useEffect(() => {
    profileData?.id && getList(profileData?.id);
  }, [debouncedValue, render]);

  return (
    <CRUDList
      title='Links'
      addTitle='Add Link'
      editTitle='Edit Link'
      titleProfileSaveButton='Remove from Links'
      list={list}
      onRemoveItem={handleRemoveLink}
      onClose={onClose}
      ListComponent={LinksList}
      AddComponent={LinksAdd}
      EditComponent={LinksAdd}
      ProfileComponent={LinksProfile}
      setRender={setRender}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      rightSectionView={rightSectionView}
      setRightSectionView={setRightSectionView}
      setCurrentItem={setCurrentItem}
      currentItem={currentItem}
    />
  );
};
