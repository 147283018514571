import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const getProject = (state: any) => state.project.project;

const getProjectFilters = (state: RootState) => state.project.filters;

const getProjectProfile = (state: RootState) =>
  state.project.projectProfile;
export const getProjectSelector = createSelector(
  getProject,
  (project) => project
);
export const getProjectProfileSelector = createSelector(
  getProjectProfile,
  (projectProfile) => projectProfile
);

export const getProjectFiltersSelector = createSelector(
  getProjectFilters,
  (filters) => filters
);

const getProjects = (state: RootState) => state.project;

export const getProjectsSelector = createSelector(
  getProjects,
  (hr) => hr
);
