import React, { FC } from 'react';
import Table from '../../../ui/Table';
import TableCell from '../../../ui/TableCell';
import TableRow from '../../../ui/TableRow';
import { TableSheetRow } from './Row/TableSheetRow';
import { useTimeSheet } from './useTimeSheet';
import { ITimeTrackingFilter } from '../../../../pages/TimeTracking/TimeTracking.types';
import { Plug } from '../Plug/Plug';
import clsx from 'clsx';
import styles from './TimeSheetTable.module.css';
import Default from '../../../Default';

interface ITimeSheetTable {
  filters: ITimeTrackingFilter;
  setFilters: React.Dispatch<
    React.SetStateAction<ITimeTrackingFilter>
  >;
}
export const TimeSheetTable: FC<ITimeSheetTable> = ({
  filters,
  setFilters,
}) => {
  const { list, sortedDate, parseHeadCellDate, loading } =
    useTimeSheet(filters);

  const handleSetDate = (date: string) => {
    date &&
      setFilters(() => {
        const splitten = date.split(' - ');
        filters.date_from = splitten[0];
        filters.date_to = splitten[1];
        return { ...filters };
      });
  };

  return (
    <div className={styles.wrapper}>
      {!loading ? (
          <>
          {list && list?.length >= 1 ?
              <>
              <Table className={styles.table}>
              <thead>
                <TableRow className={styles.theadRow}>
                  <TableCell align='left' component='th'>
                    {!filters?.show_by ||
                    filters?.show_by === 'Show by Project'
                      ? 'Project'
                      : 'Member'}
                  </TableCell>
                  {sortedDate?.sortedDate?.map((e: string) => (
                    <TableCell
                      onClick={() =>
                        sortedDate.format !== 'day' &&
                        handleSetDate(e)
                      }
                      key={e}
                      className={clsx(
                        sortedDate.format !== 'day' &&
                          styles.headCellHover
                      )}
                      align='center'
                      component='th'>
                      {parseHeadCellDate(e, sortedDate.format)}
                    </TableCell>
                  ))}
                  <TableCell align='center' component='th'>
                    Total
                  </TableCell>
                </TableRow>
              </thead>
              <tbody>
                {list.map((e) => (
                  <TableSheetRow
                    showByProject={!filters?.show_by || filters?.show_by === 'Show by Project'}
                    key={e.id}
                    data={e}
                    sortedDate={sortedDate}
                  />
                ))}
              </tbody>
            </Table>
              </> :
              <Plug /> }
            {/* <LoadMore loading={false} handleOnLoadMore={() => 'asd'} /> */}
          </>
        )  : (
        <Default />
      )}
    </div>
  );
};
