import React, {FC, useMemo, useRef, useState, useEffect} from 'react';
import clsx from 'clsx';
import {ReactComponent as ArrowIcon} from '../../../../../assets/icons/arrow.svg';
import styles from './DepartmentList.module.css';
import Button from '../../../../ui/Button/Button';
import Badge from '../../../../ui/Badge/Badge';
import {IHRListParams, IHRListResponse} from '../../../../../services/hrApi/types';
import {IPositions} from '../../../../../store/app/reducer';
import {DepartmentItem} from '../../../../../types/humanResources';
import {SearchInput} from '../../../../ui/SearchInput/SearchInput';

interface IDepartmentList {
    params: IHRListParams;
    positions?: IPositions;
    data?: IHRListResponse;
    total?: DepartmentItem[];
    searchValue: string;
    searchParams?: URLSearchParams;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
    setSearchParams?: React.Dispatch<React.SetStateAction<URLSearchParams>>;
}

export const DepartmentList: FC<IDepartmentList> = ({
                                                        params,
                                                        searchValue,
                                                        setSearchValue,
                                                        total,
                                                        setSearchParams,
                                                    }) => {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [activeTab, setActiveTab] = useState<string>('');

    const handleSetDepartment = (type?: string) => () => {
        if (setSearchParams) {
            setSearchParams((prev) => {
                const newParams = new URLSearchParams(prev);
                newParams.set('page', '1');
                newParams.set('department', type || '');
                return newParams;
            });
            setActiveTab(type || '');
        }
    };

    const handleHorizontalScroll = (speed: number, distance: number, step: number): void => {
        const element = elementRef.current;
        if (!element) return;
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            const divElement = element as HTMLDivElement;
            divElement.scrollLeft += step;
            scrollAmount += Math.abs(step);

            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
        }, speed);
    };

    useEffect(() => {
        const element = elementRef.current;
        if (element) {
            const activeTabElement = element.querySelector(`.${styles.tabItemActive}`);
            if (activeTabElement) {
                const activeTabOffsetLeft = (activeTabElement as HTMLElement).offsetLeft;
                element.scrollTo({
                    left: activeTabOffsetLeft - element.offsetLeft - 300,
                    behavior: 'smooth',
                });
            }
        }
    }, [activeTab]);

    const memoizedTotal = useMemo(() => total?.find((i) => i.department_name.includes('All'))?.users_count, [total]);

    return (
        <div className={styles.departmentsListRows}>
            <ArrowIcon onClick={() => handleHorizontalScroll(30, 350, -50)}/>
            <div className={styles.departmentsList} ref={elementRef}>
                <Button
                    variant="text"
                    onClick={handleSetDepartment('')}
                    className={clsx(styles.tabItem, {[styles.tabItemActive]: !params.department && activeTab === ''})}
                >
                    All
                    <Badge color="default" className={styles.tabItemBadge}>
                        {memoizedTotal}
                    </Badge>
                </Button>
                {total
                    ?.slice()
                    .sort((a, b) =>
                        a.department_name === 'Other'
                            ? (b.department_name === 'Other' ? 0 : 1)
                            : b.department_name === 'Other' ? -1 : b.users_count - a.users_count
                    )?.map((item, index) => {
                        const department = total.find(
                            (i) => i.department_name.includes(item.department_name) && item.department_name !== 'All'
                        );
                        return (
                            department && (
                                <Button
                                    key={department.department_name + index}
                                    variant="text"
                                    onClick={handleSetDepartment(department.department_name)}
                                    className={clsx(styles.tabItem, {
                                        [styles.tabItemActive]: params.department?.includes(department.department_name) || activeTab === department.department_name,
                                    })}
                                >
                                    <div className={styles.tabItemContainer}>
                                        {department.department_name}
                                        <Badge color="default" className={styles.tabItemBadge}>
                                            {department.users_count}
                                        </Badge>
                                    </div>
                                </Button>
                            )
                        );
                    })}
            </div>
            <ArrowIcon onClick={() => handleHorizontalScroll(30, 350, 50)}/>
            <div className={styles.searchWrapper}>
                <SearchInput value={searchValue} setValue={setSearchValue} disableRightBorder disableBottomBorder
                             placeholder="Search"/>
            </div>
        </div>
    );
};
