import AbsenceDays from '../AbsenceDays/AbsenceDays';
import styles from './EmployeeWorkInfo.module.css';
import { HeartIcon, SunIcon } from '../../assets/icons/components';
import EmployeeEquipment from '../EmployeeEquipment/EmployeeEquipment';
import { useSelector } from 'react-redux';
import { getHRSelector } from '../../store/humanResources/selectors';
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import EmployeeRecentProjects from '../EmployeeRecentProjects/EmployeeRecentProjects';
import { FC, useEffect, useState } from 'react';
import { hrApi } from '../../services/hrApi/hrApi';
import { IRecentProject } from '../../services/hrApi/types';

interface IEmployeeWorkInfo {
  canEdit: boolean;
}
const EmployeeWorkInfo: FC<IEmployeeWorkInfo> = ({ canEdit }) => {
  const { paidCounts, membersProfile } = useSelector(getHRSelector);

  const navigate = useNavigate();

  const handleRedirectToMedicalLeave = (
    type: 'day-off' | 'sick-leave'
  ) => {
    canEdit
      ? navigate(
          routes.employeeProfile + `/${membersProfile?.id}/${type}`,
          { state: type }
        )
      : navigate(routes.myProfile + `/${type}`, { state: type });
  };
  const handleCreateDayOff = (type: 'day-off' | 'sick-leave') => {
    navigate(
      routes.employeeProfile +
        `/${membersProfile?.id}/${type}?openModal=true`,
      { state: type }
    );
  };
  const handleRequestClick = () => {
    'asd';
  };

  const [list, setList] = useState<IRecentProject[]>();
  const getList = async (id: number) => {
    const res = await hrApi.getRecentProjects(id);
    setList(res);
  };

  useEffect(() => {
    membersProfile?.id && getList(Number(membersProfile?.id));
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.absenceDaysWrapper}>
        <AbsenceDays
          usedDays={paidCounts.used_sick_leave_count}
          daysLeft={paidCounts.total_sick_leaves_count}
          icon={<HeartIcon />}
          title={'Medical Leave'}
          mainColor={styles.medicalLeaveMainColor}
          secondColor={styles.medicalLeaveSecondColor}
          onAddNewClick={() => handleCreateDayOff('sick-leave')}
          onViewAllClick={() =>
            handleRedirectToMedicalLeave('sick-leave')
          }
          onRequestClick={handleRequestClick}
          canEdit={canEdit}
        />
        <AbsenceDays
          usedDays={paidCounts.used_days_off_count}
          daysLeft={paidCounts.total_days_off_count}
          icon={<SunIcon />}
          title={'Days Off'}
          onAddNewClick={() => handleCreateDayOff('day-off')}
          mainColor={styles.daysOffMainColor}
          secondColor={styles.daysOffSecondColor}
          onViewAllClick={() =>
            handleRedirectToMedicalLeave('day-off')
          }
          onRequestClick={handleRequestClick}
          canEdit={canEdit}
        />
      </div>
      <EmployeeRecentProjects
        canEdit={canEdit}
        list={list}
        memberId={Number(membersProfile?.id)}
      />
      <EmployeeEquipment canEdit={canEdit} />
    </div>
  );
};

export default EmployeeWorkInfo;
