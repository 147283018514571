import { useEffect, useState } from 'react';
import styles from './DateRangePicker.module.css';
import moment from 'moment';
import { getMonthData } from '../../../helpers/date/get-month-date.helper';

export const useDateRange = (
  initialRange: Date[],
  minDate?: Date,
  maxDate?: Date
) => {
  const getMonth = () => date.getMonth();
  const getYear = () => date.getFullYear();
  const [date, setDate] = useState(new Date());

  const currentDate = new Date().toDateString();

  const [range, setRange] = useState<Date[]>(initialRange);
  useEffect(() => {
    setRange(initialRange);
  }, [JSON.stringify(initialRange)]);

  const handlePrevMonthButtonClick = () => {
    const date = new Date(getYear(), getMonth() - 1);

    setDate(date);
  };
  const handleNextMonthButtonClick = () => {
    const date = new Date(getYear(), getMonth() + 1);

    setDate(date);
  };

  const handleClearRange = (e: React.MouseEvent) => {
    e.stopPropagation();
    setRange([]);
  };

  const handleDayClick = (date: Date) => {
    if (range.length === 0) setRange([date]);
    if (typeof includedInDateRange(date, range) === 'string') {
      const filtered = range.filter(
        (item) => item.toString() !== date.toString()
      );
      return setRange(filtered);
    }

    const firstDate = range[0];
    const secondDate = date;

    setRange((prev) =>
      range[0]
        ? firstDate && firstDate < secondDate
          ? [prev[0], date]
          : [date, prev[0]]
        : [date]
    );
  };
  const includedInDateRange = (
    date: Date,
    range: Date[],
    include?: boolean
  ) => {
    if (range.length) {
      if (`${date}` === `${range[0]}`)
        return `${styles.includedBoxRight} ${
          !range[1] && styles.round
        }`;
      if (`${date}` === `${range[1]}`) return styles.includedBoxLeft;
      if (include && range[0] < date && date < range[1])
        return styles.includedBoxCenter;
    }
  };

  const monthData = getMonthData(
    getYear(),
    getMonth(),
    minDate,
    maxDate
  );

  const getDate = moment(
    new Date(
      moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate()
    )
  );
  const handleOnClickLastWeek = () => {
    setRange([
      getDate.subtract(6, 'days').toDate(),
      getDate.subtract(-6, 'days').toDate(),
    ]);
  };
  const handleOnClickLastTwoWeek = () => {
    setRange([
      getDate.subtract(13, 'days').toDate(),
      getDate.subtract(-13, 'days').toDate(),
    ]);
  };
  const handleOnClickLastMonthWeek = () => {
    setRange([
      getDate.subtract(30, 'days').toDate(),
      getDate.subtract(-30, 'days').toDate(),
    ]);
  };

  const handleOnClickAllTime = () => {
    setRange([new Date(2017), getDate.toDate()]);
  };

  return {
    date,
    handlePrevMonthButtonClick,
    handleNextMonthButtonClick,
    monthData,
    handleDayClick,
    includedInDateRange,
    range,
    currentDate,
    handleClearRange,
    handleOnClickLastWeek,
    handleOnClickLastTwoWeek,
    handleOnClickLastMonthWeek,
    handleOnClickAllTime,
    setDate,
  };
};
