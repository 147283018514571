import React from 'react';
import { IDayOffListItem } from '../../../services/hrApi/types';
import heartIcon from '../../../assets/icons/heart.svg';
import sunnyIcon from '../../../assets/icons/sunnyIcon.svg';
import { optionType } from '../../../components/ui/CustomSelect/useSelect';
export interface IMedicalLeaveDrawerContent {
  type: 'Sick Leave' | 'Day Off' | 'Leave';
  isEdit?: boolean;
  userId?: string;
  data?: IDayOffListItem | undefined;
  updateTable: () => void;
  handleClose: () => void;
  used?: number | undefined;
  total: number | undefined;
  leave?: boolean;
  members?: any;
  setId?: any;
  setTypeLeave?: any;
}
export interface IState {
  types: optionType | any;
  person: any;
}
export interface IInitialValues {
  day_off_type: string;
  user_id: number;
  is_self_paid: boolean;
  date_from: string;
  date_to: string;
  comment: string;
  documents: File | File[] | undefined;
}

export const optionLeave = [
  { name: 'Sick Leave', avatar: heartIcon },
  { name: 'Day Off', avatar: sunnyIcon },
];

export interface IUseSelect {
  smallPadding?: boolean;
  className?: string;
  classNameOptions?: string;
  onChangeValue?: (e: optionType) => void;
  rootRef: React.RefObject<HTMLDivElement>;
  selectRef: React.RefObject<HTMLDivElement>;
  height?: number;
  menuAbove?: boolean;
  withoutAnimation?: boolean;
}
