import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Avatar.module.css';
import { ImageFallback } from '../ui/ImageFallback/ImageFallback';

export interface AvatarProps {
  className?: string;
  image: string;
  name?: ReactNode;
  size?: 'small' | 'medium';
}

const Avatar = ({
  image,
  name,
  className,
  size = 'medium',
}: AvatarProps) => {
  return (
    <div className={clsx(styles.root, styles[size], className)}>
      <ImageFallback src={image} className={styles.image} alt='' />
      {name && <div className={styles.name}>{name}</div>}
    </div>
  );
};

export default Avatar;
