import { Navigate, Outlet, useLocation } from 'react-router-dom';

import routes from '../constants/routes';

const Dashboard = () => {
  const location = useLocation();

  return (
    location.pathname === routes.home ? (
      <Navigate to={routes.dashboardInfo} />
    ) : (
      <Outlet />
    )
  );
};

export default Dashboard;
