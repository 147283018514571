import { FC } from 'react';
import { ReactComponent as UserIcon } from '../../../../../../../assets/icons/user.svg';
import styles from './ContactsModal.module.css';
import { IContactsItem } from '../../../../../../../services/projectsApi/types';
import { timezone } from '../../../../../../../common/values/timezone';
interface IContactsList {
  list: IContactsItem[];
  onItemClick: (e: any) => void;
}
export const ContactsList: FC<IContactsList> = ({
  list,
  onItemClick,
}) => {
  return (
    <div className={styles.grid}>
      {list.map((contact, index: number) => (
        <div
          onClick={() => onItemClick(contact)}
          key={index}
          className={styles.card}>
          <div className={styles.cardHeader}>
            <div className={styles.cardIcon}>
              <UserIcon />
            </div>
            <p className={styles.contactName}>{contact.full_name}</p>
            <p className={styles.contactTime}>
              {
                timezone.filter(
                  (e) => e.value === contact.gmt_timezone
                )[0].name
              }
            </p>
          </div>
          <p className={styles.contactRole}>{contact.position}</p>
          <p className={styles.contactEmail}>{contact.email}</p>
        </div>
      ))}
    </div>
  );
};
