import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const getProposal = (state: RootState) => state.proposal;
export const getProposalSelector = createSelector(getProposal, ({ proposal }) => proposal);

const getProposals = (state: RootState) => state.proposal;
export const getProposalsSelector = createSelector(getProposals, (data) => data);

const getProposalsCounts = (state: RootState) => state.proposal;
export const getProposalsCountSelector = createSelector(getProposalsCounts, ({ counts }) => counts);
