const useRegex = async (input: string, pattern: RegExp): Promise<string> => {
    if (pattern.test(input)) {
        return input;
    } else {
        throw new Error('Please fill in your valid email address.');
    }
};

export default useRegex;

