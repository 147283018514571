import { FC, useState } from 'react';
import clsx from 'clsx';

import styles from './Tags.module.css';
import { CrossIcon } from '../../../../../../assets/icons/components';
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash-icon.svg';
import { useTagsFetch } from './useTagsFetch';
import Default from '../../../../../Default';

export const TagsFetchComponent: FC<{
  onClick: (e: React.MouseEvent) => void;
  selectedItems?: { name: string }[];
  fullWidth?: boolean;
  bgColor?: string;
  color?: string;
}> = ({ onClick, selectedItems, fullWidth, bgColor, color }) => {
  return (
    <div
      className={`${styles.container} ${
        fullWidth && styles.fullWidth
      } `}
      onClick={onClick}>
      {selectedItems?.map((item, index) => (
        <div key={index} className={styles.avatar}>
          <div
            className={styles.card}
            style={
              bgColor
                ? { backgroundColor: bgColor, borderColor: bgColor }
                : undefined
            }>
            <p
              className={styles.cardText}
              style={color ? { color } : undefined}>
              {item.name}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

interface ITagSelectItem {
  handleSelectItem: (item: { name: string; id: number }) => void;
  handleDeleteFilter: (id: number) => void;
  bgColor?: string;
  color?: string;
  item: { name: string; id: number };
}
const TagSelectItem: FC<ITagSelectItem> = ({
  handleSelectItem,
  handleDeleteFilter,
  bgColor,
  color,
  item,
}) => {
  const [mouseHover, setMouseHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      onClick={() => {
        handleSelectItem(item);
      }}
      className={styles.selectableCard}>
      <div
        className={styles.card}
        style={
          bgColor
            ? {
                backgroundColor: bgColor,
                borderColor: bgColor,
              }
            : undefined
        }>
        <p
          className={styles.cardText}
          style={color ? { color } : undefined}>
          {item.name}
        </p>
      </div>
      <div
        className={clsx(
          styles.additionalInfoContainer,
          mouseHover && styles.showIcon
        )}>
        {mouseHover && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteFilter(item.id);
            }}
            className={clsx(styles.icon, styles.iconTrash)}>
            <TrashIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export interface ITagsFetchSelect {
  onClick?: () => void;
  menuTitle?: string;
  selectedItems?: { name: string; id: number }[];
  tag?: boolean;
  color?: string;
  bgColor?: string;
  onFetch?: (items: { name: string; id: number }[]) => void;
  clickListener?: (func?: () => void) => void;
  isOpen?: boolean;
  handleClickOutSide?: (event: any, callback?: () => void) => void;
  baseUrl?: string;
}

const TagsFetchSelect: FC<ITagsFetchSelect> = ({
  menuTitle = 'Select tags or create new ones',
  selectedItems = [],
  color,
  bgColor,
  onFetch,
  baseUrl,
  isOpen,
}) => {
  const {
    handleOnBlur,
    handleRemoveItem,
    handleAddTag,
    handleSelectItem,
    handleDeleteItem,
    selected,
    setSearchValue,
    searchValue,
    loading,
    list,
  } = useTagsFetch(baseUrl, isOpen, onFetch, selectedItems);

  const height =
    30 +
    44 *
      ((list?.length && (list?.length > 5 ? 5 : list?.length)) || 1);

  const [isLeave, setIsLeave] = useState(false);
  return (
    <div
      tabIndex={Math.random()}
      onMouseLeave={() => setIsLeave(true)}
      onMouseEnter={() => setIsLeave(false)}
      onBlur={() => isLeave && handleOnBlur()}>
      <div className={styles.header}>
        <div className={styles.wrap}>
          {selected?.map((item, index) => (
            <div
              onClick={() => handleRemoveItem(item)}
              key={index}
              className={styles.containerCard}>
              <div
                className={styles.card}
                style={
                  bgColor
                    ? {
                        backgroundColor: bgColor,
                        borderColor: bgColor,
                      }
                    : undefined
                }>
                <p
                  className={styles.cardText}
                  style={color ? { color } : undefined}>
                  {item.name}
                </p>
                <CrossIcon
                  {...(color && {
                    stroke: color,
                  })}
                />
              </div>
            </div>
          ))}
        </div>
        <input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          className={styles.search}
          placeholder='Search'
          type='text'
          onKeyUp={handleAddTag}
        />
      </div>
      <div
        style={{
          height,
        }}
        className={styles.main}>
        <p className={styles.title}>{menuTitle}</p>
        {!loading ? (
          list?.length ? (
            list.map((item, index) => (
              <TagSelectItem
                item={item}
                handleSelectItem={handleSelectItem}
                handleDeleteFilter={handleDeleteItem}
                key={index}
                bgColor={bgColor}
                color={color}
              />
            ))
          ) : (
            <div className={styles.plug}>Not Found</div>
          )
        ) : (
          <div className={styles.plug}>
            <Default />
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsFetchSelect;
