import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { IHRFilters } from '../../../../services/hrApi/types';
// import { useGetTotalByDepartmentsQuery } from '../../../../store/humanResources/humanResourcesApi';
import { getHRSelector } from '../../../../store/humanResources/selectors';
import { getPositionsSelector } from '../../../../store/app/selectors';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useAppDispatch } from '../../../../store';
import {
  // archiveMember,
  getArchiveList,
  totalByDepartments as tByD,
} from '../../../../store/humanResources/thunks';
import { searchParamsToObject } from '../../../../helpers/object/searchParams-to-object.helper';
import { hrApi } from '../../../../services/hrApi/hrApi';
import { snackbarPosTopRight } from '../../../ui/Snackbar/constants';
import { enqueueSnackbar } from 'notistack';

export const useTable = (
  searchParams: URLSearchParams,
  setSearchParams: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >
) => {
  const params = searchParamsToObject(searchParams);

  const defaultFilter = () => {
    const newObject: IHRFilters = {
      departments: params.departments || [],
      positions: params.positions || [],
      tech_stacks: params.tech_stacks || [],
      seniority_levels: params.seniority_levels || [],
      forms_of_coops: params.forms_of_coops || [],
    };
    return newObject;
  };

  const [filter, setFilter] = useState<IHRFilters | undefined>(
    defaultFilter()
  );
  const [searchValue, setSearchValue] = useState('');

  const [rendered, setRendered] = useState(false);
  const {
    archiveList,
    archiveListIsEmpty,
    archiveListIsLoading,
    totalByDepartmentsArchive,
  } = useSelector(getHRSelector);
  const positions = useSelector(getPositionsSelector);

  const debouncedValue = useDebounce<string>(searchValue, 400);

  const dispatch = useAppDispatch();

  const listParams = (pageOne?: boolean) => {
    return {
      ...filter,
      ...params,
      size: 10,
      is_archived: true,
      page: pageOne ? 1 : params.page,
      search: debouncedValue,
    };
  };

  const getData = (pageOne?: boolean) => {
    dispatch(getArchiveList(listParams(pageOne)));
    dispatch(tByD(listParams(pageOne)));
  };

  useEffect(() => {
    rendered &&
      setSearchParams((prev) => {
        prev.delete('page');
        prev.delete('department');
        const params = searchParamsToObject(prev);
        return { page: 1, ...params, ...filter };
      });
  }, [filter]);

  useEffect(() => {
    getData();
    if (!rendered) setRendered(true);
  }, [JSON.stringify(params)]);

  useEffect(() => {
    if (rendered) getData(true);
  }, [debouncedValue]);

  const handleArchiveUser = async (
    user_id: number,
    is_archived: boolean,
    name?: string
  ) => {
    try {
      const res = await hrApi.archiveMember({ user_id, is_archived });
      enqueueSnackbar(
        name ? name + ' was unarchived' : 'Unarchived successfully',
        {
          anchorOrigin: snackbarPosTopRight,
          variant: 'success',
        }
      );
      if (res.msg === 'ok') getData();
    } catch (e: any) {
      enqueueSnackbar(e.response.data.detail, {
        anchorOrigin: snackbarPosTopRight,
        variant: 'error',
      });
    }
  };

  // const handleArchiveUser = (
  //     user_id: number,
  //     is_archived: boolean
  // ) => {
  //     console.log(2222)
  //     dispatch(
  //         archiveMember({
  //             data: { user_id, is_archived },
  //             getListData: listParams(),
  //         })
  //     );
  // };

  const handleRemoveUser = async (
    user_id: number,
    confirm: boolean
  ) => {
    try {
      const res = await hrApi.deleteMember({ user_id, confirm });
      enqueueSnackbar('Deleted succesfully', {
        anchorOrigin: snackbarPosTopRight,
        variant: 'success',
      });
      if (res.msg === 'ok') getData();
    } catch (e: any) {
      enqueueSnackbar(e.response.data.detail, {
        anchorOrigin: snackbarPosTopRight,
        variant: 'error',
      });
    }
  };

  return {
    filter,
    setFilter,
    searchValue,
    setSearchValue,
    data: archiveList,
    total: totalByDepartmentsArchive,
    positions,
    handleArchiveUser,
    handleRemoveUser,
    listIsEmpty: archiveListIsEmpty,
    listIsLoading: archiveListIsLoading,
  };
};
