import { createSlice } from '@reduxjs/toolkit';
import { projectApi } from './projectApi';
import {
  IListResponse,
  IProfileProjectResponse,
  IProjectFilters,
  IProjectsTotalByStatuses,
} from '../../services/projectsApi/types';
import {
  createFilter,
  deleteFilter,
  editFilter,
  editProfileProject,
  getFilters,
  getList,
  getProfileProject,
  totalByStatuses,
} from './thunks';

export interface ITechStack {
  name: string;
}

export interface IProjectTypes {
  option: any;
}

export interface IProject {
  id: number | null;
  created_at: Date | string;
  name: string;
  emoji: string;
  project_types: IProjectTypes[];
  tech_stacks: ITechStack[];
}

interface IInitialState {
  projectProfile?: IProfileProjectResponse;
  projectProfileIsLoading: boolean;
  filters: IProjectFilters[];
  filterCreateError: string;
  list?: IListResponse;
  listIsLoading: boolean;
  listIsEmpty: boolean;
  totalByStatuses?: IProjectsTotalByStatuses[];
}

const initialState: IInitialState = {
  projectProfile: undefined,
  filters: [],
  filterCreateError: '',
  projectProfileIsLoading: true,
  list: undefined,
  listIsLoading: true,
  listIsEmpty: false,
  totalByStatuses: [],
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getList.fulfilled, (state, action) => {
      state.list = action.payload;
      state.listIsLoading = false;
      state.listIsEmpty = false;
    });
    builder.addCase(getList.pending, (state, action) => {
      state.listIsLoading = true;
    });
    builder.addCase(getList.rejected, (state, action) => {
      state.listIsEmpty = true;
      state.listIsLoading = false;
    });
    builder.addCase(deleteFilter.fulfilled, (state, action) => {
      state.filters = state.filters.filter(
        (e) => e.name !== action.payload
      );
    });
    builder.addCase(totalByStatuses.fulfilled, (state, action) => {
      state.totalByStatuses = action.payload.data;
    });
    builder.addCase(createFilter.fulfilled, (state) => {
      state.filterCreateError = '';
    });
    builder.addCase(createFilter.rejected, (state, action) => {
      if (action.error.message)
        state.filterCreateError = action.error.message;
    });
    builder.addCase(editFilter.fulfilled, (state, action) => {
      state.filterCreateError = '';
    });
    builder.addCase(editFilter.rejected, (state, action) => {
      if (action.error.message)
        state.filterCreateError = action.error.message;
    });
    builder.addCase(getFilters.fulfilled, (state, action) => {
      if (action.payload) {
        state.filters = action.payload;
      }
    });
    builder.addCase(getProfileProject.fulfilled, (state, action) => {
      state.projectProfile = action.payload;
      state.projectProfileIsLoading = false;
    });
    builder.addCase(editProfileProject.fulfilled, (state, action) => {
      if (action.payload)
        state.projectProfile = {
          ...state.projectProfile,
          ...action.payload,
        };
    });
    builder.addCase(getProfileProject.pending, (state, action) => {
      state.projectProfileIsLoading = true;
    });
    builder.addCase(getProfileProject.rejected, (state, action) => {
      state.projectProfileIsLoading = false;
    });
    builder.addMatcher(
      projectApi.endpoints.getProject.matchFulfilled,
      (state, { payload }) => {
        state.projectProfile = payload;
      }
    );
  },
});

export default projectSlice.reducer;
