import React, {useEffect, useState} from 'react';
import Typography from '../../ui/Typography/Typography';
import styles from './Leave.module.css';
import {useTable} from '../HumanResourcesPage/components/Table/useTable';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGetQueryParams} from '../../../utils/common';
import {DepartmentList} from '../HumanResourcesPage/components/DepartmentList/DepartmentList';
import 'react-calendar-timeline/lib/Timeline.css';
import {IHRListParams} from '../../../services/hrApi/types';
import {DataGroupItem, Period, Options, ModalInfo} from './types';
import {hrApi} from '../../../services/hrApi/hrApi';
import CalendarTable from './Calendar/CalendarTable';
import {CalendarData, createGroups} from './Items';
import moment from 'moment/moment';
import {ReactComponent as ArrowLeft} from '../../../assets/icons/arrow-left.svg'
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-right.svg'
import {Moment} from 'moment';
import Button from '../../ui/Button';
import {CustomSelect} from '../../ui/CustomSelect/CustomSelect';
import {ReactComponent as Plus} from '../../../assets/icons/plus.svg';
import {ReactComponent as Summary} from '../../../assets/icons/presentation.svg';
import Drawer from '../../ui/Drawer/Drawer';
import {ReactComponent as CalendarIcon} from '../../../assets/icons/calendar-icon.svg';
import MedicalLeaveDrawerContent from '../../../pages/MedicalLeave/MedicalLeaveDrawerContent/MedicalLeaveDrawerContent';
import {useMedicalLeave} from '../../../pages/MedicalLeave/useMedicalLeave';
import {optionType} from '../../ui/CustomSelect/useSelect';
import {enqueueSnackbar} from 'notistack';
import {snackbarPosTopLeft} from '../../ui/Snackbar/constants';
import routes from '../../../constants/routes';
import Plug from '../HumanResourcesPage/components/Plug';
import {policyApi} from '../../../services/policyApi/policyApi';
import Switch from '../../ui/Switch';
import Default from '../../Default';
import {commonApi} from '../../../services/commonApi/commonApi';
import LeaveModal from './LeaveModal';
import LeaveTableModal from './LeaveTableModal';
import Modal from '../../ui/Modal';

const LeavePage = () => {
    const navigate = useNavigate()
    const params: IHRListParams = useGetQueryParams();
    const [period, setPeriod] = useState<Period>({name: 'Week'});
    const [viewAllUser, setViewAllUser] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const {searchValue, setSearchValue, positions, total, data,} = useTable(searchParams, setSearchParams);
    const [loading, setLoading] = useState<boolean>(true)
    const [openModal, setOpenModal] = useState<any>(ModalInfo)
    const [open, setOpen] = useState<boolean>(false)
    const [dataGroup, setDataGroup] = useState<DataGroupItem[]>([]);
    const [modifiedGroups, setModifiedGroups] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Moment>(moment().startOf('week'));
    const [endDate, setEndDate] = useState<Moment>(moment().endOf('week'));
    const currentMonth: string = startDate.format('MMMM YYYY');
    const [memberData, setMemberData] = useState<any>([])
    const [id, setId] = useState<string>('')
    const [typeLeave, setTypeLeave] = useState<string>('')
    const {
        drawerCreateShow,
        handleOpenEditDrawer,
        onDrawerCreateHandler,
        used,
        totals,
        selectedItem,
    } = useMedicalLeave(id, typeLeave);

    const handleChangeMonth = (increment: number) => {
        if (period.name === 'Month') {
            const newStartDate = startDate.clone().add(increment, 'months').startOf('month');
            setStartDate(newStartDate);
            setEndDate(newStartDate.clone().endOf('month'));
        } else {
            const newStartDate = startDate.clone().add(increment, 'week').startOf('week').add(1, 'day');
            setStartDate(newStartDate);
            setEndDate(newStartDate.clone().endOf('week').add(-1, 'day'));
        }
    };
    const handleCurrentMonth = () => {
        const newStartDate = moment().startOf(period.name === 'Month' ? 'month' : 'week').add(period.name === 'Month' ? 0 : 1, 'day');
        setStartDate(newStartDate);
        setEndDate(newStartDate.clone().endOf(period.name === 'Month' ? 'month' : 'week').add(period.name === 'Month' ? 0 : -1, 'day'));
    }

    useEffect(() => {
        period.name === 'Week' ? addWeekToStartDate() : handleCurrentMonth()
    }, [period]);
    const addWeekToStartDate = () => {
        const today = moment(); // Get the current date
        const startOfWeek = today.clone().startOf('week').add(1, 'day'); // Clone the date for immutability
        const endOfWeek = today.clone().endOf('week').add(-1, 'day'); // Clone the date for immutability
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
    };

    const getList = async () => {
        try {
            const res = await hrApi.getLeaveCalendar({
                date_from: startDate.format('YYYY-MM-DD'),
                date_to: endDate.format('YYYY-MM-DD'),
                search: searchValue,
                include_all_users: viewAllUser,
                department: params.department,
            });
            const userList = createGroups(res, period, setOpenModal);
            setDataGroup(userList);
            const modifiedGroups = CalendarData(res);
            setModifiedGroups(modifiedGroups);
            setLoading(false)
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await commonApi.getUsers();
                const modifiedRes: optionType[] = res.map(item => ({
                    name: item.full_name,
                    id: item.id,
                    avatar: item.avatar_url,
                }));
                setMemberData(modifiedRes);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData?.();
    }, []);
    const handleDeleteLeave = async (id: number) => {
        try {
            await hrApi.deleteDayOff(id);
            enqueueSnackbar('Deleted Succesfully', {
                variant: 'success',
                anchorOrigin: snackbarPosTopLeft,
            });
            await getList()
        } catch (e) {
            enqueueSnackbar('Not deleted', {
                variant: 'error',
                anchorOrigin: snackbarPosTopLeft,
            });
        }
    };
    const handleDeleteHoliday = async (id: number) => {
        try {
            await policyApi.deleteHoliday(id);
            enqueueSnackbar('Deleted Succesfully', {
                variant: 'success',
                anchorOrigin: snackbarPosTopLeft,
            });
            await getList()
        } catch (e) {
            enqueueSnackbar('Not deleted', {
                variant: 'error',
                anchorOrigin: snackbarPosTopLeft,
            });
        }
    }
    const handleEditLeave = (user: any) => {
        handleOpenEditDrawer(user)
        const dayOffType = user?.day_off_type;
        const variable = dayOffType === 'Sick Leave' ? 'sick-leave' : 'day-off';
        navigate(dayOffType === 'Holiday' ? routes.policyHolidays : `${routes.employeeProfile}/${user.user_id}/${variable}?id=${user.id}`);
    }


    useEffect(() => {
        getList().then(r => r)
    }, [endDate, startDate, viewAllUser, searchValue, params.department]);


    return (
        <div className={styles.leavePage}>
            <div className={styles.leavePageHead}>
                <Typography variant="h5" weight="bold">
                    Leave
                </Typography>
                <div className={styles.leavePageHeadRight}>
                    <Button color={'secondary'} className={styles.button} onClick={() => setOpen(true)}>
                        <Summary/>
                        <p>Summary</p>
                    </Button>
                    <Button color='black' className={styles.button} onClick={onDrawerCreateHandler}>
                        <Plus className={styles.iconStyle}/>
                        <p>Add Days off or Medical Leaves</p>
                    </Button>
                </div>
            </div>
            {loading ? <Default/> :
                <>
                    <div className={styles.navContainer}>
                        <DepartmentList
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            total={total}
                            params={params}
                            data={data}
                            positions={positions}
                        />
                    </div>
                    <div className={styles.selectPeriod}>
                        <div className={styles.clicker}>
                            <div className={styles.clickerLeft}>
                                <ArrowLeft onClick={() => handleChangeMonth(-1)}/>
                                <span>
              {period.name === 'Month' ? currentMonth : `${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`}</span>
                                <ArrowRight onClick={() => handleChangeMonth(1)}/>
                            </div>
                            <div className={styles.clickerLeftCheckbox}>
                                Show only those on leave
                                <Switch checked={!viewAllUser} onChange={() => setViewAllUser((prev) => !prev)}/>
                            </div>
                        </div>

                        <div className={styles.clickerRight}>
                            <Button color='secondary'
                                    onClick={handleCurrentMonth}>{period.name === 'Month' ? 'This month' : 'This week'}</Button>
                            <CustomSelect
                                value={period}
                                options={Options}
                                onChangeValue={(e) => setPeriod(e)}
                                disableAllSubTitles
                                smallPadding
                            />
                        </div>

                    </div>

                    {!loading && <>
                        {(dataGroup?.length === 0) ?
                            <div className={styles.tableEmpty}>
                                <Plug btn={false} subTitle={''}/>
                            </div>
                            :
                            <CalendarTable items={modifiedGroups} groups={dataGroup} key={modifiedGroups?.length}
                                           startDate={startDate}
                                           handleDeleteLeave={handleDeleteLeave}
                                           handleDeleteHoliday={handleDeleteHoliday}
                                           handleEditLeave={handleEditLeave}
                                           endDate={endDate}/>}
                    </>}
                    <Drawer
                        headerIcon={<CalendarIcon/>}
                        title={'Add Leave'}
                        classNameIcon={styles.daysOffSecondColor}
                        anchor='right'
                        open={drawerCreateShow}
                        classNames={styles.drawer}
                        onClose={onDrawerCreateHandler}>
                        <MedicalLeaveDrawerContent
                            type={'Leave'}
                            data={selectedItem}
                            members={memberData}
                            setId={setId}
                            updateTable={getList}
                            handleClose={onDrawerCreateHandler}
                            used={used}
                            total={totals}
                            setTypeLeave={setTypeLeave}
                            isEdit={false}
                            leave={true}
                        />
                    </Drawer>
                </>}
            <LeaveModal openModal={openModal} setOpenModal={setOpenModal} currentMonth={startDate}/>
            {<Modal
                isOpen={open}
                size
                className={styles.leaveTableModal}>
                <LeaveTableModal setOpen={setOpen}/>
            </Modal>}
        </div>
    );
};

export default LeavePage;
