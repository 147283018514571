import { memo } from 'react';
import clsx from 'clsx';

import { TableRowProps } from './TableRow.model';

import styles from './TableRow.module.css';

const TableRow = ({
                    className,
                    hover,
                    children,
                    onClick,
                    onOptionsClick,
                    options,
                    onMouseEnter,
                    onMouseLeave,
                    ...props
                  }: TableRowProps) => {

  const classNames = clsx(className, {
    [styles.hover]: hover,
    [styles.rowWrapper]: onOptionsClick,
  });

  return (
    <tr
      onClick={onClick}
      className={classNames}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}>
      {children}
    </tr>
  );
};

export default memo(TableRow);
