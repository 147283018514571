import { ILinksItem } from '../../../../../../services/projectsApi/types';

export const LinksParser = (data: ILinksItem[]) => {
  const categoriesMap = new Map();

  data.forEach((item: ILinksItem) => {
    const category = item.category;
    if (categoriesMap.has(category)) {
      categoriesMap.get(category).push(item);
    } else {
      categoriesMap.set(category, [item]);
    }
  });

  return Array.from(categoriesMap, ([category, items]) => ({
    category,
    items,
  }));
};
