import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as UsersIcon } from '../../../assets/icons/usersTotal.svg';
import { ReactComponent as UserPlusIcon } from '../../../assets/icons/userPlus.svg';
import { useDashboardPage } from '../useDashboardPage';
import { useTable } from '../../sharedPages/HumanResourcesPage/components/Table/useTable';
import Loader from '../../Loader/Loader';
import routes from '../../../constants/routes';
import {
  renderCard,
  renderCardTable,
  renderCardTableBirthday,
  renderCardTableLeave
} from './Cards';
import Typography from '../../ui/Typography';
import { getCanEditSelector, getProfileSelector } from '../../../store/profile/selectors';
import styles from './Card.module.css';

const Card: React.FC = () => {
  const user = useSelector(getProfileSelector);
  const canEdit = useSelector(getCanEditSelector);
  const navigate = useNavigate();
  const {
    dataList,
    dataHolidays,
    dataLeave,
    availableLeave,
    dataRequest,
    dataBirthday,
    loading
  } = useDashboardPage();
  const [searchParams, setSearchParams] = useSearchParams();
  const { total } = useTable(searchParams, setSearchParams);

  return (
    loading
      ? (<div className={styles.cardLoader}><Loader /></div>)
      : (
        <>
          <Typography variant="h5" weight="bold">
            Welcome back, {user?.first_name}!
          </Typography>
          <div className={styles.cardRoot}>
            <div className={clsx(styles.cardLeft, canEdit && styles.cardLeftRevers)}>
              {renderCardTableLeave({
                title: 'Your leave balance',
                type: 'leave',
                list: availableLeave,
              })}
              {renderCardTableLeave({
                title: 'Pending requests',
                type: 'requests',
                list: dataRequest,
                navigate: navigate,
                onClick: () => navigate(`${routes.requests}?page=1&sort_by_type=desc&sort_by=created_at`)
              })}
            </div>
            {canEdit &&
              <div className={styles.cardSecond}>
                <div className={styles.cards}>
                  {renderCard({
                    title: 'Employees',
                    icon: <UsersIcon />,
                    iconSecond: <UserPlusIcon />,
                    value: dataList?.total_employees,
                    valueSecond: dataList?.total_new_employees,
                  })}

                </div>
                {renderCardTable({
                  title: 'Department count',
                  navigate: navigate,
                  list: total,
                  type: 'department'
                })}

              </div>
            }
            <div className={styles.cardsRight}>
              {renderCardTableLeave({
                title: 'On leave today',
                subtitle: dataLeave?.total_items,
                possibility: canEdit,
                list: dataLeave?.items,
                navigate: navigate,
                onClick: () => navigate(routes.leave)
              })}
              {canEdit && <>
                {renderCardTableBirthday({
                  title: 'Upcoming birthdays',
                  possibility: canEdit,
                  list: dataBirthday,
                  navigate: navigate,
                  onClick: () => navigate(routes.leave)
                })}
              </>
              }
              {renderCardTable({
                  title: 'Upcoming holidays',
                  list: dataHolidays,
                  possibility: canEdit,
                  type: 'holiday',
                  onClick: () => navigate(routes.policyHolidays)
                }
              )}
            </div>
          </div>
        </>
      )
  );
};

export default Card;
