import {FC, useEffect, useState} from 'react';
import styles from './ProfileInfoCard.module.css';
import {ProfileCardInfo} from '../../../Projects/Profile/ProfileInformation/ProfileCardInfo/ProfileCardInfo';
import {IMembersProfile} from '../../../../services/hrApi/types';
import {ManagersComponent} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Managers/Managers';
import {DescriptionComponent} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Description/Description';
import {useSelects} from './useSelects';
import {TagsComponent} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Tags/Tags';
import {
    WorkScheduleComponent
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/WorkSchedule/WorkSchedule';
import {DateRangeComponent} from '../../../Projects/Profile/ProfileInformation/ProfileCards/DateRange/DateRange';
import {
    DateCalendarComponent
} from '../../../Projects/Profile/ProfileInformation/ProfileCards/DateCalendar/DateCalendar';
import {ProfileMainInfo} from './ProfileMainInfo/ProfileMainInfo';
import {LanguagesComponent} from '../../../Projects/Profile/ProfileInformation/ProfileCards/Languages/Languages';

interface IProfileInfoCard {
    profileInfo: IMembersProfile;
    canEdit: boolean;
}

const ProfileInfoCard: FC<IProfileInfoCard> = ({
                                                   profileInfo,
                                                   canEdit,
                                               }) => {
    const [membersProfile, setMembersProfile] = useState(profileInfo);
    useEffect(() => setMembersProfile(profileInfo), [profileInfo]);
    const {
        managersSelect,
        workEmailSelect,
        personalEmailSelect,
        phoneNumberSelect,
        addressSelect,
        deliverySelect,
        cooperationSelect,
        workScheduleSelect,
        probationDateSelect,
        dateCalendarSelect,
        skillsSelect,
        languagesSelect,
    } = useSelects(membersProfile, setMembersProfile);

    return (
        <div className={styles.wrapper}>
            <ProfileMainInfo
                canEdit={canEdit}
                membersProfile={membersProfile}
            />
            <div className={styles.divide}/>
            <div className={styles.marginVertical}>
                <div className={styles.optionsWrapper}>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        isAssignIcon
                        customTitle={styles.customTitle}
                        onClickPlug={managersSelect.handleShowOptions}
                        title='Team Manager'
                        plugTitle={canEdit ? 'Assign Managers' : 'Managers'}
                        component={
                            membersProfile.hr_manager && (
                                <ManagersComponent
                                    selectedItems={[
                                        {user: membersProfile.hr_manager},
                                    ]}
                                    onClick={managersSelect.handleShowOptions}
                                />
                            )
                        }>
                        {managersSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={workEmailSelect.handleShowOptions}
                        title='Work Email'
                        plugTitle={canEdit ? 'Add Work Email' : ' Work Email'}
                        fullWidth
                        component={
                            membersProfile.email_corporate && (
                                <DescriptionComponent
                                    onClick={workEmailSelect.handleShowOptions}
                                    text={membersProfile.email_corporate}
                                />
                            )
                        }>
                        {workEmailSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={personalEmailSelect.handleShowOptions}
                        title='Personal Email'
                        plugTitle={canEdit ? 'Add Personal Email' : ' Personal Email'}
                        fullWidth
                        component={
                            membersProfile.email_personal && (
                                <DescriptionComponent
                                    onClick={personalEmailSelect.handleShowOptions}
                                    text={membersProfile.email_personal}
                                />
                            )
                        }>
                        {personalEmailSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={phoneNumberSelect.handleShowOptions}
                        title='Phone Number'
                        plugTitle={canEdit ? 'Add Phone Number' : 'Phone Number'}
                        fullWidth
                        component={
                            membersProfile.phone && (
                                <DescriptionComponent
                                    onClick={phoneNumberSelect.handleShowOptions}
                                    text={membersProfile.phone}
                                />
                            )
                        }>
                        {phoneNumberSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={addressSelect.handleShowOptions}
                        title='Address'
                        plugTitle={canEdit ? 'Add Address' : 'Address'}
                        fullWidth
                        component={
                            membersProfile.address && (
                                <DescriptionComponent
                                    onClick={addressSelect.handleShowOptions}
                                    text={membersProfile.address}
                                />
                            )
                        }>
                        {addressSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={deliverySelect.handleShowOptions}
                        title='Delivery'
                        plugTitle={canEdit ? 'Add Delivery' : 'Delivery'}
                        fullWidth
                        component={
                            membersProfile.delivery_address && (
                                <DescriptionComponent
                                    onClick={deliverySelect.handleShowOptions}
                                    text={membersProfile.delivery_address}
                                />
                            )
                        }>
                        {deliverySelect.component}
                    </ProfileCardInfo>
                </div>
                <div className={styles.divide}/>
                <div className={styles.optionsWrapper}>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={cooperationSelect.handleShowOptions}
                        title='Form of cooperation'
                        plugTitle={canEdit ? 'Add Form of cooperation' : 'Form of cooperation'}
                        component={
                            membersProfile.forms_of_coops?.length && (
                                <TagsComponent
                                    //   color='#551BCE'
                                    //   bgColor='#F0EAFC'
                                    onClick={cooperationSelect.handleShowOptions}
                                    selectedItems={membersProfile.forms_of_coops.map(
                                        (item) => {
                                            return {name: item};
                                        }
                                    )}
                                />
                            )
                        }>
                        {cooperationSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={workScheduleSelect.handleShowOptions}
                        isCalendarIcon
                        title='Work schedule'
                        plugTitle={canEdit ? 'Choose Work schedule' : 'Work schedule'}
                        component={
                            membersProfile.working_time_from && (
                                <WorkScheduleComponent
                                    onClick={workScheduleSelect.handleShowOptions}
                                    from={membersProfile.working_time_from}
                                    to={membersProfile.working_time_to}
                                />
                            )
                        }>
                        {workScheduleSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={probationDateSelect.handleShowOptions}
                        isCalendarIcon
                        title='Probation Period'
                        plugTitle={canEdit ? 'Choose Probation Period' : 'Probation Period'}
                        component={
                            membersProfile.probation_date_from && (
                                <DateRangeComponent
                                    onClick={probationDateSelect.handleShowOptions}
                                    from={membersProfile.probation_date_from}
                                    to={membersProfile.probation_date_to}
                                />
                            )
                        }>
                        {probationDateSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={dateCalendarSelect.handleShowOptions}
                        isCalendarIcon
                        title='Joined'
                        plugTitle={canEdit ? 'Choose when joined' : 'When joined'}
                        component={
                            membersProfile.joined_at && (
                                <DateCalendarComponent
                                    onClick={dateCalendarSelect.handleShowOptions}
                                    date={membersProfile.joined_at}
                                />
                            )
                        }>
                        {dateCalendarSelect.component}
                    </ProfileCardInfo>
                </div>
                <div className={styles.divide}/>
                <div className={styles.optionsWrapper}>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={skillsSelect.handleShowOptions}
                        title='Skills'
                        plugTitle={canEdit ? 'Add Skills' : 'Skills'}
                        component={
                            membersProfile.tech_stacks?.length && (
                                <TagsComponent
                                    color='#551BCE'
                                    bgColor='#F0EAFC'
                                    onClick={skillsSelect.handleShowOptions}
                                    selectedItems={membersProfile.tech_stacks}
                                />
                            )
                        }>
                        {skillsSelect.component}
                    </ProfileCardInfo>
                    <ProfileCardInfo
                        canEdit={canEdit}
                        disableClick={!canEdit}
                        customTitle={styles.customTitle}
                        onClickPlug={languagesSelect.handleShowOptions}
                        title='Languages'
                        plugTitle={canEdit ? 'Add Languages' : 'Languages'}
                        component={
                            membersProfile.languages?.length && (
                                <LanguagesComponent
                                    onClick={languagesSelect.handleShowOptions}
                                    languages={membersProfile.languages}
                                />
                            )
                        }>
                        {languagesSelect.component}
                    </ProfileCardInfo>
                </div>
            </div>
        </div>
    );
};

export default ProfileInfoCard;
