import { FC } from 'react';
import styles from './ArchiveAccount.module.css';
import Modal from '../../../../ui/Modal/Modal';
import { ReactComponent as DogIcon } from '../../../../../assets/image/error-dog.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/archive.svg';
import Button from '../../../../ui/Button/Button';

interface IProps {
  isOpen: boolean;
  handleShowModal: () => void;
}
export const ArchiveAccountModal: FC<IProps> = ({
  isOpen,
  handleShowModal,
}) => {
  return (
    <Modal
      className={styles.archiveAccountModal}
      classNameOverlay={styles.overlay}
      isOpen={isOpen}
      onClose={handleShowModal}>
      <p className={styles.title}>
        Do you want to archive White Card?
      </p>
      <p className={styles.subTitle}>
        There are transactions on this account. Transactions will be
        saved, but you{' '}
        <span className={styles.strong}>
          cannot create new ones after archiving.
        </span>
      </p>
      <div className={styles.containerImage}>
        <DogIcon />
      </div>
      <div className={styles.containerButtons}>
        <Button
          onClick={handleShowModal}
          variant='outlined'
          className={styles.outlinedBtn}>
          Cancel
        </Button>
        <Button
          onClick={() => 'asd'}
          className={styles.archiveBtn}
          startIcon={TrashIcon}
          color='black'>
          Archive
        </Button>
      </div>
    </Modal>
  );
};
