import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const TrashIcon: React.FC<Props> = (props) => {
  const { color, size } = props

  return (
    <svg width={size || '22'} height={size || '22'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66667 6.41667H18.3333M9.16667 10.0833V15.5833M12.8333 10.0833V15.5833M4.58333 6.41667L5.5 17.4167C5.5 17.9029 5.69316 18.3692 6.03697 18.713C6.38079 19.0568 6.8471 19.25 7.33333 19.25H14.6667C15.1529 19.25 15.6192 19.0568 15.963 18.713C16.3068 18.3692 16.5 17.9029 16.5 17.4167L17.4167 6.41667M8.25 6.41667V3.66667C8.25 3.42355 8.34658 3.19039 8.51849 3.01849C8.6904 2.84658 8.92355 2.75 9.16667 2.75H12.8333C13.0764 2.75 13.3096 2.84658 13.4815 3.01849C13.6534 3.19039 13.75 3.42355 13.75 3.66667V6.41667" stroke={color || '#D52F6D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </svg>
  )
}
