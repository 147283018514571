import React, {useEffect, useState} from 'react';
import styles from '../../Request/TableHeaderRequest/TableHeader.module.css';
import DateRangePicker from '../../ui/DateRangePicker';
import {CustomSelect} from '../../ui/CustomSelect/CustomSelect';
import {IRequestFilter} from '../../../pages/Requests/Request.types';
import {ActionStatus, IManager} from '../types';
import {IFilters} from '../../../pages/Bench/BenchMember';
import {commonApi} from '../../../services/commonApi/commonApi';
import moment from 'moment';

interface ITableHeader {
    params: IRequestFilter;
    filters?: IFilters  | null;
    setFilters?: any;
}


const TableHeader: React.FC<ITableHeader> = ({params, filters, setFilters}) => {
    const [managers, setManagers] = useState<IManager>({options: [], selected: []});
    const getAllUsers = async () => {
        try {
            const res: any[] = await commonApi.getUsers({bench_decision_maker: true});
            const formattedManagers: any[] = res.map(manager => ({
                name: manager.full_name,
                id: manager.id,
            }));
            setManagers({...managers, options: formattedManagers});
        } catch (error) {
            console.error('Error occurred while fetching managers:', error);
        }
    };
    useEffect(() => {
        getAllUsers?.()
    }, []);

    const handleSelectChange = (selectedOptions: any[]) => { // Change parameter type
        const selectedNames = selectedOptions.map(option => option.name);
        const filters = managers.options.filter(option => selectedNames.includes(option.name));
        setFilters((prevState: any) => ({
            ...prevState,
            created_by: filters
        }));
    };
    const onChangeDate = (date: any) => {
        setFilters((prev: any) => ({
            ...prev,
            date_from: date[0]
                ? moment(date[0]).format('YYYY-MM-DD')
                : '',
            date_to: date[1]
                ? moment(date[1]).format('YYYY-MM-DD')
                : ''
        }));
    };
    const onChangeActions = (selectedActions: any) => {
        setFilters((prev: any) => ({
            ...prev,
            action: selectedActions
        }));
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftSide}>
                <DateRangePicker
                    isWeekend={true}
                    startDate={filters?.date_from}
                    endDate={filters?.date_to}
                    onChange={onChangeDate}
                />
                <CustomSelect
                    multi
                    detailedInputStyle={styles.customInput}
                    disableAllSubTitles
                    defaultAllSelected
                    options={ActionStatus}
                    onChangeMultiValue={onChangeActions}
                    placeholder="Action"
                    multiAllTitle="Select All"
                    limitedViewOption
                />
                <CustomSelect
                    multi
                    detailedInputStyle={styles.customInput}
                    disableAllSubTitles
                    limitedViewOption
                    defaultAllSelected
                    options={managers.options}
                    onChangeMultiValue={handleSelectChange}
                    defaultOptions={managers.options}
                    placeholder="Decision Maker"
                    multiAllTitle="Select All"
                />
            </div>
        </div>
    );
};

export default TableHeader;

