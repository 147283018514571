import React, {useState} from 'react';
import clsx from 'clsx';
import {useSelector} from 'react-redux';
import styles from '../Leave.module.css';
import Table from '../../../../components/ui/Table';
import TableRow from '../../../../components/ui/TableRow';
import TableCell from '../../../../components/ui/TableCell';
import {ReactComponent as CalendarIcon} from '../../../../assets/icons/calendar-icon.svg';
import {ReactComponent as SunIcon} from '../../../../assets/icons/sunnyIcon.svg'
import {ReactComponent as HeartIcon} from '../../../../assets/icons/heart.svg';
import {getCanEditSelector} from '../../../../store/profile/selectors';
import {IListRequest} from '../../../../pages/Requests/Request.types';

interface RequestTableProps {
    data: any[];
    setUpdate?: any
}

interface Request {
    data?: IListRequest
    popup?: boolean
}

const initData: Request = {
    data: {},
    popup: false
}

const RequestTable: React.FC<RequestTableProps> = ({data}) => {
    const [currentRequest, setCurrentRequest] = useState(initData)
    const canEdit = useSelector(getCanEditSelector);
    const tableRowStyles = clsx(styles.rootTableRowTD);
    console.log('currentRequest',currentRequest)
    const handleRequest = (data: IListRequest) => {
        setCurrentRequest({
            data: data,
            popup: true
        })
    }
    return (
        <Table tableContainerClass={styles.tbody} className={clsx(styles.table, styles.tableLeaves)}>
            <thead>
            <TableRow className={styles.thead}>
                <TableCell columnClass={styles.clear} component='th'>member</TableCell>
                <TableCell align='center' component='th'><HeartIcon/>Medical leave</TableCell>
                <TableCell align='center' component='th'><SunIcon/> Days Off</TableCell>
                <TableCell align='center' component='th'><CalendarIcon/> total leave</TableCell>
            </TableRow>
            </thead>
            <tbody className={styles.tbody}>
            {data?.map((el: any, index) => {
                return (
                    <TableRow
                        key={index}
                        onClick={() => canEdit && handleRequest(el)}
                        className={tableRowStyles}>
                        <TableCell className={clsx(styles.tableInfo, styles.tableInfoFirst)}>
                            <img src={el.user_avatar_url} alt={'avatar'}/>
                            <p>{el.user_first_name} {el.user_last_name}</p>
                        </TableCell>
                        <TableCell align='center' className={styles.tableInfo}>
                            <p>{el.sick_leave_count === 1 ? '1 Day' : `${el.sick_leave_count} Days`}</p>
                        </TableCell>
                        <TableCell align='center' className={styles.tableInfo}>
                            <p>{el.day_off_count === 1 ? '1 Day' : `${el.day_off_count} Days`}</p>
                        </TableCell>
                        <TableCell align='center' className={styles.tableInfo}>
                            {el.sum_count === 1 ? '1 Day' : `${el.sum_count} Days`} {/* Whatever you want to render here */}
                        </TableCell>
                    </TableRow>
                );
            })}
            </tbody>
        </Table>
    );
};

export default RequestTable;
