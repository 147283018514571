import API from './http';
import { PasswordRecoveryData, RestorePasswordData } from '../types/auth';

export const authApi = {
  logout: () => API.post('/user/logout'),
  forgotPasswordRecovery: (data: RestorePasswordData) => {
    return API.post<RestorePasswordData>('/user/forgot_password/send_recovery_link', data);
  },
  forgotPasswordSetNewPassword: (data: PasswordRecoveryData) => {
    return API.post<PasswordRecoveryData>('/user/forgot_password/set_new_password', data);
  },
};
