import { FC } from 'react';
import styles from './styles.module.css';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plus.svg';
import { ReactComponent as IceCream } from '../../../../../../../assets/image/ice-cream.svg';
import Default from '../../../../../../Default';
interface IProfileWrapper {
  onClose: () => void;
  isLoading?: boolean;
}
export const PlugWrapper: FC<IProfileWrapper> = ({
  onClose,
  isLoading,
}) => {
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Default />
      ) : (
        <>
          <div className={styles.header}>
            <div />
            <div onClick={onClose} className={styles.btnIcon}>
              <PlusIcon />
            </div>
          </div>
          <div className={styles.plugSection}>
            <IceCream />
            <p className={styles.plugTitle}>🫥 No Results</p>
          </div>
          <div />
        </>
      )}
    </div>
  );
};
