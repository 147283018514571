import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: ApiBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getPositions: builder.query({
      query: () => ({ url: '/positions', method: 'get' }),
    }),
  }),
});

export const { useGetPositionsQuery } = appApi;
