import React from 'react';
import Modal from '../../ui/Modal';
import defaultAvatar from '../../../assets/icons/movadex.png';
import heart from '../../../assets/icons/favicons/heart.svg';
import sunny from '../../../assets/icons/favicons/sunny.svg';
import styles from './Leave.module.css';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-modal.svg';
interface DayOff {
    date_from: string;
    date_to: string;
    day_off_type: 'Sick Leave' | 'Day Off';
    half_day?: boolean;
    is_self_paid: boolean;
}

interface Info {
    days_off: DayOff[];
}

const LeaveModal: React.FC<{ openModal: any, setOpenModal: any, currentMonth: any }> = ({ openModal, setOpenModal, currentMonth }) => {
    const handleClose = () => {
        setOpenModal((prev: any) => ({ ...prev, info: null, open: false }))
    }
    const countLeaves = (openModal: { info: Info }) => {
        const { info } = openModal;
        if (!info) return { sickLeaveCount: 0, dayOffCount: 0, sickLeaveCountSelf: 0, dayOffCountSelf: 0 };
        let sickLeaveCount = 0;
        let dayOffCount = 0;
        let sickLeaveCountSelf = 0;
        let dayOffCountSelf = 0;

        info.days_off.forEach((day: DayOff) => {
            const fromDate = new Date(day.date_from);
            const toDate = new Date(day.date_to);
            let daysDiff = Math.ceil((toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
            if (day.half_day) daysDiff -= 0.5; // Subtract 0.5 if it's a half-day

            if (day.day_off_type === 'Sick Leave') {
                sickLeaveCount += daysDiff;
                if (day.is_self_paid) sickLeaveCountSelf += daysDiff;
            } else if (day.day_off_type === 'Day Off') {
                dayOffCount += daysDiff;
                if (day.is_self_paid) dayOffCountSelf += daysDiff;
            }
        });

        return { sickLeaveCount, dayOffCount, sickLeaveCountSelf, dayOffCountSelf };
    };

    const { sickLeaveCount, dayOffCount, sickLeaveCountSelf, dayOffCountSelf } = countLeaves(openModal);


    return (
        <Modal isOpen={openModal.open}
               className={styles.leaveModal}
               onClose={handleClose}>
            {openModal.info && (
                <div>
                    <div className={styles.leaveModalTitle}>
                        <span>Leave Summary</span>
                        <span onClick={handleClose}><CloseIcon/></span>
                    </div>
                    <div className={styles.leaveModalHead}>
                        <img src={openModal.info.avatar_url || defaultAvatar} alt={'account_image'} width="20" height="20" />
                        <div>
                            <span>{openModal.info.full_name}</span>
                            <span>{openModal?.info?.days_off[0]?.positions.map((e: any) => e.name).join(', ')}</span>
                        </div>
                    </div>
                    <div className={styles.leaveModalMonth}>
                        <span>{currentMonth.format('MMM YYYY')}</span>
                        <span>Used Days</span>
                    </div>
                    <div className={styles.leaveModalCount}>
                        <div>
                            <img src={heart} alt={'heart'} />
                            <div>
                                <span>Medical Leave</span>
                                <span>Where self-paid</span>
                            </div>
                            <div>
                                <span>{sickLeaveCount}</span>
                                <span>{sickLeaveCountSelf}</span>
                            </div>
                        </div>
                        <div>
                            <img src={sunny} alt={'sunny'} />
                            <div>
                                <span>Days Off</span>
                                <span>Where self-paid</span>
                            </div>
                            <div>
                                <span>{dayOffCount}</span>
                                <span>{dayOffCountSelf}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default LeaveModal;
