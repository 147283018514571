import { createSlice } from '@reduxjs/toolkit';

import { documentsApi } from './documentsApi';

export type DocumentsState = {
  list: any
}

const initialState: DocumentsState = {
  list: {},

};

const documentsSlice = createSlice(({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      documentsApi.endpoints.getDocuments.matchFulfilled,
      (state, { payload }) => {
        state.list = payload;
      });
  }
}));

export default documentsSlice.reducer;
