import React, {FC} from 'react';
import Modal from '../../../../ui/Modal/Modal';
import moment from 'moment';
import {IPropsModal} from '../../../../sharedPages/LeavePage/types';
import styles from '../DeleteAccount/DeleteAccount.module.css'
import {ReactComponent as BasketFireIcon} from '../../../../../assets/image/busket-fire.svg';
import {ReactComponent as TrashIcon} from '../../../../../assets/icons/trash-icon.svg';
import Button from '../../../../ui/Button/Button';
import {ReactComponent as HeartIcon} from '../../../../../assets/icons/heart.svg';
import {ReactComponent as HolidayIcon} from '../../../../../assets/icons/holidaysIcon.svg';
import {ReactComponent as SunIcon} from '../../../../../assets/icons/sunnyIcon.svg';
import {CalendarIcons} from '../../../../../assets/icons/components/CalendarIcons';
import {ReactComponent as Cancel} from '../../../../../assets/icons/cancleIcon.svg';
import {ReactComponent as PencilIcon} from '../../../../../assets/icons/pencil.svg';
import defaultAvatar from '../../../../../assets/icons/movadex.png';
import {getTotalDays} from '../../../../../utils/toHoursAndMinutes';

export const DeleteModal: FC<IPropsModal> = (props) => {
    const {
        isOpen,
        title = 'Do you want to delete this document?',
        subTitle = 'Are you sure you want to delete this document? You won’t be able to restore it.',
        handleSubmit,
        handleShowModal,
        deleteModal,
        handleEditLeave,
        handleDelete,
        handleDeleteHoliday,
        data,
        leave,
    } = props
    const isSickLeave = data?.day_off_type === 'Sick Leave';
    const isHolidays = data?.day_off_type === 'Holiday';
    const iconColor = isSickLeave ? '#D52F6D' : (isHolidays ? '#018782' : '#E0B457');
    return (
        <Modal
            className={leave ? styles.deleteLeaveModal : styles.deleteAccountModal}
            classNameOverlay={styles.overlay}
            isOpen={isOpen}
            onClose={handleShowModal}
        >
            {leave ? (
                <div className={styles.header}>
                    <p className={styles.title}>
                        {(isSickLeave && <HeartIcon/>) || (isHolidays ? <HolidayIcon/> : <SunIcon/>)}
                        {!isHolidays ? data?.day_off_type : data?.comment}
                    </p>
                    <Cancel onClick={handleShowModal}/>
                </div>
            ) : (
                <p className={styles.title}>{title}</p>
            )}
            {leave &&
              <div className={styles.leaveModalHead}>
                <img src={data?.avatar_url || defaultAvatar} alt={'account_image'} width="20" height="20"/>
                <div>
                  <span>{data?.full_name}</span>
                  <span>{data && data.positions.map((e: any) => e.name).join(', ')}</span>
                </div>
              </div>}
            {!leave && <p className={styles.subTitle}>{subTitle}</p>}
            {leave && (
                <div className={styles.leaveInfo}>
                    <CalendarIcons stroke={iconColor}/>
                    <div>
                        <p>{data?.date_from === data?.date_to ? 'On' : 'From'}</p>
                        <span>{moment(data?.date_from).format('DD MMM YYYY')}</span>
                    </div>
                    {data?.date_from !== data?.date_to &&
                      <span className={styles.leaveInfoArrow}>{'->'}</span>}
                    {data?.date_from !== data?.date_to &&
                      <div>
                        <p>To</p>
                        <span>{moment(data?.date_to).format('DD MMM YYYY')}</span>
                      </div>}
                </div>
            )}
            {!leave && (
                <div className={styles.containerImage}>
                    <BasketFireIcon/>
                </div>
            )}
            {leave &&
              <div className={styles.containerTags}>
                <span>{getTotalDays(data?.date_from, data?.date_to)}</span>
                  {data?.is_self_paid && <span>Self paid</span>}
                  {data?.half_day && <span>Half-day</span>}
              </div>}

            <div className={styles.containerButtons}>
                {!deleteModal ?
                    <Button
                        onClick={!leave ? handleShowModal : () => handleEditLeave(data)}
                        variant="outlined"
                        startIcon={PencilIcon}
                        className={styles.outlinedBtn}
                    >
                        Edit
                    </Button> : <Button
                        onClick={handleShowModal}
                        variant="outlined"
                        className={styles.outlinedBtn}
                    >
                        Cancel
                    </Button>}
                <Button
                    onClick={() => {
                        if (leave && !isHolidays) {
                            handleDelete && handleDelete(data?.id);
                        } else if (isHolidays) {
                            handleDeleteHoliday && handleDeleteHoliday(data?.id);
                        } else {
                            handleSubmit && handleSubmit();
                        }
                    }}
                    startIcon={TrashIcon} className={styles.wineBtn} color="black">
                    Delete
                </Button>
            </div>
        </Modal>
    );
};
