import { useState } from 'react';

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [topMargin, setTopMargin] = useState(0);
  const isOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setTopMargin(event.pageY);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return {
    isOpen,
    anchorEl,
    handleMenuOpen,
    handleMenuClose,
    topMargin,
  };
};
