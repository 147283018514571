import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import Button from '../../ui/Button';
import { SearchInput } from '../../ui/SearchInput/SearchInput';
import styles from './TableHeader.module.css';
import DateRangePicker from '../../ui/DateRangePicker';
import { CustomSelect } from '../../ui/CustomSelect/CustomSelect';
import moment from 'moment';
import { optionType } from '../../ui/CustomSelect/useSelect';
import {
  ITimeTrackingFilter,
  ITimeTrackingFilterOptions,
  toggleType,
} from '../../../pages/TimeTracking/TimeTracking.types';
import { useDebounce } from '../../../hooks/useDebounce';

const showBy = [
  { name: 'Show by Project' },
  { name: 'Show by Member' },
];

interface ITableHeader {
  listUsers: optionType[];
  listProjects: optionType[];
  setSearchParams: React.Dispatch<
    React.SetStateAction<ITimeTrackingFilter>
  >;
  params: ITimeTrackingFilter;
}

export const TableHeader: FC<ITableHeader> = ({
  params,
  listUsers,
  listProjects,
  setSearchParams,
}) => {
  const toggleData: toggleType[] = ['Work Summary', 'Timesheet', 'Submissions'];
  const [searchValue, setSearchValue] = useState(params.search || '');
  const debouncedValue = useDebounce<string>(searchValue, 400);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (rendered) {
      setSearchParams(() => {
        params.search = searchValue;
        if (!params.search) delete params.search;
        return { ...params };
      });
    } else {
      setRendered(true);
    }
  }, [debouncedValue]);

  const handleChangeItem = (name: toggleType) => {
    return () => {
      setSearchParams(() => {
        return { ...params, toggle: name };
      });
    };
  };

  const handleChangeSelectData = (
    nameKey: string,
    data: optionType[]
  ) => {
    if (
      JSON.stringify(
        params[nameKey as keyof ITimeTrackingFilterOptions]
      ) === JSON.stringify(data.map((e) => e.name)) ||
      JSON.stringify([
        params[nameKey as keyof ITimeTrackingFilterOptions],
      ]) === JSON.stringify(data.map((e) => e.name)) ||
      (!params[nameKey as keyof ITimeTrackingFilterOptions] &&
        !data.map((e) => e.name).length)
    ) {
      ('none');
    } else {
      setSearchParams(() => {
        params[nameKey as keyof ITimeTrackingFilterOptions] =
          data.map((e) => e.name);
        return { ...params };
      });
    }
  };

  const handleChangeShowBy = (e: optionType) => {
    setSearchParams(() => {
      if (e.name && params.show_by !== e.name) {
        params.show_by = e.name;
      } else return params;
      return { ...params };
    });
  };

  const handleChangeDateRange = (el: Date[]) => {
    const dateToYmd = (date: Date) => {
      return moment(date).format('YYYY-MM-DD');
    };
    setSearchParams(() => {
      if (el[0]) {
        params.date_from = dateToYmd(el[0]);
      } else {
        delete params.date_from;
      }
      if (el[1]) {
        params.date_to = dateToYmd(el[1]);
      } else {
        delete params.date_to;
      }
      return {
        ...params,
      };
    });
  };

  const handleResetPeriod = () => {
    setSearchParams(() => {
      delete params.date_from;
      delete params.date_to;
      delete params.members;
      delete params.projects;
      return { ...params };
    });
  };
  return (
    <>
      <div className={styles.navContainer}>
        <div>
          {toggleData.map((item, index) => (
            <Button
              variant='text'
              key={index}
              disabled={
                (!params.toggle && item === 'Work Summary') ||
                params.toggle === item
              }
              onClick={handleChangeItem(item)}
              className={clsx(
                styles.tabItem,
                !params.toggle
                  ? item === 'Work Summary' && styles.tabItemActive
                  : params.toggle === item && styles.tabItemActive,
                ((!params.toggle && item === 'Work Summary') ||
                  params.toggle === item) &&
                  styles.disableCursor
              )}>
              <p
                className={clsx([
                  {
                    [styles.font500]: !params.toggle
                      ? item === 'Work Summary' &&
                        styles.tabItemActive
                      : params.toggle === item &&
                        styles.tabItemActive,
                  },
                ])}>
                {item}
              </p>
            </Button>
          ))}
        </div>
        <div>
          <SearchInput
            value={searchValue}
            setValue={setSearchValue}
            placeholder='Search'
            disableBottomBorder
            disableRightBorder
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <DateRangePicker
            startDate={params?.date_from}
            endDate={params?.date_to}
            isWeekend={true}
            onChange={handleChangeDateRange}
          />
          {!params.toggle ||
          (params.toggle === 'Submissions') ||
          (params.toggle === 'Timesheet' )
            ||
          (params.toggle === 'Work Summary' &&
            listProjects.length) ? (
            <CustomSelect
              multiSearch
              multiSearchPlaceholder='Type to search'
              customTitle={`Projects${
                typeof params?.projects === 'string'
                  ? ` (${[params?.projects].length})`
                  : ` (${params?.projects?.length})`
              }`}
              detailedInputStyle={styles.customInput}
              disableAllSubTitles
              detailedInput
              defaultAllSelected
              options={listProjects}
              defaultOptions={(typeof params?.projects === 'string'
                ? [params?.projects]
                : params?.projects
              )?.map((e) => {
                return {
                  name: e,
                  label: listProjects.find((el) => el.name === e)
                    ?.label,
                };
              })}
              onChangeMultiValue={(e) =>
                handleChangeSelectData('projects', e)
              }
              multi
              placeholder='Projects (All)'
              multiAllTitle='All Projects'
            />
          ) : undefined}
          {!params.toggle ||
          (params.toggle === 'Submissions') ||
          (params.toggle === 'Timesheet') ||
          (params.toggle === 'Work Summary' && listUsers.length) ? (
            <CustomSelect
              multiSearch
              multiSearchPlaceholder='Type to search'
              detailedInputStyle={styles.customInput}
              disableAllSubTitles
              detailedInput
              defaultAllSelected
              customTitle={`Members${
                typeof params?.members === 'string'
                  ? ` (${[params?.members].length})`
                  : ` (${params?.members?.length})`
              }`}
              options={listUsers}
              defaultOptions={(typeof params?.members === 'string'
                ? [params?.members]
                : params?.members
              )?.map((e) => {
                return {
                  name: e,
                  label: listUsers.find((el) => el.name === e)?.label,
                };
              })}
              onChangeMultiValue={(e) =>
                handleChangeSelectData('members', e)
              }
              multi
              placeholder='Members (All)'
              multiAllTitle='All Members'
            />
          ) : undefined}
          {(params.date_from ||
            params.members ||
            params.projects) && (
            <Button
              onClick={handleResetPeriod}
              className={styles.rootResetBtn}>
              Reset
            </Button>
          )}
        </div>
        {params.toggle !== 'Submissions' &&
        <div>
          <CustomSelect
            detailedInputStyle={styles.customInputShowBy}
            disableAllSubTitles
            detailedInput
            defaultOptions={
              !params.show_by
                ? [showBy[0]]
                : [{ name: params.show_by }]
            }
            onChangeValue={handleChangeShowBy}
            options={showBy}
          />
        </div>}
      </div>
    </>
  );
};
