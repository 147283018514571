import clsx from 'clsx';
import { ReactNode } from 'react';

import { capitalizeFirstLetter } from '../../../utils/common';

import styles from './Stack.module.css';

export interface StackProps {
  align?: 'start' | 'center' | 'end';
  className?: string;
  children?: ReactNode;
  direction?: 'column' | 'row';
  justify?: 'start' | 'center' | 'end' | 'between' | 'around';
  fullWidth?: boolean;
  space?: 0 | 1 | 2 | 3 | 4;
  wrap?: boolean;
}

const Stack = (
  {
    align,
    className,
    children,
    direction = 'row',
    fullWidth,
    space = 1,
    justify,
    wrap,
    ...props
  }: StackProps) => {
  const classNames = clsx(
    styles.root,
    styles[direction],
    align && styles[`align${capitalizeFirstLetter(align)}`],
    justify && styles[`justify${capitalizeFirstLetter(justify)}`],
    space && styles[`space${space}`],
    {
      [styles.wrap]: wrap,
      [styles.fullWidth]: fullWidth,
    },
    className,
  );
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export default Stack;
