import { createAsyncThunk } from '@reduxjs/toolkit';
import { proposalApi } from '../../services/proposalApi';
import { IProposal, IProposalQueryParams } from '../../services/proposalApi/types';
import { ApiListResponse } from '../../types/api';

export const getList = createAsyncThunk(
  'getProposals',
  async (data: IProposalQueryParams): Promise<ApiListResponse<IProposal>> => {
    return await proposalApi.list(data);
  }
);

export const getProposalCounts = createAsyncThunk(
  'getProposalCounts',
  async (data: IProposalQueryParams): Promise<Array<{ count: number; status: string }>> => {
    return await proposalApi.listCounts(data);
  }
);

export const getProposal = createAsyncThunk(
  'getProposal',
  async (id: number) => {
    const res = await proposalApi.getProposal(id);
    return res as IProposal;
  }
);
