import clsx from 'clsx';

import Typography from '../Typography';

import { InputErrorProps } from './InputError.model';

import styles from './InputError.module.css';

const InputError = ({ className, children, ...props }: InputErrorProps) => {
  const classNames = clsx(
    styles.root,
    className,
  );

  return (
    <Typography component="p" color="error" variant="smallText" className={classNames} {...props}>
      {children}
    </Typography>
  );
};

export default InputError;
