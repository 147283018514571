import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '../../../ui/Button/Button';
import styles from './TableHeader.module.css';
import Badge from '../../../ui/Badge/Badge';
import { SearchInput } from '../../../ui/SearchInput/SearchInput';
import { PlusIcon } from '../../../../components/SvgIcons';
import Drawer from '../../../ui/Drawer/Drawer';

import { ReactComponent as CreditCard } from '../../../../assets/icons/credit-card.svg';
import { ViewOptionDrawer } from './ViewOptionDrawer/ViewOptionDrawer';
import { QuickFilter } from '../../../ui/QuickFilter/QuickFIlter';
import { FilterForm } from './FilterForm/FilterForm';
import { useSelector } from 'react-redux';
import { getProjectFiltersSelector } from '../../../../store/project/selectors';
import {
  IProjectFilters,
  IProjectsListParams,
  IProjectsTotalByStatuses,
} from '../../../../services/projectsApi/types';
import { searchParamsToObject } from '../../../../helpers/object/searchParams-to-object.helper';
import { useAppDispatch } from '../../../../store';
import { deleteFilter } from '../../../../store/project/thunks';
import { statuses } from '../../../../common/values/values';
import { IGetUsersResponse } from '../../../../services/commonApi/types';
import { commonApi } from '../../../../services/commonApi/commonApi';
import { projectsApi } from '../../../../services/projectsApi/projectsApi';

interface IFilter {
  project_services: string[];
  manager_ids: number[];
  client_names: string[];
  product_industries: string[];
  member_ids: number[];
}

interface ITableHeader {
  params: IProjectsListParams;
  total?: IProjectsTotalByStatuses[];
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchParams?: URLSearchParams;
  setSearchParams?: React.Dispatch<
    React.SetStateAction<URLSearchParams>
  >;
  filter?: IFilter;
  setFilter: React.Dispatch<
    React.SetStateAction<IFilter | undefined>
  >;
}
export const TableHeader: FC<ITableHeader> = ({
  params,
  total,
  searchValue,
  setSearchValue,
  searchParams,
  setSearchParams,
  filter,
  setFilter,
}) => {
  const [PM, setPM] = useState<IGetUsersResponse[]>();
  const [HR, setHR] = useState<IGetUsersResponse[]>();
  const [clientList, setClientList] = useState<{ name: string }[]>();

  const fetchOptions = async (
    position: string,
    setState?: React.Dispatch<IGetUsersResponse[]>,
    setStateClient?: React.Dispatch<{ name: string }[]>
  ) => {
    if (setStateClient) {
      const res = await projectsApi.clients_list();
      setStateClient(res);
      return;
    }
    const res = await commonApi.getUsers({ position });
    setState && setState(res);
  };

  useEffect(() => {
    fetchOptions('Project Manager', setPM);
    fetchOptions('', setHR);
    fetchOptions('clientList', undefined, setClientList);
  }, []);

  const [showTableViewDrawer, setShowTableViewDrawer] =
    useState(false);

  const handleShowTableViewDrawer = () => {
    setShowTableViewDrawer((prev) => !prev);
  };

  const filters = useSelector(getProjectFiltersSelector);

  const handleSetStatus = (type?: string) => {
    return () => {
      if (type) {
        setSearchParams &&
          setSearchParams((prev) => {
            prev.delete('page');
            prev.delete('project_statuses');
            const params: any = searchParamsToObject(prev);
            return { page: '1', project_statuses: type, ...params };
          });
      } else {
        setSearchParams &&
          setSearchParams((prev) => {
            prev.delete('project_statuses');
            prev.delete('page');
            const params: any = searchParamsToObject(prev);
            return { ...params };
          });
      }
    };
  };
  const dispatch = useAppDispatch();

  const handleDeleteItemInFilter = async (name: string) => {
    await dispatch(deleteFilter(name));
  };
  const handleClickCurrentFilter = (item: IProjectFilters) => {
    const object = { ...item };
    object?.name && delete object.name;
    object?.created_at && delete object?.created_at;
    setFilter(object);
  };

  const validateFilter = (filter?: IFilter) => {
    if (filter) {
      const items = Object.keys(filter);
      for (const e of items) {
        if (filter[e as keyof IFilter].length) {
          return true;
        }
      }
    }
    return false;
  };

  const handleClickItem = (name: string, index: number) => {
    setFilter((prev) => {
      const newPrev = structuredClone(prev);
      newPrev &&
        (typeof newPrev[name as keyof IFilter] === 'string'
          ? (newPrev[name as keyof IFilter] = [])
          : newPrev[name as keyof IFilter].splice(index, 1));

      return newPrev;
    });
  };

  const validateName = (
    name: string,
    el: number | string | string[] | number[]
  ) => {
    return name === 'manager_ids'
      ? PM?.filter((pm) => pm.id == el)[0]?.full_name
      : name === 'member_ids'
      ? HR?.filter((hr) => hr.id == el)[0]?.full_name
      : el;
  };

  const filterTags = () => {
    if (filter) {
      return Object.keys(filter).map(
        (name) =>
          name !== 'name' &&
          (typeof filter[name as keyof IFilter] == 'string'
            ? [filter[name as keyof IFilter]]
            : filter[name as keyof IFilter]
          ).map((el, index) => (
            <Button
              onClick={() => handleClickItem(name, index)}
              key={Math.random()}
              className={styles.rootSelectedBtn}>
              <p>{validateName(name, el)}</p>
              <PlusIcon />
            </Button>
          ))
      );
    }
    return;
  };

  const resetFilter = () => {
    setFilter({
      project_services: [],
      manager_ids: [],
      client_names: [],
      product_industries: [],
      member_ids: [],
    });
  };

  return (
    <>
      <div className={styles.navContainer}>
        <div>
          <Button
            variant='text'
            onClick={handleSetStatus('')}
            className={clsx([
              styles.tabItem,
              { [styles.tabItemActive]: !params.project_statuses },
            ])}>
            <p
              className={clsx([
                { [styles.font500]: !params.project_statuses },
              ])}>
              All
            </p>
            <Badge
              color='default'
              className={clsx(styles.tabItemBadge, styles.all)}>
              {total &&
                total.find((i) => i.status_name.includes('All'))
                  ?.projects_count}
            </Badge>
          </Button>
          {statuses.map((item) => {
            const element =
              total &&
              total.find((i) => i.status_name.includes(item.name));
            return (
              element && (
                <Button
                  key={item.name}
                  variant='text'
                  onClick={handleSetStatus(item.name)}
                  className={clsx([
                    styles.tabItem,
                    {
                      [styles.tabItemActive]:
                        params.project_statuses === item.name,
                    },
                  ])}>
                  <p
                    className={clsx(
                      params.project_statuses === item.name &&
                        styles.font500
                    )}>
                    {item.name}
                  </p>
                  <Badge
                    color='default'
                    className={clsx(
                      styles.tabItemBadge,
                      styles[element.status_name]
                    )}>
                    {element.projects_count}
                  </Badge>
                </Button>
              )
            );
          })}
        </div>
        <div>
          <SearchInput
            value={searchValue}
            setValue={setSearchValue}
            placeholder='Search'
            disableBottomBorder
            disableRightBorder
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <QuickFilter
            filter={filters}
            ModalComponent={FilterForm}
            onClickDeleteFilter={handleDeleteItemInFilter}
            handleClickCurrentFilter={handleClickCurrentFilter}
            btnDotOn={validateFilter(filter)}
            additionalOptions={{ PM, HR, clientList }}
          />
          {PM && HR && validateFilter(filter) && (
            <Button
              onClick={resetFilter}
              className={styles.rootResetBtn}>
              Reset
            </Button>
          )}
          {PM && HR && filterTags()}
        </div>
        <Drawer
          headerIcon={<CreditCard />}
          anchor='right'
          open={showTableViewDrawer}
          classNames={styles.rootDrawer}
          onClose={handleShowTableViewDrawer}
          title='View Option'>
          <ViewOptionDrawer />
        </Drawer>
      </div>
    </>
  );
};
