import { FC } from 'react';
import styles from './styles.module.css';
import { IMemberProject } from '../../../../../../services/projectsApi/types';
import fallbackImg from '../../../../../../assets/icons/movadex.png';
import { ImageFallback } from '../../../../../ui/ImageFallback/ImageFallback';
interface IProfileTeamMembers {
  item: IMemberProject;
}
export const ProfileTeamMembers: FC<IProfileTeamMembers> = ({
  item,
}) => {
  return (
    <div className={styles.profileWrapper}>
      <div className={styles.wrapperProfileIcon}>
        <ImageFallback
          className={styles.profileIcon}
          src={item.user?.avatar_url}
          fallbackSrc={fallbackImg}
        />
      </div>
      <div className={styles.profileInfoWrapper}>
        <p className={styles.profileUserName}>
          {item.user?.full_name}
        </p>
        <a
          href={`mailto:${item.user?.email_corporate}`}
          className={styles.profileEmail}>
          {item.user?.email_corporate}
        </a>
        <p className={styles.profileRole}>{item.position_name}</p>
        <div className={styles.profileWrapperTech}>
          {item.departments.map((item, index) => (
            <p key={index} className={styles.profileTechItem}>
              {item.name}
            </p>
          ))}
        </div>
        {item.user?.hr_manager?.full_name && (
          <div className={styles.wrapperAdditionalCard}>
            <p className={styles.profileAdditionalTitle}>
              HR Manager
            </p>
            <div className={styles.containerAdditionalCard}>
              <img src={item.user?.hr_manager?.avatar_url} />
              <p>{item.user?.hr_manager?.full_name}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
