import clsx from 'clsx';
import {forwardRef} from 'react';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import Typography from '../Typography';

import {InputProps} from './Input.model';

import styles from './Input.module.css';
import {Emoji} from 'emoji-picker-react';

const Input = forwardRef<HTMLInputElement, InputProps>((
    {
        label,
        margin,
        component,
        inputClassName,
        showOptions,
        fullWidth,
        error,
        EndIcon,
        StartIcon,
        inputImage,
        inputEmoji,
        type = 'text',
        className,
        onClick,
        mutedLabel,
        required,
        subTitle,
        boldLabel,
        ...props
    }, ref) => {
    const Component = component || 'input';
    const classNames = clsx(
        styles.wrap,
        className,
        {
            [styles.margin]: margin,
            [styles.inputImage]: inputImage,
            [styles.fullWidth]: fullWidth,
        },
    );

    const activeClass = clsx(styles.endIcon, showOptions && styles.endIconActive);

    return (
        <>
            {label &&
              <InputLabel marginSm muted={mutedLabel}>
                <span className={clsx(boldLabel && styles.bold)}>{label}</span>
                  {required && <span className={styles.required}>*</span>}
              </InputLabel>
            }
            {subTitle &&
              <Typography variant={'mediumLabel'} className={`mBottom4 ${styles.subTitle}`}>
                  {subTitle}
              </Typography>
            }
            <div className={classNames} onClick={onClick}>
                {inputImage && <img src={inputImage} alt={inputImage} className={styles.inputImages}/>}
                {inputEmoji && <div className={styles.startIcon}>
                                           <Emoji unified={inputEmoji} size={24}/>
                                            </div> }
                {StartIcon && <div className={styles.startIcon}>{StartIcon}</div>}
                <Component
                    ref={ref}
                    type={type}
                    aria-label="input"
                    className={clsx(styles.root, inputClassName, {
                        [styles.error]: error,
                        [styles.withAdornments]: EndIcon,
                        [styles.startIconContainer]: StartIcon,
                        [styles.startIconContainer]: inputEmoji,
                    })}
                    {...props}
                />
                {EndIcon && <div className={activeClass}>{EndIcon}</div>}
            </div>
            {
                error && <InputError>{error}</InputError>
            }
        </>
    );
});

export default Input;
