import styles from './NothingWasFound.module.css';
import { ReactComponent as NothingFoundIcon } from '../../../../../../assets/image/nothing-found.svg';

import { ReactComponent as Plus } from '../../../../../../assets/icons/plus.svg';
import { FC, ReactNode } from 'react';
import Button from '../../../../../ui/Button';

interface INothingWasFound {
  icon?: ReactNode;
  title?: string;
  subTitle?: string;
  onClickBtn?: () => void;
  btnTitle?: string;
}
export const NothingWasFound: FC<INothingWasFound> = ({
  icon,
  title = '🧭 Nothing was found',
  subTitle = 'There are no transactions with this data were found. Try again',
  onClickBtn,
  btnTitle,
}) => {
  return (
    <div className={styles.root}>
      {icon || <NothingFoundIcon />}
      <p className={styles.title}>{title}</p>
      <span className={styles.subTitle}>{subTitle}</span>
      {btnTitle && (
        <div className={styles.btnWrapper}>
          <Button onClick={onClickBtn} color='black'>
            <Plus className={styles.iconStyle} />
            <p className={styles.textBtn}>{btnTitle}</p>
          </Button>
        </div>
      )}
    </div>
  );
};
