import { useSelector } from 'react-redux';
import { CRUDList } from '../../../ProfileAdditionalInfo/Section/CRUDList/CRUDList';
import { getProjectProfileSelector } from '../../../../../../store/project/selectors';
import { FC, useEffect, useState } from 'react';
import { IMemberProject } from '../../../../../../services/projectsApi/types';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import { projectsApi } from '../../../../../../services/projectsApi/projectsApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopRight } from '../../../../../ui/Snackbar/constants';
import { ListTeamMembers } from './ListTeamMembers';
import { AddTeamMembers } from './AddTeamMembers';
import { ProfileTeamMembers } from './ProfileTeamMembers';
import { useGetQueryParams } from '../../../../../../utils/common';

interface IWrapperCRUDList {
  onClose: () => void;
}
type typeDisplays = 'add' | 'edit' | 'not' | 'profile' | 'loading';

export const WrapperCRUDList: FC<IWrapperCRUDList> = ({
  onClose,
}) => {
  const params: { department?: string; userId?: number } =
    useGetQueryParams();
  const profileData = useSelector(getProjectProfileSelector);

  const [list, setList] = useState<IMemberProject[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [render, setRender] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const debouncedValue = useDebounce<string>(searchValue, 400);
  const getList = async (
    id: number,
    func?: (data: IMemberProject[]) => void
  ) => {
    try {
      setIsLoading(true);
      const res = await projectsApi.getProjectMember({
        project_id: id,
        search: debouncedValue,
      });
      setList(res);
      if (params?.userId && rightSectionView === 'loading') {
        console.log(params, res);
        setCurrentItem(
          res.filter((e) => e.user.id == params.userId)[0]
        );
        setRightSectionView('profile');
      }
      if (func) func(res);
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar('Error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    profileData?.id && getList(profileData?.id);
  }, [debouncedValue, render]);

  const filter = (searchValue: string, array: any) => {
    return array;
  };

  const handleRemoveMember = async (
    data: IMemberProject,
    setData: React.Dispatch<React.SetStateAction<typeDisplays>>
  ) => {
    try {
      await projectsApi.deleteProjectMember(data.id);
      profileData?.id && (await getList(profileData?.id));
      setData('add');
      enqueueSnackbar('Success removed', {
        variant: 'success',
        anchorOrigin: snackbarPosTopRight,
      });
    } catch (e) {
      enqueueSnackbar('Error', {
        variant: 'error',
        anchorOrigin: snackbarPosTopRight,
      });
    }
  };

  const [rightSectionView, setRightSectionView] =
    useState<typeDisplays>(params.userId ? 'loading' : 'add');

  const [currentItem, setCurrentItem] = useState<any | undefined>(
    undefined
  );
  return (
    <CRUDList
      title='Team Members'
      addTitle='Add new team member'
      editTitle=''
      list={list}
      onClose={onClose}
      ListComponent={ListTeamMembers}
      AddComponent={AddTeamMembers}
      EditComponent={AddTeamMembers}
      ProfileComponent={ProfileTeamMembers}
      titleProfileSaveButton='Remove from Members'
      setRender={setRender}
      disableEditHeader
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      onRemoveItem={handleRemoveMember}
      rightSectionView={rightSectionView}
      setRightSectionView={setRightSectionView}
      setCurrentItem={setCurrentItem}
      currentItem={currentItem}
    />
  );
};
