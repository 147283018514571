import moment from 'moment';
import { FC } from 'react';

import TableCell from '../../../../ui/TableCell';
import TableRow from '../../../../ui/TableRow';
import Typography from '../../../../ui/Typography';
import IconButton from '../../../../ui/IconButton';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/trash-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg';

import styles from './TableRow.module.css';
import { IPublicHoliday } from '../../../../../services/policyApi/types';

interface PublicHolidayTableRow {
  data: IPublicHoliday;
  handleEditItem: (e: IPublicHoliday) => void;
  handleRemoveItem: (e: IPublicHoliday) => void;
}
export const PublicHolidayTableRow: FC<PublicHolidayTableRow> = ({
  data,
  handleEditItem,
  handleRemoveItem,
}) => {
  return (
    <TableRow hover className={styles.holidayRow}>
      <TableCell>
        <Typography variant={'largeLabel'} color={'inherit'}>
          {moment.utc(data.date_from).format('D MMM YYYY').toString()}{' '}
          {data.date_from !== data.date_to &&
            `- ${moment
              .utc(data.date_to)
              .format('D MMM YYYY')
              .toString()}`}
        </Typography>
      </TableCell>
      <TableCell columnClass={styles.contentMaxWidth}>
        <Typography
          variant={'mediumLabel'}
          color={'inherit'}
          weight={'regular'}>
          {data.name}
        </Typography>
      </TableCell>
      <TableCell align='right' columnClass={styles.closeIcon}>
        <IconButton
          size='small'
          onClick={() => handleEditItem(data)}
          icon={PencilIcon}
          className={styles.editIcon}
        />
        <IconButton
          size='small'
          onClick={() => handleRemoveItem(data)}
          icon={CloseIcon}
          className={styles.trashIcon}
        />
      </TableCell>
    </TableRow>
  );
};
