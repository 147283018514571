import React, {useEffect, useState} from 'react';
import styles from './Leave.module.css';
import clsx from 'clsx';
import {ReactComponent as ArrowLeft} from '../../../assets/icons/arrow-left.svg'
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-right.svg'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close-modal.svg';
import {ReactComponent as Cat} from '../../../assets/icons/cat.svg';
import moment, {Moment} from 'moment';
import Switch from '../../ui/Switch';
import {CustomSelect} from '../../ui/CustomSelect/CustomSelect';
import Table from './LeaveTable/Table';
import {hrApi} from '../../../services/hrApi/hrApi';
import {commonApi} from '../../../services/commonApi/commonApi';
import {optionType} from '../../ui/CustomSelect/useSelect';
import Default from '../../Default';

interface ILeaveTableModal {
    setOpen: any;
}

const LeaveType = [
    {name: 'All'},
    {name: 'Paid'},
    {name: 'Self-paid'},
];

const LeaveTableModal: React.FC<ILeaveTableModal> = (props) => {
    const {setOpen} = props;
    const [loading, setLoading] = useState<boolean>(true)
    const [type, setType] = useState<any>({name: 'All'});
    const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));
    const [endDate, setEndDate] = useState<Moment>(moment().endOf('month'));
    const [viewAllUser, setViewAllUser] = useState<boolean>(true);
    const [data, setData] = useState<any>(null);
    const [users, setUsers] = useState<any>(null);
    const [selectedOptions, setSelectedOptions] = useState<any>([]); // Initialize as an empty array
    const handleClose = () => setOpen(false);

    const handleChangeMonth = (increment: number) => {
        const newStartDate = startDate.clone().add(increment, 'months').startOf('month');
        setStartDate(newStartDate);
        setEndDate(newStartDate.clone().endOf('month'));
    };

    const getUsers = async () => {
        const res = await commonApi.getUsers();
        setUsers(res);
    };

    useEffect(() => {
        getUsers?.();
    }, []);

    useEffect(() => {
        const getList = async () => {
            try {
                const res = await hrApi.getLeaveSummary({
                    month: startDate.format('MM'),
                    year: endDate.format('YYYY'),
                    include_all_users: !viewAllUser,
                    ids: selectedOptions.join(',') || null, // Directly map to IDs
                    is_self_paid: type.name === 'All' ? null : (type.name !== 'Paid'),
                });
                setData(res);
                setLoading(false)
            } catch (e) {
                console.log(e);
            }
        };

        getList?.(); // Call getList directly

    }, [startDate, endDate, viewAllUser, selectedOptions, type]);

    const handleChangeSelectData = (filter: any) => {
        const selectedIds = filter.map((e: { id: any }) => e.id);
        setSelectedOptions(selectedIds);
    };

    return (
        <div>
            <div className={styles.leaveTableModalHead}>
                <span>Leave Summary</span>
                <span onClick={handleClose}><CloseIcon/></span>
            </div>
            <div className={styles.leaveTableModalFilters}>
                <div className={clsx(styles.clickerLeft, styles.clickerLeftModal)}>
                    <ArrowLeft onClick={() => handleChangeMonth(-1)}/>
                    <span>{endDate.format('MMM YYYY')} </span>
                    <ArrowRight onClick={() => handleChangeMonth(1)}/>
                </div>
                <CustomSelect
                    multiSearch
                    rootClassName={styles.leaveTableModalHeadSelect}
                    multiSearchPlaceholder="Type to search"
                    detailedInputStyle={styles.customInput}
                    disableAllSubTitles
                    defaultAllSelected
                    detailedInput
                    limitedViewOption
                    // customTitle={`Members ${ids.length > 0 ? `(${ids.length})` : '(All)'}`}
                    defaultOptions={users?.filter((e: any) => selectedOptions?.includes(e.id)).map((e: any) => ({
                        name: e.full_name,
                        id: e.id,
                        label: e.full_name,
                    }))}
                    options={users?.map((e: any) => ({
                        name: e.full_name,
                        id: e.id,
                        label: e.full_name,
                    }))}
                    onChangeMultiValue={(selectedOptions) => {
                        const selectedUsers = selectedOptions.map((option: optionType) => users.find((user: any) => user.full_name === option.name));
                        handleChangeSelectData(selectedUsers);
                    }}
                    multi
                    placeholder="Members"
                    multiAllTitle="All Members"
                />
                <CustomSelect
                    detailedInputStyle={styles.customInput}
                    disableAllSubTitles
                    rootClassName={styles.leaveTableModalHeadSelect}
                    customTitle={'Leave Type'}
                    defaultOptions={type ? [{name: type}] : undefined}
                    options={LeaveType}
                    value={type}
                    onChangeValue={(e) => setType(e)}
                    placeholder="Leave Type"
                />
                <div className={styles.clickerLeftCheckbox}>
                    Show only those on leave
                    <Switch checked={viewAllUser} onChange={() => setViewAllUser((prev) => !prev)}/>
                </div>
            </div>
            {!loading ?
                <div className={styles.leaveTableInner}>
                    {data?.length ?
                        <Table data={data}/> :
                        <div className={styles.leaveTableInnerNoData}>
                            <Cat/>
                            <span>No time off based on the selected criteria</span>
                        </div>}
                </div> : <Default/>}
        </div>
    );
};

export default LeaveTableModal;
