import { useEffect } from 'react';

import HumanResourcesTable from './components/Table';
import { useAppDispatch } from '../../../store';
import { getFilters } from '../../../store/humanResources/thunks';

import styles from './HumanResourcesPage.module.css';

const HumanResourcesPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getFilters());
  }, []);

  return (
    <div className={styles.root}>
      <HumanResourcesTable />
    </div>
  );
};

export default HumanResourcesPage;
