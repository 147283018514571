import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBaseQuery } from '../ApiBaseQuery';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: ApiBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getProject: builder.query({
      query: (data) => ({
        url: '',
        method: 'get',
        data,
        params: data.params,
      }),
      transformResponse: (response) => {
        return response;
      },
      keepUnusedDataFor: 1,
    }),
    editProject: builder.mutation({
      query: (data: any) => ({
        url: '/project',
        method: 'put',
        data: data,
      }),
    }),
    createProject: builder.mutation({
      query: (data: any) => ({
        url: '/project',
        method: 'post',
        data: data,
        params: {},
      }),
    }),
    deleteProject: builder.mutation({
      query: (id: number) => ({
        url: `/project?project_id=${id}`,
        method: 'delete',
      }),
    }),
    getProjectsList: builder.query({
      query: (data: any) => ({
        url: '/projects_list',
        method: 'get',
        data: data,
      }),
    }),
    getProjectMembers: builder.query({
      query: (data) => ({
        url: '/project/members',
        method: 'get',
        data,
        params: data.params,
      }),
      keepUnusedDataFor: 1,
    }),
    createProjectMembers: builder.mutation({
      query: (data) => ({
        url: '/project/members',
        method: 'post',
        data,
        params: data.params,
      }),
    }),
    deleteProjectMembers: builder.mutation({
      query: (data) => ({
        url: '/project/members',
        method: 'delete',
        data,
        params: data.params,
      }),
    }),
    getProjectTags: builder.query({
      query: (data) => ({
        url: '/project/tags',
        method: 'get',
        data,
        params: data.params,
      }),
    }),
  }),
});

export const {
  useGetProjectQuery,
  useCreateProjectMutation,
  useEditProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsListQuery,
  useGetProjectMembersQuery,
  useCreateProjectMembersMutation,
  useDeleteProjectMembersMutation,
  useGetProjectTagsQuery,
} = projectApi;
