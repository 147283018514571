import React from 'react';

import { ReactComponent as SpinnerIcon } from '../../assets/icons/spinner.svg';

import classes from './Default.module.css';

const Default = () => {
  return (
    <div className={classes.root}>
      <SpinnerIcon className={classes.spinner}/>
    </div>
  );
};

export default Default;
