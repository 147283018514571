import { FC } from 'react';

import { CustomSelect } from '../../ui/CustomSelect/CustomSelect';
import FormikInput from '../../ui/FormikInput';
import Button from '../../ui/Button';
import DateRangePicker from '../../ui/DateRangePicker/DateRangePicker';

import styles from './EmployeeEquipmentDrawer.module.css';
import { IEquipmentsItem } from '../../../services/hrApi/types';
import { Formik } from 'formik';
import { hrApi } from '../../../services/hrApi/hrApi';
import { equipmentOwn } from '../../../common/values/values';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopLeft } from '../../ui/Snackbar/constants';
import FormikTextArea from '../../ui/FormikTextArea';
import { employeeEquipmentDrawerError } from './employeeEquipmentDeawerError';

interface IEmployeeEquipmentDrawer {
  userId: number;
  isEdit?: boolean;
  data?: IEquipmentsItem;
  updateTable: () => void;
  handleClose: () => void;
}

const EmployeeEquipmentDrawer: FC<IEmployeeEquipmentDrawer> = ({
  userId,
  isEdit,
  data,
  updateTable,
  handleClose,
}) => {
  const initialValues = {
    user_id: userId,
    name: data?.name || '',
    own_type: data?.own_type ? data?.own_type : undefined,
    date_from: data?.date_from || undefined,
    date_to: data?.date_to || undefined,
    comment: data?.comment || '',
  };

  return (
    <div className={styles.body}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          const object: any = { ...values };
          object.date_from = moment(object.date_from).format(
            'YYYY-MM-DD'
          );
          object.date_to = object.date_to
            ? moment(object.date_to).format('YYYY-MM-DD')
            : object.date_from;

          const afterExecution = () => {
            updateTable();
            formikHelpers.resetForm();
            formikHelpers.validateForm();
            handleClose();
          };
          const fetchCreate = async () => {
            try {
              await hrApi.createEquipments(object);
              afterExecution();
              enqueueSnackbar('Successfully created', {
                variant: 'success',
                anchorOrigin: snackbarPosTopLeft,
              });
            } catch (e: any) {
              formikHelpers.setFieldError(
                'date_from',
                e.response.data.detail
              );
            }
          };
          const fetchEdit = async () => {
            try {
              await hrApi.editEquipments({
                ...object,
                equipment_id: data?.id,
              });
              afterExecution();
              enqueueSnackbar('Success edited', {
                variant: 'success',
                anchorOrigin: snackbarPosTopLeft,
              });
            } catch (e: any) {
              formikHelpers.setFieldError(
                'date_from',
                e.response.data.detail
              );
            }
          };

          isEdit
            ? await fetchEdit()
            : object?.own_type && (await fetchCreate());
        }}
        validationSchema={employeeEquipmentDrawerError}
        enableReinitialize
        isInitialValid={isEdit ? true : false}>
        {({
          values,
          setFieldValue,
          errors,
          submitForm,
          submitCount,
          isSubmitting,
          isValid,
          resetForm,
          setFormikState,
        }) => {
          const handleOnChangeDateRange = async (e: Date[]) => {
            if (e.length) {
              await setFieldValue('date_from', e[0]);

              if (e[1]) {
                return await setFieldValue('date_to', e[1]);
              }
              await setFieldValue('date_to', e[0]);
              return;
            }
            await setFieldValue('date_from', undefined);
            await setFieldValue('date_to', undefined);
          };

          const handleDeleteItem = async (id: number) => {
            try {
              await hrApi.deleteEquipments(id);
              updateTable();
              handleClose();
              resetForm();
            } catch (e: any) {
              console.log(e.response.data.detail);
            }
          };
          return (
            <div className={styles.form}>
              <div className={styles.contentWrapper}>
                <div className={styles.wrapper}>
                  <FormikInput
                    label='Name'
                    value={values.name}
                    onChange={(e) =>
                      setFieldValue('name', e.target.value)
                    }
                    error={errors.name}
                    placeholder={'Enter equipment name'}
                    wasRequest={Boolean(submitCount)}
                  />
                  <CustomSelect
                    label='Type'
                    defaultOptions={
                      values.own_type
                        ? [{ name: values.own_type }]
                        : undefined
                    }
                    onChangeValue={(e) =>
                      setFieldValue('own_type', e.name)
                    }
                    options={equipmentOwn}
                    detailedInput
                    error={errors.own_type}
                    placeholder='Choose equipment type'
                    wasRequest={Boolean(submitCount)}
                  />
                  <DateRangePicker
                    startDate={values.date_from}
                    endDate={values.date_to}
                    isInput
                    error={errors.date_from || errors.date_to}
                    onChange={handleOnChangeDateRange}
                    label='Period'
                    placeholder='Choose period'
                    wasRequest={Boolean(submitCount)}
                  />
                  <FormikTextArea
                    label={'Comment'}
                    placeholder={'Type here...'}
                    value={values.comment}
                    onChange={(e) =>
                      setFieldValue('comment', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={styles.bottomWrapper}>
                {isEdit && (
                  <Button
                    fullWidth
                    className={styles.deleteBtn}
                    onClick={() =>
                      data && handleDeleteItem(data?.id)
                    }>
                    Delete
                  </Button>
                )}
                <Button
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  color={'black'}
                  onClick={submitForm}>
                  Save
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmployeeEquipmentDrawer;
