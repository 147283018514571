import styles from './EmployeeRecentProjectsTable.module.css';
import TableRow from '../../ui/TableRow';
import TableCell from '../../ui/TableCell';
import Table from '../../ui/Table';
import EmployeeRecentProjectsRow from './EmployeeRecentProjectsRow/EmployeeRecentProjectsRow';
import { employeeRecentProjects } from '../../Projects/Profile/ProfileAdditionalInfo/mocks';
import { FC, useState } from 'react';
import { IListResponse } from '../../../services/projectsApi/types';
import { IRecentProject } from '../../../services/hrApi/types';

interface IEmployeeRecentProjectsTable {
  data: IRecentProject[];
}
const EmployeeRecentProjectsTable: FC<
  IEmployeeRecentProjectsTable
> = ({ data }) => {
  return (
    <div>
      <Table className={styles.table}>
        <thead>
          <TableRow className={styles.rootTableRow}>
            <TableCell component='th'>project/ Period</TableCell>
            <TableCell component='th'>Status</TableCell>
            <TableCell component='th'>Role</TableCell>
            <TableCell align='center' component='th'>
              INVOLVEMENT
            </TableCell>
          </TableRow>
        </thead>
        <tbody className={styles.tbody}>
          {data?.map((e, i) => (
            <EmployeeRecentProjectsRow data={e} key={i} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default EmployeeRecentProjectsTable;
