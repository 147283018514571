export const toHoursAndMinutes = (totalSeconds: number) => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
    const minutes = Math.floor(totalMinutes % 60).toString().padStart(2, '0');
    return hours  + ':' + minutes;
}


export const getTotalDays = (dateFrom: string, dateTo: string) => {
    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // milliseconds in a day

    // Calculate the difference in milliseconds
    const difference = endDate.getTime() - startDate.getTime();

    // Convert the difference to days and round it
    const totalDays = Math.round(difference / millisecondsPerDay);

    return (totalDays + 1) + ' day' + (totalDays !== 0 ? 's' : '') // Adding 1 to include both the start and end dates
}
