import { FC, memo } from 'react';

import styles from './TeamMembersModal.module.css';
import Modal from '../../../../ui/Modal/Modal';

import { IContact } from '../../ProfileAdditionalInfo/types';
import { WrapperCRUDList } from './components/WrapperCRUDList';

interface ILinksModal {
  isOpen: boolean;
  onClose: () => void;
}

export interface IList {
  list: IContact[] | [];
  onItemClick: (e: any) => void;
}

export const TeamMembersModal: FC<ILinksModal> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      className={styles.modal}
      classNameOverlay={styles.overlay}
      isOpen={isOpen}
      onClose={onClose}>
      <WrapperCRUDList onClose={onClose} />
    </Modal>
  );
};
