import { FC, memo, useEffect, useState } from 'react';
import { ProfileCardInfo } from './ProfileCardInfo/ProfileCardInfo';

// import { StatusComponent } from './ProfileCards/Status/Status';
import { ManagersComponent } from './ProfileCards/Managers/Managers';
import { CalendarComponent } from './ProfileCards/Calendar/Calendar';
import { DescriptionComponent } from './ProfileCards/Description/Description';

import styles from './ProfileInformation.module.css';
import { IProfileProjectResponse } from '../../../../services/projectsApi/types';
import { useSelects } from './useSelects';
import { ProfileMainInfo } from './ProfileMainInfo/ProfileMainInfo';
import { TagsComponent } from './ProfileCards/Tags/Tags';

interface Props {
  data: IProfileProjectResponse;
  handleEdit: () => void
}

const ProfileInformation: FC<Props> = ({ data,handleEdit }) => {
  const [profileInfo, setProfileInfo] = useState(data);

  useEffect(() => {
    setProfileInfo(data);
  }, [JSON.stringify(data)]);

  const {
    statusSelect,
    managersSelect,
    calendarStartDateSelect,
    calendarDueDateSelect,
    descriptionSelect,
    industrySelect,
    productSelect,
    methodologySelect,
    tagsSelect,
    fetchDescriptionOnBlur,
  } = useSelects(profileInfo, setProfileInfo);

  return (
    <div className={styles.container}>
      <ProfileMainInfo profileInfo={profileInfo} handleEdit={handleEdit} statusSelect={statusSelect}/>
      <div className={styles.grid}>
        <ProfileCardInfo
          onClickPlug={managersSelect.handleShowOptions}
          title='Project Managers'
          plugTitle='Assign Managers'
          component={
            profileInfo.managers?.length && (
              <ManagersComponent
                selectedItems={profileInfo.managers}
                onClick={managersSelect.handleShowOptions}
              />
            )
          }>
          {managersSelect.component}
        </ProfileCardInfo>
        {/*<div className={styles.divide} />*/}
        <ProfileCardInfo
          onClickPlug={calendarStartDateSelect.handleShowOptions}
          isCalendarIcon
          title='Project start date'
          plugTitle='Choose start date'
          component={
            (profileInfo.start_date ||
              profileInfo.start_date_comment) && (
              <CalendarComponent
                date={profileInfo.start_date}
                comment={profileInfo.start_date_comment}
                onClick={calendarStartDateSelect.handleShowOptions}
              />
            )
          }>
          {calendarStartDateSelect.component}
        </ProfileCardInfo>
        <ProfileCardInfo
          onClickPlug={calendarDueDateSelect.handleShowOptions}
          isCalendarIcon
          title='Project due date'
          plugTitle='Choose due date'
          component={
            (profileInfo.end_date ||
              profileInfo.end_date_comment) && (
              <CalendarComponent
                date={profileInfo.end_date}
                comment={profileInfo.end_date_comment}
                onClick={calendarDueDateSelect.handleShowOptions}
              />
            )
          }>
          {calendarDueDateSelect.component}
        </ProfileCardInfo>
        <ProfileCardInfo
          onClickPlug={industrySelect.handleShowOptions}
          title='Industry'
          plugTitle='Add Industry'
          component={
            profileInfo.product_industry && (
              <TagsComponent
                color='#2A303C'
                bgColor='#EDF2F7'
                onClick={industrySelect.handleShowOptions}
                selectedItems={[
                  { name: profileInfo.product_industry },
                ]}
              />
            )
          }>
          {industrySelect.component}
        </ProfileCardInfo>
        <ProfileCardInfo
          onClickPlug={productSelect.handleShowOptions}
          title='Product'
          plugTitle='Choose Product'
          component={
            profileInfo.product_type && (
              <TagsComponent
                color='#2A303C'
                bgColor='#EDF2F7'
                onClick={productSelect.handleShowOptions}
                selectedItems={[{ name: profileInfo.product_type }]}
              />
            )
          }>
          {productSelect.component}
        </ProfileCardInfo>
        <ProfileCardInfo
          onClickPlug={methodologySelect.handleShowOptions}
          title='Methodology'
          plugTitle='Choose Methodology'
          component={
            profileInfo.methodology && (
              <TagsComponent
                color='#2A303C'
                bgColor='#EDF2F7'
                onClick={methodologySelect.handleShowOptions}
                selectedItems={[{ name: profileInfo.methodology }]}
              />
            )
          }>
          {methodologySelect.component}
        </ProfileCardInfo>
        <ProfileCardInfo
          onClickPlug={tagsSelect.handleShowOptions}
          title='Tags'
          plugTitle='Add Tags'
          component={
            profileInfo.tags.length && (
              <TagsComponent
                color='#2A303C'
                bgColor='#EDF2F7'
                onClick={tagsSelect.handleShowOptions}
                selectedItems={profileInfo.tags}
              />
            )
          }>
          {tagsSelect.component}
        </ProfileCardInfo>
        <ProfileCardInfo
          onClickPlug={() => descriptionSelect.handleShowOptions}
          title='Description'
          plugTitle='Description'
          isDescription
          fullWidth
          component={
            <DescriptionComponent
              fullSize
              onBlur={fetchDescriptionOnBlur}
              isEditable={true}
              text={profileInfo.description || ''}
            />
          }
        />
      </div>
    </div>
  );
};

export default memo(
  ProfileInformation,
  (prev, next) =>
    JSON.stringify(prev.data) === JSON.stringify(next.data)
);
