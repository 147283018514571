import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import routes from '../../constants/routes';
import { useDebounce } from '../../hooks/useDebounce';
import { searchParamsToObject } from '../../helpers/object/searchParams-to-object.helper';
import { getList, getProposalCounts } from '../../store/proposal/thunks';
import { useAppDispatch } from '../../store';
import { getProposalsSelector } from '../../store/proposal/selectors';

import Badge from '../../components/ui/Badge';
import Button from '../../components/ui/Button';
import Default from '../../components/Default';
import Stack from '../../components/ui/Stack';
import Typography from '../../components/ui/Typography';
import ProposalsTable from '../../components/pages/Proposals/ProposalsTable';
import ProposalsEmpty from '../../components/pages/Proposals/ProposalsEmpty';
import { SearchInput } from '../../components/ui/SearchInput/SearchInput';

import { ReactComponent as Plus } from '../../assets/icons/plus.svg';

import styles from './Proposals.module.css';

export default function Proposals() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { list, counts, isLoading } = useSelector(getProposalsSelector);

  const [tab, setTab] = useState<string>('All');
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedValue = useDebounce<string>(searchValue, 400);
  const params = searchParamsToObject(searchParams);

  useEffect(() => {
    dispatch(getList(params));
    dispatch(getProposalCounts(params));
  }, [debouncedValue, JSON.stringify(params)]);

  useEffect(() => {
    setSearchParams({ size: '10', search: searchValue });
  }, [debouncedValue]);

  const handleSetTab = (status: string) => () => {
    setTab(status);
    if (status === 'All') {
      searchParams.delete('status');
      setSearchParams(searchParams.toString());
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        status
      });
    }
  };

  return (
    <div className={styles.root}>
      <Stack fullWidth direction="row" align="center" justify="between">
        <Typography variant="h5" weight="bold">
          Proposals
        </Typography>
        <Button
          color="black"
          component={Link}
          startIcon={Plus}
          to={routes.proposalsPage + '/create'}
        >
          Add Proposal
        </Button>
      </Stack>
      <Stack className={styles.tabs} align="center" justify="between">
        <div>
          {[
            { label: 'All', total: counts?.find((i) => i.status === 'All')?.count ?? 0 },
            { label: 'Pending', total: counts?.find((i) => i.status === 'Pending')?.count ?? 0 },
            { label: 'Accepted', total: counts?.find((i) => i.status === 'Accepted')?.count ?? 0 }
          ].map((item) => (
            <Button
              key={item.label}
              variant="text"
              className={clsx(styles.tabItem, tab === item.label && [styles.tabItemActive])}
              onClick={handleSetTab(item.label)}
            >
              {item.label}
              <Badge color="default">{item.total}</Badge>
            </Button>
          ))}
        </div>
        <div>
          <SearchInput
            value={searchValue}
            setValue={setSearchValue}
            placeholder="Search"
            disableBottomBorder
            disableRightBorder
          />
        </div>
      </Stack>
      {isLoading ? (
        <Default />
      ) : (
        list.total ? (
          <ProposalsTable list={list} />
        ) : (
          <ProposalsEmpty />
        )
      )}
    </div>
  );
}