import styles from '../AbsenceDays/AbsenceDays.module.css'
import Typography from '../ui/Typography'
import {AbsenceDaysTitleProps} from './AbsenceDaysTitleProps'

const AbsenceDaysTitle = ({title, secondColor, icon, onClick}: AbsenceDaysTitleProps) => {
    return (
        <div className={'row alignItemsCenter pointer'} onClick={onClick}>
            <div className={`center mRight8 ${styles.iconWrapper} ${secondColor}`}>{icon}</div>
            <Typography variant={'subHeading1'}>{title}</Typography>
        </div>
    )
}

export default AbsenceDaysTitle
