import React from 'react';
import Lottie from 'react-lottie';
import animationData from './thinking.json';

const Loader = ({className}: { className?: string }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className={className}>
            <Lottie
                options={defaultOptions}
                height={40}
                width={40}
            />
        </div>
    );
};

export default Loader;
