import React, {FC} from 'react';
import moment from 'moment';
import clsx from 'clsx';
import styles from './Calendar.module.css';
import {ReactComponent as PointerIcon} from '../../../assets/icons/pointer.svg';
import {
    monthNames,
    weeksDaysNames,
} from '../../../helpers/date/get-month-date.helper';
import {useCalendar} from './useCalendar';

interface ICalendar {
    date: Date;
    handlePrevMonthButtonClick: () => void;
    handleNextMonthButtonClick: () => void;
    monthData: [] | [{ notThisMonth?: true; date: Date }][] | [][];
    handleDayClick: (e: Date) => void;
    currentDate: string;
    isWeekend?: boolean;
    additionalStyles: (date: Date) => string | undefined;
    setDate?: React.Dispatch<React.SetStateAction<Date>>;
}

export const Calendar: FC<ICalendar> = ({
                                            date,
                                            handlePrevMonthButtonClick,
                                            handleNextMonthButtonClick,
                                            isWeekend,
                                            monthData,
                                            handleDayClick,
                                            currentDate,
                                            additionalStyles,
                                            setDate,
                                        }) => {
    const {
        handleShowMonths,
        handleChangeYear,
        handleShowYears,
        handleSwitchShowing,
        handleChangeMonth,
        listOfYears,
        showing,
    } = useCalendar(date, setDate);
    return (
        <>
            <div className={styles.containerTitle}>
                <div className={styles.wrapperTitle}>
                    <p className={styles.title}>
            <span
                onClick={handleShowMonths}
                className={styles.clickableText}>
              {moment(date).format('MMMM ')}
            </span>
                        <span
                            onClick={handleShowYears}
                            className={styles.clickableText}>
              {moment(date).format('YYYY')}
            </span>
                    </p>
                    <div
                        onClick={handleSwitchShowing}
                        className={styles.arrowBtnSmall}>
                        <PointerIcon/>
                    </div>
                </div>
                {showing === 'day' && (
                    <>
                        <div className={styles.containerButtons}>
                            <div
                                onClick={handlePrevMonthButtonClick}
                                className={styles.arrowBtn}>
                                <PointerIcon/>
                            </div>
                            <div
                                onClick={handleNextMonthButtonClick}
                                className={`${styles.arrowBtn} ${styles.arrowBtnRight}`}>
                                <PointerIcon/>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={styles.container}>
                {showing === 'year' && (
                    <div className={styles.gridYears}>
                        {listOfYears.map((e) => (
                            <div
                                onClick={() => handleChangeYear(e)}
                                className={clsx(
                                    styles.monthBox,
                                    new Date().getFullYear() === e &&
                                    styles.currentMonthBox,
                                    date.getFullYear() === e && styles.includedBox
                                )}
                                key={e}>
                                {e}
                            </div>
                        ))}
                    </div>
                )}
                {showing === 'month' && (
                    <div className={styles.grid}>
                        {monthNames.map((e, index) => (
                            <div
                                onClick={() => handleChangeMonth(index)}
                                className={clsx(
                                    styles.monthBox,
                                    new Date().getMonth() === index &&
                                    styles.currentMonthBox,
                                    date.getMonth() === index && styles.includedBox
                                )}
                                key={e}>
                                {e}
                            </div>
                        ))}
                    </div>
                )}

                {showing === 'day' && (
                    <table className={styles.table}>
                        <thead className={styles.weekDayContainer}>
                        <tr>
                            {weeksDaysNames.map((day, index) => (
                                <th className={styles.boxWeekDay} key={index}>
                                    {day}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {monthData.map((week, index) => (
                            <tr key={index} className='week'>
                                {week.map((dateInfo, index) => {
                                    const isWeekDay = [0, 6].includes(dateInfo.date.getDay()); // 0 is Sunday, 6 is Saturday
                                    return (
                                        <td
                                            onClick={() => handleDayClick(dateInfo.date)}
                                            className={clsx(
                                                styles.baseDayBox,
                                                currentDate === dateInfo.date.toDateString() &&
                                                styles.currentDayBox,
                                                styles.clickableBox,
                                                 (isWeekend && !isWeekDay) && additionalStyles(dateInfo.date),
                                                (isWeekend && isWeekDay) && styles.weekendDayBox // Add a style for weekend days
                                            )}
                                            key={index}>
                                            {dateInfo.date.getDate()}
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
};
