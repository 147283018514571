import { FC, useState } from 'react';
import clsx from 'clsx';
import { Emoji, EmojiStyle } from 'emoji-picker-react';

import TableCell from '../../../../ui/TableCell';
import TableRow from '../../../../ui/TableRow';
import { ReactComponent as ChevronDownIcon } from '../../../../../assets/icons/chevron-down.svg';
import { ImageFallback } from '../../../../ui/ImageFallback/ImageFallback';
import { IWorkSummaryItem } from '../../../../../services/timeTrackingApi/types';
import { secondsToHM } from '../../../../../helpers/date/seconds-to-hm.helper';

import styles from './WorkSummaryRow.module.css';

interface IWorkSummaryRow {
  data: IWorkSummaryItem;
  showByProject: boolean;
}
export const WorkSummaryRow: FC<IWorkSummaryRow> = ({
  data,
  showByProject,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleChangeState = () => {
    setShowInfo((prev) => !prev);
  };

  const btnShowStyles = clsx(
    styles.btnShow,
    showInfo && styles.btnShowOn
  );

  return (
    <>
      <TableRow
        hover
        onClick={handleChangeState}
        className={styles.tBodyRow}>
        <td>
          <div className={btnShowStyles}>
            <ChevronDownIcon />
          </div>
        </td>
        <TableCell>
          <div className={styles.mainInfo}>
            {data.emoji ? (
              <div
                style={{ backgroundColor: data.color }}
                className={styles.emojiWrapper}>
                <Emoji
                  unified={data.emoji}
                  emojiStyle={EmojiStyle.APPLE}
                  size={20}
                />
              </div>
            ) : (
              <ImageFallback src={data.avatar_url} className={styles.mainInfoImage} />
            )}
            {showByProject ? (
              <span>
                {data.name}
                {data.product_type && (
                  <>
                    <div className={styles.dot} />
                    <span>{data.product_type}</span>
                  </>
                )}
              </span>
            ) : (
              <span>
                {data.first_name} {data.last_name}
              </span>
            )}
          </div>
        </TableCell>
        <td align='left' className={styles.containerTime}>
          <p>{secondsToHM(data.time)}</p>
        </td>
      </TableRow>
      {data.items && (
        <tr className={styles.containerTr}>
          <td colSpan={3} className={styles.wrapperTable}>
            <table className={styles.additionalTable}>
              {data.items.map((el, index) => (
                <TableRow
                  key={index}
                  className={clsx(
                    styles.additionalContainer,
                    styles.tBodyRowAdditional,
                    showInfo && styles.tBodyRowAdditionalOn
                  )}>
                  <td className={styles.plug}>
                    <div />
                  </td>
                  <TableCell>
                    <div
                      className={clsx(
                        styles.mainInfo,
                        styles.mainInfoAdditional,
                        el.emoji && styles.mainInfoAdditionalDisable
                      )}>
                      {el.emoji ? (
                        <div
                          style={{ backgroundColor: el.color }}
                          className={styles.emojiWrapper}>
                          <Emoji
                            unified={el.emoji}
                            emojiStyle={EmojiStyle.APPLE}
                            size={20}
                          />
                        </div>
                      ) : (
                        <ImageFallback src={el.avatar_url} />
                      )}
                      {!showByProject ? (
                        <span>
                          {el.name}
                          {el.product_type && (
                            <>
                              <div className={styles.dot} />
                              <p>{el.product_type}</p>
                            </>
                          )}
                        </span>
                      ) : (
                        <span>
                          {el.first_name} {el.last_name}
                        </span>
                      )}
                    </div>
                  </TableCell>
                  <td align='left' className={styles.containerTime}>
                    <p>{secondsToHM(el.time)}</p>
                  </td>
                </TableRow>
              ))}
            </table>
          </td>
        </tr>
      )}
    </>
  );
};
