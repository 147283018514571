import React, {useEffect, useState} from 'react';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import {ProfileBreadCrumb} from '../../components/Projects/Profile/ProfileBreadCrumb/ProfileBreadCrumb';
import defaultAvatar from '../../assets/icons/movadex.png';
import routes from '../../constants/routes';
import Button from '../../components/ui/Button';
import {useParams} from 'react-router-dom';
import {benchApi} from '../../services/benchApi';
import {hrApi} from '../../services/hrApi/hrApi';
import {ReactComponent as BenchIcon} from '../../assets/icons/menu/BenchIcon.svg'
import {ReactComponent as Puzzle} from '../../assets/image/puzzle.svg'
import {ReactComponent as Calendar} from '../../assets/icons/calendarIcons.svg'
import styles from './Bench.module.css';
import Typography from '../../components/ui/Typography';
import HistoryTable from '../../components/Bench/HistoryTable/HistoryTable';
import Modal from '../../components/ui/Modal';
import Default from '../../components/Default';
import {BenchStatus} from '../../components/Bench/BenchStatus/BenchStatus';
import clsx from 'clsx';
import {IBench, IStatus} from './types';
import {IHRListParams} from '../../services/hrApi/types';
import {useGetQueryParams} from '../../utils/common';
import {BenchModal} from '../../components/Bench/Modal/BenchModal';
import TableHeader from '../../components/Bench/TableHeader/TableHeader';


export interface IModalInfo {
    open: boolean | any,
    type: string
}

export interface IFilters {
    date_from: string,
    date_to: string,
    action: any[],
    created_by?: any[]
}

type RequestData = {
    sort_by: string | undefined;
    sort_by_type: string | undefined;
    action: any[];
    created_by: any[];
    date_from?: string; // Make date_from optional
    date_to?: string;   // Make date_to optional
};

const BenchMember: React.FC = () => {
    const {id} = useParams();
    const params: IHRListParams = useGetQueryParams();
    const [modal, setModal] = useState<IModalInfo | null>(null);
    const [filters, setFilters] = useState<IFilters>({date_from: '', date_to: '', action: [], created_by: []});
    const [userData, setUserData] = useState<any | null>(null);
    const [benchStatus, setBenchStatus] = useState<IStatus | null>(null);
    const [benchHistoryData, setBenchHistoryData] = useState<IBench | null>(null);
    const activeStatus = benchStatus?.statusArray.find(e => e.active);

    const handelClick = (type: string) => {
        setModal({open: true, type: type});
    };

    const fetchData = async () => {
        try {
            const user = await hrApi.getMembersProfile({user_id: Number(id)});
            const status = await benchApi.getBenchStatus(id);
            setUserData(user);
            setBenchStatus({statusArray: status});
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchHistoryData = async () => {
        try {
            const requestData: RequestData = {
                sort_by: params.sort_by,
                sort_by_type: params.sort_by_type,
                action: filters.action.map(e => e.name) || [],
                created_by: filters.created_by?.map(e => e.id) || [],
            };
            if (filters.date_from) {
                requestData.date_from = filters.date_from;
            }
            if (filters.date_to) {
                requestData.date_to = filters.date_to;
            }
            const res = await benchApi.getBenchMember(id, requestData);
            setBenchHistoryData(res);
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    };


    useEffect(() => {
        fetchData?.();
    }, [id]);

    useEffect(() => {
        fetchHistoryData?.();
    }, [params.sort_by, params.sort_by_type, filters.date_from, filters.date_to, filters.action, filters.created_by]);

    return (
        <>
            {(benchHistoryData && benchStatus) ? (
                <div className={styles.root}>
                    <div className={styles.topContentWrapper}>
                        <Breadcrumbs
                            itemComponent={
                                <ProfileBreadCrumb
                                    isLastIndex
                                    key={userData?.id}
                                    url={userData?.avatar_url || defaultAvatar}
                                    title={userData?.full_name}
                                />
                            }
                            indexItemComponent={1}
                            routeName={`Bench$(${routes.bench}?page=1&sort_by_type=asc&sort_by=user_full_name)/Profile`}
                        />
                        <div className={styles.profileWrapperRight}>
                            {(activeStatus?.type !== 'Bench-0' || activeStatus?.action === 'Removed') &&
                              <Button color='black' className={styles.button} onClick={() => handelClick('move')}>
                                <BenchIcon/>
                                <p>Move
                                  To {activeStatus?.type === 'Bench-50' ? (activeStatus?.action === 'Removed' ? 'Bench-50' : 'Bench-0') : 'Bench-50'} </p>
                              </Button>
                            }
                            {((activeStatus?.type === 'Bench-50' && activeStatus?.action !== 'Removed') || (activeStatus?.type === 'Bench-0' && activeStatus?.action === 'Moved') || (activeStatus?.type === 'Bench-0' && activeStatus?.action === 'Removed' && !activeStatus?.active)) &&
                              <Button color={'default'} className={styles.buttonDefault}
                                      onClick={() => handelClick('prolong')}>
                                <Calendar/>
                                Prolong {activeStatus?.type}
                              </Button>}
                            {((activeStatus?.type === 'Bench-50' && activeStatus?.action !== 'Removed') || (activeStatus?.type === 'Bench-0' && activeStatus?.action === 'Moved') || (activeStatus?.type === 'Bench-0' && activeStatus?.action === 'Removed' && !activeStatus?.active)) &&
                              <Button className={styles.buttonError} onClick={() => handelClick('end')}>
                                <p>End bench</p>
                              </Button>}

                        </div>
                    </div>
                    <div className={styles.benchStatus}>
                        <div className={styles.header}>
                            <Typography className={styles.title}>Bench Status</Typography>
                            <span>
                                {(benchStatus && benchStatus?.statusArray.length === 0 || activeStatus && activeStatus.action === 'Removed') ?
                                    <span className={styles.notBench}>Not on bench</span> :
                                    <span className={clsx(
                                        activeStatus?.type === 'Bench-50' && styles.benchHalf,
                                        activeStatus?.type === 'Bench-0' && styles.bench)}>{'On ' + activeStatus?.type}</span>
                                }
                            </span>
                        </div>
                        {(benchStatus?.statusArray && activeStatus && benchStatus.statusArray.length > 0 && (activeStatus && activeStatus.action !== 'Removed')) &&
                          <BenchStatus data={benchStatus.statusArray}/>
                        }
                    </div>
                    <div className={styles.benchHistory}>
                        <Typography className={styles.title}>Bench History</Typography>
                        <TableHeader params={params} filters={filters} setFilters={setFilters}/>
                        {benchHistoryData.items.length > 0 ? (
                            <HistoryTable list={benchHistoryData.items}/>
                        ) : (
                            <div className={styles.emptyTable}>
                                <Puzzle/>
                                <span>No bench history found</span>
                                <span>Double-check filter parameters above if we’re missing something</span>
                            </div>
                        )}
                    </div>
                    <Modal isOpen={modal?.open}>
                        <BenchModal open={modal} activeStatus={activeStatus} setOpen={setModal} userData={userData}
                                    fetchHistoryData={fetchHistoryData}
                                    fetchData={fetchData}/>
                    </Modal>
                </div>
            ) : (
                <Default/>
            )}
        </>
    );
};

export default BenchMember;
