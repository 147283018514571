import { FC } from 'react';

import styles from './LinkCard.module.css';
import { linkType } from '../../../types';

import { ReactComponent as DefaultIcon } from '../../../../../../../assets/icons/favicons/default-link.svg';
import { ReactComponent as FigmaIcon } from '../../../../../../../assets/icons/favicons/figma.svg';
import { ReactComponent as GitHubIcon } from '../../../../../../../assets/icons/favicons/github.svg';
import { ReactComponent as SlackIcon } from '../../../../../../../assets/icons/favicons/slack.svg';
import { getDomainFromURL } from '../../../../../../../helpers/url/get-domain-from-url.helper';
import { ILinksItem } from '../../../../../../../services/projectsApi/types';

interface ILinkCard {
  link: ILinksItem;
}

type linksTypes = 'slack.com' | 'www.figma.com' | 'github.com';

export const LinkCard: FC<ILinkCard> = ({ link }) => {
  const icons = {
    'slack.com': <SlackIcon />,
    'www.figma.com': <FigmaIcon />,
    'github.com': <GitHubIcon />,
  };
  const value = getDomainFromURL(link.url) as linksTypes;

  return (
    <a href={link.url} className={styles.card}>
      <div className={styles.icon}>
        {icons[value] || <DefaultIcon />}
      </div>
      <p className={styles.cardTitle}>{link.name}</p>
    </a>
  );
};
