import { FC, useState } from 'react';
import clsx from 'clsx';

import styles from './WorkSchedule.module.css';
import Button from '../Button/Button';
import FormikInput from '../FormikInput';

interface IWordSchedule {
  onSave?: (from: string, to: string) => void;
  initialFrom: string;
  initialTo: string;
}
export const WorkSchedule: FC<IWordSchedule> = ({
  onSave,
  initialFrom,
  initialTo,
}) => {
  type dayType = { name: string; id: number };
  const week = [
    { name: 'Monday', id: 0 },
    { name: 'Tuesday', id: 1 },
    { name: 'Wednesday', id: 2 },
    { name: 'Thursday', id: 3 },
    { name: 'Friday', id: 4 },
    { name: 'Saturday', id: 5 },
    { name: 'Sunday', id: 6 },
  ];

  const [range, setRange] = useState<dayType[]>([
    { name: 'Monday', id: 0 },
    { name: 'Friday', id: 4 },
  ]);

  const [to, setTo] = useState<string | undefined>(
    initialTo?.slice(0, 5)
  );
  const [from, setFrom] = useState<string | undefined>(
    initialFrom?.slice(0, 5)
  );

  const handleClick = (day: dayType) => {
    const isAvailable = range.find(
      (rangeDay) => rangeDay.id === day.id
    );
    if (isAvailable) {
      setRange((prev) => {
        prev.splice(range[0].id === day.id ? 0 : 1, 1);
        return [...prev];
      });
      return;
    }
    if (!range.length) setRange([day]);

    const firstDay = range[0];
    const secondDay = day;
    setRange((prev) =>
      range[0]
        ? firstDay && firstDay.id < secondDay.id
          ? [prev[0], day]
          : [day, prev[0]]
        : [day]
    );
  };

  const includedInRange = (
    day: dayType,
    range: dayType[],
    include?: boolean
  ) => {
    if (range.length) {
      if (day.id === range[0].id)
        return clsx(
          styles.selectedDay,
          styles.includedBoxRight,
          !range[1] && styles.round
        );
      if (day.id === range[1]?.id)
        return clsx(styles.selectedDay, styles.includedBoxLeft);
      if (include && range[0].id < day.id && day.id < range[1]?.id)
        return styles.includedBoxCenter;
    }
  };

  const handleClear = () => {
    // setRange([]);
    setTo('');
    setFrom('');
  };

  const handleSave = () => {
    const sorted = range.sort((a, b) => a.id - b.id);
    const reqText = `${sorted[0].name}$${from}&${sorted[1].name}$${to}`;
    onSave && from && to && onSave(from, to);
  };

  const parseTime = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    let value = e.target.value;
    if (value.length === 1 && Number(value[0]) > 2) {
      value = '0' + value[0];
    }
    if (value.length === 2 && Number(value) > 23) {
      value = '23';
    }
    if (value.length === 4 && Number(value[3]) > 5) {
      const item = value.split('');
      item.splice(3, 1, '5');
      value = item.join('');
    }
    setState(value);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Days</p>
      <table className={styles.containerDays}>
        <tbody>
          <tr>
            {week.map((day) => (
              <td
                onClick={() => handleClick(day)}
                key={day.id}
                className={clsx(
                  styles.containerDay,
                  includedInRange(day, range, true)
                )}>
                <p className={styles.dayText}>{day.name[0]}</p>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <p className={styles.title}>Time</p>
      <div className={styles.containerInputs}>
        <div>
          <FormikInput
            label='From'
            value={from}
            className={styles.input}
            mask='hh:mm'
            placeholder='hh:mm'
            regex={/[^0-9]/g}
            onChange={(e) => parseTime(e, setFrom)}
          />
        </div>
        <div>
          <FormikInput
            label='To'
            value={to}
            regex={/[^0-9]/}
            className={styles.input}
            mask='HH:MM'
            placeholder='hh:mm'
            onChange={(e) => parseTime(e, setTo)}
          />
        </div>
      </div>
      <div className={styles.containerButtons}>
        <Button
          onClick={handleClear}
          variant='outlined'
          className={`${styles.outlinedBtn} ${styles.baseBtn}`}>
          Сlear
        </Button>
        <Button
          disabled={!(to?.length === 5) || !(from?.length === 5)}
          onClick={handleSave}
          className={styles.baseBtn}
          color='black'>
          Save
        </Button>
      </div>
    </div>
  );
};
