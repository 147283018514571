import Typography from '../../../components/ui/Typography';
import styles from './Holidays.module.css';
import { PublicHolidaysTable } from '../../../components/Policy/PublicHolidays/Table/Table';
import NoHolidays from '../../../components/Policy/NoHolidays';
import Button from '../../../components/ui/Button';

import { ReactComponent as Plus } from '../../../assets/icons/plusWhite.svg';
import Default from '../../../components/Default';
import Drawer from '../../../components/ui/Drawer/Drawer';
import AddHoliday from '../../../components/Policy/Drawers/AddHoliday/AddHoliday';

import { ReactComponent as ChartLine } from '../../../assets/icons/chart-line.svg';
import { useHolidays } from './useHolidays';

const Holidays = () => {
  const {
    list,
    loading,
    setRender,
    openDrawer,
    handleCloseDrawer,
    handleOpenDrawer,
  } = useHolidays();

  if (loading) return <Default />;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>
          <Typography variant='h5' weight='bold'>
            Public Holidays
          </Typography>
          {list.length ? (
            <Button
              onClick={handleOpenDrawer}
              startIcon={Plus}
              color='black'>
              Add Public Holiday
            </Button>
          ) : undefined}
        </div>
        {!list.length ? (
          <NoHolidays openModal={handleOpenDrawer} />
        ) : (
          <PublicHolidaysTable setRender={setRender} data={list} />
        )}
        <Drawer
          classNames={styles.drawerWrapper}
          headerIcon={<ChartLine />}
          anchor='right'
          open={openDrawer}
          onClose={handleCloseDrawer}
          title='Add Public Holiday'>
          <AddHoliday
            setRender={setRender}
            closeDrawer={handleCloseDrawer}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default Holidays;
