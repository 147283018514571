import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';

const getHumanResources = (state: RootState) =>
  state.humanResources.list;
export const getHumanResourcesSelector = createSelector(
  getHumanResources,
  (list) => list
);

const getHR = (state: RootState) => state.humanResources;

const getTotalAll = (state: RootState) =>
  state.humanResources.totalAll;

const getHRFilters = (state: RootState) =>
  state.humanResources.filters;

export const getTotalAllSelector = createSelector(
  getTotalAll,
  (totalAll) => totalAll
);

export const getHRFiltersSelector = createSelector(
  getHRFilters,
  (filters) => filters
);

const getTotalByDepartments = (state: RootState) =>
  state.humanResources.totalByDepartments;

export const getTotalByDepartmentsSelector = createSelector(
  getTotalByDepartments,
  (totalByDepartments) => totalByDepartments
);

export const getHRSelector = createSelector(getHR, (hr) => hr);
