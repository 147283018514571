import DragAndDropComponent from '../DragAndDropComponent/DragAndDropComponent';
import { ImageUploaderProps } from '../ImageUploader/ImageUploaderProps';
import UploadedFile from './UploadedFile/UploadedFile';

interface CustomFile extends File {
  id: number;
}

const FileUploader = (
  {
    multiple = true,
    selectedFile = [],
    setSelectedFile,
    setDeleteFiles,
    deleteFiles,
    compact
  }: ImageUploaderProps) => {
  const files: CustomFile[] = [...selectedFile];

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (!multiple) {
        setSelectedFile([file]);
      } else {
        setSelectedFile((prev: any) => [...prev, file]);
      }
    }
  };

  const handleClickUploadField = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = multiple;
    fileInput.accept = 'image/*, .pdf, .doc, .txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    fileInput.onchange = handleFileInputChange;
    fileInput.click();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      if (!multiple) {
        setSelectedFile([file]);
      } else {
        setSelectedFile((prev: any) => [...prev, file]);
      }
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = selectedFile.filter((file: CustomFile) => {
      if ((file.id ? file.id !== index : file.lastModified !== index)) {
        return true;
      } else if (file.id) {
        setDeleteFiles([...deleteFiles, index]);
        return false;
      }
      return false;
    });

    setSelectedFile(updatedFiles);
  };

  return (
    <div>
      <DragAndDropComponent
        compact={compact}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleClickUploadField={handleClickUploadField}
      />
      <div>
        {selectedFile?.length !== 0 && files.map((file: CustomFile) => (
          <UploadedFile
            key={file.id}
            file={file}
            onDelete={() => handleDeleteFile(file.id ? file.id : file.lastModified)}
          />
        ))}
      </div>
    </div>
  );
};

export default FileUploader;
