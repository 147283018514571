import {createSlice} from '@reduxjs/toolkit';
import {timeTrackingApi} from './timeTrackingApi';
import {
    ITimeTrackingByDateProjectKey,
    ITimeTrackingByDateProjectMembersKey,
    ITimeTrackingByDateUserTotalKey, ITimeTrackingProjectUsersLength
} from '../../pages/TimeTracking/components';

export interface ITimeTrackingDays {
    date: string;
    user_id: number;
    id: number;
    user_name: string;
    project_id: number;
    project_name: string;
    time_sum: number;
    descriptions: string;
    day_total_time: number;
    avatar_url: any,
    // is_holiday: boolean;
    projects: ITimeTrackingByDateProjectKey,
    users_total: ITimeTrackingByDateUserTotalKey,
    members: ITimeTrackingByDateProjectMembersKey
}

export interface ITimeTrackingProjectUsers {
    [key: string]: ITimeTrackingProjectUsersLength;
}


export interface Department {
    name: string;
}

export interface Position {
    name: string;
}

export interface TechStack {
    name: string;
}

export interface ITimeTrackingUsers {
    id: number;
    first_name: string;
    last_name: string;
    avatar_url: string;
    seniority_level: string;
    departments: Department[];
    positions: Position[];
    tech_stacks: TechStack[];
}

export interface ITimeTrackingProjects {
    id: number;
    created_at: Date;
    name: string;
    emoji: string;
    project_types: string[];
    tech_stacks: TechStack[];
}

const initialState: {
    timeTrackingDays: Array<ITimeTrackingDays>;
    timeTrackingUsers: Array<ITimeTrackingUsers>;
    timeTrackingProjects: Array<ITimeTrackingProjects>;
} = {
    timeTrackingDays: [],
    timeTrackingUsers: [],
    timeTrackingProjects: [],
}

export const timeTrackingSlice = createSlice({
    name: 'timeTracking',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(timeTrackingApi.endpoints.getTimeTrackingDays.matchFulfilled, (state, {payload}) => {
            state.timeTrackingDays = payload;
        });
        builder.addMatcher(timeTrackingApi.endpoints.getTimeTrackingUsers.matchFulfilled, (state, {payload}) => {
            state.timeTrackingUsers = payload;
        });
        builder.addMatcher(timeTrackingApi.endpoints.getTimeTrackingProjects.matchFulfilled, (state, {payload}) => {
            state.timeTrackingProjects = payload;
        });
    }
});

export default timeTrackingSlice.reducer;
