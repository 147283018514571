import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Table.module.css';

export interface TableProps {
  variant?: 'bordered';
  className?: string;
  tableContainerClass?: string;
  children?: ReactNode | ReactNode[];
}

const Table = (
  {
    variant,
    className,
    children,
    tableContainerClass
  }: TableProps) => {
  const classNames = clsx(
    styles.root,
    variant && styles[variant],
    className,
  );

  return (
    <div className={clsx([styles.tableContainer, tableContainerClass])}>
      <table className={classNames}>
        {children}
      </table>
    </div>
  );
};

export default Table;
