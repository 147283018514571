import styles from './MultiSelect.module.css';
import { ReactComponent as DefaultIcon } from '../../Checkbox/assets/unchecked-icon.svg';
import { ReactComponent as DefaultCheckedIcon } from '../../Checkbox/assets/checked-icon.svg';
import { FC } from 'react';
import clsx from 'clsx';

interface IMultiSelectItem {
  checked: boolean;
  onChange?: () => void;
  title: string;
  marginLeft?: boolean;
  isAll?: boolean;
}
export const MultiSelectItem: FC<IMultiSelectItem> = ({
  checked,
  onChange,
  title,
  marginLeft,
  isAll,
}) => {
  const containerStyles = clsx(
    marginLeft && styles.marginLeft,
    styles.multiContainer
  );

  const titleStyles = clsx(styles.multiTitle, isAll && styles.isAll);
  return (
    <div onClick={onChange} className={containerStyles}>
      {checked ? <DefaultIcon /> : <DefaultCheckedIcon />}
      <p className={titleStyles}>{title}</p>
    </div>
  );
};
