import { FC, useState } from 'react';
import styles from './Links.module.css';
import { LinkCard } from './LinkCard/LinkCard';
import { ILinksItem } from '../../../../../../services/projectsApi/types';
import { LinksParser } from './linksParser';
import { ReactComponent as Arrow } from '../../../../../../assets/icons/arrow.svg';
import clsx from 'clsx';

interface ILinks {
  data: ILinksItem[];
}

export const Links: FC<ILinks> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
      <div className={styles.wrapper}>
        {LinksParser(data).map((el, index) => (
            <>

            <div key={index} className={styles.container} onClick={() => handleClick(index)}>
              <p className={styles.title}>{el.category}</p>

              <Arrow
                  className={clsx(styles.arrow, activeIndex === index && styles.arrowActive)}

              />
            </div>
              <div className={styles.gap}>
                {activeIndex === index && (
                    el.items.map((link: ILinksItem, itemIndex: number) => (
                        <LinkCard key={itemIndex} link={link} />
                    ))
                )}
              </div>
            </>

        ))}
      </div>
  );
};
