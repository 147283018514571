import AddTeamMemberButton from '../../../../AddTeamMemberButton';
import Stack from '../../../../ui/Stack';
import Typography from '../../../../ui/Typography';

import image from '../../../../../assets/image/hr-no-data.svg';

import styles from './Plug.module.css';
import { FC, ReactNode } from 'react';

interface IPlug {
  title?: string;
  subTitle?: string;
  btn?: ReactNode;
}
const Plug: FC<IPlug> = ({
  title = 'Oh no! UFO has stolen all team members',
  subTitle = 'Add a team and easily control days off, sick leave and more!',
  btn = <AddTeamMemberButton />,
}) => {
  return (
    <Stack
      space={4}
      direction='column'
      justify='center'
      align='center'
      className={styles.root}>
      <img src={image} width={220} height={220} alt='' />
      <Stack space={1} direction='column' align='center'>
        <Typography variant='subHeading1'>{title}</Typography>
        <Typography>{subTitle}</Typography>
      </Stack>
      {btn}
    </Stack>
  );
};

export default Plug;
