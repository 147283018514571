import routes from '../../constants/routes';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import styles from './EmployeeDocuments.module.css';
import Typography from '../../components/ui/Typography';
import { SearchInput } from '../../components/ui/SearchInput/SearchInput';
import { useGetDocumentsQuery } from '../../store/documents/documentsApi';
import EmployeeDocument from '../../components/EmployeeDocument/EmployeeDocument';
import { useEffect, useState } from 'react';
import {
  onSelectChangeHandler,
} from '../../helpers/functions/functions';
import DateRangePicker from '../../components/ui/DateRangePicker';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';
import { DateRange, ReqData } from './IDocuments';
import { ReactComponent as NothingFoundIcon } from '../../assets/image/hr-no-data.svg';
import { ProfileBreadCrumb } from '../../components/Projects/Profile/ProfileBreadCrumb/ProfileBreadCrumb';
import { useSelector } from 'react-redux';
import { getHRSelector } from '../../store/humanResources/selectors';
import Button from '../../components/ui/Button';
import { ReactComponent as UploadFile } from '../../assets/icons/upload_reverse.svg';
import defaultAvatar from '../../assets/icons/movadex.png';
import { hrApi } from '../../services/hrApi/hrApi';
import { enqueueSnackbar } from 'notistack';
import { snackbarPosTopLeft } from '../../components/ui/Snackbar/constants';
import { typeOfDocumentSortSelect } from '../../common/values/values';
import { CustomSelect } from '../../components/ui/CustomSelect/CustomSelect';

const EmployeeDocuments = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { membersProfile } = useSelector(getHRSelector);
  const [reqData, setReqData] = useState<ReqData>({
    user_id: id,
    search: '',
    sort_by: 'created_at',
  });
  const [dateRange, setDateRange] = useState<DateRange>({
    before_created_at: '',
    after_created_at: '',
  });
  const {
    data = [],
    // isFetching,
    refetch,
  } = useGetDocumentsQuery(reqData, {
    refetchOnMountOrArgChange: true,
  });
  const [documentsData, setDocumentsData] = useState(data);

  useEffect(() => {
    setDocumentsData(data);
  }, [data]);

  const onSearchHandler = (value: string) => {
    setReqData((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const onChangeDate = (date: Date[]) => {
    setDateRange((prev) => ({
      ...prev,
      after_created_at: date[0]
        ? moment(date[0]).format('YYYY-MM-DD')
        : '',
      before_created_at: date[1]
        ? moment(date[1]).format('YYYY-MM-DD')
        : '',
    }));
  };

  useEffect(() => {
    if (
      dateRange.before_created_at.length !== 0 &&
      dateRange.after_created_at.length !== 0
    ) {
      setReqData((prevState) => ({
        ...prevState,
        before_created_at: dateRange.before_created_at,
        after_created_at: dateRange.after_created_at,
      }));
    } else {
      setReqData((current) => {
        const { before_created_at, after_created_at, ...rest } =
          current;
        return rest;
      });
    }
  }, [dateRange]);

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const created_at = new Date().toISOString();
    const document_name = file.name;
    const document_size_mb = file.size / (1024 * 1024);

    const newFileObject = {
      created_at,
      document_name,
      document_size_mb,
    };
    document_size_mb < 10 &&
      setDocumentsData((prevData: any[]) => [
        newFileObject,
        ...prevData,
      ]);
    const formData = new FormData();
    formData.append('documents', file);
    if (document_size_mb < 10) {
      await hrApi.uploadFile(formData, id);
      enqueueSnackbar('File uploaded successfully', {
        variant: 'success',
        anchorOrigin: snackbarPosTopLeft,
        transitionDuration: 1000,
      });
      refetch();
    } else {
      refetch();
      enqueueSnackbar('The attachment is too large', {
        variant: 'error',
        anchorOrigin: snackbarPosTopLeft,
        transitionDuration: 1000,
      });
    }
  };

  const handleUploadDocument = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept =
      'image/*, .pdf, .png, .doc, .txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    fileInput.onchange = handleFileInputChange;
    fileInput.click();
  };

  const sorted = typeOfDocumentSortSelect.find(
    (e) => e.label === reqData.sort_by
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_header}>
        <Breadcrumbs
          itemComponent={
            membersProfile && (
              <ProfileBreadCrumb
                clickable={true}
                onClick={() =>
                  navigate(routes.employeeProfile + `/${id}`)
                }
                key={id + membersProfile.full_name}
                url={membersProfile.avatar_url || defaultAvatar}
                title={membersProfile.full_name}
              />
            )
          }
          indexItemComponent={
            membersProfile?.id == id ? 1 : undefined
          }
          routeName={`Human Resources$(${routes.humanResources})/Profile$(${routes.employeeProfile}/${id})/Documents`}
        />
        <Button
          onClick={handleUploadDocument}
          color='black'
          className={styles.buttonAdd}>
          <UploadFile className={styles.iconStyle} />
          <p>Upload Documents</p>
        </Button>
      </div>

      <div className={styles.documentsWrapper}>
        <div className={styles.documents}>
          <Typography variant={'subHeading1'}>Documents</Typography>
          <div className={styles.documentsTopBar}>
            <div className={styles.selectorsWrapper}>
              <DateRangePicker
                startDate={dateRange?.before_created_at || ''}
                endDate={dateRange?.after_created_at || ''}
                onChange={onChangeDate}
              />
              <div className={styles.selectorWrapper}>
                <CustomSelect
                  detailedInputStyle={styles.customInputShowBy}
                  options={typeOfDocumentSortSelect}
                  onChangeValue={(e) =>
                    onSelectChangeHandler(setReqData, 'sort_by', {
                      value: e.label,
                    })
                  }
                  defaultOptions={
                    sorted ? [{ name: sorted.name }] : undefined
                  }
                  detailedInput
                  disableAllSubTitles
                />
              </div>
            </div>
            <div className={styles.searchWrapper}>
              <SearchInput
                value={reqData.search || ''}
                setValue={onSearchHandler}
                placeholder={'Search'}
              />
            </div>
          </div>
          <div className={styles.documentsContainer}>
            <div className={styles.employeeDocumentWrapper}>
              {documentsData.length !== 0 ? (
                <EmployeeDocument
                  setData={setDocumentsData}
                  data={documentsData}
                  refetch={refetch}
                />
              ) : (
                <div className={styles.noContentWrapper}>
                  <div className={styles.noContentContainer}>
                    <NothingFoundIcon />
                    <Typography variant='subHeading1'>
                      Oh no! UFO has stolen all documents
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDocuments;
